import { useState } from "react"
import SideModal from "components/modals/SideModal"
import Scientist from "components/commanders/Scientist"
import Merchant from "components/commanders/Merchant"
import Military from "components/commanders/Military"
import Mercenary from "components/commanders/Mercenary"
import Player from "components/commanders/Player"
import mp3Toggle from "sounds/toggle.mp3"
import { useNFTConfig } from "data/queries/nft"
import {
  ImgCommander02,
  ImgCommander03,
  ImgCommander04,
  ImgCommander05,
} from "./Commanders"
import styles from "./CommandersControl.module.scss"
import { useTranslation } from "react-i18next"

const CommandersControl = () => {
  const soundToggle = new Audio(mp3Toggle)
  const { t } = useTranslation()
  const nftConfig = useNFTConfig()

  const [open, setOpen] = useState<boolean>(false)
  const [content, setContent] = useState<any>()
  const [commander, setCommander] = useState<number>(0)
  const [control, setControl] = useState<boolean>(false)
  const [c1Hover, setC1Hover] = useState<boolean>(false)
  const [c2Hover, setC2Hover] = useState<boolean>(false)
  const [c3Hover, setC3Hover] = useState<boolean>(false)
  const [c4Hover, setC4Hover] = useState<boolean>(false)
  const [c5Hover, setC5Hover] = useState<boolean>(false)

  function loadColonizerCommander() {
    if (nftConfig.data) {
      if (nftConfig.data.slot5) {
        return "/assets/commanders/nft/NFT" + nftConfig.data.slot5 + ".png"
      }
    }
    return "/assets/commanders/commander01.png"
  }

  return (
    <div
      onMouseOver={() => {
        setControl(true)
      }}
      onMouseOut={() => {
        setControl(false)
      }}
      className={control ? styles.controlOpen : styles.control}
    >
      <div className={styles.arco1}></div>
      <div className={styles.arco2}></div>
      <div className={styles.arco3}></div>
      <div className={styles.arco4}></div>
      <div className={styles.commander}>
        <div
          className={`${styles.commanderUnit} colonizerInfo`}
          onClick={() => {
            if (commander === 1) {
              setOpen(false)
              setCommander(0)
            } else {
              setContent(<Player />)
              setCommander(1)
              setOpen(true)
            }
            soundToggle.play()
          }}
        >
          <img
            className={commander === 1 ? styles.activeCommander : ""}
            src={loadColonizerCommander()}
            alt={"commander01"}
            draggable={false}
            onMouseOver={() => {
              setC1Hover(true)
            }}
            onMouseOut={() => {
              setC1Hover(false)
            }}
          />
          <div
            className={
              c1Hover || commander === 1 ? styles.commanderName : styles.hide
            }
          >
            {t("Colonizer")}
          </div>
        </div>
        <div
          className={`${styles.commanderUnit} scientistInfo`}
          onClick={() => {
            if (commander === 2) {
              setOpen(false)
              setCommander(0)
            } else {
              setContent(<Scientist />)
              setCommander(2)
              setOpen(true)
            }
            soundToggle.play()
          }}
        >
          <img
            className={commander === 2 ? styles.activeCommander : ""}
            src={ImgCommander02}
            alt={"commander02"}
            draggable={false}
            onMouseOver={() => {
              setC2Hover(true)
            }}
            onMouseOut={() => {
              setC2Hover(false)
            }}
          />
          <div
            className={
              c2Hover || commander === 2 ? styles.commanderName : styles.hide
            }
          >
            {t("Scientist")}
          </div>
        </div>
        <div
          className={`${styles.commanderUnit} militaryInfo`}
          onClick={() => {
            if (commander === 3) {
              setOpen(false)
              setCommander(0)
            } else {
              setContent(<Military />)
              setCommander(3)
              setOpen(true)
            }
            soundToggle.play()
          }}
        >
          <img
            className={commander === 3 ? styles.activeCommander : ""}
            src={ImgCommander03}
            alt={"commander03"}
            draggable={false}
            onMouseOver={() => {
              setC3Hover(true)
            }}
            onMouseOut={() => {
              setC3Hover(false)
            }}
          />
          <div
            className={
              c3Hover || commander === 3 ? styles.commanderName : styles.hide
            }
          >
            {t("Commandant")}
          </div>
        </div>
        <div
          className={`${styles.commanderUnit} marketInfo`}
          onClick={() => {
            if (commander === 4) {
              setOpen(false)
              setCommander(0)
            } else {
              setContent(<Merchant />)
              setCommander(4)
              setOpen(true)
            }
            soundToggle.play()
          }}
        >
          <img
            className={commander === 4 ? styles.activeCommander : ""}
            src={ImgCommander04}
            alt={"commander04"}
            draggable={false}
            onMouseOver={() => {
              setC4Hover(true)
            }}
            onMouseOut={() => {
              setC4Hover(false)
            }}
          />
          <div
            className={
              c4Hover || commander === 4 ? styles.commanderName : styles.hide
            }
          >
            {t("Merchant")}
          </div>
        </div>
        <div
          className={`${styles.commanderUnit} mercenaryInfo`}
          onClick={() => {
            if (commander === 5) {
              setOpen(false)
              setCommander(0)
            } else {
              setCommander(5)
              setContent(<Mercenary />)
              setOpen(true)
            }
            soundToggle.play()
          }}
        >
          <img
            className={commander === 5 ? styles.activeCommander : ""}
            src={ImgCommander05}
            alt={"commander05"}
            draggable={false}
            onMouseOver={() => {
              setC5Hover(true)
            }}
            onMouseOut={() => {
              setC5Hover(false)
            }}
          />
          <div
            className={
              c5Hover || commander === 5 ? styles.commanderName : styles.hide
            }
          >
            {t("Mercenary")}
          </div>
        </div>
      </div>
      <div className={open ? styles.elementBarClose : styles.elementBar} />
      <div
        className={
          open ? styles.elementBarComplementClose : styles.elementBarComplement
        }
      />
      <SideModal open={open} setOpen={setOpen} resetSelect={setCommander}>
        {content}
      </SideModal>
    </div>
  )
}

export default CommandersControl
