import { useState, useContext, useEffect, Fragment } from "react"
import { GameContext } from "pages/GameContext"
import { useTranslation } from "react-i18next"
import { UseQueryResult, useQueryClient } from "react-query"
import { queryKey } from "data/query"
import { usePlayer } from "data/queries/player"
import { TRITIUM_CONSTRUCTION_SPEED } from "config/constants"
import { pushBuilding } from "data/queries/build"
import { ScifiButton } from "components/general"
import { calcResourceRequirements, missingFunds } from "utils/requires"
import { checkFunds } from "utils/requires"
import { convertToClock } from "utils/time"
import { checkResearch } from "utils/requires"
import { registerLog } from "data/queries/log"
import { BuildKeys } from "data/builds"
import StructureItem from "./StructureItem"
import BuildModal from "components/modals/BuildModal"
import disableScroll from "disable-scroll"
import { Row, Col } from "components/layout"
import Warning from "./Warning"
import styles from "./Structures.module.scss"
import Build from "./Build"
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material"
import {
  useConstructionBuildSpeed,
  useDiscountBuild,
  useSpeedMining,
} from "utils/hooks/gameModeEffect"
import { calcSpeedConstructionBuild } from "utils/effect"

interface Props {
  planet: string
  selectedSlot: any
  modalBuild: any
  setModalBuild: any
  availableBuilds: UseQueryResult<Build[], unknown>
}

const Structures = (props: Props) => {
  const { t } = useTranslation()
  const { planet, selectedSlot, modalBuild, setModalBuild, availableBuilds } =
    props
  const { onBuilding, setOnBuilding, planetData, researcheds } =
    useContext(GameContext)
  const planetContext = planetData instanceof Array ? planetData[0] : planetData
  const player = usePlayer()
  const queryClient = useQueryClient()

  const [selectedBuild, setSelectedBuild] = useState<Build | null>(null)
  const [disableBuild, setDisableBuild] = useState<boolean>(false)

  const discountBuild = useDiscountBuild(planet)
  const speedMining = useSpeedMining(planet)
  const speedConstructionBuild = useConstructionBuildSpeed(planet)

  useEffect(() => {
    if (selectedSlot === 4) {
      if (availableBuilds && availableBuilds.data) {
        const shieldBuild = availableBuilds.data.find(
          (build) => build.code === BuildKeys.SHIELD
        )
        if (shieldBuild) {
          setSelectedBuild(shieldBuild)
          setDisableBuild(true)
        } else {
          setSelectedBuild(null)
          setDisableBuild(false)
        }
      }
    } else {
      setSelectedBuild(null)
      setDisableBuild(true)
    }
  }, [selectedSlot, availableBuilds])

  const handlerBuild = (id: string | undefined, code: Number, time: Number) => {
    if (id === undefined) return
    if (selectedSlot === 4 && code !== 12) return
    const building: Building = {
      code: code,
      build: id,
      planet: planet || "",
      slot: selectedSlot,
    }
    setOnBuilding(true)
    pushBuilding(building)
      .then(() => {
        setTimeout(() => queryClient.invalidateQueries(), 200)
        setTimeout(() => {
          queryClient.invalidateQueries()
          setOnBuilding(false)
        }, Number(time) * 1000 * calcSpeedConstructionBuild(TRITIUM_CONSTRUCTION_SPEED, speedConstructionBuild) + 200)
      })
      .catch((error) => {
        console.error("Erro ao iniciar a construção:", error)
      })
      .finally(() => {
        setDisableBuild(false)
        setModalBuild(false)
        disableScroll.on()
        setTimeout(() => {
          queryClient.invalidateQueries()
          setOnBuilding(false)
        }, Number(time) * 1000 * calcSpeedConstructionBuild(TRITIUM_CONSTRUCTION_SPEED, speedConstructionBuild) + 500)
      })
  }

  const getRequirements = (build: Build, level: number) => {
    return calcResourceRequirements(
      build,
      level,
      discountBuild,
      speedConstructionBuild
    )
  }

  const getMetal = (build: Build, level: number) => {
    const requirements = getRequirements(build, level)
    return requirements.metal
  }

  const getUranium = (build: Build, level: number) => {
    const requirements = getRequirements(build, level)
    return requirements.uranium
  }

  const getCrystal = (build: Build, level: number) => {
    const requirements = getRequirements(build, level)
    return requirements.crystal
  }

  const getTime = (build: Build, level: number) => {
    const requirements = getRequirements(build, level)
    return convertToClock(requirements.time * TRITIUM_CONSTRUCTION_SPEED)
  }

  const setBuildDefault = (): Build | null => {
    if (selectedBuild === null && availableBuilds.data) {
      const orderedCodes = [1, 2, 3, 4, 7, 6]
      const filteredBuilds = availableBuilds.data.filter((element) => {
        if (element.disable === undefined && planetContext) {
          return true
        }
        return false
      })
      const sortedBuilds = orderedCodes
        .map((code) => filteredBuilds.find((element) => element.code === code))
        .filter(Boolean)

      if (sortedBuilds.length > 0) {
        return sortedBuilds[0] as Build
      } else if (filteredBuilds.length > 0) {
        return filteredBuilds[0]
      } else if (availableBuilds.data.length > 0) {
        return availableBuilds.data[0]
      }
    }

    return null
  }

  const getTooltipMessage = (
    selectedBuild: Build,
    researcheds: Researched[]
  ) => {
    const checkFundsResults = missingFunds(
      selectedBuild,
      1,
      planetContext,
      discountBuild,
      speedMining,
      speedConstructionBuild
    )

    let messages: React.ReactNode[] = []

    let resourcesMessage: React.ReactNode[] = []
    if (checkFundsResults.length > 0) {
      const resourceImages: { [key: string]: string } = {
        Metal: "/assets/icons/resources/metal.png",
        Uranium: "/assets/icons/resources/uranium.png",
        Crystal: "/assets/icons/resources/crystal.png",
      }

      resourcesMessage = checkFundsResults.map((resource) => (
        <div>
          {t("Insufficient resources:")}{" "}
          <span key={resource}>
            {resource}
            <img
              src={resourceImages[resource]}
              width={35}
              alt={resource}
              style={{ marginLeft: "3px" }}
            />
          </span>
        </div>
      ))
    }

    type ResearchInfo = {
      name: string
      code: number
      icon: string
    }

    const researchMapping: { [key: string]: ResearchInfo } = {
      "5": {
        name: "Extraction",
        code: 1300,
        icon: "/assets/icons/research13.svg",
      },
      "6": {
        name: "Extraction",
        code: 1300,
        icon: "/assets/icons/research13.svg",
      },
      "8": {
        name: "Storage Center",
        code: 1800,
        icon: "/assets/icons/research18.svg",
      },
      "9": {
        name: "Space Mechanics",
        code: 300,
        icon: "/assets/icons/research3.svg",
      },
      "10": {
        name: "Battery",
        code: 2700,
        icon: "/assets/icons/research27.svg",
      },
      "11": {
        name: "Advanced Robotics",
        code: 200,
        icon: "/assets/icons/research2.svg",
      },
      "12": {
        name: "Energy Force Field",
        code: 100,
        icon: "/assets/icons/research1.svg",
      },
      "13": { name: "Trade", code: 1500, icon: "/assets/icons/research15.svg" },
      "14": {
        name: "Diplomacy",
        code: 400,
        icon: "/assets/icons/research4.svg",
      },
      "15": {
        name: "Tritium",
        code: 1400,
        icon: "/assets/icons/research14.svg",
      },
    }

    let researchMessage: React.ReactNode[] = []
    const researchInfo = researchMapping[selectedBuild.code]
    if (
      researchInfo &&
      !checkResearch(researcheds as unknown as Researched[], researchInfo.code)
    ) {
      researchMessage.push(
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}
        >
          <span style={{ marginRight: "8px" }}>
            {t("Missing research")} {researchInfo.name}
          </span>
          <img src={researchInfo.icon} width={25} alt={researchInfo.name} />
        </div>
      )
    }

    if (resourcesMessage.length > 0 || researchMessage.length > 0) {
      messages.push(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "auto",
          }}
        >
          {resourcesMessage.map((msg, index) => (
            <Fragment key={index}>
              {msg}
              {(index !== resourcesMessage.length - 1 ||
                researchMessage.length > 0) && (
                <div
                  style={{ position: "relative", top: "8px", margin: "0 5px" }}
                >
                  |
                </div>
              )}
            </Fragment>
          ))}

          {researchMessage.map((msg, index) => (
            <Fragment key={index}>
              {msg}
              {index !== researchMessage.length - 1 && (
                <div
                  style={{ position: "relative", top: "8px", margin: "0 5px" }}
                >
                  |
                </div>
              )}
            </Fragment>
          ))}
        </div>
      )
    }

    return messages
  }

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className, tooltip: "tooltip-class" }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      position: "relative",
      bottom: "60px",
      left: "-100px",
      backgroundColor: "#5850B41c",
      color: "#f1f1f1f1",
      borderRadius: "5px",
      border: "1px solid #5850B46c",
      padding: "5px",
      fontSize: "12px",
      fontWeight: "bold",
      width: "auto",
      maxWidth: "700px",
    },
  }))

  const tooltipMessage = selectedBuild
    ? getTooltipMessage(selectedBuild, researcheds || [])
    : []

  return (
    <BuildModal
      open={modalBuild}
      title={t("Structures")}
      onClose={() => {
        disableScroll.on()
        setModalBuild(false)
      }}
    >
      <Row>
        <Col>
          {availableBuilds.data && (
            <div>
              {Array.isArray(availableBuilds.data) &&
                availableBuilds.data.map((element) => (
                  <div key={element.id}>
                    <>
                      {selectedBuild === null &&
                        setSelectedBuild(setBuildDefault())}
                    </>
                    {selectedBuild !== null && (
                      <StructureItem
                        build={element}
                        selectedBuild={selectedBuild}
                        slot={selectedSlot}
                        reqResearch={
                          Number(element.code) === BuildKeys.URANIUMMINING
                            ? !checkResearch(researcheds, 1300)
                            : Number(element.code) === BuildKeys.CRYSTALMINING
                            ? !checkResearch(researcheds, 1300)
                            : Number(element.code) === BuildKeys.WAREHOUSE
                            ? !checkResearch(researcheds, 1800)
                            : Number(element.code) === BuildKeys.SHIPYARD
                            ? !checkResearch(researcheds, 300)
                            : Number(element.code) === BuildKeys.BATERYHOUSE
                            ? !checkResearch(researcheds, 2700)
                            : Number(element.code) === BuildKeys.MILITARYCAMP
                            ? !checkResearch(researcheds, 200)
                            : Number(element.code) === BuildKeys.SHIELD
                            ? !checkResearch(researcheds, 100)
                            : Number(element.code) === BuildKeys.MARKET
                            ? !checkResearch(researcheds, 1500)
                            : Number(element.code) === BuildKeys.GALACTICCOUNCIL
                            ? !checkResearch(researcheds, 400)
                            : Number(element.code) === BuildKeys.TRITIUMMINING
                            ? !checkResearch(researcheds, 1400)
                            : false
                        }
                        setDisableBuild={setDisableBuild}
                        setSelectedBuild={setSelectedBuild}
                      />
                    )}
                  </div>
                ))}
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <div className={styles.content}>
          {selectedBuild && (
            <>
              <div className={styles.buildShow}>
                <img
                  style={{
                    filter: disableBuild ? "grayscale(100%)" : "grayscale(0%)",
                  }}
                  src={`/assets/builds/${selectedBuild.image}`}
                  alt={selectedBuild.image}
                  draggable={false}
                />
              </div>
              <div className={styles.contentDescription}>
                <div className={styles.description}>
                  <span className={styles.buildTitle}>
                    {t(`${selectedBuild.name}`)}
                  </span>
                  <br />
                  <div>
                    <div
                      className={`${styles.buildDescription} descriptionTour`}
                    >
                      {t(`${selectedBuild.description}`)}
                    </div>
                    <div>
                      <div>{t("Requires")}</div>
                      <div>
                        <img
                          src="/assets/icons/resources/metal.png"
                          width={40}
                          alt={t("Metal")}
                        />
                        <span style={{ color: "#FFC100", fontWeight: 600 }}>
                          {Number(getMetal(selectedBuild, 1))}
                        </span>
                        <img
                          src="/assets/icons/resources/uranium.png"
                          width={40}
                          alt={t("Uranium")}
                        />
                        <span style={{ color: "#C806DD", fontWeight: 600 }}>
                          {Number(getUranium(selectedBuild, 1))}
                        </span>
                        <img
                          src="/assets/icons/resources/crystal.png"
                          width={40}
                          alt={t("Crystal")}
                        />
                        <span style={{ color: "#00A7F5", fontWeight: 600 }}>
                          {Number(getCrystal(selectedBuild, 1))}
                        </span>
                        <img
                          src="/assets/icons/resources/time.png"
                          width={40}
                          alt={t("Time")}
                        />
                        <span className={styles.resourceCount}>
                          {getTime(selectedBuild, 1)}
                        </span>
                      </div>
                      {(Number(selectedBuild.code) ===
                        BuildKeys.URANIUMMINING ||
                        Number(selectedBuild.code) ===
                          BuildKeys.CRYSTALMINING) && (
                        <>
                          <div>{t("Research: ")}</div>
                          <div>
                            <img
                              src="/assets/icons/research13.svg"
                              width={30}
                              alt={t("Extraction")}
                            />
                            <span className={styles.research}>
                              {t("Extraction")}
                            </span>
                          </div>
                        </>
                      )}
                      {Number(selectedBuild.code) === BuildKeys.WAREHOUSE && (
                        <>
                          <div>{t("Research: ")}</div>
                          <div>
                            <img
                              src="/assets/icons/research18.svg"
                              width={30}
                              alt={t("Storage Center")}
                            />
                            <span className={styles.research}>
                              {t("Storage Center")}
                            </span>
                          </div>
                        </>
                      )}
                      {Number(selectedBuild.code) === BuildKeys.SHIPYARD && (
                        <>
                          <div>{t("Research: ")}</div>
                          <div>
                            <img
                              src="/assets/icons/research3.svg"
                              width={30}
                              alt={t("Space Mechanics")}
                            />
                            <span className={styles.research}>
                              {t("Space Mechanics")}
                            </span>
                          </div>
                        </>
                      )}
                      {Number(selectedBuild.code) === BuildKeys.BATERYHOUSE && (
                        <>
                          <div>{t("Research: ")}</div>
                          <div>
                            <img
                              src="/assets/icons/research27.svg"
                              width={30}
                              alt={t("Battery")}
                            />
                            <span className={styles.research}>
                              {t("Battery")}
                            </span>
                          </div>
                        </>
                      )}
                      {Number(selectedBuild.code) ===
                        BuildKeys.MILITARYCAMP && (
                        <>
                          <div>{t("Research: ")}</div>
                          <div>
                            <img
                              src="/assets/icons/research2.svg"
                              width={30}
                              alt={t("Advanced Robotics")}
                            />
                            <span className={styles.research}>
                              {t("Advanced Robotics")}
                            </span>
                          </div>
                        </>
                      )}
                      {Number(selectedBuild.code) === BuildKeys.SHIELD && (
                        <>
                          <div>{t("Research: ")}</div>
                          <div>
                            <img
                              src="/assets/icons/research1.svg"
                              width={30}
                              alt={t("Energy Force Field")}
                            />
                            <span className={styles.research}>
                              {t("Energy Force Field")}
                            </span>
                          </div>
                        </>
                      )}
                      {Number(selectedBuild.code) === BuildKeys.MARKET && (
                        <>
                          <div>{t("Research: ")}</div>
                          <div>
                            <img
                              src="/assets/icons/research15.svg"
                              width={30}
                              alt={t("Trade")}
                            />
                            <span className={styles.research}>
                              {t("Trade")}
                            </span>
                          </div>
                        </>
                      )}
                      {Number(selectedBuild.code) ===
                        BuildKeys.GALACTICCOUNCIL && (
                        <>
                          <div>{t("Research: ")}</div>
                          <div>
                            <img
                              src="/assets/icons/research4.svg"
                              width={30}
                              alt={t("Diplomacy")}
                            />
                            <span className={styles.research}>
                              {t("Diplomacy")}
                            </span>
                          </div>
                        </>
                      )}
                      {Number(selectedBuild.code) ===
                        BuildKeys.TRITIUMMINING && (
                        <>
                          <div>{t("Research: ")}</div>
                          <div>
                            <img
                              src="/assets/icons/research14.svg"
                              width={30}
                              alt={t("Tritium")}
                            />
                            <span className={styles.research}>
                              {t("Tritium")}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    textAlign: "right",
                    marginTop: "8px",
                    position: "relative",
                    bottom: "10px",
                  }}
                >
                  {player.data && (
                    <>
                      {onBuilding && <Warning />}
                      <div>
                        {selectedBuild && tooltipMessage.length > 0 ? (
                          <StyledTooltip title={tooltipMessage}>
                            <div>
                              <ScifiButton
                                size="small"
                                onClick={() => {
                                  handlerBuild(
                                    selectedBuild.id,
                                    selectedBuild.code,
                                    1 // Time unit base
                                    //tempo de construcao = tempo de unidade * tempo speed da construcao em segundos (quanto maior o numero de speed mais lento)
                                    // Unit * TRITIUM_CONSTRUCTION_SPEED
                                  )
                                  registerLog({
                                    type: "build",
                                    text: "Construct " + selectedBuild.name,
                                  })
                                  queryClient.invalidateQueries(
                                    queryKey.tritium.logs.logs
                                  )
                                }}
                                disabled={
                                  onBuilding ||
                                  !checkFunds(
                                    selectedBuild,
                                    1,
                                    planetContext,
                                    discountBuild,
                                    speedMining,
                                    speedConstructionBuild
                                  ) ||
                                  disableBuild ||
                                  (Number(selectedBuild.code) ===
                                  BuildKeys.URANIUMMINING
                                    ? !checkResearch(researcheds, 1300)
                                    : false) ||
                                  (Number(selectedBuild.code) ===
                                  BuildKeys.CRYSTALMINING
                                    ? !checkResearch(researcheds, 1300)
                                    : false) ||
                                  (Number(selectedBuild.code) ===
                                  BuildKeys.WAREHOUSE
                                    ? !checkResearch(researcheds, 1800)
                                    : false) ||
                                  (Number(selectedBuild.code) ===
                                  BuildKeys.SHIPYARD
                                    ? !checkResearch(researcheds, 300)
                                    : false) ||
                                  (Number(selectedBuild.code) ===
                                  BuildKeys.BATERYHOUSE
                                    ? !checkResearch(researcheds, 2700)
                                    : false) ||
                                  (Number(selectedBuild.code) ===
                                  BuildKeys.MILITARYCAMP
                                    ? !checkResearch(researcheds, 200)
                                    : false) ||
                                  (Number(selectedBuild.code) ===
                                  BuildKeys.SHIELD
                                    ? !checkResearch(researcheds, 100)
                                    : false) ||
                                  (Number(selectedBuild.code) ===
                                  BuildKeys.MARKET
                                    ? !checkResearch(researcheds, 1500)
                                    : false) ||
                                  (Number(selectedBuild.code) ===
                                  BuildKeys.GALACTICCOUNCIL
                                    ? !checkResearch(researcheds, 400)
                                    : false) ||
                                  (Number(selectedBuild.code) ===
                                  BuildKeys.TRITIUMMINING
                                    ? !checkResearch(researcheds, 1400)
                                    : false)
                                }
                              >
                                {t("Build")}
                              </ScifiButton>
                            </div>
                          </StyledTooltip>
                        ) : (
                          <div className="buildButton">
                            <ScifiButton
                              size="small"
                              onClick={() => {
                                handlerBuild(
                                  selectedBuild.id,
                                  selectedBuild.code,
                                  1
                                )
                                registerLog({
                                  type: "build",
                                  text: "Construct " + selectedBuild.name,
                                })
                                queryClient.invalidateQueries(
                                  queryKey.tritium.logs.logs
                                )
                              }}
                              disabled={
                                onBuilding ||
                                !checkFunds(
                                  selectedBuild,
                                  1,
                                  planetContext,
                                  discountBuild,
                                  speedMining,
                                  speedConstructionBuild
                                ) ||
                                disableBuild ||
                                (Number(selectedBuild.code) ===
                                BuildKeys.URANIUMMINING
                                  ? !checkResearch(researcheds, 1300)
                                  : false) ||
                                (Number(selectedBuild.code) ===
                                BuildKeys.CRYSTALMINING
                                  ? !checkResearch(researcheds, 1300)
                                  : false) ||
                                (Number(selectedBuild.code) ===
                                BuildKeys.WAREHOUSE
                                  ? !checkResearch(researcheds, 1800)
                                  : false) ||
                                (Number(selectedBuild.code) ===
                                BuildKeys.SHIPYARD
                                  ? !checkResearch(researcheds, 300)
                                  : false) ||
                                (Number(selectedBuild.code) ===
                                BuildKeys.BATERYHOUSE
                                  ? !checkResearch(researcheds, 2700)
                                  : false) ||
                                (Number(selectedBuild.code) ===
                                BuildKeys.MILITARYCAMP
                                  ? !checkResearch(researcheds, 200)
                                  : false) ||
                                (Number(selectedBuild.code) === BuildKeys.SHIELD
                                  ? !checkResearch(researcheds, 100)
                                  : false) ||
                                (Number(selectedBuild.code) === BuildKeys.MARKET
                                  ? !checkResearch(researcheds, 1500)
                                  : false) ||
                                (Number(selectedBuild.code) ===
                                BuildKeys.GALACTICCOUNCIL
                                  ? !checkResearch(researcheds, 400)
                                  : false) ||
                                (Number(selectedBuild.code) ===
                                BuildKeys.TRITIUMMINING
                                  ? !checkResearch(researcheds, 1400)
                                  : false)
                              }
                            >
                              {t("Build")}
                            </ScifiButton>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Row>
    </BuildModal>
  )
}

export default Structures
