import { createContext, ReactNode, useState } from "react"
import { usePlayer } from "data/queries/player"
import { usePlanets } from "data/queries/planet"
import { useResearched } from "data/queries/research"
import { usePlanetById } from "data/queries/planet"
import { useQuadrant } from "data/queries/quadrant"
import { useParams } from "react-router-dom"

type PropsContext = {
  address: string
  player: Player | undefined
  researcheds: Researched[] | undefined
  planets: Planet[] | undefined
  planet: string
  planetData: Planet | undefined | never[]
  setPlanet: any
  onBuilding: boolean
  setOnBuilding: any
}

export const GameContext = createContext<PropsContext>({
  address: "",
  player: {
    id: "0",
    address: "",
    country: "",
    name: "",
    aliance: 0,
    ready: 0,
    score: 0,
    buildScore: 0,
    labScore: 0,
    tradeScore: 0,
    attackScore: 0,
    defenseScore: 0,
    warScore: 0,
    researchPoints: 0,
    tritium: 0,
    transportShips: 0,
  },
  researcheds: [],
  planets: [],
  planet: "",
  planetData: undefined,
  setPlanet: () => {},
  onBuilding: false,
  setOnBuilding: () => {},
})

const GameProvider = ({ children }: { children: ReactNode }) => {
  const address = "nowallet"
  const player = usePlayer()
  const planets = usePlanets()
  const params = useParams()
  const researcheds = useResearched()

  const [planet, setPlanet] = useState(params.planet || "0")
  const [onBuilding, setOnBuilding] = useState(false)

  localStorage.setItem("planet", params.planet || "0")

  const planetCurrentName = localStorage.getItem("planetName")

  if (planets.data) {
    if (planetCurrentName === null) {
      localStorage.setItem("planetName", planets.data[0].name)
      localStorage.setItem("quadrant", planets.data[0].quadrant)
      localStorage.setItem("planetId", planets.data[0].id)
    }
  }

  const quandrantStorage = localStorage.getItem("quadrant")
  const quadrant = useQuadrant(
    !quandrantStorage
      ? planets.data
        ? planets.data[0].quadrant
        : "A000"
      : quandrantStorage
  )

  localStorage.setItem(
    "quadrantName",
    quadrant.data?.name ? quadrant.data.name : "Loading..."
  )

  const planetData = usePlanetById(planet)

  return (
    <GameContext.Provider
      value={{
        address: address || "",
        player: player.data,
        researcheds: researcheds.data,
        planets: planets.data,
        planet: planet,
        planetData: planetData.data,
        setPlanet: { setPlanet },
        onBuilding: onBuilding,
        setOnBuilding: setOnBuilding,
      }}
    >
      {children}
    </GameContext.Provider>
  )
}

export default GameProvider
