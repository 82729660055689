import { useTranslation } from "react-i18next"
import styles from "./StrategyOptions.module.scss"

import { useStrategies } from "data/queries/combat"

import { Tooltip } from "@mui/material"

interface Props {
  setStrategy: any
  strategy: number
}

const StrategyOptions = (props: Props) => {
  const { t } = useTranslation()

  const strategies = useStrategies()

  return (
    <>
      <div style={{ padding: "4px" }}>{t("Select strategy")}</div>
      {strategies && strategies.data && strategies.data.length > 0 && (
        <div className={styles.strategyOptions}>
          <div>
            {strategies.data.map((strategy: any, index: number) => (
              <Tooltip key={index} title={strategy.name}>
                <div
                  className={
                    props.strategy === strategy.code
                      ? styles.strategyButtonSelected
                      : styles.strategyButton
                  }
                  key={index}
                  onClick={() => {
                    props.setStrategy(strategy.code)
                  }}
                >
                  <img
                    src={`/assets/combat/strategy/${strategy.image}`}
                    alt={strategy.name}
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default StrategyOptions
