import styles from "./Aliance.module.scss"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined"
import { cancelRequest, removeMember } from "data/queries/aliance"
import { Col, Row, Card } from "components/layout"
import { useTranslation } from "react-i18next"
import CancelIcon from "@mui/icons-material/Close"
import { useState, useEffect } from "react"

interface BackBarProp {
  changeScreen: (newScreen: number) => void
}
const BackBar = (BackBar: BackBarProp) => {
  return (
    <>
      <div className={styles.backbar}>
        <div className={styles.itensBackBar}>
          <span
            className={styles.backicon}
            onClick={() => BackBar.changeScreen(0)}
          >
            <ArrowBackIcon />
          </span>
        </div>
      </div>
      <div className={styles.divider} />
    </>
  )
}

interface PendingProp {
  changeScreen: (newScreen: number) => void
}
const InfoPending = (PendingProp: PendingProp) => {
  const { changeScreen } = PendingProp
  const { t } = useTranslation()
  const handleCancelRequest = async () => {
    await cancelRequest()
      .then((res) => changeScreen(0))
      .catch((er) => console.log("erro ao cancelar", er))
  }
  return (
    <>
      <Row>
        <Col>
          <Card>
            {t("You have a pending request")}
            <br />
            <br />
            <button
              className={styles.btnAliance}
              style={{ marginLeft: "20%" }}
              type="button"
              onClick={(e) => handleCancelRequest()}
            >
              <CancelIcon />
              {t("Cancel request")}
            </button>
          </Card>
        </Col>
      </Row>
    </>
  )
}
interface RankMember {
  idRank: number
}
const IconsRankMember = (RankMember: RankMember) => {
  const { idRank } = RankMember
  const [logoName, setLogoName] = useState("")
  const [alt, setAlt] = useState("")
  const getIconRankMember = () => {
    switch (idRank) {
      case 1:
        setLogoName("patentes_fundador.png")
        setAlt("Fundador")
        break
      case 2:
        setLogoName("patentes_general.png")
        setAlt("General")
        break
      case 3:
        setLogoName("patentes_capitao_de_frota.png")
        setAlt("Capitão de Frota")
        break
      case 4:
        setLogoName("patentes_capitao_de_tropa.png")
        setAlt("Capitão de tropa")
        break
      case 5:
        setLogoName("patentes_diplomata.png")
        setAlt("Diplomata")
        break
      case 6:
        setLogoName("patentes_cabo.png")
        setAlt("Cabo")
        break
      case 7:
        setLogoName("patentes_soldado.png")
        setAlt("Soldado")
        break
      default:
        setLogoName("")
        setAlt("")
    }
  }
  useEffect(() => {
    if (idRank) {
      getIconRankMember()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idRank])
  return (
    <>
      <img src={`/assets/rank/${logoName}`} width={20} alt={alt} />
    </>
  )
}

interface ActionRemoveMemberProps {
  memberId: number
  disabled: boolean | true
  founder: boolean | false
}
const ActionRemoveMember = (ActionRemoveMember: ActionRemoveMemberProps) => {
  const { memberId, disabled, founder } = ActionRemoveMember

  const handleRemoveMember = (id: number) => {
    if (!id || id <= 0) {
      alert("erro")
      return
    }
    removeMember(id)
      .then((res) => console.log("res", res))
      .catch((err) => console.log("erro ao remover ", err))
  }
  return (
    <button
      disabled={disabled}
      style={{ marginLeft: "10px" }}
      onClick={() => {
        if (!founder) handleRemoveMember(memberId)
      }}
    >
      <PersonRemoveOutlinedIcon fontSize={"small"} />
    </button>
  )
}

export { BackBar, ActionRemoveMember, InfoPending, IconsRankMember }
