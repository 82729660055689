import { Col, Row } from "components/layout"
import styles from "./BuildDescriptionModal.module.scss"
import { useTranslation } from "react-i18next"
import { PropsWithChildren } from "react"

interface Props {}

const LinkDetails = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation()

  return (
    <>
      <Row>
        <Col>
          <div>
            <div className={styles.specialTitleLinks}>{t("LINKS")}</div>
            <hr />
            <div className={styles.lineDetails}>
              <a
                href="https://stake.terratritium.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Stake")}
              </a>
              <br />
            </div>
            <div className={styles.lineDetails}>
              <a
                href="https://bridge.terratritium.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Bridge")}
              </a>
              <br />
            </div>
            <div className={styles.lineDetails}>
              <a
                href="https://rocket.terratritium.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Rocket")}
              </a>
              <br />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default LinkDetails
