import { useEffect, useState } from "react"
import { useFrame } from "@react-three/fiber"

interface Props {
  shipRef: any
}

const Ship = (props: Props) => {
  const [movement, setMovement] = useState({
    forward: false,
    backward: false,
    left: false,
    right: false,
  })
  const speed = 0.1

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "w":
        case "ArrowUp":
          setMovement((prevMovement) => ({ ...prevMovement, forward: true }))
          break
        case "s":
        case "ArrowDown":
          setMovement((prevMovement) => ({ ...prevMovement, backward: true }))
          break
        case "a":
        case "ArrowLeft":
          setMovement((prevMovement) => ({ ...prevMovement, left: true }))
          break
        case "d":
        case "ArrowRight":
          setMovement((prevMovement) => ({ ...prevMovement, right: true }))
          break
        default:
          break
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      switch (event.key) {
        case "w":
        case "ArrowUp":
          setMovement((prevMovement) => ({ ...prevMovement, forward: false }))
          break
        case "s":
        case "ArrowDown":
          setMovement((prevMovement) => ({ ...prevMovement, backward: false }))
          break
        case "a":
        case "ArrowLeft":
          setMovement((prevMovement) => ({ ...prevMovement, left: false }))
          break
        case "d":
        case "ArrowRight":
          setMovement((prevMovement) => ({ ...prevMovement, right: false }))
          break
        default:
          break
      }
    }

    document.addEventListener("keydown", handleKeyDown)
    document.addEventListener("keyup", handleKeyUp)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
      document.removeEventListener("keyup", handleKeyUp)
    }
  }, [])

  useFrame(({ camera }) => {
    const ship = props.shipRef.current
    if (ship) {
      if (movement.forward) camera.position.z -= speed
      if (movement.backward) camera.position.z += speed
      if (movement.left) ship.position.x += speed / 2
      if (movement.right) ship.position.x -= speed / 2

      if (movement.left) {
        props.shipRef.current.rotateZ(-0.005)
      }
      if (movement.right) {
        props.shipRef.current.rotateZ(+0.005)
      }
    }
  })

  return null
}

export default Ship
