import { ScifiModal } from "components/feedback"
import styles from "./BuildDescriptionModal.module.scss"
import { useTranslation } from "react-i18next"

interface BuildDescriptionModalProps {
  isOpen: boolean
  onRequestClose: () => void
  build: Build
}

export const BuildDescriptionModal = ({
  isOpen,
  onRequestClose,
  build,
}: BuildDescriptionModalProps) => {
  const { t } = useTranslation()
  return (
    <ScifiModal
      title={build.name}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <img
        src={`/assets/builds/${build.image}`}
        alt="Colonization"
        className={styles.buildImage}
        draggable={false}
      />

      <p className={styles.description}>{t(`${build.description}`)}</p>
    </ScifiModal>
  )
}
