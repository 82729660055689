export const calcConstBuild = (base: number, percent: number) => {
  return Math.floor(base + (base * percent) / 100)
}

export const calcBaseMining = (base: number, percent: number) => {
  return base + (base * percent) / 100
}

export const calcSpeedProduction = (base: number, percent: number) => {
  percent = percent * -1
  return Math.floor(base + (base * percent) / 100)
}

export const calcSpeedConstructionBuild = (base: number, percent: number) => {
  percent = percent * -1
  if (percent === 0) {
    return base
  }
  return Math.floor(base + (base * percent) / 100)
}

export const calcSpeedResearch = (base: number, percent: number) => {
  return base + (base * percent) / 100
}
