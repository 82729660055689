import { useEffect, useRef } from "react"
import mp3Open from "sounds/open.mp3"

const useSoundEffect = (isActive: boolean) => {
  const audioRef = useRef(new Audio(mp3Open))

  useEffect(() => {
    if (isActive) {
      const promisePlay = audioRef.current.play()

      if (promisePlay !== undefined) {
        promisePlay.then(() => {}).catch((error) => {})
      }
    } else {
      audioRef.current.pause()
    }
  }, [isActive])
}

export default useSoundEffect
