import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const usePlayersRanking = () => {
  return useQuery(
    [queryKey.tritium.ranking.players],
    async () => {
      const { data } = await api.get<PlayerRanking[]>(
        `/api/ranking/players/top`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const usePlayersRankingByCategory = (
  type: string,
  page: number,
  orderBy: string
) => {
  return useQuery(
    [queryKey.tritium.ranking.category, page],
    async () => {
      const { data } = await api.get<Ranking[]>(
        `/api/ranking/players?page=${page}&type=${type}&orderBy=${orderBy}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: 0, retry: false } }
  )
}

export const useAlliancesRanking = (
  type: string,
  page: number,
  orderBy: string
) => {
  return useQuery(
    [queryKey.tritium.ranking.alliances],
    async () => {
      // const { data } = await api.get(`/api/ranking/aliances/${type}`, {
      //   baseURL: TRITIUM,
      // })
      const { data } = await api.get(
        `/api/ranking/aliances?page=${page}&type=${type}&orderBy=${orderBy}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const getMyRanking = (planetId: string) => {
  return api.get<PlayerRanking>(`/api/ranking/my/${planetId}`)
}
