import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Flex, Row, Col } from "components/layout"
import { Select } from "components/form"
import ScfiButton from "components/general/ScifiButton"
import { getQuadrant } from "data/queries/quadrant"
import ScifiDiv from "components/general/ScifiDiv"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import mp3Toggle from "sounds/toggle.mp3"
import styles from "./SpaceNavigator.module.scss"

const SpaceNavigator = () => {
  const { t } = useTranslation()
  const soundToggle = new Audio(mp3Toggle)
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const [letter, setLetter] = useState<string>("0")
  const [number, setNumber] = useState<string>("000")
  const [quadrant, setQuadrant] = useState<any>({})

  const numberOptions = []

  for (let i = 0; i < 100; i++) {
    numberOptions.push(
      <option key={i} value={String(i).padStart(3, "0")}>
        {String(i).padStart(3, "0")}
      </option>
    )
  }

  /* useEffect(()=>{
    setDisableBtn(quadrant.name === undefined);
  },[quadrant])
*/

  return (
    <Flex>
      <div
        className={`${styles.open} spaceWarp`}
        onClick={() => {
          setOpen(!open)
          soundToggle.play()
        }}
      >
        {"Space Warp"}
      </div>
      <div>
        <SwipeableDrawer
          className="navigator"
          variant="persistent"
          PaperProps={{
            sx: {
              height: "140px",
              background: "#00000000",
              width: "550px",
              position: "asolute",
              left: `calc(50% - 275px)`,
            },
          }}
          anchor="bottom"
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          onOpen={() => {}}
        >
          <div className={styles.content}>
            <Row>
              <Col span={2}>
                <Flex>
                  <div className={styles.label}>Where will you jump?</div>
                </Flex>
              </Col>
              <Col>
                <Flex>
                  <div className={styles.label}>Destination</div>
                </Flex>
              </Col>
              <Col>.</Col>
            </Row>
            <Row>
              <Col span={2}>
                <div>
                  <ScifiDiv>
                    <Row>
                      <Col>
                        <Select
                          style={{ width: "60px" }}
                          className={styles.qLetter}
                          name="qLetter"
                          onChange={async (e) => {
                            setLetter(e.target.value)
                            const qData = await getQuadrant(
                              e.target.value + number
                            )
                            setQuadrant(qData.data)
                          }}
                        >
                          <option value="A">0</option>
                          <option value="A">A</option>
                          <option value="B">B</option>
                          <option value="C">C</option>
                          <option value="D">D</option>
                          <option value="E">E</option>
                          <option value="F">F</option>
                          <option value="G">G</option>
                          <option value="H">H</option>
                          <option value="I">I</option>
                          <option value="J">J</option>
                          <option value="K">K</option>
                          <option value="L">L</option>
                          <option value="M">M</option>
                          <option value="N">N</option>
                          <option value="O">O</option>
                          <option value="P">P</option>
                        </Select>
                      </Col>
                      <Col>
                        <Select
                          style={{ width: "74px" }}
                          onChange={async (e) => {
                            setNumber(e.target.value)
                            const qData = await getQuadrant(
                              letter + e.target.value
                            )
                            setQuadrant(qData.data)
                          }}
                          name="qNumber"
                        >
                          {numberOptions}
                        </Select>
                      </Col>
                    </Row>
                  </ScifiDiv>
                </div>
              </Col>
              <Col>
                <div style={{ position: "relative", left: "-10px" }}>
                  <ScifiDiv>
                    <div style={{ width: "120px" }}>
                      {quadrant.name ? quadrant.name : "00000000"}
                    </div>
                  </ScifiDiv>
                </div>
              </Col>
              <Col>
                <div style={{ position: "relative", left: "-20px" }}>
                  <ScfiButton
                    disabled={quadrant.name === undefined}
                    onClick={() => {
                      navigate(`/game/quadrant/${letter}${number}`)
                      window.location.reload()
                    }}
                    size="small"
                  >
                    {t("Go")}
                  </ScfiButton>
                </div>
              </Col>
            </Row>
          </div>
        </SwipeableDrawer>
      </div>
    </Flex>
  )
}

export default SpaceNavigator
