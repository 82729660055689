import React from "react"
import { useState } from "react"
import { useAlliancesRanking } from "../../data/queries/ranking"
import { useTranslation } from "react-i18next"
import disableScroll from "disable-scroll"

import { Table } from "components/layout"
import Filter from "components/display/Filter"
import { ScifiButton } from "components/general"
import styles from "./PlayerRanking.module.scss"

interface Props {
  order: string
}
const AlianceRanking = ({ order }: Props) => {
  const { t } = useTranslation()
  const [filterValue, setFilterValue] = useState("")
  const [appliedFilter, setAppliedFilter] = useState("")
  const [page, setPage] = useState(1)
  const list = useAlliancesRanking("general", page, order)

  const pageSize = 100

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value)
  }

  const handleGoClick = () => {
    setAppliedFilter(filterValue)
  }

  // const filteredData = Array.isArray(list.data) ? list.data : []
  const filteredData = Array.isArray(list.data)
    ? list.data.filter((aliance) =>
        aliance.name.toLowerCase().includes(appliedFilter.toLowerCase())
      )
    : []

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  return (
    <div>
      <div className={styles.filter}>
        <Filter
          value={filterValue}
          onChange={handleFilterChange}
          placeholder="Filter by Name"
        />
      </div>
      <div className={styles.go}>
        <ScifiButton onClick={handleGoClick}>{t("GO")}</ScifiButton>
      </div>
      <div
        className={styles.tableAliance}
        onMouseOver={() => disableScroll.off()}
        onMouseOut={() => disableScroll.on()}
      >
        <Table
          columns={[
            {
              key: "position",
              title: "position",
              dataIndex: "position",
              align: "center",
              render: (text, record, index) => {
                const originalIndex =
                  list && list.data ? list.data.indexOf(record) : -1
                const position =
                  originalIndex !== -1
                    ? originalIndex + 1
                    : (page - 1) * pageSize + index + 1
                return position
              },
            },
            {
              key: "name",
              title: "name",
              dataIndex: "name",
              align: "center",
            },
            {
              key: "score",
              title: "score",
              dataIndex: "score",
              align: "center",
            },
            {
              key: "attackScore",
              title: "attackScore",
              dataIndex: "attackScore",
              align: "center",
            },
            {
              key: "defenseScore",
              title: "defenseScore",
              dataIndex: "defenseScore",
              align: "center",
            },
            {
              key: "countMembers",
              title: t("Members"),
              dataIndex: "countMembers",
              align: "center",
            },
            //countMembers
            {
              key: "action",
              title: "action",
              dataIndex: "action",
              align: "center",
            },
          ]}
          dataSource={filteredData}
          size="small"
          pagination={{
            currentPage: page,
            pageSize: page,
            onPageChange: handlePageChange,
          }}
        />
      </div>
    </div>
  )
}

export default AlianceRanking
