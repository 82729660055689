import { PropsWithChildren } from "react"

interface ShapeProps {
  color: any
  position: any
  children: any
  setHovered: any
}

const QuadrantPoint = (props: PropsWithChildren<ShapeProps>) => {
  const { children, color, position, setHovered } = props

  return (
    <mesh
      position={position}
      onPointerOver={() => {
        setHovered(true)
      }}
      onPointerOut={() => {
        setTimeout(() => {
          setHovered(false)
        }, 3000)
      }}
    >
      {children}
      {/* Now, in order to get selective bloom we simply crank colors out of
        their natural spectrum. Where colors are normally defined between 0 - 1 we push them
        way out of range, into a higher defintion (HDR). What previously was [1, 1, 1] now could
        for instance be [10, 10, 10]. This requires that toneMapping is off, or it clamps to 1 */}
      <meshBasicMaterial color={color} toneMapped={false} />
    </mesh>
  )
}

export default QuadrantPoint
