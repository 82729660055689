import { Suspense, useEffect, useState } from "react"
import { Canvas } from "@react-three/fiber"
import { Html } from "@react-three/drei"
import { useParams } from "react-router-dom"
// import { Gltf } from "@react-three/drei"
import PlanetLabels from "components/comp3d/PlanetLabels"
import ControlPanel from "app/sections/ControlPanel"
import CommandersControl from "app/sections/CommandersControl"
import SpaceNavigator from "components/navigation/SpaceNavigator"
import System from "components/comp3d/System"
import Footer from "app/sections/Footer"
import Logger from "components/notifications/Logger"
import { useQuadrant } from "data/queries/quadrant"
import AnimateText from "components/general/AnimateText"
import mp3Open from "sounds/open.mp3"
import { Wrong } from "components/feedback"
import { Page } from "components/layout"
import { getErrorMessage } from "utils/error"
// import SendNewMessage from "app/components/NewMessage"
// import { SendNewMessageModal } from "app/sections/Conversation"
import PlanetDetail from "components/planets/PlanetDetail"
import SideModal from "components/modals/SideModal"
import styles from "./Quadrant.module.scss"
import useSoundEffect from "app/sections/useSoundEffect"

interface QuadrantProps {
  isActive?: boolean
}

const getInitialActiveState = () => {
  const storedValue = localStorage.getItem("power")
  return storedValue === "true"
}

const Quadrant: React.FC<QuadrantProps> = ({ isActive: propIsActive }) => {
  const { code } = useParams()
  const planetId = localStorage.getItem("planet") || ""

  const quadrant = useQuadrant(code || "A000", planetId)
  const [countScrool, setCountScrool] = useState<number>(0)
  // const [showModalNewMessage, setShowModalNewMessage] = useState<boolean>(false)

  const [selected, setSeleted] = useState<number>(6)
  const [sPlanet, setSPlanet] = useState<Planet | null>(null)
  const [openDesc, setOpenDesc] = useState<boolean>(false)
  const [isActive] = useState(propIsActive ?? getInitialActiveState())

  // const [destinatario, setDestinatario] = useState(sPlanet?.player)

  useSoundEffect(isActive)

  useEffect(() => {
    if (!isActive) return

    const promissePlay = new Audio(mp3Open).play()

    if (promissePlay !== undefined) {
      promissePlay.then((_) => {}).catch((error) => {})
    }
  }, [quadrant.data?.name, quadrant.status, isActive])

  // useEffect(() => {
  //   if (sPlanet?.player) {
  //     setDestinatario(sPlanet.player)
  //   }
  // }, [sPlanet])

  function openNewMessage(id?: number) {
    // setShowModalNewMessage(true)
    // setDestinatario(id!)
  }
  // const closeModal = () => {
  //   setShowModalNewMessage(false)
  // }
  return (
    <>
      {quadrant && quadrant.data?.name === undefined ? (
        <></>
      ) : (
        <>
          <ControlPanel />
          <Logger />
          <SpaceNavigator />
          <CommandersControl />
          <div>
            <SideModal open={openDesc} setOpen={setOpenDesc}>
              {quadrant && quadrant.data && (
                <PlanetDetail
                  planet={sPlanet}
                  quadrant={quadrant.data}
                  position={String(selected)}
                  setOpen={setOpenDesc}
                />
              )}
            </SideModal>
          </div>
          <Footer />

          <div className={styles.scena}>
            <Canvas
              onWheel={(e) => {
                if (countScrool > 12) {
                  document.location.href = `/game/galaxy/${code?.substring(
                    0,
                    1
                  )}`
                } else {
                  if (e.nativeEvent.deltaY === 120) {
                    setCountScrool(countScrool + 1)
                  } else {
                    setCountScrool(countScrool - 1)
                  }
                }
              }}
            >
              <Suspense fallback={null}>
                <Html
                  zIndexRange={[100, 0]}
                  style={{
                    position: "relative",
                    top: "-450px",
                    left: "-440px",
                  }}
                >
                  <div className={styles.tag}>{"Quadrant"}</div>
                  <div className={styles.regionLogo}>
                    <img
                      src={"/assets/icons/regions/A.png"}
                      alt="Region"
                      width={60}
                    />
                  </div>
                  <div className={styles.quadrantName}>
                    {quadrant.data && (
                      <AnimateText
                        text={
                          quadrant.data?.quadrant + " " + quadrant.data.name
                        }
                      />
                    )}
                  </div>
                </Html>
                {quadrant.data && (
                  <>
                    <System
                      openNewMsg={openNewMessage}
                      quadrant={quadrant.data}
                      setSeleted={setSeleted}
                      setOpenDesc={setOpenDesc}
                      setSPlanet={setSPlanet}
                    />
                    <PlanetLabels quadrant={quadrant.data.quadrant} />
                  </>
                )}
                {/* <Gltf
                  src="/objects/asteroid005.glb"
                  receiveShadow
                  castShadow
                  position={[-30, -40, 2]}
                  scale={1.5}
                /> */}
                <ambientLight intensity={0.9} />
              </Suspense>
            </Canvas>
            {/* // @todo recuperar o id correto do dono do planeta */}
            {/* {showModalNewMessage && destinatario && (
              <SendNewMessageModal
                recipientId={destinatario}
                closeModal={closeModal}
              />
            )} */}
          </div>
        </>
      )}
    </>
  )
}

export default Quadrant

/* error */
export const fallback = (error: Error) => (
  <Page>
    <Wrong>{getErrorMessage(error)}</Wrong>
  </Page>
)
