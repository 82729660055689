import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"
import { isAuthenticated } from "data/services/authService"

export const useMessage = () => {
  return useQuery(
    [queryKey.tritium.message.messages],
    async () => {
      if (!isAuthenticated()) return []

      const { data } = await api.get<Message[]>(`api/message/not-read`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}
export const useConversation = (senderId: number) => {
  return useQuery(
    [queryKey.tritium.message.conversation],
    async () => {
      if (!isAuthenticated()) return []

      const { data } = await api.get<Message[]>(
        `api/message/conversation/${senderId}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const getMessages = () => {
  return api.get<Message[]>(TRITIUM + `/api/message/not-read`)
}

export const getAllMsgForRecipent = () => {
  return api.get<Message[]>(TRITIUM + `/api/message/all-recipient`)
}

export const getAllMsgSenderForRecipient = (senderId: any) => {
  return api.get<Message[]>(
    TRITIUM + `/api/message/send-for-recipient/${senderId}`
  )
}

export const getMessagesInterations = () => {
  return api.get<MessagePlayerInterations[]>(
    TRITIUM + `/api/message/getSenders`
  )
}

export const getConversation = (senderId: number) => {
  return api.get<Message[]>(TRITIUM + `/api/message/conversation/${senderId}`)
}

export const sendNewMessage = (message: Message) => {
  return api.post<Return>(TRITIUM + `/api/message/new`, message)
}

/**sender id, no caso o id de quem ja enviou a msg e está aguardando a confirmação de leitura */
export const readMessage = (senderId: number) => {
  return api.post<Return>(TRITIUM + `/api/message/read`, {
    id: senderId,
  })
}

export const getAllMessegeNotRead = () => {
  return api.get<MessagePlayerInterations[]>(TRITIUM + `/api/message/not-read`)
}

export const getLastMessageNotRead = (senderId: number) => {
  return api.get<Message>(TRITIUM + `/api/message/lastmsg-sender/${senderId}`)
}
export const listSearchUser = (user: string) => {
  return api.get<MessagePlayerInterations[]>(
    TRITIUM + `/api/message/search-usuer/${user}`
  )
}

// export const getCountMessageNotRead = () => {
//   return api.get<number>(TRITIUM + `/api/message/count`)
// }

export const useConversationUnread = () => {
  return useQuery(
    [queryKey.tritium.message.unread],
    async () => {
      const { data } = await api.get(`api/message/unread`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

// export const getOwnerPlanet = (planetId:number)=>{
//   return api.get<Player>(TRITIUM + `/api/planet/owner/${planetId}`)
// }
export const getOwnerPlanet = (planetId: number) => {
  return api.get<Player>(TRITIUM + `/api/message/owner/${planetId}`)
}
