import { useRef } from "react"
import RegionMenu from "./RegionMenu"
import styles from "./RegionPanel.module.scss"

const RegioinPanel = () => {
  const effects = useRef<HTMLDivElement>(null)

  function renderRegionItemsMenu() {
    const regions = []
    regions.push(<RegionMenu position="A" key="A" name="Andromeda Arm" />)
    regions.push(<RegionMenu position="B" key="B" name="Blazing Blue" />)
    regions.push(<RegionMenu position="C" key="C" name="Centauri Citadel" />)
    regions.push(<RegionMenu position="D" key="D" name="Draco Dominion" />)
    regions.push(<RegionMenu position="E" key="E" name="Eridanus Enclave" />)
    regions.push(<RegionMenu position="F" key="F" name="Felicity Falls" />)
    regions.push(<RegionMenu position="G" key="G" name="Ghostly Grove" />)
    regions.push(<RegionMenu position="H" key="H" name="Harmony Harbor" />)
    regions.push(<RegionMenu position="I" key="I" name="Iris Interstice" />)
    regions.push(<RegionMenu position="J" key="J" name="Juniper Junction" />)
    regions.push(<RegionMenu position="K" key="K" name="Kronos Keep" />)
    regions.push(<RegionMenu position="L" key="L" name="Luminous Lagoon" />)
    regions.push(<RegionMenu position="M" key="M" name="Misty Moors" />)
    regions.push(<RegionMenu position="N" key="N" name="Nova Nexus" />)
    regions.push(<RegionMenu position="O" key="O" name="Onyx Oasis" />)
    regions.push(<RegionMenu position="P" key="P" name="Polaris Pathway" />)

    return regions
  }

  return (
    <div ref={effects} className={styles.regionPanel}>
      {renderRegionItemsMenu()}
    </div>
  )
}

export default RegioinPanel
