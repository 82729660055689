import { useTranslation } from "react-i18next"
import { useFleets } from "data/queries/fleet"
import Colonize from "styles/images/navigation/colonize.png"
import Attack from "styles/images/navigation/attack.png"
import Defense from "styles/images/navigation/defense.png"
import Resource from "styles/images/navigation/resource.png"
import Spy from "styles/images/navigation/spy.png"
import Explore from "styles/images/navigation/explore.png"
import Message from "styles/images/navigation/message.png"
import Tooltip from "@mui/material/Tooltip"
import styles from "./ActionMenu.module.scss"

interface Props {
  action: string
  setAction: any
  player: number | undefined
  planetId: string
}

const ActionMenu = (props: Props) => {
  const { t } = useTranslation()
  const { action, setAction, player } = props
  let msg = "Planeta não explorado!"

  const fleets = useFleets(props.planetId)

  return (
    <div className={styles.actions}>
      {player ? (
        <>
          {fleets.data && fleets.data.length > 0 && (
            <Tooltip title={t("Attack")}>
              <div
                onClick={() => setAction("attack")}
                className={
                  action === "attack" ? styles.optionActive : styles.option
                }
              >
                <img src={Attack} alt="attack" />
              </div>
            </Tooltip>
          )}
        </>
      ) : (
        <Tooltip title={t("Colonize")}>
          <div
            onClick={() => setAction("colonize")}
            className={
              action === "colonize" ? styles.optionActive : styles.option
            }
          >
            <img src={Colonize} alt="colonize" />
          </div>
        </Tooltip>
      )}
      {fleets.data && fleets.data.length > 0 && (
        <Tooltip
          title={`${player ? t("Defense") : t(msg)}`}
          className={!player ? styles.disabledAction : ""}
        >
          <div
            onClick={() => setAction("defense")}
            className={
              action === "defense" ? styles.optionActive : styles.option
            }
          >
            <img src={Defense} alt="defense" />
          </div>
        </Tooltip>
      )}
      <Tooltip
        title={`${player ? t("Send Resource") : t(msg)}`}
        className={!player ? styles.disabledAction : ""}
      >
        <div
          onClick={() => setAction("resource")}
          className={
            action === "resource" ? styles.optionActive : styles.option
          }
        >
          <img src={Resource} alt="resource" />
        </div>
      </Tooltip>
      <Tooltip title={t("Tritium Explorer")}>
        <div
          onClick={() => setAction("mission")}
          className={action === "mission" ? styles.optionActive : styles.option}
        >
          <img src={Explore} alt="Tritium Explorer" />
        </div>
      </Tooltip>
      <Tooltip
        title={`${player ? t("Message") : t(msg)}`}
        className={!player ? styles.disabledAction : ""}
      >
        <div
          className={action === "message" ? styles.option : styles.option}
          onClick={() => {
            setAction("message")
          }}
        >
          <img src={Message} alt="message" />
        </div>
      </Tooltip>
      <Tooltip
        title={`${player ? t("Spy On") : t(msg)}`}
        className={!player ? styles.disabledAction : ""}
      >
        <div
          className={action === "spy" ? styles.option : styles.option}
          onClick={() => {
            setAction("spy")
          }}
        >
          <img src={Spy} alt="spy" />
        </div>
      </Tooltip>
    </div>
  )
}

export default ActionMenu
