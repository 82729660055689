import { useQuery } from "react-query"
import { queryKey, RefetchOptions } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const useLogs = () => {
  return useQuery(
    [queryKey.tritium.logs.logs],
    async () => {
      const { data } = await api.get<Log[]>("api/logs/logs", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const readLog = (id: string) => {
  return api.put(TRITIUM + `/api/logs/update/${id}`)
}
export const registerLog = (log: LogRegister) => {
  return api.post(TRITIUM + `/api/logs/create`, log)
}

export const useJobSleep = (typeJ: string) => {
  return useQuery(
    [queryKey.tritium.logs.jobs],
    async () => {
      const { data } = await api.get<JobSleep[]>(
        `api/logs/processjob/${typeJ}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}
