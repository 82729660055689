import { formatDistanceToNowStrict } from "date-fns"

export const toNow = (date: Date) =>
  formatDistanceToNowStrict(date, { addSuffix: true })

export const dataAtual = () => {
  const dataHoraAtual = new Date()
  const ano = dataHoraAtual.getFullYear()
  const mes = pad(dataHoraAtual.getMonth() + 1, 2)
  const dia = pad(dataHoraAtual.getDate(), 2)
  const horas = pad(dataHoraAtual.getHours(), 2)
  const minutos = pad(dataHoraAtual.getMinutes(), 2)
  const segundos = pad(dataHoraAtual.getSeconds(), 2)
  return `${ano}-${mes}-${dia} ${horas}:${minutos}:${segundos}`
}
export const getDate = (date: string): string => {
  const newDate: Date = new Date(date)
  const dia = newDate.getDate().toString().padStart(2, "0")
  const mes = (newDate.getMonth() + 1).toString().padStart(2, "0")
  const ano = newDate.getFullYear().toString().slice(-2)
  return `${dia}/${mes}/${ano}`
}
export const pad = (num: number, length: number) => {
  return num.toString().padStart(length, "0")
}
export const getHours = (hora: string): string => {
  const newDate: Date = new Date(hora)
  let horaFormatada: number = newDate.getHours()
  const minutosFormatados: string = newDate
    .getMinutes()
    .toString()
    .padStart(2, "0")
  let periodo: string = "am"

  if (horaFormatada >= 12) {
    periodo = "pm"
    if (horaFormatada > 12) {
      horaFormatada -= 12
    }
  }
  return `${horaFormatada
    .toString()
    .padStart(2, "0")}:${minutosFormatados} ${periodo}`
}
