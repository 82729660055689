import { useMemo } from "react"
import { EllipseCurve } from "three"
import * as THREE from "three"

type EllipseProps = {
  radiusX: number
  radiusY: number
  centerX: number
  centerY: number
  rotation: number
  color: string
}

function createEllipseCurve(
  radiusX: number,
  radiusY: number,
  centerX: number,
  centerY: number,
  rotation: number
): any {
  const curve = new EllipseCurve(
    centerX,
    centerY,
    radiusX,
    radiusY,
    0,
    2 * Math.PI,
    false,
    rotation
  )
  return new THREE.BufferGeometry().setFromPoints(curve.getPoints(90))
}

function Ellipse(props: EllipseProps) {
  const { radiusX, radiusY, centerX, centerY, rotation, color } = props

  const geometry = useMemo(
    () => createEllipseCurve(radiusX, radiusY, centerX, centerY, rotation),
    [radiusX, radiusY, centerX, centerY, rotation]
  )

  return (
    <line>
      <lineDashedMaterial opacity={0.5} transparent color={color} />
      <bufferGeometry attach="geometry" {...geometry} />
    </line>
  )
}

const EllipseGroup = () => {
  const ellipses: any = []

  for (let i = 2; i <= 7; i++) {
    ellipses.push(
      <Ellipse
        key={i}
        radiusX={i * 10}
        radiusY={i * 8}
        centerX={0}
        centerY={0}
        rotation={0}
        color="#868289"
      />
    )
  }

  return ellipses
}

export default EllipseGroup
