import { PropsWithChildren, useContext, useRef } from "react"
import DesertMap from "styles/images/maps/terrainDesert.png"
import GrassMap from "styles/images/maps/terrainGrass.png"
import IceMap from "styles/images/maps/terrainIce.png"
import VulcanMap from "styles/images/maps/terrainVulcan.png"
import Draggable from "react-draggable"
import disableScroll from "disable-scroll"
import styles from "./PlanetMap.module.scss"
import { GameContext } from "pages/GameContext"

interface Props {
  title?: string
  isDragable: boolean
}

disableScroll.on()

const PlanetMap = (props: PropsWithChildren<Props>) => {
  const { children, isDragable } = props

  const { planetData } = useContext(GameContext)
  const planetResource =
    planetData instanceof Array ? planetData[0] : planetData

  const limit = {
    left: -500,
    right: -40,
    top: -540,
    bottom: -250,
  }

  const position = {
    x: Number(localStorage.getItem("x")) || -200,
    y: Number(localStorage.getItem("y")) || -400,
  }

  const nodeRef = useRef(null)

  let terrainImage

  switch (planetResource && planetResource.terrainType) {
    case "Desert":
      terrainImage = DesertMap
      break
    case "Grass":
      terrainImage = GrassMap
      break
    case "Ice":
      terrainImage = IceMap
      break
    case "Vulcan":
      terrainImage = VulcanMap
      break
  }

  return (
    <div>
      <Draggable
        disabled={!isDragable}
        bounds={limit}
        nodeRef={nodeRef}
        defaultPosition={position}
        onStop={(e, data) => {
          localStorage.setItem("x", String(data.x === 0 ? -1 : data.x))
          localStorage.setItem("y", String(data.y === 0 ? -1 : data.y))
        }}
      >
        <div ref={nodeRef} className={styles.starMap}>
          <img
            className={styles.imageMap}
            draggable="false"
            src={terrainImage}
            width={2400}
            alt="map"
          />
          <div>{children}</div>
        </div>
      </Draggable>
    </div>
  )
}

export default PlanetMap
