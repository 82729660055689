import { useEffect } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { verifyEmailUser } from "data/queries/users"

const EmailVerify = () => {
  const { id, hash } = useParams()
  const [searchParams] = useSearchParams()

  const signature = searchParams.get("signature")
  const expires = searchParams.get("expires")

  const url_api = `${id}/${hash}?signature=${signature}&expires=${expires}`

  const verifyEmailLoad = async (url_api: string) => {
    try {
      await verifyEmailUser(url_api).then((data) => {
        if (data.data.success) {
          window.location.href = "https://play.terratritium.com?verify=true"
        } else {
          window.location.href = "https://play.terratritium.com?verify=false"
        }
      })
    } catch (error) {
      window.location.href = "https://play.terratritium.com?verify=false"
    }
  }

  useEffect(() => {
    verifyEmailLoad(url_api || "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export default EmailVerify
