import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

const LoginConnect = () => {
  const [queryParameters] = useSearchParams()

  useEffect(() => {
    const planet = queryParameters.get("planet")
    const token = queryParameters.get("token")

    if (planet && token) {
      const tour = localStorage.getItem("hasCompletedTour")
      if (tour == null) {
        localStorage.setItem("hasCompletedTour", "false")
      }

      localStorage.setItem("tritium-token", token)
      window.location.href = "/game/planet/" + planet
    }
  }, [queryParameters])
  return <>connect...</>
}

export default LoginConnect
