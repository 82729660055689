import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import styles from "./BuildLabel.module.scss"

interface Props {
  title: string
  level: number
  onConstruction: boolean
}

const BuildLabel = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation()
  return (
    <div
      className={
        props.title.indexOf("Colo") === 0
          ? styles.labelColonization
          : props.onConstruction
          ? styles.labelOnConstruction
          : styles.label
      }
    >
      {t(`${props.title}`)}
      <span className={styles.level}>{props.level}</span>
    </div>
  )
}

export default BuildLabel
