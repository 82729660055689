import { useTranslation } from "react-i18next"
import { useContext } from "react"
import { GameContext } from "pages/GameContext"

const TritiumDisplay = () => {
  const { t } = useTranslation()
  const { player } = useContext(GameContext)

  return (
    <div>
      <img
        src="https://raw.githubusercontent.com/terra-tritium/assets/main/trit.png"
        width={26}
        alt={t("TRITIUM")}
      />{" "}
      {player?.tritium}
    </div>
  )
}

export default TritiumDisplay
