import { TRITIUM, TOKEN_KEY } from "config/constants"
import api from "../services/api"

export const loginUser = (user: User) => {
  return api.post(TRITIUM + `/api/user/login`, user)
}

export const logoutUser = () => {
  localStorage.removeItem(TOKEN_KEY)
  return api.get(TRITIUM + `/api/user/logout`)
}

export const forgotPasswordUser = (user: User) => {
  return api.post(TRITIUM + `/api/user/forgot-password`, user)
}

export const resetPasswordUser = (resetPassword: ResetPassword) => {
  return api.post(TRITIUM + `/api/user/reset-password`, resetPassword)
}

export const verifyEmailUser = (url_api: string) => {
  return api.get(TRITIUM + `/api/user/email-verify/${url_api}`)
}

export const sendLinkVerifyEmailRestister = (email: string) => {
  return api.post(TRITIUM + `/api/user/verification-notification/${email}`)
}
