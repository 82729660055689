/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react"
import { useThree } from "@react-three/fiber"
import { Sphere, useTexture } from "@react-three/drei"
import { usePlanetsByQuadrant } from "data/queries/planet"

interface Props {
  openNewMsg: (id: any) => void
  quadrant: Quadrant
  setSeleted: any
  setOpenDesc: any
  setSPlanet: any
}

const Planets: React.FC<Props> = ({
  openNewMsg,
  quadrant,
  setSeleted,
  setOpenDesc,
  setSPlanet,
}) => {
  const { camera } = useThree()

  //const quadrantNumber = Number(quadrant.quadrant.slice(-3))

  const planetsData = usePlanetsByQuadrant(String(quadrant.quadrant))
  const desert = useTexture("/assets/planets/desert.jpg")
  const grass = useTexture("/assets/planets/grass.jpg")
  const ice = useTexture("/assets/planets/ice.jpg")
  const vulcan = useTexture("/assets/planets/vulcan.jpg")
  const sun = useTexture("/assets/planets/sun.png")
  useEffect(() => {
    camera.position.set(2, -60, 70)
    camera.lookAt(0, 0, 0)
    camera.rotation.set(0, 0, 0)
  }, [camera])

  useEffect(() => {}, [])

  const planets: any = []

  const pos = [
    { x: 0, y: 0, z: 0, s: 6 }, // 0
    { x: 49, y: -27, z: 0, s: 2.4 }, // 1
    { x: 56, y: 18.2, z: 0, s: 2 }, // 2
    { x: 10, y: 14, z: 0, s: 2.5 }, // 3
    { x: 40, y: 0, z: 0, s: 2 }, // 4
    { x: -11, y: -30, z: 0, s: 2 }, // 5
    { x: -41, y: 22, z: 0, s: 2.5 }, // 6
    { x: 15, y: -21, z: 0, s: 2 }, // 7
    { x: -16, y: 9.2, z: 0, s: 1.8 }, // 8
    { x: -24, y: -13.2, z: 0, s: 2 }, // 9
    { x: -60, y: 0, z: 0, s: 2.5 }, // 10
    { x: 23, y: 14, z: 0, s: 2.5 }, // 11
    { x: -2, y: 32, z: 0, s: 2 }, // 12
    { x: -1, y: -16, z: 0, s: 2 }, // 13
    { x: -40, y: -4.4, z: 0, s: 1.8 }, // 14
    { x: -46, y: -16, z: 0, s: 2 }, // 15
    { x: 60, y: 0, z: 0, s: 1.5 }, // 16
  ]

  let contPlanets = 0

  planetsData.data &&
    planetsData.data.map((planet: Planet, idx: number) => {
      contPlanets++
      let color = 0x333333

      if (planet.player) {
        switch (planet.terrainType) {
          case "Desert":
            color = 0x918d52
            break
          case "Grass":
            color = 0x96cf5a
            break
          case "Ice":
            color = 0xcbffff
            break
          default:
            color = 0xc57e3e
        }
      }
      planets.push(
        <mesh key={idx}>
          <group>
            <Sphere
              position={[pos[idx].x, pos[idx].y, pos[idx].z]}
              key={idx}
              scale={pos[idx].s}
              onClick={() => {
                if (idx !== 0) {
                  setSeleted(idx)
                  setOpenDesc(true)
                  selectPlanet(idx + 1)
                }
              }}
            >
              {idx !== 0 && (
                <meshStandardMaterial
                  map={
                    planet.terrainType === "Desert"
                      ? desert
                      : planet.terrainType === "Grass"
                      ? grass
                      : planet.terrainType === "Ice"
                      ? ice
                      : vulcan
                  }
                  color={color}

                  // color={
                  //   quadrantNumber % 4 === 0
                  //     ? 0x918d52
                  //     : quadrantNumber % 4 === 1
                  //       ? 0x96cf5a
                  //       : quadrantNumber % 4 === 2
                  //         ? 0xcbffff
                  //         : 0xc57e3e
                  // }
                />
              )}
              {idx === 0 && (
                <meshStandardMaterial
                  map={sun}
                  color={0xffc222}
                ></meshStandardMaterial>
              )}
            </Sphere>
          </group>
        </mesh>
      )
      return null
    })

  contPlanets++

  for (let i = contPlanets; i < 17; i++) {
    planets.push(
      <mesh key={i}>
        <group>
          <Sphere
            position={[pos[i].x, pos[i].y, pos[i].z]}
            scale={pos[i].s}
            receiveShadow
            castShadow
            onClick={() => {
              setSeleted(i)
              setOpenDesc(true)
              selectPlanet(i)
            }}
          >
            <meshStandardMaterial color={0x333333} />
          </Sphere>
        </group>
      </mesh>
    )
  }

  function selectPlanet(id: number) {
    let count = 0
    Array.isArray(planetsData.data) &&
      planetsData.data.map((planet: Planet) => {
        if (planet.position === id) {
          setSPlanet(planet)
          count++
        }
        return 0
      })
    if (count === 0) {
      setSPlanet(null)
    }
  }

  return <>{planets}</>
}

export default Planets
