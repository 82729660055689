import styles from "./Aliance.module.scss"
import {
  changeRankMember,
  deixarPatente,
  getRanksMembers,
  getUnitsMember,
  responseRequestMember,
  useGetRanks,
} from "data/queries/aliance"
import { useTranslation } from "react-i18next"
import { Button } from "components/general"
import { LoadingCircular, Modal } from "components/feedback"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import { useState, useEffect } from "react"
import disableScroll from "disable-scroll"
import { Select } from "components/form"
import { GroupRemoveOutlined } from "@mui/icons-material"
import Exit from "@mui/icons-material/Logout"
import ChangeRank from "@mui/icons-material/PublishedWithChanges"
import Warning from "./Warning"
import {
  TRITIUM_MEMBER_DIPLOMAT,
  TRITIUM_MEMBER_FLEET_CAP,
  TRITIUM_MEMBER_FOUNDER,
  TRITIUM_MEMBER_GENERAL,
  TRITIUM_PROMOVER,
  TRITIUM_REBAIXAR,
  TRITIUM_REMOVER_MEMBRO,
  TRITIUM_MEMBER_TROOP_CAP,
} from "config/constants"
import { IconsRankMember } from "./Aliance"
import DetailsUnitsMembers from "./DetailsUnitsMember"
interface ListActionMemberProps {
  memberAction: DetailsMyAliance
  idAliance: number
  handleConfirmAction: (idMemberRequest: number, action: string) => void
  setOptionListMembers: (idOptionLisg: number) => void
  handleRefreshListMembers: (refresh: boolean) => void
  isFounder: boolean
  idRankUserLogged: number
  idCurrentPlayer: number
}
const ListActionMember = (ListActionMember: ListActionMemberProps) => {
  const {
    memberAction,
    idAliance,
    isFounder,
    setOptionListMembers,
    idRankUserLogged,
    idCurrentPlayer,
    handleRefreshListMembers,
  } = ListActionMember
  const [rankSelected, setRankSelected] = useState(memberAction.idRank! | 7)
  const ranks = useGetRanks()
  const [disableBtn, setDisableBtn] = useState(true)
  const [showWarning, setShowWarning] = useState(false)
  const [textButton, setTextButton] = useState("")
  const [load, setLoad] = useState(false)
  const { t } = useTranslation()
  const [isMemberCurrent, setIsMemberCurrent] = useState(false)
  const [actionRequestMember, setActionRequestMember] = useState("")

  const [confirmAction, setConfirmAction] = useState<boolean>(false)
  const [fleet, setFleet] = useState<UnitDetails[] | null>(null)
  const [troop, setTroop] = useState<UnitDetails[] | null>(null)

  useEffect(() => {
    if (memberAction.idRank) setRankSelected(memberAction.idRank)
    setIsMemberCurrent(memberAction.player_id === idCurrentPlayer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAction])

  useEffect(() => {
    if (
      [TRITIUM_MEMBER_FLEET_CAP, TRITIUM_MEMBER_TROOP_CAP].includes(
        idRankUserLogged
      )
    ) {
      fetchFrota(
        idRankUserLogged === TRITIUM_MEMBER_FLEET_CAP ? "fleet" : "troop"
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAction.player_id])
  const fetchFrota = (type: string) => {
    getUnitsMember(memberAction.player_id, type)
      .then((res) => {
        if (type === "fleet") setFleet(res.data)
        if (type === "troop") setTroop(res.data)
      })
      .catch((er) => console.log("erro ao recuperar tropas", er))
  }
  const handleChangeRankMember = async () => {
    setLoad(true)
    changeRankMember(rankSelected, memberAction.id, idAliance)
      .then((res) => console.log("change", res))
      .catch((e) => console.log("error change ", e))
      .finally(() => {
        setLoad(false)
        setOptionListMembers(1)
        handleRefreshListMembers(true)
      })
  }

  const handleStatusButton = (levelSelected: string, rankSelected: string) => {
    setDisableBtn(memberAction.idRank === parseInt(rankSelected))
    setTextButton(
      parseInt(levelSelected) < memberAction.level! ? "Rebaixar" : "Promover"
    )
  }

  const verifyRankAvailable = (idAliance: number, rankId: number) => {
    getRanksMembers(idAliance)
      .then((res) => {
        res.data.forEach((e) => {
          if (e.idRank === rankId) setShowWarning(!e.roleAvailable)
        })
      })
      .catch((er) => {
        console.log("error rank", er)
      })
  }

  const handleRemoveMember = async () => {
    alert("Ajustar a remoção do membro")
    // console.log("id do membro ",memberAction)
    await responseRequestMember(memberAction.player_id, "R")
      .then((res) => console.log("acao de remover", res))
      .catch((err) => {})
      .finally(() => {
        setOptionListMembers(1)
      })
  }

  const actionResponse = async () => {
    switch (actionRequestMember) {
      case TRITIUM_REBAIXAR:
      case TRITIUM_PROMOVER:
        handleChangeRankMember()
        setOptionListMembers(1)
        break
      case TRITIUM_REMOVER_MEMBRO:
        handleRemoveMember()
        break
      case "exit":
        await deixarPatente(idAliance, memberAction.id)
          .then((res) => {
            console.log("deixou ", res)
          })
          .catch((err) => console.log("erro ao deixar cargo ", err))
          .finally(() => {
            handleRefreshListMembers(true)
            setOptionListMembers(1)
          })
        break
    }
  }

  const modalConfirm = !confirmAction
    ? undefined
    : {
        title: t("Confirmar"),
        children: (
          <section>
            <section>
              {actionRequestMember === "A" &&
                t("Do you Want accept the member?")}
              {actionRequestMember === TRITIUM_REMOVER_MEMBRO &&
                t("Do you want to remove the member?")}
              {actionRequestMember === TRITIUM_PROMOVER &&
                t("Do you want to advance the member?")}
              {actionRequestMember === TRITIUM_REBAIXAR &&
                t("Do you want to demote the member?")}
              {actionRequestMember === "exit" &&
                t("Do you really want to relinquish your rank?")}
            </section>
          </section>
        ),
      }
  const showConfirm = (idMember: number, action: string) => {
    setActionRequestMember(action)
    setConfirmAction(true)
  }
  return (
    <>
      <table
        className={styles.tbMembers}
        onMouseOut={(e) => {
          disableScroll.on()
        }}
      >
        <thead>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("Name")}</td>
            <td colSpan={2}>{memberAction.name}</td>
          </tr>
          <tr>
            <td>{t("Current rank")}</td>
            <td>{memberAction.rankName}</td>
            <td>
              <IconsRankMember idRank={memberAction.idRank!} />
            </td>
          </tr>
          {!isMemberCurrent &&
            [TRITIUM_MEMBER_FOUNDER, TRITIUM_MEMBER_GENERAL].includes(
              idRankUserLogged
            ) && (
              <tr>
                <td>{t("New Rank")}</td>
                <td>
                  <Select
                    name="rank"
                    value={rankSelected}
                    onChange={(e) => {
                      const selectedLevel =
                        e.target.options[e.target.selectedIndex].getAttribute(
                          "data-level"
                        )
                      setRankSelected(parseInt(e.target.value))
                      verifyRankAvailable(idAliance, parseInt(e.target.value))
                      handleStatusButton(selectedLevel!, e.target.value)
                      // setLevelSelected(parseInt(e.target.value))
                    }}
                  >
                    {ranks &&
                      ranks.data?.map((rank, i) => {
                        return (
                          <>
                            <option
                              key={i}
                              value={rank.id}
                              data-level={rank.level}
                            >
                              {t(rank.rankName)}
                            </option>
                          </>
                        )
                      })}
                  </Select>
                </td>
                <td>
                  <IconsRankMember idRank={rankSelected} />
                </td>
              </tr>
            )}
        </tbody>
      </table>
      {/* {loadFrota && <LoadingCircular />} */}
      {showWarning && (
        <>
          <Warning
            msg={t(
              "There is already a member with this position, do you want to replace them?"
            )}
          />
          <br />
        </>
      )}
      <br />
      {load && <LoadingCircular />}
      {/* 
                Visao do capitao
            */}
      {idRankUserLogged === TRITIUM_MEMBER_FLEET_CAP && (
        <DetailsUnitsMembers unitsDetails={fleet} />
      )}
      {idRankUserLogged === TRITIUM_MEMBER_TROOP_CAP && (
        <DetailsUnitsMembers unitsDetails={troop} />
      )}

      {!isMemberCurrent && (
        <>
          {idRankUserLogged &&
            [
              TRITIUM_MEMBER_FOUNDER,
              TRITIUM_MEMBER_DIPLOMAT,
              TRITIUM_MEMBER_GENERAL,
            ].includes(idRankUserLogged) && (
              <div className={styles.gpBtnMembers}>
                {[TRITIUM_MEMBER_FOUNDER, TRITIUM_MEMBER_DIPLOMAT].includes(
                  idRankUserLogged
                )}
                <button
                  className={styles.btnAliance}
                  type="button"
                  onClick={() => {
                    //setConfirmAction(memberAction.id, "R")
                    setActionRequestMember(TRITIUM_REMOVER_MEMBRO)
                    setConfirmAction(true)
                  }}
                >
                  {t("Remove")} &nbsp;&nbsp;
                  <GroupRemoveOutlined />
                </button>
                &nbsp; &nbsp;
                {[TRITIUM_MEMBER_FOUNDER, TRITIUM_MEMBER_GENERAL].includes(
                  idRankUserLogged
                ) && (
                  <>
                    <button
                      className={styles.btnAliance}
                      type="button"
                      onClick={() => {
                        //  handleConfirmAction(memberAction.id, 'R')
                        if (textButton === "Rebaixar")
                          showConfirm(memberAction.id, TRITIUM_REBAIXAR)
                        else showConfirm(memberAction.id, TRITIUM_PROMOVER)
                      }}
                      disabled={disableBtn}
                    >
                      {textButton} &nbsp;&nbsp;
                      <ChangeRank />
                    </button>
                  </>
                )}
              </div>
            )}
        </>
      )}
      <br />
      {!isFounder && isMemberCurrent && (
        <button
          className={styles.btnExitRank}
          type="button"
          onClick={() => {
            // handleConfirmAction(memberAction.id, "R")
            setActionRequestMember("exit")
            setConfirmAction(true)
          }}
        >
          {t("Relinquish rank")}
          <Exit />
        </button>
      )}
      {confirmAction && (
        <Modal
          {...modalConfirm}
          icon={<DoneAllIcon fontSize="inherit" className="success" />}
          footer={() => (
            <Button
              color="primary"
              onClick={() => {
                actionResponse()
                setConfirmAction(false)
              }}
              block
            >
              {t("Yes")}
            </Button>
          )}
          onRequestClose={() => {
            // setSuccess(false)
            setConfirmAction(false)
          }}
          isOpen
        />
      )}
    </>
  )
}

export { ListActionMember }
