import * as THREE from "three"

// Lines A
export const linesA = [
  [
    new THREE.Vector3(-183, 184, 0),
    new THREE.Vector3(-188, 144, 0),
    new THREE.Vector3(-176, 137, 0),
    new THREE.Vector3(-156, 122, 0),
    new THREE.Vector3(-135, 120, 0),
    new THREE.Vector3(-107, 104, 0),
    new THREE.Vector3(-75, 166, 0),
    new THREE.Vector3(-32, 197, 0),
    new THREE.Vector3(-44, 156, 0),
    new THREE.Vector3(-75, 166, 0),
    new THREE.Vector3(-28, 133, 0),
    new THREE.Vector3(-25, 127, 0),
    new THREE.Vector3(-6, 105, 0),
    new THREE.Vector3(-9, 99, 0),
    new THREE.Vector3(-23, 101, 0),
    new THREE.Vector3(-24, 81, 0),
    new THREE.Vector3(30, 55, 0),
    new THREE.Vector3(-14, 17, 0),
    new THREE.Vector3(-24, 81, 0),
  ],
  [
    new THREE.Vector3(62, 189, 0),
    new THREE.Vector3(77, 147, 0),
    new THREE.Vector3(86, 152, 0),
    new THREE.Vector3(99, 171, 0),
    new THREE.Vector3(118, 169, 0),
    new THREE.Vector3(118, 169, 0),
    new THREE.Vector3(124, 156, 0),
    new THREE.Vector3(157, 153, 0),
    new THREE.Vector3(154, 149, 0),
    new THREE.Vector3(149, 129, 0),
    new THREE.Vector3(184, 129, 0),
    new THREE.Vector3(193, 126, 0),
  ],
  [
    new THREE.Vector3(-181, -187, 0),
    new THREE.Vector3(-170, -191, 0),
    new THREE.Vector3(-147, -158, 0),
    new THREE.Vector3(-144, -156, 0),
    new THREE.Vector3(-141, -167, 0),
    new THREE.Vector3(-136, -200, 0),
    new THREE.Vector3(-90, -190, 0),
    new THREE.Vector3(-96, -176, 0),
    new THREE.Vector3(-106, -177, 0),
  ],
  [
    new THREE.Vector3(-199, -55, 0),
    new THREE.Vector3(-162, -91, 0),
    new THREE.Vector3(-157, -80, 0),
    new THREE.Vector3(-140, -79, 0),
    new THREE.Vector3(-168, -130, 0),
    new THREE.Vector3(-144, -156, 0),
  ],
  [
    new THREE.Vector3(-110, -102, 0),
    new THREE.Vector3(-88, -113, 0),
    new THREE.Vector3(-61, -98, 0),
    new THREE.Vector3(-52, -92, 0),
    new THREE.Vector3(-48, -78, 0),
  ],
  [
    new THREE.Vector3(-43, -180, 0),
    new THREE.Vector3(-74, -152, 0),
    new THREE.Vector3(-63, -135, 0),
    new THREE.Vector3(-28, -142, 0),
    new THREE.Vector3(-5, -152, 0),
    new THREE.Vector3(-43, -180, 0),
  ],
  [
    new THREE.Vector3(48, -149, 0),
    new THREE.Vector3(65, -145, 0),
    new THREE.Vector3(72, -149, 0),
    new THREE.Vector3(104, -138, 0),
    new THREE.Vector3(148, -132, 0),
    new THREE.Vector3(189, -130, 0),
    new THREE.Vector3(200, -134, 0),
  ],
  [
    new THREE.Vector3(110, -94, 0),
    new THREE.Vector3(106, -120, 0),
    new THREE.Vector3(104, -138, 0),
    new THREE.Vector3(124, -186, 0),
    new THREE.Vector3(140, -193, 0),
  ],
  [
    new THREE.Vector3(25, -94, 0),
    new THREE.Vector3(58, -73, 0),
    new THREE.Vector3(75, -48, 0),
    new THREE.Vector3(75, -20, 0),
    new THREE.Vector3(65, -16, 0),
    new THREE.Vector3(38, -20, 0),
    new THREE.Vector3(53, -42, 0),
  ],
  [
    new THREE.Vector3(75, -20, 0),
    new THREE.Vector3(123, -35, 0),
    new THREE.Vector3(134, -30, 0),
    new THREE.Vector3(177, -26, 0),
  ],
  [
    new THREE.Vector3(110, -94, 0),
    new THREE.Vector3(107, -81, 0),
    new THREE.Vector3(109, -73, 0),
    new THREE.Vector3(131, -55, 0),
    new THREE.Vector3(138, -42, 0),
    new THREE.Vector3(134, -30, 0),
  ],
  [
    new THREE.Vector3(-190, 9, 0),
    new THREE.Vector3(-168, -3, 0),
    new THREE.Vector3(-130, -20, 0),
    new THREE.Vector3(-106, -35, 0),
    new THREE.Vector3(-74, -19, 0),
    new THREE.Vector3(-42, -33, 0),
    new THREE.Vector3(-27, -33, 0),
    new THREE.Vector3(-32, -8, 0),
    new THREE.Vector3(-16, -5, 0),
    new THREE.Vector3(19, 8, 0),
    new THREE.Vector3(-14, 17, 0),
    new THREE.Vector3(-16, -5, 0),
  ],
  [
    new THREE.Vector3(200, 66, 0),
    new THREE.Vector3(118, 46, 0),
    new THREE.Vector3(80, 51, 0),
    new THREE.Vector3(39, 47, 0),
    new THREE.Vector3(30, 55, 0),
    new THREE.Vector3(55, 80, 0),
    new THREE.Vector3(70, 77, 0),
    new THREE.Vector3(70, 77, 0),
    new THREE.Vector3(80, 51, 0),
    new THREE.Vector3(74, 6, 0),
    new THREE.Vector3(65, -16, 0),
    new THREE.Vector3(53, -42, 0),
  ],
  [
    new THREE.Vector3(-24, 81, 0),
    new THREE.Vector3(-39, 65, 0),
    new THREE.Vector3(-54, 32, 0),
    new THREE.Vector3(-63, 41, 0),
    new THREE.Vector3(-39, 65, 0),
    new THREE.Vector3(-39, 65, 0),
  ],
  [
    new THREE.Vector3(-160, 75, 0),
    new THREE.Vector3(-153, 70, 0),
    new THREE.Vector3(-153, 70, 0),
    new THREE.Vector3(-108, 55, 0),
    new THREE.Vector3(-63, 41, 0),
  ],
  [new THREE.Vector3(-32, 197, 0), new THREE.Vector3(62, 189, 0)],
  [
    new THREE.Vector3(-96, -176, 0),
    new THREE.Vector3(-74, -152, 0),
    new THREE.Vector3(-63, -135, 0),
    new THREE.Vector3(25, -94, 0),
    new THREE.Vector3(-48, -78, 0),
  ],
]

// Lines B
export const linesB = [
  [
    new THREE.Vector3(-176, 185, 0),
    new THREE.Vector3(-193, 161, 0),
    new THREE.Vector3(-179, 152, 0),
    new THREE.Vector3(-176, 117, 0),
    new THREE.Vector3(-157, 120, 0),
    new THREE.Vector3(-138, 122, 0),
    new THREE.Vector3(-141, 102, 0),
    new THREE.Vector3(-97, 119, 0),
    new THREE.Vector3(-99, 144, 0),
    new THREE.Vector3(-94, 149, 0),
    new THREE.Vector3(-96, 167, 0),
    new THREE.Vector3(-63, 152, 0),
  ],
  [
    new THREE.Vector3(-16, 194, 0),
    new THREE.Vector3(-57, 184, 0),
    new THREE.Vector3(-54, 165, 0),
    new THREE.Vector3(-63, 152, 0),
    new THREE.Vector3(-22, 159, 0),
    new THREE.Vector3(-22, 159, 0),
    new THREE.Vector3(-15, 146, 0),
    new THREE.Vector3(-30, 128, 0),
    new THREE.Vector3(-63, 152, 0),
  ],
  [
    new THREE.Vector3(36, 198, 0),
    new THREE.Vector3(39, 191, 0),
    new THREE.Vector3(38, 186, 0),
    new THREE.Vector3(77, 165, 0),
  ],
  [
    new THREE.Vector3(96, 195, 0),
    new THREE.Vector3(77, 165, 0),
    new THREE.Vector3(87, 138, 0),
    new THREE.Vector3(88, 127, 0),
    new THREE.Vector3(96, 104, 0),
    new THREE.Vector3(50, 87, 0),
    new THREE.Vector3(40, 99, 0),
    new THREE.Vector3(-35, 96, 0),
    new THREE.Vector3(-73, 66, 0),
    new THREE.Vector3(-48, 37, 0),
    new THREE.Vector3(-7, 34, 0),
    new THREE.Vector3(5, 20, 0),
    new THREE.Vector3(50, 87, 0),
    new THREE.Vector3(82, 55, 0),
    new THREE.Vector3(145, 58, 0),
  ],
  [
    new THREE.Vector3(82, 55, 0),
    new THREE.Vector3(65, 39, 0),
    new THREE.Vector3(80, 35, 0),
    new THREE.Vector3(66, -2, 0),
  ],
  [
    new THREE.Vector3(186, 184, 0),
    new THREE.Vector3(177, 158, 0),
    new THREE.Vector3(161, 138, 0),
    new THREE.Vector3(160, 130, 0),
    new THREE.Vector3(162, 114, 0),
    new THREE.Vector3(187, 78, 0),
    new THREE.Vector3(180, 68, 0),
    new THREE.Vector3(180, 68, 0),
    new THREE.Vector3(145, 58, 0),
    new THREE.Vector3(149, 38, 0),
    new THREE.Vector3(180, 68, 0),
    new THREE.Vector3(162, 114, 0),
  ],
  [
    new THREE.Vector3(-196, -8, 0),
    new THREE.Vector3(-178, 54, 0),
    new THREE.Vector3(-167, 54, 0),
    new THREE.Vector3(-159, 43, 0),
    new THREE.Vector3(-135, -11, 0),
  ],
  [
    new THREE.Vector3(-125, -115, 0),
    new THREE.Vector3(-131, -135, 0),
    new THREE.Vector3(-111, -152, 0),
    new THREE.Vector3(-119, -156, 0),
    new THREE.Vector3(-120, -164, 0),
    new THREE.Vector3(-132, -174, 0),
    new THREE.Vector3(-149, -162, 0),
  ],
  [new THREE.Vector3(-30, 128, 0), new THREE.Vector3(-35, 96, 0)],
  [
    new THREE.Vector3(-35, 96, 0),
    new THREE.Vector3(-48, 37, 0),
    new THREE.Vector3(-53, -18, 0),
    new THREE.Vector3(-51, -26, 0),
    new THREE.Vector3(-135, -11, 0),
    new THREE.Vector3(-98, -46, 0),
    new THREE.Vector3(-106, -59, 0),
    new THREE.Vector3(-149, -68, 0),
    new THREE.Vector3(-173, -59, 0),
    new THREE.Vector3(-176, -90, 0),
    new THREE.Vector3(-190, -77, 0),
    new THREE.Vector3(-186, -109, 0),
    new THREE.Vector3(-131, -135, 0),
    new THREE.Vector3(-131, -135, 0),
    new THREE.Vector3(-171, -140, 0),
    new THREE.Vector3(-197, -127, 0),
    new THREE.Vector3(-186, -169, 0),
    new THREE.Vector3(-163, -186, 0),
    new THREE.Vector3(-149, -162, 0),
    new THREE.Vector3(-138, -199, 0),
    new THREE.Vector3(-69, -188, 0),
    new THREE.Vector3(-52, -177, 0),
    new THREE.Vector3(-111, -152, 0),
    new THREE.Vector3(-51, -26, 0),
    new THREE.Vector3(-21, -28, 0),
  ],
  [
    new THREE.Vector3(-125, -115, 0),
    new THREE.Vector3(-79, -100, 0),
    new THREE.Vector3(-84, -85, 0),
    new THREE.Vector3(-84, -85, 0),
    new THREE.Vector3(-71, -87, 0),
    new THREE.Vector3(-64, -68, 0),
    new THREE.Vector3(-45, -73, 0),
    new THREE.Vector3(-20, -109, 0),
  ],
  [
    new THREE.Vector3(66, -2, 0),
    new THREE.Vector3(113, -25, 0),
    new THREE.Vector3(80, 35, 0),
    new THREE.Vector3(149, 38, 0),
    new THREE.Vector3(85, 55, 0),
    new THREE.Vector3(149, 38, 0),
    new THREE.Vector3(113, -25, 0),
    new THREE.Vector3(170, -26, 0),
    new THREE.Vector3(170, -26, 0),
    new THREE.Vector3(176, -64, 0),
    new THREE.Vector3(179, -68, 0),
    new THREE.Vector3(174, -86, 0),
    new THREE.Vector3(185, -99, 0),
  ],
  [
    new THREE.Vector3(-52, -177, 0),
    new THREE.Vector3(5, -125, 0),
    new THREE.Vector3(-20, -109, 0),
    new THREE.Vector3(5, -125, 0),
    new THREE.Vector3(-2, -191, 0),
    new THREE.Vector3(61, -172, 0),
    new THREE.Vector3(21, -149, 0),
    new THREE.Vector3(36, -106, 0),
    new THREE.Vector3(66, -2, 0),
  ],
  [
    new THREE.Vector3(36, -106, 0),
    new THREE.Vector3(82, -88, 0),
    new THREE.Vector3(100, -115, 0),
    new THREE.Vector3(88, -190, 0),
    new THREE.Vector3(101, -149, 0),
    new THREE.Vector3(106, -142, 0),
    new THREE.Vector3(139, -138, 0),
    new THREE.Vector3(155, -127, 0),
    new THREE.Vector3(163, -134, 0),
  ],
]

// Lines C
export const linesC = [
  [
    new THREE.Vector3(-196, 130, 0),
    new THREE.Vector3(-170, 134, 0),
    new THREE.Vector3(-167, 159, 0),
    new THREE.Vector3(-139, 142, 0),
    new THREE.Vector3(-125, 146, 0),
    new THREE.Vector3(-116, 164, 0),
    new THREE.Vector3(-89, 184, 0),
    new THREE.Vector3(-112, 105, 0),
    new THREE.Vector3(-185, 48, 0),
    new THREE.Vector3(-148, 55, 0),
    new THREE.Vector3(-112, 105, 0),
    new THREE.Vector3(-148, 55, 0),
    new THREE.Vector3(-142, 34, 0),
  ],
  [
    new THREE.Vector3(-142, 34, 0),
    new THREE.Vector3(-112, 41, 0),
    new THREE.Vector3(-112, 105, 0),
    new THREE.Vector3(-72, 81, 0),
    new THREE.Vector3(-71, 60, 0),
    new THREE.Vector3(-70, 50, 0),
    new THREE.Vector3(-40, 92, 0),
    new THREE.Vector3(-36, 84, 0),
    new THREE.Vector3(-70, 50, 0),
  ],
  [
    new THREE.Vector3(-2, 58, 0),
    new THREE.Vector3(13, 68, 0),
    new THREE.Vector3(33, 88, 0),
    new THREE.Vector3(35, 91, 0),
    new THREE.Vector3(40, 129, 0),
    new THREE.Vector3(-9, 110, 0),
    new THREE.Vector3(19, 143, 0),
    new THREE.Vector3(-9, 171, 0),
    new THREE.Vector3(1, 180, 0),
    new THREE.Vector3(41, 164, 0),
    new THREE.Vector3(40, 129, 0),
  ],
  [
    new THREE.Vector3(41, 164, 0),
    new THREE.Vector3(66, 147, 0),
    new THREE.Vector3(64, 165, 0),
    new THREE.Vector3(67, 171, 0),
    new THREE.Vector3(96, 163, 0),
    new THREE.Vector3(86, 196, 0),
  ],
  [
    new THREE.Vector3(96, 163, 0),
    new THREE.Vector3(104, 128, 0),
    new THREE.Vector3(110, 85, 0),
    new THREE.Vector3(120, 115, 0),
    new THREE.Vector3(104, 128, 0),
  ],
  [
    new THREE.Vector3(120, 115, 0),
    new THREE.Vector3(139, 94, 0),
    new THREE.Vector3(166, 73, 0),
    new THREE.Vector3(181, 32, 0),
    new THREE.Vector3(189, 131, 0),
    new THREE.Vector3(183, 134, 0),
    new THREE.Vector3(177, 128, 0),
    new THREE.Vector3(176, 124, 0),
    new THREE.Vector3(165, 126, 0),
    new THREE.Vector3(167, 154, 0),
    new THREE.Vector3(155, 166, 0),
    new THREE.Vector3(172, 183, 0),
  ],
  [
    new THREE.Vector3(-36, 84, 0),
    new THREE.Vector3(-32, 103, 0),
    new THREE.Vector3(-9, 110, 0),
    new THREE.Vector3(-13, 91, 0),
    new THREE.Vector3(-2, 58, 0),
    new THREE.Vector3(-67, -6, 0),
    new THREE.Vector3(-76, -18, 0),
    new THREE.Vector3(-146, -5, 0),
  ],
  [
    new THREE.Vector3(-67, -6, 0),
    new THREE.Vector3(-146, -12, 0),
    new THREE.Vector3(-178, -4, 0),
    new THREE.Vector3(-161, -67, 0),
    new THREE.Vector3(-76, -18, 0),
  ],
  [
    new THREE.Vector3(-200, -68, 0),
    new THREE.Vector3(-175, -114, 0),
    new THREE.Vector3(-167, -116, 0),
    new THREE.Vector3(-194, -174, 0),
    new THREE.Vector3(-190, -183, 0),
    new THREE.Vector3(-88, -95, 0),
    new THREE.Vector3(-83, -103, 0),
    new THREE.Vector3(-72, -107, 0),
    new THREE.Vector3(-83, -119, 0),
    new THREE.Vector3(-81, -136, 0),
    new THREE.Vector3(-96, -172, 0),
    new THREE.Vector3(-190, -183, 0),
  ],
  [
    new THREE.Vector3(-88, -95, 0),
    new THREE.Vector3(-59, -55, 0),
    new THREE.Vector3(-18, -20, 0),
    new THREE.Vector3(-6, -11, 0),
    new THREE.Vector3(-35, -58, 0),
    new THREE.Vector3(-29, -64, 0),
  ],
  [
    new THREE.Vector3(-29, -64, 0),
    new THREE.Vector3(-6, -11, 0),
    new THREE.Vector3(-3, -47, 0),
    new THREE.Vector3(-16, -59, 0),
    new THREE.Vector3(-29, -87, 0),
    new THREE.Vector3(9, -90, 0),
    new THREE.Vector3(32, -97, 0),
    new THREE.Vector3(60, -122, 0),
    new THREE.Vector3(60, -137, 0),
    new THREE.Vector3(45, -172, 0),
    new THREE.Vector3(29, -165, 0),
    new THREE.Vector3(-2, -151, 0),
    new THREE.Vector3(1, -162, 0),
    new THREE.Vector3(-11, -172, 0),
  ],
  [
    new THREE.Vector3(32, -97, 0),
    new THREE.Vector3(94, -117, 0),
    new THREE.Vector3(125, -136, 0),
    new THREE.Vector3(99, -142, 0),
    new THREE.Vector3(108, -152, 0),
    new THREE.Vector3(122, -157, 0),
    new THREE.Vector3(125, -136, 0),
    new THREE.Vector3(139, -169, 0),
    new THREE.Vector3(163, -194, 0),
    new THREE.Vector3(189, -171, 0),
    new THREE.Vector3(191, -164, 0),
  ],
  [
    new THREE.Vector3(191, -164, 0),
    new THREE.Vector3(190, -115, 0),
    new THREE.Vector3(153, -107, 0),
    new THREE.Vector3(134, -98, 0),
    new THREE.Vector3(144, -82, 0),
    new THREE.Vector3(126, -66, 0),
    new THREE.Vector3(99, -54, 0),
    new THREE.Vector3(149, -58, 0),
    new THREE.Vector3(149, -64, 0),
    new THREE.Vector3(196, -82, 0),
  ],
]

// Lines D
export const linesD = [
  [
    new THREE.Vector3(-186, 193, 0),
    new THREE.Vector3(-160, 139, 0),
    new THREE.Vector3(-147, 183, 0),
    new THREE.Vector3(-145, 172, 0),
    new THREE.Vector3(-141, 164, 0),
    new THREE.Vector3(-98, 171, 0),
    new THREE.Vector3(-88, 196, 0),
    new THREE.Vector3(-87, 176, 0),
    new THREE.Vector3(-84, 169, 0),
    new THREE.Vector3(-101, 135, 0),
    new THREE.Vector3(-136, 92, 0),
    new THREE.Vector3(-139, 107, 0),
    new THREE.Vector3(-151, 108, 0),
    new THREE.Vector3(-168, 83, 0),
    new THREE.Vector3(-136, 92, 0),
  ],
  [new THREE.Vector3(-168, 83, 0), new THREE.Vector3(-187, 108, 0)],

  [
    new THREE.Vector3(-136, 92, 0),
    new THREE.Vector3(-93, 93, 0),
    new THREE.Vector3(-96, 121, 0),
    new THREE.Vector3(-73, 107, 0),
    new THREE.Vector3(-53, 122, 0),
    new THREE.Vector3(-43, 117, 0),
    new THREE.Vector3(-31, 196, 0),
    new THREE.Vector3(-23, 177, 0),
    new THREE.Vector3(-4, 177, 0),
    new THREE.Vector3(3, 123, 0),
    new THREE.Vector3(26, 125, 0),
    new THREE.Vector3(-9, 92, 0),
    new THREE.Vector3(-23, 177, 0),
  ],
  [
    new THREE.Vector3(-23, 177, 0),
    new THREE.Vector3(28, 192, 0),
    new THREE.Vector3(30, 177, 0),
    new THREE.Vector3(41, 159, 0),
    new THREE.Vector3(35, 129, 0),
    new THREE.Vector3(61, 159, 0),
    new THREE.Vector3(124, 71, 0),
    new THREE.Vector3(146, 30, 0),
    new THREE.Vector3(179, 52, 0),
    new THREE.Vector3(170, 62, 0),
    new THREE.Vector3(154, 100, 0),
    new THREE.Vector3(190, 121, 0),
    new THREE.Vector3(149, 109, 0),
    new THREE.Vector3(124, 150, 0),
    new THREE.Vector3(170, 152, 0),
    new THREE.Vector3(110, 179, 0),
    new THREE.Vector3(114, 188, 0),
    new THREE.Vector3(162, 193, 0),
  ],

  [
    new THREE.Vector3(-168, 83, 0),
    new THREE.Vector3(-184, 49, 0),
    new THREE.Vector3(-185, 45, 0),
    new THREE.Vector3(-170, 48, 0),
    new THREE.Vector3(-172, 43, 0),
    new THREE.Vector3(-86, 40, 0),
    new THREE.Vector3(-58, 66, 0),
    new THREE.Vector3(-93, 93, 0),
  ],
  [
    new THREE.Vector3(-172, 43, 0),
    new THREE.Vector3(-167, 14, 0),
    new THREE.Vector3(-129, 17, 0),
    new THREE.Vector3(-108, -9, 0),
    new THREE.Vector3(-106, -10, 0),
    new THREE.Vector3(-184, -13, 0),
    new THREE.Vector3(-135, -46, 0),
    new THREE.Vector3(-180, -55, 0),
  ],
  [
    new THREE.Vector3(-135, -46, 0),
    new THREE.Vector3(-111, -47, 0),
    new THREE.Vector3(-75, -67, 0),
    new THREE.Vector3(-90, -81, 0),
    new THREE.Vector3(-81, -89, 0),
    new THREE.Vector3(-87, -92, 0),
    new THREE.Vector3(-178, -88, 0),
    new THREE.Vector3(-179, -99, 0),
    new THREE.Vector3(-163, -114, 0),
    new THREE.Vector3(-138, -120, 0),
    new THREE.Vector3(-51, -103, 0),
    new THREE.Vector3(-35, 5, 0),
    new THREE.Vector3(-8, 1, 0),
  ],
  [
    new THREE.Vector3(-8, 1, 0),
    new THREE.Vector3(27, -23, 0),
    new THREE.Vector3(24, -40, 0),
    new THREE.Vector3(28, -59, 0),
    new THREE.Vector3(88, -45, 0),
    new THREE.Vector3(27, -23, 0),
  ],
  [
    new THREE.Vector3(28, -59, 0),
    new THREE.Vector3(12, -72, 0),
    new THREE.Vector3(9, -85, 0),
    new THREE.Vector3(33, -89, 0),
    new THREE.Vector3(71, -89, 0),
    new THREE.Vector3(91, -76, 0),
    new THREE.Vector3(159, -83, 0),
    new THREE.Vector3(149, -74, 0),
    new THREE.Vector3(76, 6, 0),
    new THREE.Vector3(101, -9, 0),
    new THREE.Vector3(116, -6, 0),
    new THREE.Vector3(128, -18, 0),
    new THREE.Vector3(149, -21, 0),
    new THREE.Vector3(184, -12, 0),
  ],
  [
    new THREE.Vector3(-163, -114, 0),
    new THREE.Vector3(-171, -129, 0),
    new THREE.Vector3(-188, -152, 0),
    new THREE.Vector3(-117, -151, 0),
    new THREE.Vector3(-46, -129, 0),
    new THREE.Vector3(-49, -136, 0),
    new THREE.Vector3(-65, -169, 0),
    new THREE.Vector3(-19, -197, 0),
    new THREE.Vector3(23, -190, 0),
    new THREE.Vector3(43, -192, 0),
    new THREE.Vector3(56, -118, 0),
    new THREE.Vector3(108, -136, 0),
    new THREE.Vector3(124, -124, 0),
    new THREE.Vector3(136, -190, 0),
    new THREE.Vector3(168, -176, 0),
  ],
]

// Lines E
export const linesE = [
  [
    new THREE.Vector3(-128, 194, 0),
    new THREE.Vector3(-157, 160, 0),
    new THREE.Vector3(-147, 155, 0),
    new THREE.Vector3(-132, 161, 0),
    new THREE.Vector3(-123, 165, 0),
    new THREE.Vector3(-117, 157, 0),
    new THREE.Vector3(-178, 103, 0),
    new THREE.Vector3(-92, 85, 0),
    new THREE.Vector3(-101, 82, 0),
    new THREE.Vector3(-120, 72, 0),
    new THREE.Vector3(-161, 75, 0),
    new THREE.Vector3(-166, 66, 0),
    new THREE.Vector3(-198, 64, 0),
  ],
  [
    new THREE.Vector3(-198, 64, 0),
    new THREE.Vector3(-78, 46, 0),
    new THREE.Vector3(-67, 43, 0),
    new THREE.Vector3(-121, 12, 0),
    new THREE.Vector3(-14, 49, 0),
    new THREE.Vector3(-93, 85, 0),
    new THREE.Vector3(-7, 68, 0),
    new THREE.Vector3(0, 81, 0),
    new THREE.Vector3(12, 80, 0),
    new THREE.Vector3(17, 78, 0),
    new THREE.Vector3(1, 119, 0),
    new THREE.Vector3(-8, 126, 0),
    new THREE.Vector3(-54, 135, 0),
    new THREE.Vector3(-31, 140, 0),
    new THREE.Vector3(-7, 155, 0),
    new THREE.Vector3(-28, 182, 0),
  ],
  [
    new THREE.Vector3(-54, 135, 0),
    new THREE.Vector3(-28, 182, 0),
    new THREE.Vector3(42, 105, 0),
    new THREE.Vector3(81, 130, 0),
    new THREE.Vector3(67, 160, 0),
    new THREE.Vector3(72, 175, 0),
    new THREE.Vector3(79, 166, 0),
    new THREE.Vector3(116, 162, 0),
    new THREE.Vector3(125, 190, 0),
    new THREE.Vector3(141, 170, 0),
    new THREE.Vector3(181, 198, 0),
    new THREE.Vector3(189, 189, 0),
    new THREE.Vector3(183, 179, 0),
    new THREE.Vector3(192, 155, 0),
    new THREE.Vector3(129, 126, 0),
    new THREE.Vector3(95, 106, 0),
    new THREE.Vector3(81, 130, 0),
  ],
  [
    new THREE.Vector3(81, 130, 0),
    new THREE.Vector3(29, 39, 0),
    new THREE.Vector3(48, 20, 0),
    new THREE.Vector3(95, 42, 0),
    new THREE.Vector3(119, 42, 0),
  ],
  [new THREE.Vector3(95, 42, 0), new THREE.Vector3(126, 57, 0)],
  [new THREE.Vector3(95, 42, 0), new THREE.Vector3(122, 67, 0)],
  [new THREE.Vector3(95, 42, 0), new THREE.Vector3(113, 70, 0)],
  [
    new THREE.Vector3(113, 70, 0),
    new THREE.Vector3(179, 86, 0),
    new THREE.Vector3(182, 85, 0),
    new THREE.Vector3(188, 20, 0),
    new THREE.Vector3(193, 6, 0),
    new THREE.Vector3(99, -27, 0),
    new THREE.Vector3(92, -42, 0),
    new THREE.Vector3(62, -53, 0),
    new THREE.Vector3(59, -51, 0),
    new THREE.Vector3(59, -13, 0),
    new THREE.Vector3(44, -20, 0),
    new THREE.Vector3(41, -45, 0),
    new THREE.Vector3(40, -58, 0),
  ],
  [
    new THREE.Vector3(40, -58, 0),
    new THREE.Vector3(-55, -39, 0),
    new THREE.Vector3(-77, -50, 0),
    new THREE.Vector3(-94, -45, 0),
    new THREE.Vector3(-114, -82, 0),
    new THREE.Vector3(-145, -40, 0),
    new THREE.Vector3(-155, -20, 0),
    new THREE.Vector3(-196, -40, 0),
    new THREE.Vector3(-194, -94, 0),
    new THREE.Vector3(-171, -84, 0),
    new THREE.Vector3(-153, -79, 0),
  ],
  [
    new THREE.Vector3(-153, -79, 0),
    new THREE.Vector3(-128, -110, 0),
    new THREE.Vector3(-114, -119, 0),
    new THREE.Vector3(-99, -123, 0),
    new THREE.Vector3(-88, -149, 0),
    new THREE.Vector3(-109, -168, 0),
    new THREE.Vector3(-162, -199, 0),
    new THREE.Vector3(-87, -190, 0),
    new THREE.Vector3(-77, -129, 0),
    new THREE.Vector3(-52, -112, 0),
    new THREE.Vector3(-17, -194, 0),
    new THREE.Vector3(19, -117, 0),
    new THREE.Vector3(56, -84, 0),
    new THREE.Vector3(40, -151, 0),
    new THREE.Vector3(47, -152, 0),
    new THREE.Vector3(53, -194, 0),
  ],
  [
    new THREE.Vector3(53, -194, 0),
    new THREE.Vector3(83, -198, 0),
    new THREE.Vector3(95, -140, 0),
    new THREE.Vector3(96, -119, 0),
    new THREE.Vector3(123, -173, 0),
    new THREE.Vector3(149, -184, 0),
    new THREE.Vector3(155, -189, 0),
    new THREE.Vector3(167, -159, 0),
    new THREE.Vector3(198, -155, 0),
    new THREE.Vector3(200, -152, 0),
    new THREE.Vector3(174, -131, 0),
    new THREE.Vector3(173, -127, 0),
    new THREE.Vector3(164, -94, 0),
    new THREE.Vector3(179, -67, 0),
    new THREE.Vector3(200, -59, 0),
  ],
]

// Lines F
export const linesF = [
  [
    new THREE.Vector3(-194, 190, 0),
    new THREE.Vector3(-199, 177, 0),
    new THREE.Vector3(-131, 160, 0),
    new THREE.Vector3(-132, 133, 0),
    new THREE.Vector3(-139, 116, 0),
    new THREE.Vector3(-119, 83, 0),
    new THREE.Vector3(-98, 71, 0),
    new THREE.Vector3(-95, 109, 0),
    new THREE.Vector3(-56, 149, 0),
    new THREE.Vector3(-60, 183, 0),
  ],
  [new THREE.Vector3(90, 165, 0), new THREE.Vector3(138, 187, 0)],
  [
    new THREE.Vector3(-60, 183, 0),
    new THREE.Vector3(-23, 195, 0),
    new THREE.Vector3(-30, 153, 0),
    new THREE.Vector3(-40, 110, 0),
    new THREE.Vector3(-51, 102, 0),
    new THREE.Vector3(-17, 120, 0),
    new THREE.Vector3(-5, 155, 0),
    new THREE.Vector3(7, 144, 0),
    new THREE.Vector3(29, 139, 0),
    new THREE.Vector3(26, 169, 0),
    new THREE.Vector3(51, 130, 0),
    new THREE.Vector3(60, 142, 0),
    new THREE.Vector3(68, 128, 0),
    new THREE.Vector3(90, 165, 0),
    new THREE.Vector3(83, 191, 0),
    new THREE.Vector3(74, 196, 0),
    new THREE.Vector3(138, 187, 0),
    new THREE.Vector3(119, 120, 0),
    new THREE.Vector3(133, 131, 0),
    new THREE.Vector3(124, 118, 0),
    new THREE.Vector3(129, 111, 0),
    new THREE.Vector3(156, 155, 0),
    new THREE.Vector3(161, 117, 0),
    new THREE.Vector3(200, 163, 0),
  ],
  [
    new THREE.Vector3(161, 117, 0),
    new THREE.Vector3(163, 74, 0),
    new THREE.Vector3(130, 77, 0),
    new THREE.Vector3(103, 52, 0),
    new THREE.Vector3(116, 35, 0),
    new THREE.Vector3(156, 32, 0),
    new THREE.Vector3(165, 30, 0),
    new THREE.Vector3(192, 24, 0),
    new THREE.Vector3(178, 9, 0),
    new THREE.Vector3(175, -15, 0),
    new THREE.Vector3(175, -56, 0),
    new THREE.Vector3(157, -50, 0),
    new THREE.Vector3(138, -28, 0),
    new THREE.Vector3(57, 9, 0),
    new THREE.Vector3(47, -2, 0),
    new THREE.Vector3(69, -66, 0),
    new THREE.Vector3(75, -71, 0),
  ],
  [
    new THREE.Vector3(75, -71, 0),
    new THREE.Vector3(122, -78, 0),
    new THREE.Vector3(138, -83, 0),
    new THREE.Vector3(109, -141, 0),
    new THREE.Vector3(127, -134, 0),
    new THREE.Vector3(141, -141, 0),
    new THREE.Vector3(158, -154, 0),
    new THREE.Vector3(164, -164, 0),
    new THREE.Vector3(154, -169, 0),
    new THREE.Vector3(128, -198, 0),
    new THREE.Vector3(63, -199, 0),
    new THREE.Vector3(66, -150, 0),
    new THREE.Vector3(49, -148, 0),
    new THREE.Vector3(23, -119, 0),
  ],
  [
    new THREE.Vector3(23, -119, 0),
    new THREE.Vector3(24, -87, 0),
    new THREE.Vector3(20, -50, 0),
    new THREE.Vector3(22, -34, 0),
    new THREE.Vector3(18, -45, 0),
    new THREE.Vector3(41, 48, 0),
    new THREE.Vector3(54, 55, 0),
    new THREE.Vector3(65, 90, 0),
    new THREE.Vector3(-5, 97, 0),
    new THREE.Vector3(-7, 52, 0),
    new THREE.Vector3(8, 39, 0),
    new THREE.Vector3(-26, -17, 0),
    new THREE.Vector3(-15, -53, 0),
    new THREE.Vector3(-14, -68, 0),
    new THREE.Vector3(-47, -87, 0),
    new THREE.Vector3(-50, -60, 0),
    new THREE.Vector3(-50, -57, 0),
    new THREE.Vector3(-62, -70, 0),
    new THREE.Vector3(-67, -89, 0),
    new THREE.Vector3(-48, -94, 0),
    new THREE.Vector3(-18, -131, 0),
    new THREE.Vector3(-33, -144, 0),
  ],
  [
    new THREE.Vector3(-33, -144, 0),
    new THREE.Vector3(-9, -174, 0),
    new THREE.Vector3(33, -192, 0),
    new THREE.Vector3(-4, -193, 0),
    new THREE.Vector3(-31, -199, 0),
    new THREE.Vector3(-41, -195, 0),
    new THREE.Vector3(-68, -169, 0),
    new THREE.Vector3(-84, -183, 0),
    new THREE.Vector3(-144, -148, 0),
    new THREE.Vector3(-135, -127, 0),
    new THREE.Vector3(-128, -102, 0),
    new THREE.Vector3(-167, -70, 0),
    new THREE.Vector3(-83, -31, 0),
    new THREE.Vector3(-76, -18, 0),
    new THREE.Vector3(-125, 3, 0),
    new THREE.Vector3(-158, -12, 0),
    new THREE.Vector3(-154, -37, 0),
    new THREE.Vector3(-173, -30, 0),
  ],
]

// Lines G
export const linesG = [
  [
    new THREE.Vector3(-173, 168, 0),
    new THREE.Vector3(-190, 128, 0),
    new THREE.Vector3(-116, 161, 0),
    new THREE.Vector3(-100, 176, 0),
    new THREE.Vector3(-64, 158, 0),
    new THREE.Vector3(-61, 191, 0),
    new THREE.Vector3(-36, 184, 0),
    new THREE.Vector3(-7, 198, 0),
    new THREE.Vector3(-15, 122, 0),
    new THREE.Vector3(-10, 109, 0),
    new THREE.Vector3(2, 86, 0),
    new THREE.Vector3(-67, 71, 0),
    new THREE.Vector3(-53, 52, 0),
    new THREE.Vector3(-68, 40, 0),
    new THREE.Vector3(-90, 61, 0),
    new THREE.Vector3(-87, 82, 0),
    new THREE.Vector3(-131, 80, 0),
    new THREE.Vector3(-129, 51, 0),
    new THREE.Vector3(-138, 45, 0),
    new THREE.Vector3(-182, 84, 0),
    new THREE.Vector3(-200, 93, 0),
    new THREE.Vector3(-200, 58, 0),
    new THREE.Vector3(-196, 47, 0),
    new THREE.Vector3(-184, 51, 0),
  ],
  [
    new THREE.Vector3(-184, 51, 0),
    new THREE.Vector3(-195, 10, 0),
    new THREE.Vector3(-198, 5, 0),
    new THREE.Vector3(-173, 6, 0),
    new THREE.Vector3(-179, -1, 0),
    new THREE.Vector3(-160, -8, 0),
    new THREE.Vector3(-123, 1, 0),
    new THREE.Vector3(-67, 20, 0),
    new THREE.Vector3(-78, 34, 0),
    new THREE.Vector3(-5, 45, 0),
    new THREE.Vector3(42, 25, 0),
    new THREE.Vector3(57, 64, 0),
    new THREE.Vector3(17, 161, 0),
    new THREE.Vector3(76, 144, 0),
  ],
  [
    new THREE.Vector3(64, 159, 0),
    new THREE.Vector3(91, 138, 0),
    new THREE.Vector3(102, 135, 0),
    new THREE.Vector3(126, 111, 0),
    new THREE.Vector3(150, 135, 0),
    new THREE.Vector3(166, 192, 0),
    new THREE.Vector3(195, 147, 0),
    new THREE.Vector3(191, 130, 0),
    new THREE.Vector3(197, 85, 0),
    new THREE.Vector3(162, 72, 0),
    new THREE.Vector3(110, 55, 0),
    new THREE.Vector3(179, 30, 0),
    new THREE.Vector3(200, -10, 0),
    new THREE.Vector3(157, -4, 0),
    new THREE.Vector3(101, 8, 0),
  ],
  [new THREE.Vector3(150, 135, 0), new THREE.Vector3(195, 147, 0)],
  [
    new THREE.Vector3(101, 8, 0),
    new THREE.Vector3(68, -21, 0),
    new THREE.Vector3(64, -22, 0),
    new THREE.Vector3(38, -14, 0),
    new THREE.Vector3(18, -42, 0),
    new THREE.Vector3(80, -54, 0),
    new THREE.Vector3(70, -69, 0),
    new THREE.Vector3(71, -72, 0),
    new THREE.Vector3(76, -73, 0),
    new THREE.Vector3(87, -80, 0),
    new THREE.Vector3(72, -89, 0),
    new THREE.Vector3(120, -74, 0),
    new THREE.Vector3(149, -87, 0),
    new THREE.Vector3(137, -109, 0),
    new THREE.Vector3(145, -108, 0),
    new THREE.Vector3(174, -110, 0),
    new THREE.Vector3(174, -119, 0),
    new THREE.Vector3(150, -153, 0),
    new THREE.Vector3(131, -165, 0),
    new THREE.Vector3(112, -170, 0),
    new THREE.Vector3(124, -148, 0),
    new THREE.Vector3(84, -126, 0),
    new THREE.Vector3(54, -143, 0),
    new THREE.Vector3(40, -109, 0),
    new THREE.Vector3(27, -119, 0),
    new THREE.Vector3(29, -161, 0),
    new THREE.Vector3(69, -155, 0),
    new THREE.Vector3(102, -196, 0),
  ],
  [
    new THREE.Vector3(-145, -84, 0),
    new THREE.Vector3(-145, -101, 0),
    new THREE.Vector3(-137, -114, 0),
  ],
  [
    new THREE.Vector3(102, -196, 0),
    new THREE.Vector3(-50, -172, 0),
    new THREE.Vector3(-46, -169, 0),
    new THREE.Vector3(-34, -137, 0),
    new THREE.Vector3(-39, -91, 0),
    new THREE.Vector3(-52, -38, 0),
    new THREE.Vector3(-67, -127, 0),
    new THREE.Vector3(-89, -46, 0),
    new THREE.Vector3(-109, -61, 0),
    new THREE.Vector3(-151, -27, 0),
    new THREE.Vector3(-162, -36, 0),
    new THREE.Vector3(-144, -82, 0),
    new THREE.Vector3(-145, -84, 0),
    new THREE.Vector3(-122, -99, 0),
    new THREE.Vector3(-137, -114, 0),
    new THREE.Vector3(-131, -147, 0),
    new THREE.Vector3(-172, -72, 0),
    new THREE.Vector3(-200, -105, 0),
    new THREE.Vector3(-137, -160, 0),
    new THREE.Vector3(-172, -187, 0),
  ],
]

// Lines H
export const linesH = [
  [
    new THREE.Vector3(-169, 178, 0),
    new THREE.Vector3(-152, 168, 0),
    new THREE.Vector3(-145, 180, 0),
    new THREE.Vector3(-67, 186, 0),
    new THREE.Vector3(-43, 155, 0),
    new THREE.Vector3(-39, 172, 0),
    new THREE.Vector3(-25, 195, 0),
    new THREE.Vector3(23, 162, 0),
    new THREE.Vector3(25, 142, 0),
    new THREE.Vector3(-4, 120, 0),
    new THREE.Vector3(-37, 109, 0),
    new THREE.Vector3(-36, 117, 0),
    new THREE.Vector3(-58, 101, 0),
    new THREE.Vector3(-88, 115, 0),
    new THREE.Vector3(-148, 137, 0),
    new THREE.Vector3(-188, 110, 0),
    new THREE.Vector3(-197, 109, 0),
  ],
  [
    new THREE.Vector3(25, 142, 0),
    new THREE.Vector3(49, 142, 0),
    new THREE.Vector3(7, 99, 0),
    new THREE.Vector3(25, 97, 0),
    new THREE.Vector3(62, 105, 0),
    new THREE.Vector3(106, 150, 0),
    new THREE.Vector3(109, 154, 0),
    new THREE.Vector3(98, 196, 0),
    new THREE.Vector3(123, 168, 0),
    new THREE.Vector3(130, 163, 0),
    new THREE.Vector3(167, 172, 0),
    new THREE.Vector3(185, 169, 0),
    new THREE.Vector3(122, 92, 0),
    new THREE.Vector3(128, 81, 0),
    new THREE.Vector3(101, 74, 0),
    new THREE.Vector3(73, 75, 0),
    new THREE.Vector3(111, 45, 0),
    new THREE.Vector3(143, 67, 0),
    new THREE.Vector3(161, 65, 0),
    new THREE.Vector3(177, 67, 0),
  ],
  [
    new THREE.Vector3(177, 67, 0),
    new THREE.Vector3(133, 33, 0),
    new THREE.Vector3(61, 15, 0),
    new THREE.Vector3(70, 1, 0),
    new THREE.Vector3(103, -2, 0),
    new THREE.Vector3(174, 39, 0),
    new THREE.Vector3(190, 21, 0),
    new THREE.Vector3(199, 8, 0),
    new THREE.Vector3(170, -28, 0),
    new THREE.Vector3(142, -50, 0),
    new THREE.Vector3(148, -134, 0),
    new THREE.Vector3(152, -152, 0),
    new THREE.Vector3(107, -118, 0),
    new THREE.Vector3(136, -148, 0),
    new THREE.Vector3(135, -165, 0),
    new THREE.Vector3(79, -166, 0),
    new THREE.Vector3(55, -179, 0),
  ],
  [
    new THREE.Vector3(55, -179, 0),
    new THREE.Vector3(-27, -187, 0),
    new THREE.Vector3(-13, -165, 0),
    new THREE.Vector3(4, -125, 0),
    new THREE.Vector3(1, -105, 0),
    new THREE.Vector3(-20, -113, 0),
    new THREE.Vector3(-27, -137, 0),
    new THREE.Vector3(-56, -170, 0),
    new THREE.Vector3(-69, -179, 0),
    new THREE.Vector3(-68, -193, 0),
    new THREE.Vector3(-87, -179, 0),
    new THREE.Vector3(-87, -140, 0),
    new THREE.Vector3(-63, -89, 0),
    new THREE.Vector3(-102, -113, 0),
    new THREE.Vector3(-85, -70, 0),
    new THREE.Vector3(-52, -49, 0),
    new THREE.Vector3(-18, -48, 0),
    new THREE.Vector3(-34, -28, 0),
    new THREE.Vector3(-37, -27, 0),
    new THREE.Vector3(1, -4, 0),
    new THREE.Vector3(8, 17, 0),
    new THREE.Vector3(-11, 34, 0),
  ],
  [
    new THREE.Vector3(22, 46, 0),
    new THREE.Vector3(-29, 87, 0),
    new THREE.Vector3(-63, 62, 0),
    new THREE.Vector3(-67, 44, 0),
    new THREE.Vector3(-74, 38, 0),
    new THREE.Vector3(-103, 81, 0),
    new THREE.Vector3(-177, 28, 0),
    new THREE.Vector3(-198, -1, 0),
    new THREE.Vector3(-166, -4, 0),
    new THREE.Vector3(-107, -15, 0),
    new THREE.Vector3(-134, -22, 0),
    new THREE.Vector3(-181, -43, 0),
    new THREE.Vector3(-196, -58, 0),
    new THREE.Vector3(-126, -52, 0),
    new THREE.Vector3(-87, -67, 0),
  ],
  [
    new THREE.Vector3(-102, -113, 0),
    new THREE.Vector3(-109, -108, 0),
    new THREE.Vector3(-130, -78, 0),
    new THREE.Vector3(-133, -69, 0),
    new THREE.Vector3(-155, -85, 0),
    new THREE.Vector3(-173, -132, 0),
    new THREE.Vector3(-176, -137, 0),
    new THREE.Vector3(-171, -146, 0),
    new THREE.Vector3(-200, -164, 0),
  ],
]

// Lines I

export const linesI = [
  [
    new THREE.Vector3(-185, 173, 0),
    new THREE.Vector3(-115, 157, 0),
    new THREE.Vector3(-101, 193, 0),
    new THREE.Vector3(-48, 196, 0),
    new THREE.Vector3(16, 197, 0),
    new THREE.Vector3(7, 146, 0),
    new THREE.Vector3(-27, 141, 0),
    new THREE.Vector3(-37, 110, 0),
    new THREE.Vector3(-54, 88, 0),
    new THREE.Vector3(-60, 95, 0),
    new THREE.Vector3(-99, 104, 0),
    new THREE.Vector3(-120, 111, 0),
    new THREE.Vector3(-167, 135, 0),
  ],
  [
    new THREE.Vector3(7, 146, 0),
    new THREE.Vector3(36, 179, 0),
    new THREE.Vector3(43, 148, 0),
    new THREE.Vector3(81, 169, 0),
    new THREE.Vector3(88, 172, 0),
    new THREE.Vector3(89, 145, 0),
    new THREE.Vector3(150, 142, 0),
    new THREE.Vector3(158, 86, 0),
    new THREE.Vector3(102, 92, 0),
    new THREE.Vector3(91, 102, 0),
    new THREE.Vector3(78, 101, 0),
    new THREE.Vector3(125, 59, 0),
    new THREE.Vector3(128, 58, 0),
    new THREE.Vector3(156, 55, 0),
    new THREE.Vector3(180, 23, 0),
    new THREE.Vector3(120, 21, 0),
    new THREE.Vector3(104, 24, 0),
    new THREE.Vector3(42, 52, 0),
    new THREE.Vector3(20, 33, 0),
    new THREE.Vector3(-2, 16, 0),
    new THREE.Vector3(-10, 11, 0),
    new THREE.Vector3(-75, 58, 0),
    new THREE.Vector3(-113, 74, 0),
  ],
  [
    new THREE.Vector3(-113, 74, 0),
    new THREE.Vector3(-95, 17, 0),
    new THREE.Vector3(-122, 49, 0),
    new THREE.Vector3(-148, 48, 0),
    new THREE.Vector3(-186, 22, 0),
    new THREE.Vector3(-149, 8, 0),
    new THREE.Vector3(-122, 31, 0),
    new THREE.Vector3(-116, 30, 0),
    new THREE.Vector3(-113, 13, 0),
    new THREE.Vector3(-108, -9, 0),
    new THREE.Vector3(-188, -32, 0),
    new THREE.Vector3(-195, -38, 0),
    new THREE.Vector3(-171, -65, 0),
    new THREE.Vector3(-167, -75, 0),
    new THREE.Vector3(-158, -69, 0),
    new THREE.Vector3(-152, -75, 0),
    new THREE.Vector3(-179, -103, 0),
    new THREE.Vector3(-173, -125, 0),
    new THREE.Vector3(-141, -140, 0),
    new THREE.Vector3(-161, -170, 0),
    new THREE.Vector3(-178, -175, 0),
  ],
  [
    new THREE.Vector3(-178, -175, 0),
    new THREE.Vector3(-110, -166, 0),
    new THREE.Vector3(-93, -199, 0),
    new THREE.Vector3(-88, -169, 0),
    new THREE.Vector3(-101, -131, 0),
    new THREE.Vector3(-100, -86, 0),
    new THREE.Vector3(-108, -58, 0),
    new THREE.Vector3(-87, -39, 0),
    new THREE.Vector3(-84, -67, 0),
    new THREE.Vector3(-76, -70, 0),
    new THREE.Vector3(-64, -42, 0),
    new THREE.Vector3(-61, -143, 0),
    new THREE.Vector3(-43, -143, 0),
    new THREE.Vector3(-34, -120, 0),
    new THREE.Vector3(-32, -95, 0),
    new THREE.Vector3(-17, -75, 0),
    new THREE.Vector3(5, -93, 0),
    new THREE.Vector3(-20, -148, 0),
    new THREE.Vector3(-31, -175, 0),
    new THREE.Vector3(-25, -188, 0),
    new THREE.Vector3(-13, -185, 0),
  ],
  [
    new THREE.Vector3(120, 21, 0),
    new THREE.Vector3(134, 8, 0),
    new THREE.Vector3(126, -12, 0),
    new THREE.Vector3(103, -42, 0),
    new THREE.Vector3(172, -41, 0),
    new THREE.Vector3(110, -75, 0),
    new THREE.Vector3(142, -89, 0),
    new THREE.Vector3(189, -101, 0),
    new THREE.Vector3(193, -127, 0),
    new THREE.Vector3(165, -154, 0),
    new THREE.Vector3(149, -170, 0),
    new THREE.Vector3(133, -118, 0),
    new THREE.Vector3(98, -137, 0),
    new THREE.Vector3(91, -112, 0),
    new THREE.Vector3(79, -145, 0),
    new THREE.Vector3(55, -126, 0),
    new THREE.Vector3(49, -104, 0),
    new THREE.Vector3(35, -125, 0),
    new THREE.Vector3(19, -126, 0),
    new THREE.Vector3(10, -136, 0),
    new THREE.Vector3(69, -193, 0),
  ],
  [
    new THREE.Vector3(103, -42, 0),
    new THREE.Vector3(67, -61, 0),
    new THREE.Vector3(64, -33, 0),
    new THREE.Vector3(66, 1, 0),
    new THREE.Vector3(78, -1, 0),
    new THREE.Vector3(104, 24, 0),
  ],
]

// Lines J

export const linesJ = [
  [
    new THREE.Vector3(-199, 197, 0),
    new THREE.Vector3(-175, 195, 0),
    new THREE.Vector3(-176, 181, 0),
    new THREE.Vector3(-147, 179, 0),
    new THREE.Vector3(-108, 180, 0),
    new THREE.Vector3(-97, 176, 0),
    new THREE.Vector3(-80, 171, 0),
    new THREE.Vector3(-71, 180, 0),
    new THREE.Vector3(-50, 176, 0),
    new THREE.Vector3(-78, 141, 0),
    new THREE.Vector3(-90, 130, 0),
    new THREE.Vector3(-82, 118, 0),
    new THREE.Vector3(-55, 101, 0),
    new THREE.Vector3(25, 130, 0),
    new THREE.Vector3(15, 148, 0),
    new THREE.Vector3(28, 181, 0),
    new THREE.Vector3(56, 163, 0),
    new THREE.Vector3(78, 133, 0),
    new THREE.Vector3(84, 167, 0),
    new THREE.Vector3(112, 161, 0),
    new THREE.Vector3(98, 139, 0),
    new THREE.Vector3(95, 117, 0),
    new THREE.Vector3(104, 101, 0),
    new THREE.Vector3(143, 91, 0),
    new THREE.Vector3(178, 96, 0),
    new THREE.Vector3(197, 155, 0),
    new THREE.Vector3(148, 184, 0),
  ],
  [
    new THREE.Vector3(-90, 130, 0),
    new THREE.Vector3(-121, 100, 0),
    new THREE.Vector3(-126, 100, 0),
    new THREE.Vector3(-176, 135, 0),
    new THREE.Vector3(-166, 90, 0),
    new THREE.Vector3(-166, 85, 0),
    new THREE.Vector3(-171, 80, 0),
    new THREE.Vector3(-191, 63, 0),
    new THREE.Vector3(-68, 52, 0),
    new THREE.Vector3(9, 47, 0),
    new THREE.Vector3(-8, 15, 0),
    new THREE.Vector3(-28, 2, 0),
    new THREE.Vector3(-44, -7, 0),
    new THREE.Vector3(-69, -2, 0),
    new THREE.Vector3(-100, -7, 0),
    new THREE.Vector3(-128, 22, 0),
    new THREE.Vector3(-129, 5, 0),
    new THREE.Vector3(-146, 7, 0),
    new THREE.Vector3(-158, 45, 0),
  ],
  [
    new THREE.Vector3(-8, 15, 0),
    new THREE.Vector3(45, 55, 0),
    new THREE.Vector3(109, 46, 0),
    new THREE.Vector3(140, 40, 0),
    new THREE.Vector3(125, 11, 0),
    new THREE.Vector3(200, 69, 0),
    new THREE.Vector3(184, 10, 0),
    new THREE.Vector3(180, -3, 0),
    new THREE.Vector3(99, -14, 0),
    new THREE.Vector3(105, -26, 0),
    new THREE.Vector3(96, -34, 0),
    new THREE.Vector3(121, -42, 0),
    new THREE.Vector3(134, -55, 0),
    new THREE.Vector3(163, -55, 0),
  ],
  [
    new THREE.Vector3(96, -34, 0),
    new THREE.Vector3(39, -52, 0),
    new THREE.Vector3(75, -80, 0),
    new THREE.Vector3(74, -88, 0),
    new THREE.Vector3(107, -111, 0),
    new THREE.Vector3(177, -120, 0),
    new THREE.Vector3(165, -148, 0),
    new THREE.Vector3(153, -166, 0),
    new THREE.Vector3(165, -170, 0),
    new THREE.Vector3(138, -188, 0),
    new THREE.Vector3(110, -184, 0),
    new THREE.Vector3(69, -163, 0),
    new THREE.Vector3(79, -141, 0),
    new THREE.Vector3(49, -124, 0),
    new THREE.Vector3(25, -113, 0),
    new THREE.Vector3(44, -83, 0),
    new THREE.Vector3(41, -83, 0),
    new THREE.Vector3(39, -52, 0),
    new THREE.Vector3(2, -53, 0),
    new THREE.Vector3(-1, -41, 0),
  ],
  [
    new THREE.Vector3(-1, -41, 0),
    new THREE.Vector3(-28, -90, 0),
    new THREE.Vector3(38, -163, 0),
    new THREE.Vector3(1, -165, 0),
    new THREE.Vector3(-35, -170, 0),
    new THREE.Vector3(-75, -181, 0),
    new THREE.Vector3(-44, -149, 0),
    new THREE.Vector3(-63, -134, 0),
    new THREE.Vector3(-82, -131, 0),
    new THREE.Vector3(-138, -157, 0),
    new THREE.Vector3(-154, -168, 0),
    new THREE.Vector3(-167, -192, 0),
    new THREE.Vector3(-193, -166, 0),
    new THREE.Vector3(-153, -136, 0),
    new THREE.Vector3(-122, -82, 0),
    new THREE.Vector3(-136, -78, 0),
    new THREE.Vector3(-103, -57, 0),
    new THREE.Vector3(-112, -53, 0),
    new THREE.Vector3(-126, -35, 0),
    new THREE.Vector3(-165, -15, 0),
    new THREE.Vector3(-173, -3, 0),
    new THREE.Vector3(-180, -17, 0),
    new THREE.Vector3(-159, -59, 0),
  ],
  [
    new THREE.Vector3(-122, -82, 0),
    new THREE.Vector3(-98, -102, 0),
    new THREE.Vector3(-51, -43, 0),
  ],
]

// Lines K

export const linesK = [
  [
    new THREE.Vector3(-166, 145, 0),
    new THREE.Vector3(-159, 146, 0),
    new THREE.Vector3(-158, 147, 0),
    new THREE.Vector3(-85, 193, 0),
    new THREE.Vector3(-108, 143, 0),
    new THREE.Vector3(-96, 129, 0),
    new THREE.Vector3(-178, 117, 0),
    new THREE.Vector3(-164, 90, 0),
    new THREE.Vector3(-105, 98, 0),
    new THREE.Vector3(-83, 100, 0),
    new THREE.Vector3(-69, 88, 0),
    new THREE.Vector3(-69, 70, 0),
    new THREE.Vector3(-77, 37, 0),
    new THREE.Vector3(-97, 34, 0),
    new THREE.Vector3(-115, 10, 0),
    new THREE.Vector3(-132, -11, 0),
    new THREE.Vector3(-142, -13, 0),
    new THREE.Vector3(-161, -7, 0),
    new THREE.Vector3(-171, 1, 0),
    new THREE.Vector3(-154, 21, 0),
    new THREE.Vector3(-171, 27, 0),
    new THREE.Vector3(-182, 36, 0),
    new THREE.Vector3(-185, 47, 0),
    new THREE.Vector3(-200, 51, 0),
    new THREE.Vector3(-154, 58, 0),
  ],
  [
    new THREE.Vector3(-77, 37, 0),
    new THREE.Vector3(-64, 20, 0),
    new THREE.Vector3(-161, -57, 0),
    new THREE.Vector3(-143, -69, 0),
    new THREE.Vector3(-141, -79, 0),
    new THREE.Vector3(-137, -91, 0),
    new THREE.Vector3(-170, -90, 0),
    new THREE.Vector3(-197, -127, 0),
    new THREE.Vector3(-145, -141, 0),
    new THREE.Vector3(-111, -112, 0),
    new THREE.Vector3(-113, -128, 0),
    new THREE.Vector3(-116, -147, 0),
    new THREE.Vector3(-99, -156, 0),
    new THREE.Vector3(-82, -158, 0),
    new THREE.Vector3(-60, -154, 0),
    new THREE.Vector3(-84, -192, 0),
    new THREE.Vector3(-120, -171, 0),
    new THREE.Vector3(-169, -189, 0),
  ],
  [
    new THREE.Vector3(-60, -154, 0),
    new THREE.Vector3(-27, -164, 0),
    new THREE.Vector3(-6, -159, 0),
    new THREE.Vector3(19, -127, 0),
    new THREE.Vector3(9, -119, 0),
    new THREE.Vector3(-44, -114, 0),
    new THREE.Vector3(-42, -108, 0),
    new THREE.Vector3(-43, -77, 0),
    new THREE.Vector3(-21, -50, 0),
    new THREE.Vector3(8, -41, 0),
    new THREE.Vector3(27, -35, 0),
    new THREE.Vector3(42, -41, 0),
    new THREE.Vector3(72, -14, 0),
    new THREE.Vector3(68, 3, 0),
    new THREE.Vector3(16, 14, 0),
    new THREE.Vector3(30, 27, 0),
    new THREE.Vector3(59, 64, 0),
    new THREE.Vector3(3, 71, 0),
    new THREE.Vector3(6, 92, 0),
    new THREE.Vector3(1, 95, 0),
    new THREE.Vector3(0, 118, 0),
    new THREE.Vector3(42, 117, 0),
    new THREE.Vector3(44, 126, 0),
    new THREE.Vector3(49, 140, 0),
    new THREE.Vector3(55, 169, 0),
    new THREE.Vector3(47, 186, 0),
  ],
  [
    new THREE.Vector3(0, 118, 0),
    new THREE.Vector3(-39, 158, 0),
    new THREE.Vector3(-39, 158, 0),
    new THREE.Vector3(-39, 158, 0),
    new THREE.Vector3(-39, 158, 0),
    new THREE.Vector3(11, -22, 0),
    new THREE.Vector3(8, -41, 0),
    new THREE.Vector3(11, -22, 0),
    new THREE.Vector3(8, -41, 0),
  ],
  [
    new THREE.Vector3(19, -127, 0),
    new THREE.Vector3(51, -160, 0),
    new THREE.Vector3(64, -169, 0),
    new THREE.Vector3(31, -183, 0),
    new THREE.Vector3(55, -190, 0),
    new THREE.Vector3(71, -193, 0),
    new THREE.Vector3(72, -194, 0),
    new THREE.Vector3(184, -193, 0),
    new THREE.Vector3(193, -186, 0),
    new THREE.Vector3(183, -166, 0),
    new THREE.Vector3(147, -156, 0),
    new THREE.Vector3(175, -144, 0),
    new THREE.Vector3(197, -131, 0),
    new THREE.Vector3(189, -110, 0),
    new THREE.Vector3(106, -113, 0),
    new THREE.Vector3(127, -95, 0),
    new THREE.Vector3(133, -83, 0),
    new THREE.Vector3(142, -48, 0),
    new THREE.Vector3(154, -42, 0),
    new THREE.Vector3(166, -43, 0),
    new THREE.Vector3(150, -19, 0),
    new THREE.Vector3(127, -12, 0),
    new THREE.Vector3(168, 65, 0),
    new THREE.Vector3(120, 121, 0),
    new THREE.Vector3(185, 147, 0),
    new THREE.Vector3(134, 154, 0),
    new THREE.Vector3(176, 164, 0),
    new THREE.Vector3(155, 173, 0),
    new THREE.Vector3(182, 189, 0),
  ],
  [
    new THREE.Vector3(120, 121, 0),
    new THREE.Vector3(127, 63, 0),
    new THREE.Vector3(127, -12, 0),
  ],
  [
    new THREE.Vector3(-145, -141, 0),
    new THREE.Vector3(-156, -157, 0),
    new THREE.Vector3(-169, -189, 0),
  ],
]

// Lines L

export const linesL = [
  [
    new THREE.Vector3(-148, 180, 0),
    new THREE.Vector3(-156, 155, 0),
    new THREE.Vector3(-91, 146, 0),
    new THREE.Vector3(-34, 200, 0),
    new THREE.Vector3(-31, 187, 0),
    new THREE.Vector3(-18, 174, 0),
    new THREE.Vector3(-14, 197, 0),
    new THREE.Vector3(46, 175, 0),
    new THREE.Vector3(48, 172, 0),
    new THREE.Vector3(69, 159, 0),
    new THREE.Vector3(83, 120, 0),
    new THREE.Vector3(122, 103, 0),
    new THREE.Vector3(146, 115, 0),
    new THREE.Vector3(190, 120, 0),
    new THREE.Vector3(129, 55, 0),
    new THREE.Vector3(65, 44, 0),
    new THREE.Vector3(69, 52, 0),
    new THREE.Vector3(53, 54, 0),
    new THREE.Vector3(34, 50, 0),
    new THREE.Vector3(21, 65, 0),
    new THREE.Vector3(-1, 60, 0),
    new THREE.Vector3(-6, 39, 0),
    new THREE.Vector3(-47, 66, 0),
    new THREE.Vector3(-50, 92, 0),
    new THREE.Vector3(-55, 96, 0),
    new THREE.Vector3(-57, 93, 0),
    new THREE.Vector3(-63, 117, 0),
  ],
  [
    new THREE.Vector3(-63, 117, 0),
    new THREE.Vector3(-116, 100, 0),
    new THREE.Vector3(-140, 122, 0),
    new THREE.Vector3(-159, 129, 0),
    new THREE.Vector3(-184, 114, 0),
    new THREE.Vector3(-175, 83, 0),
    new THREE.Vector3(-184, 74, 0),
    new THREE.Vector3(-104, 77, 0),
    new THREE.Vector3(-89, 41, 0),
    new THREE.Vector3(-60, 2, 0),
    new THREE.Vector3(-60, -15, 0),
    new THREE.Vector3(-82, -26, 0),
    new THREE.Vector3(-114, -9, 0),
    new THREE.Vector3(-140, -19, 0),
    new THREE.Vector3(-151, -2, 0),
    new THREE.Vector3(-156, 6, 0),
    new THREE.Vector3(-171, 22, 0),
    new THREE.Vector3(-194, 7, 0),
    new THREE.Vector3(-186, -36, 0),
    new THREE.Vector3(-181, -58, 0),
    new THREE.Vector3(-165, -63, 0),
    new THREE.Vector3(-158, -48, 0),
    new THREE.Vector3(-142, -77, 0),
    new THREE.Vector3(-126, -107, 0),
    new THREE.Vector3(-193, -105, 0),
    new THREE.Vector3(-174, -138, 0),
    new THREE.Vector3(-150, -133, 0),
    new THREE.Vector3(-143, -156, 0),
    new THREE.Vector3(-145, -159, 0),
    new THREE.Vector3(-171, -170, 0),
  ],
  [
    new THREE.Vector3(-171, -170, 0),
    new THREE.Vector3(-94, -176, 0),
    new THREE.Vector3(-100, -167, 0),
    new THREE.Vector3(-104, -155, 0),
    new THREE.Vector3(-104, -147, 0),
    new THREE.Vector3(-36, -154, 0),
    new THREE.Vector3(-25, -154, 0),
    new THREE.Vector3(-47, -122, 0),
    new THREE.Vector3(-83, -120, 0),
    new THREE.Vector3(-61, -98, 0),
    new THREE.Vector3(-68, -57, 0),
    new THREE.Vector3(17, -59, 0),
    new THREE.Vector3(34, -48, 0),
    new THREE.Vector3(32, -58, 0),
    new THREE.Vector3(55, -71, 0),
    new THREE.Vector3(82, -39, 0),
    new THREE.Vector3(74, -28, 0),
    new THREE.Vector3(88, -33, 0),
    new THREE.Vector3(91, 6, 0),
    new THREE.Vector3(139, -14, 0),
    new THREE.Vector3(141, 23, 0),
    new THREE.Vector3(151, 32, 0),
    new THREE.Vector3(172, -13, 0),
    new THREE.Vector3(169, -31, 0),
  ],
  [
    new THREE.Vector3(169, -31, 0),
    new THREE.Vector3(141, -84, 0),
    new THREE.Vector3(105, -87, 0),
    new THREE.Vector3(98, -68, 0),
    new THREE.Vector3(54, -91, 0),
    new THREE.Vector3(15, -110, 0),
    new THREE.Vector3(2, -119, 0),
    new THREE.Vector3(-3, -112, 0),
    new THREE.Vector3(-14, -117, 0),
    new THREE.Vector3(19, -165, 0),
    new THREE.Vector3(38, -189, 0),
    new THREE.Vector3(56, -175, 0),
    new THREE.Vector3(66, -189, 0),
    new THREE.Vector3(100, -171, 0),
    new THREE.Vector3(89, -141, 0),
    new THREE.Vector3(118, -125, 0),
    new THREE.Vector3(133, -132, 0),
    new THREE.Vector3(138, -111, 0),
    new THREE.Vector3(146, -123, 0),
    new THREE.Vector3(172, -156, 0),
    new THREE.Vector3(145, -193, 0),
    new THREE.Vector3(123, -185, 0),
  ],
]

export const linesM = [
  [
    new THREE.Vector3(-150, 183, 0),
    new THREE.Vector3(-142, 183, 0),
    new THREE.Vector3(-127, 185, 0),
    new THREE.Vector3(-134, 142, 0),
    new THREE.Vector3(-118, 110, 0),
    new THREE.Vector3(-110, 106, 0),
    new THREE.Vector3(-81, 118, 0),
    new THREE.Vector3(-71, 137, 0),
    new THREE.Vector3(-43, 143, 0),
    new THREE.Vector3(-50, 165, 0),
    new THREE.Vector3(-71, 172, 0),
    new THREE.Vector3(-28, 191, 0),
    new THREE.Vector3(-11, 110, 0),
    new THREE.Vector3(0, 92, 0),
    new THREE.Vector3(34, 72, 0),
    new THREE.Vector3(46, 63, 0),
    new THREE.Vector3(64, 98, 0),
    new THREE.Vector3(69, 105, 0),
    new THREE.Vector3(21, 151, 0),
    new THREE.Vector3(40, 146, 0),
    new THREE.Vector3(89, 156, 0),
    new THREE.Vector3(112, 179, 0),
    new THREE.Vector3(151, 195, 0),
    new THREE.Vector3(142, 143, 0),
    new THREE.Vector3(128, 61, 0),
    new THREE.Vector3(184, 86, 0),
  ],
  [
    new THREE.Vector3(128, 61, 0),
    new THREE.Vector3(95, 25, 0),
    new THREE.Vector3(161, 41, 0),
    new THREE.Vector3(171, 20, 0),
    new THREE.Vector3(192, 16, 0),
    new THREE.Vector3(104, -32, 0),
    new THREE.Vector3(158, -35, 0),
    new THREE.Vector3(160, -42, 0),
    new THREE.Vector3(193, -64, 0),
    new THREE.Vector3(139, -89, 0),
    new THREE.Vector3(114, -90, 0),
    new THREE.Vector3(114, -92, 0),
    new THREE.Vector3(86, -90, 0),
    new THREE.Vector3(59, -88, 0),
    new THREE.Vector3(71, -131, 0),
    new THREE.Vector3(126, -126, 0),
    new THREE.Vector3(44, -174, 0),
    new THREE.Vector3(44, -186, 0),
    new THREE.Vector3(73, -175, 0),
    new THREE.Vector3(93, -175, 0),
    new THREE.Vector3(190, -136, 0),
  ],
  [
    new THREE.Vector3(-118, 110, 0),
    new THREE.Vector3(-170, 123, 0),
    new THREE.Vector3(-166, 97, 0),
    new THREE.Vector3(-191, 85, 0),
    new THREE.Vector3(-189, 54, 0),
    new THREE.Vector3(-184, 45, 0),
    new THREE.Vector3(-193, 33, 0),
    new THREE.Vector3(-143, 53, 0),
    new THREE.Vector3(-134, 36, 0),
    new THREE.Vector3(-106, 62, 0),
    new THREE.Vector3(-107, 60, 0),
    new THREE.Vector3(-87, 62, 0),
    new THREE.Vector3(-84, 53, 0),
    new THREE.Vector3(-65, 74, 0),
    new THREE.Vector3(-36, 72, 0),
    new THREE.Vector3(-26, 24, 0),
    new THREE.Vector3(-11, 18, 0),
    new THREE.Vector3(-13, 3, 0),
    new THREE.Vector3(0, -6, 0),
    new THREE.Vector3(-29, -25, 0),
    new THREE.Vector3(-53, 29, 0),
    new THREE.Vector3(-80, 25, 0),
    new THREE.Vector3(-119, 8, 0),
    new THREE.Vector3(-144, 7, 0),
    new THREE.Vector3(-158, -3, 0),
    new THREE.Vector3(-186, -3, 0),
    new THREE.Vector3(-156, -31, 0),
    new THREE.Vector3(-187, -19, 0),
    new THREE.Vector3(-193, -20, 0),
  ],
  [
    new THREE.Vector3(-29, -25, 0),
    new THREE.Vector3(15, -84, 0),
    new THREE.Vector3(-17, -116, 0),
    new THREE.Vector3(-33, -96, 0),
    new THREE.Vector3(-38, -123, 0),
    new THREE.Vector3(7, -164, 0),
    new THREE.Vector3(7, -166, 0),
    new THREE.Vector3(-52, -185, 0),
    new THREE.Vector3(-59, -175, 0),
    new THREE.Vector3(-66, -178, 0),
    new THREE.Vector3(-82, -180, 0),
    new THREE.Vector3(-58, -132, 0),
    new THREE.Vector3(-70, -137, 0),
    new THREE.Vector3(-87, -158, 0),
    new THREE.Vector3(-114, -150, 0),
    new THREE.Vector3(-116, -151, 0),
    new THREE.Vector3(-143, -185, 0),
    new THREE.Vector3(-165, -163, 0),
    new THREE.Vector3(-116, -131, 0),
    new THREE.Vector3(-105, -119, 0),
    new THREE.Vector3(-97, -96, 0),
    new THREE.Vector3(-122, -107, 0),
    new THREE.Vector3(-131, -128, 0),
    new THREE.Vector3(-147, -122, 0),
    new THREE.Vector3(-149, -113, 0),
    new THREE.Vector3(-150, -81, 0),
    new THREE.Vector3(-150, -77, 0),
  ],
]

export const linesN = [
  [
    new THREE.Vector3(-199, 161, 0),
    new THREE.Vector3(-180, 147, 0),
    new THREE.Vector3(-143, 157, 0),
    new THREE.Vector3(-89, 148, 0),
    new THREE.Vector3(-95, 137, 0),
    new THREE.Vector3(-57, 147, 0),
    new THREE.Vector3(-54, 144, 0),
    new THREE.Vector3(-41, 140, 0),
    new THREE.Vector3(-45, 125, 0),
    new THREE.Vector3(-81, 117, 0),
    new THREE.Vector3(-145, 114, 0),
    new THREE.Vector3(-137, 106, 0),
    new THREE.Vector3(-150, 88, 0),
    new THREE.Vector3(-157, 70, 0),
    new THREE.Vector3(-174, 61, 0),
    new THREE.Vector3(-58, 61, 0),
    new THREE.Vector3(-54, 43, 0),
    new THREE.Vector3(-7, 34, 0),
    new THREE.Vector3(-12, 9, 0),
    new THREE.Vector3(-9, -19, 0),
    new THREE.Vector3(1, -19, 0),
    new THREE.Vector3(-43, -79, 0),
  ],
  [
    new THREE.Vector3(-43, -79, 0),
    new THREE.Vector3(-87, -38, 0),
    new THREE.Vector3(-123, -40, 0),
    new THREE.Vector3(-149, -15, 0),
    new THREE.Vector3(-148, 33, 0),
    new THREE.Vector3(-160, 36, 0),
    new THREE.Vector3(-182, 27, 0),
    new THREE.Vector3(-196, 23, 0),
    new THREE.Vector3(-170, -37, 0),
    new THREE.Vector3(-164, -48, 0),
    new THREE.Vector3(-164, -68, 0),
    new THREE.Vector3(-147, -89, 0),
    new THREE.Vector3(-146, -109, 0),
    new THREE.Vector3(-100, -82, 0),
    new THREE.Vector3(-114, -104, 0),
    new THREE.Vector3(-100, -111, 0),
    new THREE.Vector3(-97, -106, 0),
    new THREE.Vector3(-60, -103, 0),
    new THREE.Vector3(-92, -149, 0),
    new THREE.Vector3(-136, -155, 0),
    new THREE.Vector3(-161, -171, 0),
    new THREE.Vector3(-174, -150, 0),
    new THREE.Vector3(-196, -134, 0),
  ],
  [
    new THREE.Vector3(-92, -149, 0),
    new THREE.Vector3(-86, -197, 0),
    new THREE.Vector3(0, -157, 0),
    new THREE.Vector3(26, -91, 0),
    new THREE.Vector3(38, -99, 0),
    new THREE.Vector3(31, -162, 0),
    new THREE.Vector3(56, -124, 0),
    new THREE.Vector3(93, -151, 0),
    new THREE.Vector3(127, -142, 0),
    new THREE.Vector3(146, -132, 0),
    new THREE.Vector3(185, -160, 0),
    new THREE.Vector3(194, -137, 0),
    new THREE.Vector3(174, -125, 0),
    new THREE.Vector3(183, -120, 0),
    new THREE.Vector3(173, -99, 0),
    new THREE.Vector3(127, -104, 0),
    new THREE.Vector3(178, -85, 0),
    new THREE.Vector3(192, -88, 0),
    new THREE.Vector3(190, -62, 0),
    new THREE.Vector3(175, -60, 0),
    new THREE.Vector3(82, -70, 0),
    new THREE.Vector3(87, -52, 0),
    new THREE.Vector3(90, -53, 0),
    new THREE.Vector3(81, -46, 0),
    new THREE.Vector3(80, -15, 0),
    new THREE.Vector3(43, -13, 0),
    new THREE.Vector3(38, -10, 0),
    new THREE.Vector3(38, 1, 0),
  ],
  [
    new THREE.Vector3(175, -60, 0),
    new THREE.Vector3(177, -31, 0),
    new THREE.Vector3(192, -7, 0),
    new THREE.Vector3(138, 51, 0),
    new THREE.Vector3(106, 48, 0),
    new THREE.Vector3(71, 67, 0),
    new THREE.Vector3(72, 71, 0),
    new THREE.Vector3(23, 60, 0),
    new THREE.Vector3(30, 85, 0),
    new THREE.Vector3(46, 87, 0),
    new THREE.Vector3(47, 89, 0),
    new THREE.Vector3(72, 108, 0),
    new THREE.Vector3(86, 101, 0),
    new THREE.Vector3(98, 91, 0),
    new THREE.Vector3(154, 105, 0),
    new THREE.Vector3(190, 94, 0),
    new THREE.Vector3(165, 128, 0),
    new THREE.Vector3(198, 163, 0),
    new THREE.Vector3(174, 190, 0),
    new THREE.Vector3(156, 184, 0),
    new THREE.Vector3(109, 162, 0),
    new THREE.Vector3(83, 162, 0),
    new THREE.Vector3(75, 174, 0),
  ],
  [
    new THREE.Vector3(-58, 61, 0),
    new THREE.Vector3(-40, 90, 0),
    new THREE.Vector3(-7, 105, 0),
    new THREE.Vector3(19, 112, 0),
    new THREE.Vector3(35, 143, 0),
    new THREE.Vector3(10, 164, 0),
    new THREE.Vector3(-6, 184, 0),
  ],
]

export const linesO = [
  [
    new THREE.Vector3(-145, 164, 0),
    new THREE.Vector3(-47, 186, 0),
    new THREE.Vector3(-63, 151, 0),
    new THREE.Vector3(-35, 146, 0),
    new THREE.Vector3(-21, 154, 0),
    new THREE.Vector3(-5, 164, 0),
    new THREE.Vector3(5, 151, 0),
    new THREE.Vector3(16, 163, 0),
    new THREE.Vector3(21, 172, 0),
    new THREE.Vector3(23, 144, 0),
    new THREE.Vector3(39, 130, 0),
    new THREE.Vector3(38, 125, 0),
    new THREE.Vector3(28, 125, 0),
    new THREE.Vector3(13, 124, 0),
    new THREE.Vector3(-34, 114, 0),
    new THREE.Vector3(-60, 106, 0),
    new THREE.Vector3(-82, 71, 0),
    new THREE.Vector3(-90, 51, 0),
    new THREE.Vector3(-107, 35, 0),
    new THREE.Vector3(-150, 27, 0),
    new THREE.Vector3(-165, 62, 0),
    new THREE.Vector3(-165, 67, 0),
    new THREE.Vector3(-147, 94, 0),
  ],
  [
    new THREE.Vector3(39, 130, 0),
    new THREE.Vector3(65, 137, 0),
    new THREE.Vector3(104, 99, 0),
    new THREE.Vector3(107, 131, 0),
    new THREE.Vector3(115, 176, 0),
    new THREE.Vector3(177, 188, 0),
    new THREE.Vector3(198, 171, 0),
    new THREE.Vector3(160, 128, 0),
    new THREE.Vector3(154, 130, 0),
    new THREE.Vector3(197, 102, 0),
    new THREE.Vector3(181, 103, 0),
    new THREE.Vector3(171, 100, 0),
    new THREE.Vector3(149, 95, 0),
    new THREE.Vector3(106, 54, 0),
    new THREE.Vector3(111, 48, 0),
    new THREE.Vector3(182, 56, 0),
    new THREE.Vector3(168, 37, 0),
    new THREE.Vector3(145, 12, 0),
    new THREE.Vector3(88, 0, 0),
    new THREE.Vector3(54, 21, 0),
    new THREE.Vector3(32, 54, 0),
    new THREE.Vector3(15, 70, 0),
    new THREE.Vector3(22, 80, 0),
    new THREE.Vector3(5, 95, 0),
  ],
  [
    new THREE.Vector3(5, 95, 0),
    new THREE.Vector3(-81, 10, 0),
    new THREE.Vector3(-48, -3, 0),
    new THREE.Vector3(3, 16, 0),
    new THREE.Vector3(-7, -52, 0),
    new THREE.Vector3(-20, -45, 0),
    new THREE.Vector3(-48, -59, 0),
    new THREE.Vector3(-55, -50, 0),
    new THREE.Vector3(-61, -41, 0),
    new THREE.Vector3(-66, -56, 0),
    new THREE.Vector3(-118, -65, 0),
    new THREE.Vector3(-178, -60, 0),
    new THREE.Vector3(-195, -50, 0),
    new THREE.Vector3(-199, -52, 0),
    new THREE.Vector3(-198, -84, 0),
    new THREE.Vector3(-197, -103, 0),
    new THREE.Vector3(-200, -114, 0),
    new THREE.Vector3(-182, -88, 0),
    new THREE.Vector3(-168, -96, 0),
    new THREE.Vector3(-157, -111, 0),
    new THREE.Vector3(-125, -104, 0),
    new THREE.Vector3(-122, -181, 0),
    new THREE.Vector3(-81, -193, 0),
  ],
  [
    new THREE.Vector3(-81, -193, 0),
    new THREE.Vector3(-64, -184, 0),
    new THREE.Vector3(-59, -167, 0),
    new THREE.Vector3(-59, -115, 0),
    new THREE.Vector3(-24, -117, 0),
    new THREE.Vector3(-16, -92, 0),
    new THREE.Vector3(-11, -90, 0),
    new THREE.Vector3(-10, -186, 0),
    new THREE.Vector3(6, -164, 0),
    new THREE.Vector3(9, -193, 0),
    new THREE.Vector3(40, -171, 0),
    new THREE.Vector3(44, -134, 0),
    new THREE.Vector3(80, -98, 0),
    new THREE.Vector3(78, -48, 0),
    new THREE.Vector3(121, -50, 0),
    new THREE.Vector3(145, -22, 0),
    new THREE.Vector3(150, -12, 0),
    new THREE.Vector3(198, -42, 0),
    new THREE.Vector3(199, -66, 0),
    new THREE.Vector3(164, -87, 0),
    new THREE.Vector3(145, -76, 0),
    new THREE.Vector3(144, -81, 0),
    new THREE.Vector3(134, -97, 0),
    new THREE.Vector3(136, -116, 0),
    new THREE.Vector3(154, -114, 0),
    new THREE.Vector3(148, -125, 0),
    new THREE.Vector3(155, -129, 0),
    new THREE.Vector3(167, -136, 0),
    new THREE.Vector3(169, -155, 0),
    new THREE.Vector3(185, -160, 0),
    new THREE.Vector3(200, -133, 0),
  ],
  [
    new THREE.Vector3(80, -98, 0),
    new THREE.Vector3(91, -103, 0),
    new THREE.Vector3(126, -164, 0),
  ],
]

export const linesP = [
  [
    new THREE.Vector3(-195, 197, 0),
    new THREE.Vector3(-174, 185, 0),
    new THREE.Vector3(-198, 147, 0),
    new THREE.Vector3(-176, 131, 0),
    new THREE.Vector3(-172, 112, 0),
    new THREE.Vector3(-158, 90, 0),
    new THREE.Vector3(-154, 144, 0),
    new THREE.Vector3(-141, 154, 0),
    new THREE.Vector3(-126, 143, 0),
    new THREE.Vector3(-116, 101, 0),
    new THREE.Vector3(-103, 82, 0),
    new THREE.Vector3(-83, 112, 0),
    new THREE.Vector3(-69, 131, 0),
    new THREE.Vector3(-88, 151, 0),
    new THREE.Vector3(-82, 167, 0),
    new THREE.Vector3(-79, 184, 0),
    new THREE.Vector3(-62, 150, 0),
    new THREE.Vector3(-53, 192, 0),
    new THREE.Vector3(-41, 160, 0),
    new THREE.Vector3(-16, 184, 0),
    new THREE.Vector3(-10, 170, 0),
    new THREE.Vector3(-29, 112, 0),
    new THREE.Vector3(13, 107, 0),
    new THREE.Vector3(13, 137, 0),
    new THREE.Vector3(24, 145, 0),
    new THREE.Vector3(54, 85, 0),
    new THREE.Vector3(57, 114, 0),
    new THREE.Vector3(86, 150, 0),
    new THREE.Vector3(132, 178, 0),
    new THREE.Vector3(151, 191, 0),
    new THREE.Vector3(157, 182, 0),
    new THREE.Vector3(179, 162, 0),
  ],
  [
    new THREE.Vector3(-103, 82, 0),
    new THREE.Vector3(-158, 53, 0),
    new THREE.Vector3(-184, 49, 0),
    new THREE.Vector3(-170, 6, 0),
    new THREE.Vector3(-132, 33, 0),
    new THREE.Vector3(-107, 42, 0),
    new THREE.Vector3(-106, 38, 0),
    new THREE.Vector3(-68, 43, 0),
    new THREE.Vector3(-68, 75, 0),
    new THREE.Vector3(-65, 107, 0),
    new THREE.Vector3(-35, 75, 0),
    new THREE.Vector3(-5, 81, 0),
    new THREE.Vector3(17, 24, 0),
    new THREE.Vector3(29, 4, 0),
    new THREE.Vector3(100, 21, 0),
    new THREE.Vector3(138, 41, 0),
    new THREE.Vector3(140, 67, 0),
    new THREE.Vector3(166, 109, 0),
    new THREE.Vector3(187, 92, 0),
    new THREE.Vector3(192, 58, 0),
    new THREE.Vector3(167, 30, 0),
    new THREE.Vector3(191, 0, 0),
    new THREE.Vector3(171, -8, 0),
    new THREE.Vector3(178, -29, 0),
  ],
  [
    new THREE.Vector3(178, -29, 0),
    new THREE.Vector3(126, -55, 0),
    new THREE.Vector3(95, -32, 0),
    new THREE.Vector3(53, -53, 0),
    new THREE.Vector3(48, -19, 0),
    new THREE.Vector3(21, -65, 0),
    new THREE.Vector3(22, -36, 0),
    new THREE.Vector3(10, -35, 0),
    new THREE.Vector3(-16, -11, 0),
    new THREE.Vector3(-9, -57, 0),
    new THREE.Vector3(-4, -83, 0),
    new THREE.Vector3(-9, -86, 0),
    new THREE.Vector3(-47, 8, 0),
    new THREE.Vector3(-54, 3, 0),
    new THREE.Vector3(-48, -45, 0),
    new THREE.Vector3(-61, -56, 0),
    new THREE.Vector3(-93, -1, 0),
    new THREE.Vector3(-84, -65, 0),
    new THREE.Vector3(-83, -94, 0),
    new THREE.Vector3(-85, -94, 0),
    new THREE.Vector3(-79, -109, 0),
    new THREE.Vector3(-65, -122, 0),
    new THREE.Vector3(-121, -129, 0),
    new THREE.Vector3(-135, -96, 0),
    new THREE.Vector3(-128, -133, 0),
    new THREE.Vector3(-195, -119, 0),
    new THREE.Vector3(-156, -142, 0),
    new THREE.Vector3(-160, -176, 0),
  ],
  [
    new THREE.Vector3(-160, -176, 0),
    new THREE.Vector3(-53, -189, 0),
    new THREE.Vector3(-43, -194, 0),
    new THREE.Vector3(-28, -160, 0),
    new THREE.Vector3(6, -170, 0),
    new THREE.Vector3(33, -170, 0),
    new THREE.Vector3(73, -197, 0),
    new THREE.Vector3(39, -135, 0),
    new THREE.Vector3(68, -108, 0),
    new THREE.Vector3(61, -99, 0),
    new THREE.Vector3(74, -86, 0),
    new THREE.Vector3(88, -84, 0),
    new THREE.Vector3(95, -121, 0),
    new THREE.Vector3(104, -149, 0),
    new THREE.Vector3(129, -92, 0),
    new THREE.Vector3(153, -80, 0),
    new THREE.Vector3(185, -103, 0),
    new THREE.Vector3(171, -162, 0),
    new THREE.Vector3(200, -200, 0),
  ],
]
