import { PropsWithChildren, useContext } from "react"
import { useTranslation } from "react-i18next"
import { ScifiButton } from "components/general"
import { energyBalance } from "data/queries/balance"
import { GameContext } from "pages/GameContext"
import Upgrade from "../Upgrade"
import ProgressBar from "../../feedback/ProgressBar"
import styles from "./BuildDetail.module.scss"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
}

const BateryHouse = (props: PropsWithChildren<Props>) => {
  const { building, build, setIsOpen } = props
  const { t } = useTranslation()

  const { planetData } = useContext(GameContext)
  const planet = planetData instanceof Array ? planetData[0] : planetData

  return (
    <>
      <div>
        <span className={styles.level}>{Number(building.level)}</span>
        <span>{t("level")}</span>
      </div>

      {planetData && (
        <div style={{ display: "flex" }}>
          <div>
            <img
              src={`/assets/builds/build-11.png`}
              alt="Colonization"
              className={styles.buildImage}
              draggable={false}
            />
            <Upgrade setIsOpen={setIsOpen} build={build} building={building} />
          </div>
          <div className={styles.buildContent}>
            <div className={styles.tip}>
              {t("Energy")}
              {" : "}
              <span className={styles.stats}>
                {planet ? Number(energyBalance(planet)) : 0}
                {"/"}
                {planet?.battery}
              </span>
            </div>
            <ProgressBar
              currentValue={planet ? Number(energyBalance(planet)) : 0}
              maxValue={planet?.battery}
              filledColor="#90ee90"
              color="#90ee9033"
              size="custom-build"
              bars={30}
            />
            <div className={styles.tip}>
              {t("Extra Battery")}
              {" : "}
              <span className={styles.stats}>
                {(planet ? Number(energyBalance(planet)) : 0) >
                (planet?.battery ?? 0)
                  ? Math.min(
                      (planet ? Number(energyBalance(planet)) : 0 ?? 0) -
                        (planet?.battery ?? 0),
                      planet?.extraBattery ?? 0
                    )
                  : 0}
                {"/"}
                {planet?.extraBattery}
              </span>
            </div>
            <ProgressBar
              currentValue={
                (planet ? Number(energyBalance(planet)) : 0) >
                (planet?.battery ?? 0)
                  ? Math.min(
                      (planet ? Number(energyBalance(planet)) : 0) -
                        (planet?.battery ?? 0),
                      planet?.extraBattery ?? 0
                    )
                  : 0
              }
              maxValue={planet?.extraBattery}
              filledColor={
                (planet ? Number(energyBalance(planet)) : 0) >
                (planet?.battery ?? 0)
                  ? "#006400"
                  : `rgba(128,128,128,0.4)`
              }
              color="gray"
              size="custom-build"
              bars={30}
            />

            <div className={styles.tip}>
              {t("Total Battery")}
              {" : "}
              <span className={styles.stats}>
                {planet ? Number(energyBalance(planet)) : 0}
                {"/"}
                {(planet?.battery ?? 0) + (planet?.extraBattery ?? 0)}
              </span>
            </div>

            <ProgressBar
              currentValue={planet ? Number(energyBalance(planet)) : 0}
              maxValue={(planet?.battery ?? 0) + (planet?.extraBattery ?? 0)}
              filledColor="#90ee90"
              color="#90ee9033"
              size="custom-build"
              bars={30}
            />
          </div>
        </div>
      )}

      <div className={styles.footer}>
        <ScifiButton onClick={() => {}} size="small">
          {t("Save")}
        </ScifiButton>
      </div>
    </>
  )
}

export default BateryHouse
