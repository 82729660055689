import { getErrorMessage } from "utils/error"
import Layout, { Page } from "components/layout"
import { Content, Header, Actions } from "components/layout"
import { ErrorBoundary, Wrong } from "components/feedback"
import { isAuthenticated } from "data/services/authService"

/* routes */
import { useNav } from "./routes"

/* header */
import Perfil from "./sections/Perfil"
import Refresh from "./sections/Refresh"
import Preferences from "./sections/Preferences"
import Logout from "./sections/Logout"

/* extra */
import DevTools from "./sections/DevTools"
import BackgroundAudio from "pages/BackgroundAudio"
import { useEffect, useState } from "react"

const App = () => {
  const { element: routes } = useNav()
  const [isAudioPlaying] = useState(() => {
    const localStorageValue = localStorage.getItem("audioPlaying")
    return localStorageValue ? localStorageValue === "true" : true
  })

  useEffect(() => {
    localStorage.setItem("audioPlaying", isAudioPlaying.toString())
  }, [isAudioPlaying])

  return (
    <>
      <BackgroundAudio isActive={isAudioPlaying} />
      <Layout>
        <Header>
          {isAuthenticated() && <Perfil />}
          <Actions>
            <DevTools />
            <section>
              <Refresh />
              <Preferences />
              <Logout />
            </section>
          </Actions>
        </Header>

        <Content>
          <ErrorBoundary fallback={fallback}>{routes}</ErrorBoundary>
        </Content>
      </Layout>
    </>
  )
}

export default App

/* error */
export const fallback = (error: Error) => (
  <Page>
    <Wrong>{getErrorMessage(error)}</Wrong>
  </Page>
)
