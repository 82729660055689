import styles from "./ProgressBar.module.scss"

interface ProgressBarProps {
  currentValue?: number
  maxValue?: number
  color: string
  filledColor?: string
  size: "small" | "medium" | "large" | "custom-build"
  bars?: number
}

const ProgressBar = ({
  currentValue = 0,
  maxValue = 0,
  bars = 20,
  color,
  filledColor,
  size,
}: ProgressBarProps) => {
  if (maxValue === 0) {
    maxValue = 1
  }

  if (!currentValue) {
    currentValue = 0
  }

  let percentage = (currentValue / maxValue) * 100

  percentage = Math.min(percentage, 100)

  const numRectangles = bars

  let filledRectangles = Math.floor((percentage / 100) * bars)

  filledRectangles = Math.min(filledRectangles, bars)

  let className = `${styles["progress-bar"]} `
  switch (size) {
    case "small":
      className += `${styles["progress-bar-small"]}`
      break
    case "medium":
      className += `${styles["progress-bar-medium"]}`
      break
    case "large":
      className += `${styles["progress-bar-large"]}`
      break
    case "custom-build":
      className += `${styles["progress-bar-custom-build"]}`
      break
    default:
      className += `${styles["progress-bar-medium"]}`
  }

  return (
    <div className={className}>
      {[...Array(numRectangles)].map((_, i) => (
        <div
          key={i}
          className={styles["progress-rectangle"]}
          style={{
            backgroundColor: i < filledRectangles ? filledColor : color,
            opacity: i < filledRectangles ? 1 : 0.4,
          }}
        />
      ))}
    </div>
  )
}

export default ProgressBar
