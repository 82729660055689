import { TRITIUM_ENERGY_WORKERS_BY_LEVEL } from "config/constants"

export function getMaxWorkers(
  planets: Planet[] | undefined,
  building: Building,
  planetId: string
) {
  let wWorkers = 0
  let wLevel = Number(building.level) * TRITIUM_ENERGY_WORKERS_BY_LEVEL

  planets &&
    planets.forEach((planet) => {
      if (Number(planet.id) === Number(planetId)) {
        wWorkers = planet.workersWaiting + Number(building.workers)
      }
    })

  if (wWorkers > wLevel) {
    return wLevel
  } else {
    return wWorkers
  }
}

export function getDisponibleWorkers(
  planets: Planet[] | undefined,
  building: Building,
  planetId: string
) {
  let wWorkers = 0

  planets &&
    planets.forEach((planet) => {
      if (Number(planet.id) === Number(planetId)) {
        wWorkers = planet.workersWaiting
      }
    })

  return wWorkers
}
