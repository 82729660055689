import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import Stack from "@mui/material/Stack"
import { useTranslation } from "react-i18next"
import styles from "./Warning.module.scss"

interface PropsAlert {
  msg: string
}
export default function DescriptionAlerts(props: PropsAlert) {
  const { msg } = props
  const { t } = useTranslation()

  return (
    <Stack sx={{ width: "100%" }} spacing={1}>
      <Alert className={styles.container} severity="warning">
        <AlertTitle className={styles.warning}>Warning</AlertTitle>
        {t(`${msg}`)}
      </Alert>
    </Stack>
  )
}
