import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { isAuthenticated } from "data/services/authService"

const Lobby = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/game/planet/" + localStorage.getItem("planet"))
    } else {
      window.location.href = "https://play.terratritium.com"
    }
  }, [navigate])

  return <></>
}

export default Lobby
