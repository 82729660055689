import ShipEnimie from "./ShipEnimie"
import { useFigthers } from "data/queries/combat"
import { useParams } from "react-router-dom"

interface Props {
  planets: any
}

const SpaceWar = (props: Props) => {
  const { combat } = useParams()

  const figthers = useFigthers(combat || "0")

  const invaders: any[] = []
  const locals: any[] = []

  // let invadersNames = ""
  // let localsNames = ""

  let mySide = 2

  figthers.data?.map((figther) => {
    if (figther.side === 1) {
      invaders.push(figther)
      // invadersNames += figther.player + " "
    } else {
      locals.push(figther)
      // localsNames += figther.player + " "
    }
    return 0
  })

  invaders.map((invader) => {
    if (props.planets) {
      if (invader.playerId === props.planets[0].player) {
        mySide = 1
      }
    }
    return 0
  })

  let invadersCrafts = 0
  let invadersBombers = 0
  let invadersCruisers = 0
  let invadersScout = 0
  let invadersStealth = 0
  let invadersFlagship = 0

  let localsCrafts = 0
  let localsBombers = 0
  let localsCruisers = 0
  let localsScout = 0
  let localsStealth = 0
  let localsFlagship = 0

  let winner = "none"
  let totalInvaders = 0
  let totalLocals = 0

  invaders.map((invader) => {
    invadersCrafts += invader.craft
    invadersBombers += invader.bomber
    invadersCruisers += invader.cruiser
    invadersScout += invader.scout
    invadersStealth += invader.stealth
    invadersFlagship += invader.flagship
    totalInvaders +=
      invader.craft +
      invader.bomber +
      invader.cruiser +
      invader.scout +
      invader.stealth +
      invader.flagship
    return 0
  })

  locals.map((local) => {
    localsCrafts += local.craft
    localsBombers += local.bomber
    localsCruisers += local.cruiser
    localsScout += local.scout
    localsStealth += local.stealth
    localsFlagship += local.flagship
    totalLocals +=
      local.craft +
      local.bomber +
      local.cruiser +
      local.scout +
      local.stealth +
      local.flagship
    return 0
  })

  if (totalInvaders === 0) {
    winner = "locals"
  }

  if (totalLocals === 0) {
    winner = "invaders"
  }

  return (
    <>
      {/* <Ship
        position={[0,-20,0]}
        number={1}
        name={dataShips[0].name}
        quantity={invadersCrafts}
      /> */}

      {/* <Html>
        {winner === "invaders" && (
          <>
            <div>{invadersNames + " WIN!"}</div>
          </>
        )}
        {winner === "locals" && (
          <>
            <div className={styles.winMessage}>{localsNames + " WIN!"}</div>
          </>
        )}
      </Html> */}
      {winner === "none" && (
        <>
          {/* {mySide === 1 && invadersCrafts > 0 && (
            <Ship
              position={dataShips[0].position}
              number={1}
              name={dataShips[0].name}
              quantity={invadersCrafts}
              camera={props.camera}
            />
          )}
          {mySide === 1 && invadersBombers > 0 && (
            <Ship
              position={dataShips[1].position}
              number={dataShips[1].number}
              name={dataShips[1].name}
              quantity={invadersBombers}
              camera={props.camera}
            />
          )}
          {mySide === 1 && invadersCruisers > 0 && (
            <Ship
              position={dataShips[2].position}
              number={dataShips[2].number}
              name={dataShips[2].name}
              quantity={invadersCruisers}
              camera={props.camera}
            />
          )}
          {mySide === 1 && invadersScout > 0 && (
            <Ship
              position={dataShips[3].position}
              number={dataShips[3].number}
              name={dataShips[3].name}
              quantity={invadersScout}
              camera={props.camera}
            />
          )}
          {mySide === 1 && invadersStealth > 0 && (
            <Ship
              position={dataShips[4].position}
              number={dataShips[5].number}
              name={dataShips[4].name}
              quantity={invadersStealth}
              camera={props.camera}
            />
          )}
          {mySide === 1 && invadersFlagship > 0 && (
            <Ship
              position={dataShips[5].position}
              number={dataShips[5].number}
              name={dataShips[5].name}
              quantity={invadersFlagship}
              camera={props.camera}
            />
          )}
          {mySide === 2 && localsCrafts > 0 && (
            <Ship
              position={dataShips[0].position}
              number={dataShips[0].number}
              name={dataShips[0].name}
              quantity={localsCrafts}
              camera={props.camera}
            />
          )}
          {mySide === 2 && localsBombers > 0 && (
            <Ship
              position={dataShips[1].position}
              number={dataShips[1].number}
              name={dataShips[1].name}
              quantity={localsBombers}
              camera={props.camera}
            />
          )}
          {mySide === 2 && localsCruisers > 0 && (
            <Ship
              position={dataShips[2].position}
              number={dataShips[2].number}
              name={dataShips[2].name}
              quantity={localsCruisers}
              camera={props.camera}
            />
          )}
          {mySide === 2 && localsScout > 0 && (
            <Ship
              position={dataShips[3].position}
              number={dataShips[3].number}
              name={dataShips[3].name}
              quantity={localsScout}
              camera={props.camera}
            />
          )}
          {mySide === 2 && localsStealth > 0 && (
            <Ship
              position={dataShips[4].position}
              number={dataShips[4].number}
              name={dataShips[4].name}
              quantity={localsStealth}
              camera={props.camera}
            />
          )}
          {mySide === 2 && localsFlagship > 0 && (
            <Ship
              position={dataShips[5].position}
              number={dataShips[5].number}
              name={dataShips[5].name}
              quantity={localsFlagship}
              camera={props.camera}
            />
          )} */}

          {/* Enimies */}
          {mySide === 1 && localsCrafts > 0 && (
            <ShipEnimie
              name="Fighter Craft"
              number={1}
              posX={0}
              posY={70}
              quantity={localsCrafts}
              key={1}
            />
          )}
          {mySide === 1 && localsBombers > 0 && (
            <ShipEnimie
              name="Bomber"
              number={2}
              posX={65}
              posY={25}
              quantity={localsBombers}
              key={2}
            />
          )}
          {mySide === 1 && localsCruisers > 0 && (
            <ShipEnimie
              name="Cruiser"
              number={3}
              posX={-65}
              posY={25}
              quantity={localsCruisers}
              key={3}
            />
          )}
          {mySide === 1 && localsScout > 0 && (
            <ShipEnimie
              name="Scout Ship"
              number={4}
              posX={0}
              posY={20}
              quantity={localsScout}
              key={4}
            />
          )}
          {mySide === 1 && localsStealth > 0 && (
            <ShipEnimie
              name="Stealth Ship"
              number={5}
              posX={-65}
              posY={55}
              quantity={localsStealth}
              key={5}
            />
          )}
          {mySide === 1 && localsFlagship > 0 && (
            <ShipEnimie
              name="Flagship"
              number={6}
              posX={55}
              posY={55}
              quantity={localsFlagship}
              key={6}
            />
          )}
          {mySide === 2 && invadersCrafts > 0 && (
            <ShipEnimie
              name="Fighter Craft"
              number={1}
              posX={0}
              posY={60}
              quantity={invadersCrafts}
              key={1}
            />
          )}
          {mySide === 2 && invadersBombers > 0 && (
            <ShipEnimie
              name="Bomber"
              number={2}
              posX={55}
              posY={35}
              quantity={invadersBombers}
              key={2}
            />
          )}
          {mySide === 2 && invadersCruisers > 0 && (
            <ShipEnimie
              name="Cruiser"
              number={3}
              posX={-55}
              posY={35}
              quantity={invadersCruisers}
              key={3}
            />
          )}
          {mySide === 2 && invadersScout > 0 && (
            <ShipEnimie
              name="Scout Ship"
              number={4}
              posX={0}
              posY={20}
              quantity={invadersScout}
              key={4}
            />
          )}
          {mySide === 2 && invadersStealth > 0 && (
            <ShipEnimie
              name="Stealth Ship"
              number={5}
              posX={-85}
              posY={55}
              quantity={invadersStealth}
              key={5}
            />
          )}
          {mySide === 2 && invadersFlagship > 0 && (
            <ShipEnimie
              name="Flagship"
              number={6}
              posX={45}
              posY={55}
              quantity={invadersFlagship}
              key={6}
            />
          )}
        </>
      )}
    </>
  )
}

export default SpaceWar
