import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const useNFTConfig = () => {
  return useQuery(
    [queryKey.tritium.NFT.config],
    async () => {
      const { data } = await api.get<NFTConfig>("/api/nft/config/get", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const setUseNFT = (slot: number, code: number) => {
  return api.post<Return>(TRITIUM + `/api/nft/config/${slot}/${code}`)
}
