import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import { isAuthenticated } from "data/services/authService"
import api from "../services/api"

export const start = (from: string, to: string) => {
  return api.post(TRITIUM + "/api/challange/start/" + from + "/" + to)
}

export const convert = (planet: string) => {
  return api.post(TRITIUM + "/api/challange/convert/" + planet)
}

export const useChallangeMission = (planet: string) => {
  return useQuery(
    [queryKey.tritium.travel.challangeMission],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<any>("api/challange/mission/" + planet, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useChallangePodium = () => {
  return useQuery(
    [queryKey.tritium.podium],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<any>("api/challange/podium", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}
