import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { Snackbar } from "@mui/material"
import styles from "./buildings/BuildDetail.module.scss"

interface Props {
  open: boolean
  onClose: () => void
}

const SimpleAlert = (props: PropsWithChildren<Props>) => {
  const { open, onClose } = props
  const { t } = useTranslation()

  return (
    <Snackbar
      className={styles.snackbar}
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <div className={styles.alert}>{t("Saved successfully")}</div>
    </Snackbar>
  )
}

export default SimpleAlert
