import { useState, useEffect } from "react"
import car from "styles/images/animations/smallCar.webp"
import styles from "./SmallCar.module.scss"

const positions = [
  { top: "60px", left: "-600px", animationName: "move1" },
  { top: "-20px", left: "-490px", animationName: "move2" },
  { top: "-150px", left: "60px", animationName: "move4" },
  { top: "-145px", left: "310px", animationName: "move5" },
]

const SmallCar = () => {
  const [currentPosition, setCurrentPosition] = useState(() => {
    const savedPosition = localStorage.getItem("carPosition")
    const pos = savedPosition ? Number(savedPosition) : 0
    return pos < positions.length ? pos : 0
  })

  const [animationName, setAnimationName] = useState(
    positions.length > 0
      ? positions[currentPosition].animationName
      : "defaultAnimation"
  )

  useEffect(() => {
    const nextPosition = (currentPosition + 1) % positions.length
    setCurrentPosition(nextPosition)
    localStorage.setItem("carPosition", nextPosition.toString())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (positions.length > 0) {
      setAnimationName(positions[currentPosition].animationName)
    }
  }, [currentPosition])

  const carStyle =
    positions.length > 0
      ? {
          top: positions[currentPosition].top,
          left: positions[currentPosition].left,
        }
      : {}

  return (
    <div
      className={`${styles.car} ${styles[animationName] || ""}`}
      style={carStyle}
    >
      <img src={car} alt="car" draggable={false} />
    </div>
  )
}

export default SmallCar
