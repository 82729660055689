import { useState } from "react"
import { ScifiModal } from "components/feedback"
import { useNFTConfig } from "data/queries/nft"
import { useWallet, WalletStatus } from "@terra-money/wallet-kit"
import NFTPage from "./NFTPage"
import styles from "./NFTPanel.module.scss"
import {
  useWallet as useWalletGalaxy,
  WalletStatus as WalletStatusGalaxy,
} from "@hexxagon/wallet-kit"

const NFTPanel = () => {
  const [open, setOpen] = useState(false)
  const [slot, setSlot] = useState(0)

  const { status } = useWallet()

  const { status: statusGalaxy } = useWalletGalaxy()

  const nftConfig = useNFTConfig()

  const openModal = (option: number) => {
    setOpen(true)
    setSlot(option)
  }

  return (
    <>
      <div className={styles.nftTitle}>NFT</div>
      <div
        className={styles.nftSlot}
        onClick={() => {
          openModal(1)
        }}
      >
        {(status === WalletStatus.CONNECTED ||
          statusGalaxy === WalletStatusGalaxy.CONNECTED) && (
          <>
            {nftConfig.data &&
            nftConfig.data.slot1 >= 1 &&
            nftConfig.data &&
            nftConfig.data.slot1 <= 128 ? (
              <img
                className={styles.imgNFT}
                width={36}
                src={`/assets/nft/NFT${nftConfig.data.slot1}.jpg`}
                alt="nft"
              />
            ) : (
              "1"
            )}
          </>
        )}
        {status === WalletStatus.NOT_CONNECTED &&
          statusGalaxy === WalletStatusGalaxy.NOT_CONNECTED &&
          "1"}
      </div>
      <div
        className={styles.nftSlot}
        onClick={() => {
          openModal(2)
        }}
      >
        {(status === WalletStatus.CONNECTED ||
          statusGalaxy === WalletStatusGalaxy.CONNECTED) && (
          <>
            {nftConfig.data &&
            nftConfig.data.slot2 >= 1 &&
            nftConfig.data &&
            nftConfig.data.slot2 <= 128 ? (
              <img
                className={styles.imgNFT}
                width={36}
                src={`/assets/nft/NFT${nftConfig.data.slot2}.jpg`}
                alt="nft"
              />
            ) : (
              "2"
            )}
          </>
        )}
        {status === WalletStatus.NOT_CONNECTED &&
          statusGalaxy === WalletStatusGalaxy.NOT_CONNECTED &&
          "2"}
      </div>
      <div
        className={styles.nftSlot}
        onClick={() => {
          openModal(3)
        }}
      >
        {(status === WalletStatus.CONNECTED ||
          statusGalaxy === WalletStatusGalaxy.CONNECTED) && (
          <>
            {nftConfig.data &&
            nftConfig.data.slot3 >= 1 &&
            nftConfig.data &&
            nftConfig.data.slot3 <= 128 ? (
              <img
                className={styles.imgNFT}
                width={36}
                src={`/assets/nft/NFT${nftConfig.data.slot3}.jpg`}
                alt="nft"
              />
            ) : (
              "3"
            )}
          </>
        )}
        {status === WalletStatus.NOT_CONNECTED &&
          statusGalaxy === WalletStatusGalaxy.NOT_CONNECTED &&
          "3"}
      </div>
      <div
        className={styles.nftSlot}
        onClick={() => {
          openModal(4)
        }}
      >
        {(status === WalletStatus.CONNECTED ||
          statusGalaxy === WalletStatusGalaxy.CONNECTED) && (
          <>
            {nftConfig.data &&
            nftConfig.data.slot4 >= 1 &&
            nftConfig.data &&
            nftConfig.data.slot4 <= 128 ? (
              <img
                className={styles.imgNFT}
                width={36}
                src={`/assets/nft/NFT${nftConfig.data.slot4}.jpg`}
                alt="nft"
              />
            ) : (
              "4"
            )}
          </>
        )}
        {status === WalletStatus.NOT_CONNECTED &&
          statusGalaxy === WalletStatusGalaxy.NOT_CONNECTED &&
          "4"}
      </div>
      <ScifiModal
        title={`NFT SLOT ${slot}`}
        isOpen={open}
        onRequestClose={() => {
          setOpen(false)
        }}
      >
        <NFTPage slot={slot} />
      </ScifiModal>
    </>
  )
}

export default NFTPanel
