import { useNavigate } from "react-router-dom"
import { usePlanets } from "data/queries/planet"

const Game = () => {
  const planets = usePlanets()
  const navigate = useNavigate()

  if (planets.data) {
    navigate(`/game/planet/${planets.data[0].id}`)
  }
  return <></>
}

export default Game
