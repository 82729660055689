import { PropsWithChildren } from "react"
import styles from "./Ranking.module.scss"
import TableScore from "./TableScore"

interface Props {
  ranking: PlayerRanking[]
}

const Ranking = (props: PropsWithChildren<Props>) => {
  const { ranking } = props

  return (
    <div className={styles.ranking}>
      <TableScore ranking={ranking} />
    </div>
  )
}

export default Ranking
