import { PropsWithChildren, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
//import { useNavigate } from "react-router-dom"
import { renamePlanet } from "data/queries/planet"
import { Table } from "components/layout"
import { ScifiButton } from "components/general"
import { ScifiModal } from "components/feedback"
import { GameContext } from "pages/GameContext"
import { Tooltip } from "@mui/material"
import ChangeIco from "@mui/icons-material/FlipCameraAndroid"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import LinkIcon from "@mui/icons-material/Link"
import Upgrade from "../Upgrade"
import styles from "./BuildDetail.module.scss"
import ColonizationDetails from "../ColonizationDetails"
import LinkDetails from "../LinkDetails"
//import { region } from "data/regions"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
  //region?: string | null
}

const Colonization = (props: PropsWithChildren<Props>) => {
  const queryClient = useQueryClient()
  const { building, build, setIsOpen } = props
  const { t } = useTranslation()
  const { planets } = useContext(GameContext)

  //const regionName = region(props.region || "A")

  //const regionCode = String(regionName).charAt(0)

  //const navigate = useNavigate()
  const colonizers = Number(building.level) - 1

  const [open, setOpen] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)
  const [openLinks, setOpenLinks] = useState(false)
  const [selected, setSelected] = useState<number>(0)
  const [planetName, setPlanetName] = useState<string>("")

  return (
    <>
      <div>
        <span className={styles.level}>{Number(building.level)}</span>
        <span>{t("level")}</span>
      </div>
      <div style={{ display: "flex" }}>
        <div>
          <img
            src={`/assets/builds/build-01.png`}
            alt="Colonization"
            className={styles.buildImage}
            draggable={false}
          />
          <Upgrade setIsOpen={setIsOpen} build={build} building={building} />
        </div>
        <div className={styles.buildContent}>
          <div>
            <span className={styles.stats}>{"00" + colonizers}</span>{" "}
            {t("Available colony ships")}{" "}
          </div>
          <br />
          {planets && (
            <Table
              columns={[
                {
                  key: "name",
                  title: t("planet"),
                  dataIndex: "name",
                },
                {
                  key: "resource",
                  title: t("resource"),
                  dataIndex: "resource",
                  align: "center",
                  render: (resource) => (
                    <Tooltip title={t("Uranium")}>
                      <img
                        src="/assets/icons/resources/uranium.png"
                        width={30}
                        alt={t("Uranium")}
                        className={styles.resourceIco}
                        draggable={false}
                      />
                    </Tooltip>
                  ),
                },
                {
                  key: "id",
                  title: t("action"),
                  dataIndex: "id",
                  align: "center",
                  render: (id) => (
                    <Tooltip title={t("Rename planet")}>
                      <ChangeIco
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOpen(true)
                          setSelected(id)
                        }}
                      />
                    </Tooltip>
                  ),
                },
                {
                  key: "details",
                  title: t("details"),
                  dataIndex: "details",
                  align: "right",
                  render: (details) => (
                    <OpenInFullIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenLinks(true)
                        setSelected(details)
                      }}
                    />
                  ),
                },
                {
                  key: "links",
                  title: t("links"),
                  dataIndex: "links",
                  align: "right",
                  render: (links) => (
                    <LinkIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenLinks(true)
                        setSelected(links)
                      }}
                    />
                  ),
                },
              ]}
              dataSource={planets}
              size="small"
            />
          )}
        </div>
      </div>
      {/* <div className={styles.footer}>
        <ScifiButton
          onClick={() => {
            navigate("/game/galaxy/" + regionCode, { replace: true })
          }}
          size="small"
          disabled={colonizers > 0 ? false : true}
        >
          {t("Search new planet")}
        </ScifiButton>
        </div>*/}
      <ScifiModal
        title={t("Rename planet")}
        isOpen={open}
        onRequestClose={() => {
          setOpen(false)
        }}
      >
        <input
          maxLength={20}
          value={planetName}
          onChange={(e) => {
            setPlanetName(e.target.value)
          }}
          className={styles.input}
          type="text"
        />
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <ScifiButton
            onClick={() => {
              renamePlanet(selected, planetName)
                .then(() => {
                  queryClient.invalidateQueries(queryKey.tritium.planet.planets)
                })
                .catch((error) => {
                  console.error("Erro ao renomear o planeta:", error)
                })
                .finally(() => {
                  setOpen(false)
                })
            }}
            size="small"
          >
            {t("Save")}
          </ScifiButton>
        </div>
      </ScifiModal>
      <ScifiModal
        title={t("Details")}
        isOpen={openDetails}
        onRequestClose={() => {
          setOpenDetails(false)
        }}
      >
        <ColonizationDetails />
      </ScifiModal>
      <ScifiModal
        title={t("Links")}
        isOpen={openLinks}
        onRequestClose={() => {
          setOpenLinks(false)
        }}
      >
        <LinkDetails />
      </ScifiModal>
    </>
  )
}

export default Colonization
