import { useState } from "react"
import {
  useWallet,
  WalletStatus,
  useConnectedWallet,
  useWalletProvider,
} from "@terra-money/wallet-kit"
import { ScifiModal } from "components/feedback"
import { ScifiButton } from "components/general"
import { useTranslation } from "react-i18next"
import { Flex } from "components/layout"
import { truncate } from "@terra-money/terra-utils"
import StationConnect from "./StationConnect"
import styles from "./WalletConnect.module.scss"
import {
  useWalletProvider as useWalletProviderGalaxy,
  WalletStatus as WalletStatusGalaxy,
  useWallet as useWalletGalaxy,
  useConnectedWallet as useConnectedWalletGalaxy,
} from "@hexxagon/wallet-kit"
import GalaxyConnect from "./GalaxyConnect"

const WalletConnect = () => {
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false)
  const [isConnectedOpen, setIsConnectedOpen] = useState(false)
  const [msgOpen, setMsgOpen] = useState(false)
  const { status, availableWallets, connect, disconnect } = useWallet()

  const { t } = useTranslation()

  const { defaultNetworks: defaultNetworksGalaxy, wallets: walletsGalaxy } =
    useWalletProviderGalaxy()
  const walletGalaxy = useConnectedWalletGalaxy()
  const addressGalaxy = walletGalaxy?.addresses["columbus-5"]

  const { defaultNetworks, wallets } = useWalletProvider()
  const wallet = useConnectedWallet()
  const address = wallet?.addresses["columbus-5"]

  const {
    availableWallets: availableWalletsGalaxy,
    status: statusGalaxy,
    connect: connectGalaxy,
    disconnect: disconnectGalaxy,
  } = useWalletGalaxy()

  function getAddress() {
    if (wallets.length > 0) {
      if (!address && !addressGalaxy) {
        return "Invalid Network"
      }
      if (wallets[0].id === "station-extension") {
        return truncate(address)
      }
    }
    return null
  }

  function getGalaxyAddress() {
    if (walletsGalaxy.length > 0) {
      if (!address && !addressGalaxy) {
        return "Invalid Network"
      }
      if (walletsGalaxy[0].id === "galaxy-station-extension") {
        return truncate(addressGalaxy)
      }
    }
    return null
  }

  function getWalletDetails() {
    if (wallets.length > 0) {
      if (wallets[0].id === "station-extension") {
        return (
          <StationConnect
            wallets={wallet ? [wallet] : []}
            network={defaultNetworks}
          />
        )
      }
    }
    return null
  }

  function getWalletGalaxyDetails() {
    if (walletsGalaxy.length > 0) {
      if (walletsGalaxy[0].id === "galaxy-station-extension") {
        return (
          <GalaxyConnect
            wallets={walletGalaxy ? [walletGalaxy] : []}
            network={defaultNetworksGalaxy}
          />
        )
      }
    }
    return null
  }

  return (
    <>
      {status === WalletStatus.NOT_CONNECTED &&
        statusGalaxy === WalletStatusGalaxy.NOT_CONNECTED && (
          <button
            onClick={() => {
              setIsConnectModalOpen(true)
            }}
            className={styles.connect}
          >
            {t("Connect")}
          </button>
        )}
      {(status === WalletStatus.CONNECTED ||
        statusGalaxy === WalletStatusGalaxy.CONNECTED) && (
        <button
          onClick={() => {
            if ((getAddress() || getGalaxyAddress()) === "invalid network") {
              setMsgOpen(true)
            } else {
              setIsConnectedOpen(true)
            }
          }}
          className={
            (getAddress() || getGalaxyAddress()) === "invalid network"
              ? styles.invalid
              : styles.connect
          }
        >
          {getAddress() || getGalaxyAddress()}
        </button>
      )}
      <ScifiModal
        isOpen={isConnectModalOpen}
        onRequestClose={() => {
          setIsConnectModalOpen(false)
        }}
      >
        <div className={styles.title}>
          <Flex>{"Connect Wallet"}</Flex>
        </div>
        <Flex>
          {availableWalletsGalaxy.map((wallet) => (
            <div
              key={"connect-" + wallet.id}
              onClick={() => {
                if (wallet.isInstalled) {
                  connectGalaxy(wallet.id)
                  setIsConnectModalOpen(false)
                } else {
                  if (wallet.website) {
                    window.open(wallet.website, "_blank")
                  } else {
                    alert("This wallet is not installed!")
                  }
                }
              }}
            >
              <div className={styles.connects}>
                <img src={wallet.icon} alt={wallet.name} />
                <span className={styles.titleGalaxy}>{wallet.name}</span>
              </div>
            </div>
          ))}
          {availableWallets.map((connectType) => (
            <div
              key={"connect-" + connectType.id}
              onClick={() => {
                connect(connectType.id)
                setIsConnectModalOpen(false)
              }}
            >
              <div className={styles.connects}>
                <img src={connectType.icon} alt={connectType.name} />
                <span className={styles.titleGalaxy}>{connectType.name}</span>
              </div>
            </div>
          ))}
        </Flex>
      </ScifiModal>
      <ScifiModal
        isOpen={isConnectedOpen}
        onRequestClose={() => {
          setIsConnectedOpen(false)
        }}
      >
        <div className={styles.connected}>
          {getWalletDetails()}
          {getWalletGalaxyDetails()}
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <ScifiButton
              onClick={() => {
                disconnect()
                disconnectGalaxy()
                setIsConnectedOpen(false)
              }}
            >
              {t("Disconnect")}
            </ScifiButton>
          </div>
        </div>
      </ScifiModal>
      <ScifiModal
        isOpen={msgOpen}
        onRequestClose={() => {
          setMsgOpen(false)
        }}
      >
        <div className={styles.modalMsg}>
          <div className={styles.msg}>
            {t("Please, select classic network in your wallet and try again")}
          </div>
          <ScifiButton
            onClick={() => {
              disconnect()
              disconnectGalaxy()
              setMsgOpen(false)
            }}
          >
            {t("Disconnect")}
          </ScifiButton>
        </div>
      </ScifiModal>
    </>
  )
}

export default WalletConnect
