import { useState } from "react"
import { useTranslation } from "react-i18next"
import { usePlanets } from "data/queries/planet"
import { Button } from "components/general"
import Troop from "./military/Troop"
import Fleet from "./military/Fleet"
import Missions from "./military/Missions"
import Combat from "./military/Combat"
import styles from "./Commanders.module.scss"

interface Props {
  setAction: any
  setOpen: any
  myPlanet: string
}
const TroopsMode = (props: Props) => {
  return <Troop planet={props.myPlanet} />
}

const FleetMode = (props: Props) => {
  return <Fleet planet={props.myPlanet} />
}

interface Option {
  label: string
  value: string
}

const options: Option[] = [
  {
    label: "Fleet",
    value: "fleet",
  },
  {
    label: "Troop",
    value: "troop",
  },
  {
    label: "Missions",
    value: "missions",
  },
  {
    label: "Combat",
    value: "combat",
  },
]

const Military = () => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState("fleet")
  const [selectedPlanet, setSelectedPlanet] = useState<string>(
    localStorage.getItem("planet") || "0"
  )

  const planets = usePlanets()

  const quadrantNumber = Number(localStorage.getItem("quadrant")?.slice(-3))

  const handleOptionClick = (option: string) => {
    setSelectedOption(option)
  }

  return (
    <div>
      <div className={styles.top}>
        {options.map((option) => (
          <Button
            key={option.value}
            className={
              selectedOption === option.value ? styles.active : styles.button
            }
            onClick={() => handleOptionClick(option.value)}
          >
            {t(`${option.label}`)}
          </Button>
        ))}
      </div>

      <div className={styles.line}></div>

      <div className={styles.commanderScreen}>
        {(selectedOption === "troop" || selectedOption === "fleet") && (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {planets.data &&
              planets.data.map((planet: Planet) => (
                <div
                  key={planet.id}
                  className={styles.planet}
                  style={{
                    color: planet.id === selectedPlanet ? "#f5a23c" : "",
                  }}
                  onClick={() => {
                    setSelectedPlanet(planet.id || "0")
                  }}
                >
                  <img
                    src={
                      quadrantNumber % 4 === 0
                        ? "/assets/planets/planetDesert.png"
                        : quadrantNumber % 4 === 1
                        ? "/assets/planets/planetGrass.png"
                        : quadrantNumber % 4 === 2
                        ? "/assets/planets/planetIce.png"
                        : "/assets/planets/planetVulcan.png"
                    }
                    alt={planet.name}
                  />
                  <br />
                  <span className={styles.planetName}>{planet.name}</span>
                </div>
              ))}
          </div>
        )}
        {/* {selectedOption === "fleet" && <div>{}</div>} */}
      </div>

      <div>
        {/* {selectedOption === "troop" && <Troop planet={selectedPlanet} />} */}
        {selectedOption === "troop" && (
          <TroopsMode
            setAction={() => {}}
            setOpen={() => {}}
            myPlanet={selectedPlanet}
          />
        )}
        {selectedOption === "fleet" && (
          <FleetMode
            setAction={() => {}}
            setOpen={() => {}}
            myPlanet={selectedPlanet}
          />
        )}
        {selectedOption === "missions" && <Missions />}
        {selectedOption === "combat" && <Combat />}
      </div>
    </div>
  )
}

export default Military
