import { useTranslation } from "react-i18next"
import HelpIcon from "@mui/icons-material/Help"
import HeaderIconButton from "../components/HeaderIconButton"
import { Tooltip } from "@mui/material"
import styles from "./Conversation.module.scss"

const Docs = () => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t("Help")}>
      <HeaderIconButton
        onClick={() => {
          window.open("https://docs.terratritium.com", "_blank")
        }}
      >
        <div className={styles.help}>
          <HelpIcon style={{ fontSize: 18 }} />
        </div>
      </HeaderIconButton>
    </Tooltip>
  )
}

export default Docs
