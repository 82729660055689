import { LAZY_LIMIT } from "config/constants"

/* refetch */
export const RefetchOptions = {
  DEFAULT: /* onMount, onFocus */ {},
  INFINITY: { staleTime: Infinity, retry: false },
}

/* params */
export const Pagination = {
  "pagination.limit": String(LAZY_LIMIT),
}

/* helpers */
export const combineState = (...results: QueryState[]) => ({
  isIdle: results.some((result) => result.isIdle),
  isLoading: results.some((result) => result.isLoading),
  isFetching: results.some((result) => result.isFetching),
  isSuccess: results.every((result) => result.isSuccess),
  error: results.find((result) => result.error)?.error,
})

/* queryKey */
const mirror = <T extends Object>(obj: T, parentKey?: string): T =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    const next = value
      ? mirror(value, key)
      : [parentKey, key].filter(Boolean).join(".")

    return { ...acc, [key]: next }
  }, {} as T)

export const queryKey = mirror({
  /* Tritium */
  tritium: {
    build: {
      builds: "",
      availableBuilds: "",
      pushBuild: "",
      requires: "",
    },
    podium: {},
    building: {
      buildings: "",
    },
    quadrant: {
      quadrant: "",
      planets: "",
    },
    country: {
      countrys: "",
    },
    unit: {
      units: "",
    },
    ship: {
      ship: "",
    },
    troop: {
      troop: "",
      troopUnderconstruction: "",
    },
    fleet: {
      fleet: "",
      fleetUnderconstruction: "",
    },
    planet: {
      planets: "",
      planet: "",
    },
    player: {
      player: "",
    },
    balance: "",
    ranking: {
      players: "",
      alliances: "",
      category: "",
    },
    research: {
      list: "",
    },
    researched: {
      details: "",
    },
    gameMode: {
      list: "",
      gameModeSelected: "",
      gameModePlayerSelected: "",
    },
    message: {
      messages: "",
      conversation: "",
      unread: "",
    },
    market: {
      resource: "",
    },
    notification: {
      notifications: "",
    },
    aliances: {
      list: "",
      disponibles: "",
      listAliance: "",
      myAliance: "",
      ranks: "",
      messagesGroup: "",
      getInvites: "",
    },
    NFT: {
      config: "",
    },
    nftEffect: {
      effects: "",
    },
    travel: {
      attack: "",
      defense: "",
      transport: "",
      colonize: "",
      explore: "",
      militar: "",
      comerce: "",
      all: "",
      challangeMission: "",
    },
    logs: {
      logs: "",
      jobs: "",
    },
    combat: {
      combats: "",
      CombatLogs: "",
      strategies: "",
      strategiesSelected: "",
      numberPlanets: "",
      availableShip: "",
      availableResources: "",
      figthers: "",
      currentCombat: "",
    },
    region: {
      quadrant: "",
      planets: "",
    },
    espionage: {},
  },
})
