export function nftNameToCode(nftName: string) {
  const nftArr = nftName.split("#")
  const id = Number(nftArr[1])

  if (id === undefined) {
    return 0
  }

  if (id <= 30) {
    return 1
  }
  if (id <= 60) {
    return 2
  }
  if (id <= 90) {
    return 3
  }
  if (id <= 120) {
    return 4
  }
  if (id <= 150) {
    return 5
  }
  if (id <= 180) {
    return 6
  }
  if (id <= 210) {
    return 7
  }
  if (id <= 240) {
    return 8
  }
  if (id <= 270) {
    return 9
  }
  if (id <= 300) {
    return 10
  }
  if (id <= 330) {
    return 11
  }
  if (id <= 360) {
    return 12
  }
  if (id <= 390) {
    return 13
  }
  if (id <= 420) {
    return 14
  }
  if (id <= 450) {
    return 15
  }
  if (id <= 480) {
    return 16
  }
  if (id <= 510) {
    return 17
  }
  if (id <= 540) {
    return 18
  }
  if (id <= 570) {
    return 19
  }
  if (id <= 600) {
    return 20
  }
  if (id <= 630) {
    return 21
  }
  if (id <= 660) {
    return 22
  }
  if (id <= 690) {
    return 23
  }
  if (id <= 720) {
    return 24
  }
  if (id <= 750) {
    return 25
  }
  if (id <= 780) {
    return 26
  }
  if (id <= 810) {
    return 27
  }
  if (id <= 840) {
    return 28
  }
  if (id <= 870) {
    return 29
  }
  if (id <= 900) {
    return 30
  }
  if (id <= 930) {
    return 31
  }
  if (id <= 960) {
    return 32
  }
  if (id <= 990) {
    return 33
  }
  if (id <= 1020) {
    return 34
  }
  if (id <= 1050) {
    return 35
  }
  if (id <= 1080) {
    return 36
  }
  if (id <= 1110) {
    return 37
  }
  if (id <= 1140) {
    return 38
  }
  if (id <= 1170) {
    return 39
  }
  if (id <= 1200) {
    return 40
  }
  if (id <= 1230) {
    return 41
  }
  if (id <= 1260) {
    return 42
  }
  if (id <= 1290) {
    return 43
  }
  if (id <= 1320) {
    return 44
  }
  if (id <= 1350) {
    return 45
  }
  if (id <= 1380) {
    return 46
  }
  if (id <= 1410) {
    return 47
  }
  if (id <= 1440) {
    return 48
  }
  if (id <= 1470) {
    return 49
  }
  if (id <= 1500) {
    return 50
  }
  if (id <= 1530) {
    return 51
  }
  if (id <= 1560) {
    return 52
  }
  if (id <= 1590) {
    return 53
  }
  if (id <= 1620) {
    return 54
  }
  if (id <= 1650) {
    return 55
  }
  if (id <= 1680) {
    return 56
  }
  if (id <= 1710) {
    return 57
  }
  if (id <= 1740) {
    return 58
  }
  if (id <= 1770) {
    return 59
  }
  if (id <= 1800) {
    return 60
  }
  if (id <= 1830) {
    return 61
  }
  if (id <= 1860) {
    return 62
  }
  if (id <= 1890) {
    return 63
  }
  if (id <= 1920) {
    return 64
  }
  if (id <= 1950) {
    return 65
  }
  if (id <= 1980) {
    return 66
  }
  if (id <= 2010) {
    return 67
  }
  if (id <= 2040) {
    return 68
  }
  if (id <= 2055) {
    return 69
  }
  if (id <= 2070) {
    return 70
  }
  if (id <= 2085) {
    return 71
  }
  if (id <= 2100) {
    return 72
  }
  if (id <= 2115) {
    return 73
  }
  if (id <= 2130) {
    return 74
  }
  if (id <= 2145) {
    return 75
  }
  if (id <= 2160) {
    return 76
  }
  if (id <= 2175) {
    return 77
  }
  if (id <= 2190) {
    return 78
  }
  if (id <= 2205) {
    return 79
  }
  if (id <= 2220) {
    return 80
  }
  if (id <= 2235) {
    return 81
  }
  if (id <= 2250) {
    return 82
  }
  if (id <= 2265) {
    return 83
  }
  if (id <= 2280) {
    return 84
  }
  if (id <= 2295) {
    return 85
  }
  if (id <= 2310) {
    return 86
  }
  if (id <= 2325) {
    return 87
  }
  if (id <= 2340) {
    return 88
  }
  if (id <= 2355) {
    return 89
  }
  if (id <= 2370) {
    return 90
  }
  if (id <= 2385) {
    return 91
  }
  if (id <= 2400) {
    return 92
  }
  if (id <= 2415) {
    return 93
  }
  if (id <= 2430) {
    return 94
  }
  if (id <= 2445) {
    return 95
  }
  if (id <= 2460) {
    return 96
  }
  if (id <= 2475) {
    return 97
  }
  if (id <= 2490) {
    return 98
  }
  if (id <= 2495) {
    return 99
  }
  if (id <= 2500) {
    return 100
  }
  if (id <= 2505) {
    return 101
  }
  if (id <= 2510) {
    return 102
  }
  if (id <= 2515) {
    return 103
  }
  if (id <= 2520) {
    return 104
  }
  if (id <= 2525) {
    return 105
  }
  if (id <= 2530) {
    return 106
  }
  if (id <= 2535) {
    return 107
  }
  if (id <= 2540) {
    return 108
  }
  if (id <= 2545) {
    return 109
  }
  if (id <= 2550) {
    return 110
  }
  if (id <= 2555) {
    return 111
  }
  if (id <= 2560) {
    return 112
  }
  if (id <= 2565) {
    return 113
  }
  if (id <= 2570) {
    return 114
  }
  if (id <= 2575) {
    return 115
  }
  if (id <= 2580) {
    return 116
  }
  if (id <= 2585) {
    return 117
  }
  if (id <= 2590) {
    return 118
  }
  if (id <= 2595) {
    return 119
  }
  if (id <= 2600) {
    return 120
  }
  if (id <= 2605) {
    return 121
  }
  if (id <= 2610) {
    return 122
  }
  if (id <= 2615) {
    return 123
  }
  if (id <= 2620) {
    return 124
  }
  if (id <= 2625) {
    return 125
  }
  if (id <= 2630) {
    return 126
  }
  if (id <= 2635) {
    return 127
  }
  if (id <= 2640) {
    return 128
  }
  if (id <= 2645) {
    return 129
  }
  if (id <= 2790) {
    return 130
  }
  if (id <= 2890) {
    return 131
  }
  if (id <= 2990) {
    return 132
  }
}
