import { usePlanets } from "data/queries/planet"
import mp3Assemble from "sounds/assemble.mp3"
import minerMetalAnimation from "styles/images/animations/minerMetal.webp"
import minerUraniumAnimation from "styles/images/animations/minerUranium.webp"
import minerCrystalAnimation from "styles/images/animations/minerCrystal.webp"
import { BuildKeys } from "data/builds"
import styles from "./Slot.module.scss"

interface Props {
  code: number
  planet: string
  image: string
  setOnModalDetail: any
  setIsDragable: any
  setOnCircular: any
  setBuildHover: any
  onCircular: boolean
  onClickX: number
  onClickY: number
  onConstruct: boolean
}

const Build = (props: Props) => {
  const {
    code,
    image,
    setOnModalDetail,
    setIsDragable,
    setOnCircular,
    setBuildHover,
    onCircular,
    onClickX,
    onClickY,
    onConstruct,
  } = props

  const planets = usePlanets()
  const imgBuild = `/assets/builds/${image}`

  function soundAssemplePlay() {
    const soundAssemble = new Audio(mp3Assemble)
    if (soundAssemble.canPlayType("audio/mpeg")) {
      soundAssemble.play()
    }
  }

  return (
    <div
      className={onConstruct ? styles.imageBuildOnConstruct : styles.imageBuild}
    >
      {planets.data && planets.data[0] && (
        <img
          src={
            code === BuildKeys.METALMINING && planets.data[0].workersOnMetal > 0
              ? minerMetalAnimation
              : code === BuildKeys.URANIUMMINING &&
                planets.data[0].workersOnUranium > 0
              ? minerUraniumAnimation
              : code === BuildKeys.CRYSTALMINING &&
                planets.data[0].workersOnCrystal > 0
              ? minerCrystalAnimation
              : imgBuild
          }
          className={
            code === BuildKeys.COLONIZATION
              ? styles.colonization
              : code === BuildKeys.SHIELD
              ? styles.shield
              : styles.default
          }
          alt={image}
          onDoubleClick={() => {
            setOnModalDetail(true)
            setIsDragable(false)
          }}
          onClick={(e) => {
            if (onClickX === e.clientX && onClickY === e.clientY) {
              setOnCircular(!onCircular)
              soundAssemplePlay()
            }
          }}
          onMouseOver={() => {
            setBuildHover(true)
          }}
          onMouseOut={() => {
            setBuildHover(false)
          }}
          draggable={false}
        />
      )}
    </div>
  )
}

export default Build
