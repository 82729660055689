/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { upBuilding } from "data/queries/build"
import { calcResourceRequirements } from "utils/requires"
import { checkFunds } from "utils/requires"
import { GameContext } from "pages/GameContext"
import { Flex } from "components/layout"
import { Button } from "components/general"
import { ScifiModal } from "components/feedback"
import { TRITIUM_CONSTRUCTION_SPEED } from "config/constants"
import UpgradeRequire from "./UpgradeRequire"
import CircularMenu from "components/general/CircularMenu"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp"
import SettingsIcon from "@mui/icons-material/Settings"
import styles from "./Slot.module.scss"
import {
  useConstructionBuildSpeed,
  useDiscountBuild,
  useSpeedMining,
} from "utils/hooks/gameModeEffect"
import { calcSpeedConstructionBuild } from "utils/effect"

interface Props {
  code: number
  setOnModalDetail: any
  setOnDescriptionModal: any
  setIsDragable: any
  setOnCircular: any
  building: Building
  build: Build
  onConstruction: boolean
}

const BuildMenu = (props: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const {
    code,
    setOnModalDetail,
    setOnDescriptionModal,
    setIsDragable,
    setOnCircular,
    building,
    build,
    onConstruction,
  } = props

  const { planetData, onBuilding, setOnBuilding } = useContext(GameContext)
  let planetId =
    typeof planetData !== "undefined" && "id" in planetData
      ? planetData.id
      : "0"
  const [item, setItem] = useState<string>("")
  const [isOpen, setOpen] = useState(false)

  const discountBuild = useDiscountBuild(planetId)
  const speedMining = useSpeedMining(planetId)
  const speedConstructionBuild = useConstructionBuildSpeed(planetId)

  const requires = calcResourceRequirements(
    build,
    Number(building.level) + 1,
    discountBuild,
    speedConstructionBuild
  )

  const upgradeBuild = (time: number) => {
    upBuilding(building)
    setOnBuilding(true)
    setTimeout(() => queryClient.invalidateQueries(), 200)
    setTimeout(() => {
      queryClient.invalidateQueries()
      setOnBuilding(false)
    }, time * 1000 * calcSpeedConstructionBuild(TRITIUM_CONSTRUCTION_SPEED, speedConstructionBuild) + 200)
  }

  return (
    <>
      <CircularMenu
        active={true}
        x={60}
        y={code === 1 ? 10 : 20}
        selectedText={item}
        size="medium"
        actionButton={true}
        setOnCircular={setOnCircular}
      >
        <ul>
          <li
            onMouseOver={() => {
              setItem(t("Settings"))
            }}
            onMouseOut={() => {
              setItem("")
            }}
          >
            <a
              href="_"
              onClick={(e) => {
                e.preventDefault()
                setOnModalDetail(true)
                setIsDragable(false)
                setOnCircular(false)
              }}
            >
              <span>
                <SettingsIcon />
              </span>
            </a>
          </li>
          <li
            onMouseOver={() => {
              setItem(t("Upgrade"))
            }}
            onMouseOut={() => {
              setItem("")
            }}
            onClick={() => {
              setOpen(true)
            }}
          >
            <a
              className={
                onConstruction ||
                onBuilding ||
                !checkFunds(
                  build,
                  Number(building.level) + 1,
                  planetData,
                  discountBuild,
                  speedMining,
                  speedConstructionBuild
                )
                  ? styles.disabled
                  : styles.zero
              }
            >
              <span>
                <ArrowCircleUpIcon />
              </span>
            </a>
          </li>
          <li
            onMouseOver={() => {
              setItem(t("Help"))
            }}
            onMouseOut={() => {
              setItem("")
            }}
          >
            <a
              href="_"
              onClick={(e) => {
                e.preventDefault()
                setOnDescriptionModal(true)
                setIsDragable(false)
                setOnCircular(false)
              }}
            >
              <span>
                <HelpOutlineIcon />
              </span>
            </a>
          </li>
          <li>
            <a href="_" className={styles.disabled}>
              <span></span>
            </a>
          </li>
          <li>
            <a href="_" className={styles.disabled}>
              <span></span>
            </a>
          </li>
        </ul>
      </CircularMenu>

      <ScifiModal
        title={t("Upgrade")}
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
      >
        <Flex>
          <div className={styles.text}>
            <div className={styles.upgrade}>
              <div className={styles.upText}>{t(`${build.effect}`)}</div>
            </div>
            <br />
            <div className={styles.msg}>
              {t("The upgrade will spend the following resources")}
            </div>
            <br />
            <div className={styles.requires}>
              <UpgradeRequire requires={requires} />
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  if (!onConstruction) {
                    upgradeBuild(Number(requires.time))
                    setOnCircular(false)
                  }
                }}
                size="small"
                disabled={
                  onConstruction ||
                  onBuilding ||
                  !checkFunds(
                    build,
                    Number(building.level) + 1,
                    planetData,
                    discountBuild,
                    speedMining,
                    speedConstructionBuild
                  )
                }
              >
                {t("Confirm")}
              </Button>
            </div>
          </div>
        </Flex>
      </ScifiModal>
    </>
  )
}

export default BuildMenu
