import { useState, useRef } from "react"
import { useFleets } from "data/queries/fleet"
import { useTranslation } from "react-i18next"
import { startTravel } from "data/queries/travel"
import { ScifiButton } from "components/general"
import { Button } from "components/general"
import { registerLog } from "data/queries/log"
import { useAvailableShip } from "data/queries/combat"
import { TRITIUM_TRAVEL_ATTACK_FLEET } from "config/constants"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import ShipUnit from "./ShipUnit"
import TransportUnit from "./TransportUnit"
import StrategyOptions from "./StrategyOptions"
import styles from "./SendFleet.module.scss"

interface Props {
  setAction: any
  target: string
  targetName: string
  targetCoods: string
  setOpen: any
  planet: any
}

const SendFleet = (props: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const planetId = localStorage.getItem("planet")
  const fleetData = useFleets(String(planetId))
  const [fleets, setFleets] = useState<any>([])
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [strategy, setStrategy] = useState(1)
  const [transportShip, setTransportShip] = useState<number>(0)

  const availableShip = useAvailableShip()
  // console.log('availabe',availableShip)
  /**se nao possui naves disponível, disabled será true */
  const [disabled, setDisabled] = useState<boolean>(
    !availableShip.data || !availableShip.data.ships
      ? true
      : availableShip && availableShip.data.ships === 0
      ? true
      : false
  )

  const refInput0 = useRef<HTMLInputElement>(null)
  const refInput1 = useRef<HTMLInputElement>(null)
  const refInput2 = useRef<HTMLInputElement>(null)
  const refInput3 = useRef<HTMLInputElement>(null)
  const refInput4 = useRef<HTMLInputElement>(null)
  const refInput5 = useRef<HTMLInputElement>(null)
  const refInput6 = useRef<HTMLInputElement>(null)
  const refInput7 = useRef<HTMLInputElement>(null)
  const refInput8 = useRef<HTMLInputElement>(null)
  const refInput9 = useRef<HTMLInputElement>(null)
  const refInput10 = useRef<HTMLInputElement>(null)
  const refInput11 = useRef<HTMLInputElement>(null)
  const refInput12 = useRef<HTMLInputElement>(null)
  const refInput13 = useRef<HTMLInputElement>(null)
  const refInput14 = useRef<HTMLInputElement>(null)
  const refInput15 = useRef<HTMLInputElement>(null)

  function getRefInput(id: number) {
    switch (id) {
      case 0:
        return refInput0
      case 1:
        return refInput1
      case 2:
        return refInput2
      case 3:
        return refInput3
      case 4:
        return refInput4
      case 5:
        return refInput5
      case 6:
        return refInput6
      case 7:
        return refInput7
      case 8:
        return refInput8
      case 9:
        return refInput9
      case 10:
        return refInput10
      case 11:
        return refInput11
      case 12:
        return refInput12
      case 13:
        return refInput13
      case 14:
        return refInput14
      case 15:
        return refInput15
    }
  }

  function reset() {
    refInput0.current && (refInput0.current.value = "0")
    refInput1.current && (refInput1.current.value = "0")
    refInput2.current && (refInput2.current.value = "0")
    refInput3.current && (refInput3.current.value = "0")
    refInput4.current && (refInput4.current.value = "0")
    refInput5.current && (refInput5.current.value = "0")
    refInput6.current && (refInput6.current.value = "0")
    refInput7.current && (refInput7.current.value = "0")
    refInput8.current && (refInput8.current.value = "0")
    refInput9.current && (refInput9.current.value = "0")
    refInput10.current && (refInput10.current.value = "0")
    refInput11.current && (refInput11.current.value = "0")
    refInput12.current && (refInput12.current.value = "0")
    refInput13.current && (refInput13.current.value = "0")
    refInput14.current && (refInput14.current.value = "0")
    refInput15.current && (refInput15.current.value = "0")
    fleetData &&
      fleetData.data &&
      fleetData.data.map((element, idx) => {
        fleets[idx] = {
          unit: Number(element.ship.id),
          quantity: 0,
        }
        setFleets(fleets)
        setDisabled(true)
        return null
      })
  }

  function all() {
    // @todo: o cargueiro está sendo preenchido com valor errado, soh liberar essa funcionalidade depois de corrigir o bug
    // refInput0.current &&
    //   (refInput0.current.value = getRefInput(0)?.current?.max || "0")
    // refInput1.current &&
    //   (refInput1.current.value = getRefInput(1)?.current?.max || "0")
    // refInput2.current &&
    //   (refInput2.current.value = getRefInput(2)?.current?.max || "0")
    // refInput3.current &&
    //   (refInput3.current.value = getRefInput(3)?.current?.max || "0")
    // refInput4.current &&
    //   (refInput4.current.value = getRefInput(4)?.current?.max || "0")
    // refInput5.current &&
    //   (refInput5.current.value = getRefInput(5)?.current?.max || "0")
    // refInput6.current &&
    //   (refInput6.current.value = getRefInput(6)?.current?.max || "0")
    // refInput7.current &&
    //   (refInput7.current.value = getRefInput(7)?.current?.max || "0")
    // refInput8.current &&
    //   (refInput8.current.value = getRefInput(8)?.current?.max || "0")
    // refInput9.current &&
    //   (refInput9.current.value = getRefInput(9)?.current?.max || "0")
    // refInput10.current &&
    //   (refInput10.current.value = getRefInput(10)?.current?.max || "0")
    // refInput11.current &&
    //   (refInput11.current.value = getRefInput(11)?.current?.max || "0")
    // refInput12.current &&
    //   (refInput12.current.value = getRefInput(12)?.current?.max || "0")
    // refInput13.current &&
    //   (refInput13.current.value = getRefInput(13)?.current?.max || "0")
    // refInput14.current &&
    //   (refInput14.current.value = getRefInput(14)?.current?.max || "0")
    // refInput15.current &&
    //   (refInput15.current.value = getRefInput(15)?.current?.max || "0")
    // fleetData &&
    //   fleetData.data &&
    //   fleetData.data.map((element, idx) => {
    //     fleets[idx] = {
    //       unit: Number(element.ship.id),
    //       quantity: element.quantity,
    //     }
    //     setFleets(fleets)
    //     setDisabled(false)
    //     return null
    //   })
  }

  function isEmpty() {
    let empty = true
    fleets &&
      fleets.map((element: any) => {
        if (element.quantity > 0) {
          empty = false
        }
        return null
      })
    return empty
  }

  return (
    <>
      {!alert && (
        <>
          <div className={styles.options}>
            <Button onClick={() => reset()} size="small">
              {t("Reset")}
            </Button>
            <Button disabled onClick={() => all()} size="small">
              {t("All")}
            </Button>
          </div>
          <div className={styles.fleet}>
            <div style={{ padding: "4px" }}>{t("Select space ships")}</div>
            {fleets &&
              fleetData &&
              fleetData.data &&
              fleetData.data.map((element, idx) => (
                <div key={idx}>
                  <ShipUnit
                    idx={idx}
                    fleets={fleets}
                    setFleets={setFleets}
                    setDisabled={setDisabled}
                    isEmpty={isEmpty}
                    fleetUnit={element}
                    getRefInput={getRefInput}
                  />
                </div>
              ))}
            {fleetData.data && fleetData.data.length <= 0 && (
              <div className={styles.msg}></div>
            )}
            <TransportUnit
              idx={-9999}
              fleets={fleets}
              setFleets={setFleets}
              setDisabled={setDisabled}
              isEmpty={isEmpty}
              maxShips={100}
              getRefInput={getRefInput}
              setTransportShip={setTransportShip}
              planet={props.planet}
            />
          </div>

          <div className={styles.strategies}>
            <StrategyOptions strategy={strategy} setStrategy={setStrategy} />
          </div>

          <div className={styles.actions}>
            <ScifiButton
              onClick={() => {
                props.setAction("default")
              }}
            >
              {t("Cancel")}
            </ScifiButton>
            <ScifiButton
              disabled={disabled}
              onClick={() => {
                startTravel(
                  TRITIUM_TRAVEL_ATTACK_FLEET,
                  localStorage.getItem("planet"),
                  props.target,
                  [],
                  fleets,
                  strategy,
                  transportShip
                ).then((response) => {
                  if (response.data === "success") {
                    registerLog({
                      type: "Space",
                      text:
                        "Send fleet for " +
                        props.targetName +
                        ":" +
                        props.targetCoods,
                    })
                    setAlertMessage(
                      t(
                        "Success, loading the ships for travel. Check your missions on the Commandant"
                      )
                    )
                    queryClient.invalidateQueries(
                      queryKey.tritium.travel.militar
                    )
                    queryClient.invalidateQueries(queryKey.tritium.fleet.fleet)
                  } else {
                    setAlertMessage(
                      t(response.data || "Error, try again later")
                    )
                  }
                  setAlert(true)
                })
              }}
            >
              {t("Send Fleet")}
            </ScifiButton>
          </div>
        </>
      )}
      {alert && (
        <div style={{ textAlign: "center" }}>
          <div className={styles.alertMessage}>{alertMessage}</div>
          <ScifiButton
            onClick={() => {
              props.setOpen(false)
            }}
          >
            {t("Ok")}
          </ScifiButton>
        </div>
      )}
    </>
  )
}

export default SendFleet
