// import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined"
import PersonIcon from "@mui/icons-material/Person"
import styles from "./AlianceMembers.module.scss"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  getMembersAliance,
  getMembersPending,
  getMyAlianceDetails,
  responseRequestMember,
} from "data/queries/aliance"
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined"
import { GroupRemoveOutlined } from "@mui/icons-material"
import ListIcon from "@mui/icons-material/List"
import { Button } from "components/general"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import { LoadingCircular, Modal } from "components/feedback"
import { Flex, Table } from "components/layout"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"

import { IconsRankMember } from "./Aliance"
import {
  TRITIUM_MEMBER_CORPORAL,
  TRITIUM_MEMBER_DIPLOMAT,
  TRITIUM_MEMBER_FLEET_CAP,
  TRITIUM_MEMBER_FOUNDER,
  TRITIUM_MEMBER_GENERAL,
  TRITIUM_MEMBER_SOLDIER,
  TRITIUM_MEMBER_TROOP_CAP,
} from "config/constants"
import { ListActionMember } from "./AlianceMemberAction"
import InviteMemberAliance from "./InvateMemberAliance"
import { Tooltip } from "@mui/material"
import disableScroll from "disable-scroll"

/**
 * Ações prevista
 * A - Aprovar (accept)
 * R - Remover (remove)
 * U - Promover (Up Level - advance)
 * D - Rebaixar (down level - demote)
 *
 * OptionListeMember "Opção de exibição da tela de membros"
 * 1 - Listagem de membros da aliança
 * 2 - Listagem de solicitação de ingresso na aliança
 * 3 - Edição de cargos do membro
 * 4 - Visão do General
 * 5 - Convidar um novo membro para a aliança (exclusivo para o fundador)
 */
interface FindMembersProps {
  idAliance: number
  founderId: number
}
const Members = (Props: FindMembersProps) => {
  const { idAliance, founderId } = Props
  const [members, setMembers] = useState<DetailsMyAliance[]>([])
  const planet = localStorage.getItem("planet")
  const { t } = useTranslation()
  const [optionListMembers, setOptionListMembers] =
    useState(1) /**verificar comentário no topo */
  const [membersRequest, setMembersRequest] = useState<MemberRequest[]>([])
  const isFounder =
    parseInt(localStorage.getItem("planet") ?? "0") === founderId

  const [actionRequestMember, setActionRequestMember] =
    useState("") /**verificar comentário no topo */
  const [confirmAction, setConfirmAction] = useState<boolean>(false)
  const [memberId, setMemberId] = useState(0)

  const [error, setError] = useState<Error>()
  const [msgErr, setMsgErr] = useState("")
  const [load, setLoad] = useState(true)

  const [refreshListMembers, setRefreshListMembers] = useState(false)
  const [memberAction, setMemberAction] = useState<DetailsMyAliance | null>(
    null
  )
  const [alianceMemberDetail, setAlianceMemberDetail] = useState<
    DetailsMyAliance | undefined
  >()

  useEffect(() => {
    fetchMemberAliance(idAliance)
    fetchMembersPending(idAliance)
    fecthDetailsMember()
  }, [idAliance])

  useEffect(() => {
    if (refreshListMembers) {
      fetchMemberAliance(idAliance)
      fetchMembersPending(idAliance)
      fecthDetailsMember()
      setRefreshListMembers(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshListMembers])

  /*aquii, mudar a dependencia para recuperar os membros apos alterar*/

  const fecthDetailsMember = () => {
    getMyAlianceDetails()
      .then((res) => {
        setAlianceMemberDetail(res.data)
      })
      .catch((er) => console.log("detail", er))
    // .finally(() => setLoadDetail(false))
  }

  const fetchMembersPending = async (idAliance: number) => {
    setLoad(true)
    getMembersPending(idAliance)
      .then((res) => {
        setMembersRequest(res.data)
        const countRequest = res.data.length
        if (countRequest === 0) setOptionListMembers(1)
      })
      .catch((err) => console.log("erro ao recuperar pendentes", err))
      .finally(() => setLoad(false))
  }
  const fetchMemberAliance = async (idAliance: number) => {
    getMembersAliance(idAliance)
      .then((res) => {
        setMembers(res.data)
        // console.log("membros", res)
        // console.log("alianca", idAliance)
      })
      .catch((err) => console.log("err", err))
  }

  const change = (alianceId: number) => {
    if (optionListMembers === 1) setOptionListMembers(2)
    else setOptionListMembers(1)
  }

  const showOptionForUser = (member: DetailsMyAliance) => {
    setMemberAction(member)
    setOptionListMembers(3)
  }

  const handleConfirmAction = async (
    idMemberResquest: number,
    action: string
  ) => {
    setMemberId(idMemberResquest)
    setActionRequestMember(action)
    setConfirmAction(true)
  }
  const actionResponse = async (idMemberResquest: number, action: string) => {
    await responseRequestMember(idMemberResquest, action)
      .then((res) => console.log("acao de aceitar/remover", res))
      .catch((err) => {
        setError(err as Error)
        setMsgErr(err.response.data.message)
      })
      .finally(() => {
        fetchMemberAliance(idAliance)
        fetchMembersPending(idAliance)
      })
  }
  const [page, setPage] = useState(1)

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const modalError = !error
    ? undefined
    : {
        title: t("Error"),
        children: (
          <section>
            <aside>
              <Flex>
                {
                  // t("Order registration failed!")
                  msgErr
                }
              </Flex>
            </aside>
          </section>
        ),
      }

  const modalConfirm = !confirmAction
    ? undefined
    : {
        title: t("Confirmar"),
        children: (
          <section>
            <section>
              {actionRequestMember === "A" &&
                t("Do you Want accept the member?")}
              {actionRequestMember === "R" &&
                t("Do you want to remove the member?")}
              {actionRequestMember === "U" &&
                t("Do you want to advance the member?")}
              {actionRequestMember === "D" &&
                t("Do you want to demote the member?")}
            </section>
          </section>
        ),
      }

  return (
    <>
      {load && <LoadingCircular />}
      {[TRITIUM_MEMBER_FOUNDER].includes(alianceMemberDetail?.idRank!) && (
        <>
          <div
            className={styles.addMember}
            onClick={
              () => setOptionListMembers(5)
              // alert('oi')
              //  editarAlianca(alianceId)
            }
          >
            <Tooltip title={t("Invite Member")}>
              <AddCircleOutlineIcon />
            </Tooltip>
          </div>
        </>
      )}

      {membersRequest &&
        membersRequest.length > 0 &&
        [
          TRITIUM_MEMBER_FOUNDER,
          TRITIUM_MEMBER_DIPLOMAT,
          TRITIUM_MEMBER_GENERAL,
        ].includes(alianceMemberDetail?.idRank!) && (
          <div className={styles.aceptMember} onClick={() => change(idAliance)}>
            {optionListMembers === 1 ? (
              <>
                <Tooltip title={t("Received requests")}>
                  <PersonAddAltOutlinedIcon />
                </Tooltip>
                <div className={styles.notification}>
                  {membersRequest.length}
                </div>
              </>
            ) : (
              <ListIcon />
            )}
          </div>
        )}

      {/* /adicionar o icone de retornar para a listagem para esses membros e quando não tem solicitações */}
      {!membersRequest ||
        (membersRequest.length === 0 &&
          optionListMembers === 3 &&
          [
            TRITIUM_MEMBER_FOUNDER,
            TRITIUM_MEMBER_DIPLOMAT,
            TRITIUM_MEMBER_GENERAL,
            TRITIUM_MEMBER_FLEET_CAP,
            TRITIUM_MEMBER_TROOP_CAP,
          ].includes(alianceMemberDetail?.idRank!) && (
            <div
              className={styles.aceptMember}
              onClick={() => change(idAliance)}
            >
              <ListIcon />
            </div>
          ))}
      {/* akii */}
      {optionListMembers === 1 && (
        <div className={styles.contentMembersList}>
          <div style={{ marginBottom: "5px" }}>
            <div
              onWheel={(e) => {
                disableScroll.off()
              }}
              onMouseOut={(e) => {
                disableScroll.on()
              }}
            >
              <Table
                columns={[
                  {
                    key: "name",
                    title: t("Player"),
                    dataIndex: "name",
                    align: "left",
                    render: (name: string) => {
                      return <>{name?.substring(0, 15)}</>
                    },
                  },
                  {
                    key: "dateAdmission",
                    title: t("Since"),
                    dataIndex: "dateAdmission",
                    align: "left",
                    render: (dateAdmission: string) => {
                      return <>{dateAdmission?.toString().slice(0, 10)}</>
                    },
                  },
                  {
                    key: "idRank",
                    dataIndex: "idRank",
                    align: "right",
                    render: (idRank: number) => {
                      return (
                        <Tooltip title={t("Rank")}>
                          <div style={{ marginRight: "-20px" }}>
                            <IconsRankMember idRank={idRank ?? 7} />
                          </div>
                        </Tooltip>
                      )
                    },
                  },
                  {
                    key: "idRank",
                    dataIndex: "idRank",
                    align: "right",
                    render: (idRank: number, record: DetailsMyAliance) => {
                      return (
                        <>
                          <Tooltip title={t("Edit Member")}>
                            <div>
                              {founderId.toString() === planet ||
                              (alianceMemberDetail?.idRank !==
                                TRITIUM_MEMBER_SOLDIER &&
                                alianceMemberDetail?.idRank !==
                                  TRITIUM_MEMBER_CORPORAL) ? (
                                <button
                                  disabled={idRank === TRITIUM_MEMBER_FOUNDER}
                                  style={{ marginLeft: "1px" }}
                                  onClick={() => {
                                    if (idRank !== TRITIUM_MEMBER_FOUNDER) {
                                      showOptionForUser(record)
                                    }
                                  }}
                                >
                                  <PersonIcon fontSize={"small"} />
                                </button>
                              ) : (
                                <>
                                  <button disabled={true}>
                                    <PersonIcon fontSize={"small"} />
                                  </button>
                                </>
                              )}
                            </div>
                          </Tooltip>
                        </>
                      )
                    },
                  },
                ]}
                dataSource={members}
                size="small"
                pagination={{
                  currentPage: page,
                  pageSize: 100,
                  onPageChange: handlePageChange,
                }}
              />
            </div>
          </div>
          <div className={styles.numberMembersBottom}>
            {t("Total")}: {members.length}
          </div>
        </div>
      )}
      {optionListMembers === 2 && (
        <>
          {membersRequest && membersRequest.length > 0 && (
            <>
              <div className={styles.contentMembersList}>
                <div style={{ marginBottom: "5px" }}>
                  <div
                    onWheel={(e) => {
                      disableScroll.off()
                    }}
                    onMouseOut={(e) => {
                      disableScroll.on()
                    }}
                  ></div>
                  <Table
                    columns={[
                      {
                        key: "name",
                        title: t("Player"),
                        dataIndex: "name",
                        align: "left",
                        render: (name: string) => {
                          return <>{name?.substring(0, 15)}</>
                        },
                      },
                      {
                        key: "createdAt",
                        title: t("Solicited"),
                        dataIndex: "createdAt",
                        align: "left",
                        render: (createdAt: string) => {
                          return <>{createdAt?.toString().slice(0, 10)}</>
                        },
                      },
                      {
                        key: "id",
                        dataIndex: "id",
                        align: "right",
                        render: (id: number) => {
                          return (
                            <div style={{ marginRight: "0px" }}>
                              <Tooltip title={t("Reject")}>
                                <button
                                  onClick={() => handleConfirmAction(id, "R")}
                                >
                                  <GroupRemoveOutlined fontSize={"small"} />
                                </button>
                              </Tooltip>
                            </div>
                          )
                        },
                      },
                      {
                        key: "id",
                        dataIndex: "id",
                        align: "center",
                        render: (id: number) => {
                          return (
                            <div style={{ marginRight: "-10px" }}>
                              <Tooltip title={t("Accept")}>
                                <button
                                  onClick={() => handleConfirmAction(id, "A")}
                                >
                                  <PersonAddAltOutlinedIcon
                                    fontSize={"small"}
                                  />
                                </button>
                              </Tooltip>
                            </div>
                          )
                        },
                      },
                    ]}
                    dataSource={membersRequest}
                    size="small"
                    pagination={{
                      currentPage: page,
                      pageSize: 100,
                      onPageChange: handlePageChange,
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
      {optionListMembers === 3 && (
        <>
          <ListActionMember
            idAliance={idAliance}
            memberAction={memberAction!}
            handleConfirmAction={handleConfirmAction}
            isFounder={isFounder}
            setOptionListMembers={setOptionListMembers}
            idRankUserLogged={alianceMemberDetail?.idRank!}
            idCurrentPlayer={alianceMemberDetail?.player_id!}
            handleRefreshListMembers={setRefreshListMembers}
          />
        </>
      )}
      {optionListMembers === 4 && <h1>Visão do general</h1>}
      {optionListMembers === 5 && (
        <div>
          <div className={styles.titlePage}>{t("Invite Member")}</div>
          <InviteMemberAliance idAliance={idAliance} />
        </div>
      )}
      {modalError && (
        <Modal
          {...modalError}
          icon={<ErrorOutlineIcon fontSize="inherit" className="danger" />}
          onRequestClose={() => {
            setError(undefined)
          }}
          isOpen
        />
      )}
      {confirmAction && (
        <Modal
          {...modalConfirm}
          icon={<DoneAllIcon fontSize="inherit" className="success" />}
          footer={() => (
            <Button
              color="primary"
              onClick={() => {
                actionResponse(memberId, actionRequestMember)
                setConfirmAction(false)
              }}
              block
            >
              {t("Yes")}
            </Button>
          )}
          onRequestClose={() => {
            // setSuccess(false)
            setConfirmAction(false)
          }}
          isOpen
        />
      )}
    </>
  )
}

export default Members
