export const slotMap = [
  {
    position: 1,
    top: 1014,
    left: 612,
  },
  {
    position: 2,
    top: 937,
    left: 739,
  },
  {
    position: 3,
    top: 861,
    left: 866,
  },
  {
    position: 4,
    top: 675,
    left: 1120,
  },
  {
    position: 5,
    top: 1091,
    left: 739,
  },
  {
    position: 6,
    top: 1014,
    left: 866,
  },
  {
    position: 7,
    top: 937,
    left: 993,
  },
  {
    position: 8,
    top: 861,
    left: 1120,
  },
  {
    position: 9,
    top: 1167,
    left: 866,
  },
  {
    position: 10,
    top: 1091,
    left: 993,
  },
  {
    position: 11,
    top: 1014,
    left: 1120,
  },
  {
    position: 12,
    top: 937,
    left: 1247,
  },
  {
    position: 13,
    top: 861,
    left: 1374,
  },
  {
    position: 14,
    top: 1167,
    left: 1120,
  },
  {
    position: 15,
    top: 1091,
    left: 1247,
  },
  {
    position: 16,
    top: 1014,
    left: 1374,
  },
  {
    position: 17,
    top: 937,
    left: 1501,
  },
  {
    position: 18,
    top: 1167,
    left: 1374,
  },
  {
    position: 19,
    top: 1091,
    left: 1501,
  },
  {
    position: 20,
    top: 1014,
    left: 1628,
  },
]
