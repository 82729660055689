import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Tooltip } from "@mui/material"
import HeaderIconButton from "../components/HeaderIconButton"
import MessageIcon from "@mui/icons-material/Message"
import ConversationData from "components/conversation/ConversationData"
import { LoadingCircular, ScifiModal } from "components/feedback"
import ScifiDiv from "components/general/ScifiDiv"
import styles from "./Conversation.module.scss"
import SendIcon from "@mui/icons-material/Send"
import { TRITIUM_MAX_LENGTH_MSG } from "config/constants"
import { getNameUser } from "data/queries/player"
import { sendNewMessage, useConversationUnread } from "data/queries/message"
import { trataString } from "utils/validations"
import { ScifiButton } from "components/general"

interface Prop {
  openModal: boolean | null
}
const Conversation = (props: Prop) => {
  const { openModal } = props
  const [open, setOpen] = useState(openModal ? openModal : false)
  const unreadConversations = useConversationUnread()

  const hasUnread =
    Array.isArray(unreadConversations.data) &&
    unreadConversations.data.length > 0

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title="Messages">
        <HeaderIconButton
          onClick={() => {
            handleOpen()
          }}
        >
          <MessageIcon className={styles.message} style={{ fontSize: 18 }} />
          {hasUnread && (
            <div className={styles.notification}>
              {unreadConversations.data?.length < 100
                ? unreadConversations.data?.length
                : "+"}
            </div>
          )}
        </HeaderIconButton>
      </Tooltip>
      <ScifiModal isOpen={open} onRequestClose={handleClose}>
        <ConversationData />
      </ScifiModal>
    </>
  )
}

// @todo recuperar o id correto do dono do planeta
interface PropModal {
  closeModal: () => void
  recipientId: any
}
const SendNewMessageModal = (props: PropModal) => {
  const { t } = useTranslation()
  const recipientId = props.recipientId
  const close = props.closeModal
  const [open, setOpen] = useState(true)
  const [nameUser, setNameUser] = useState<string>("")
  const [messageText, setMessageText] = useState<string>("")

  console.log("destinatário na msg", recipientId)

  //getNameUser
  useEffect(() => {
    if (recipientId) {
      getNameUser(recipientId)
        .then((res) => setNameUser(res.data))
        .catch((e) => console.log(e))
    }
  }, [recipientId])

  const handleSubmit = () => {
    const newMessage: Message = {
      content: trataString(messageText),
      read: false,
      recipientId: recipientId,
    }
    sendNewMessage(newMessage).catch((err) => console.log("error", err))
    setMessageText("")
  }

  const handleClose = () => {
    setOpen(false)
    close()
  }

  return (
    <>
      <ScifiModal isOpen={open} onRequestClose={handleClose}>
        <div className={styles.titleFrom}>
          <h2>
            From:{" "}
            {nameUser.length > 0 ? nameUser : <LoadingCircular size={18} />}{" "}
          </h2>
        </div>
        <div className={styles.send}>
          <ScifiDiv>
            <div style={{ width: "600px" }} className="txtsend">
              <div className={styles.inputContainer}>
                <form onSubmit={handleSubmit}>
                  {/* <input type="hidden" value={destination} id="destination" /> */}
                  <input
                    type="text"
                    maxLength={TRITIUM_MAX_LENGTH_MSG}
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                    placeholder={t("Type your message") + " "}
                  />
                  <button>
                    <SendIcon className={styles.icon} />
                  </button>
                </form>
              </div>
            </div>
          </ScifiDiv>
        </div>
      </ScifiModal>
    </>
  )
}
interface NewMsg {
  destinarioId: string
  setAction?: (action: string) => void
}
const NewMessage = (props: NewMsg) => {
  const { destinarioId } = props
  const [messageText, setMessageText] = useState("")
  const { t } = useTranslation()
  function pad(num: number, length: number) {
    return num.toString().padStart(length, "0")
  }
  function dataAtual() {
    const dataHoraAtual = new Date()
    const ano = dataHoraAtual.getFullYear()
    const mes = pad(dataHoraAtual.getMonth() + 1, 2)
    const dia = pad(dataHoraAtual.getDate(), 2)
    const horas = pad(dataHoraAtual.getHours(), 2)
    const minutos = pad(dataHoraAtual.getMinutes(), 2)
    const segundos = pad(dataHoraAtual.getSeconds(), 2)
    return `${ano}-${mes}-${dia} ${horas}:${minutos}:${segundos}`
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setMessageText(trataString(messageText))
    const dataAgora = dataAtual()
    const newMessage: Message = {
      content: trataString(messageText),
      read: false,
      sender: false,
      recipientId: parseInt(destinarioId),
      createdAt: dataAgora,
    }
    sendNewMessage(newMessage).catch((err) => console.log(t("error"), err))
    setMessageText("")
  }
  return (
    <div className={styles.msgInPlanetDetail}>
      <input
        type="text"
        value={"apresentar aqui o histórico de msg com esse usuario"}
      />

      <ScifiDiv>
        <div style={{ width: "400px" }} className="txtsend">
          <div className={styles.inputContainer}>
            <form onSubmit={handleSubmit}>
              <input type="hidden" value={destinarioId} id="destination" />
              <input
                type="text"
                maxLength={TRITIUM_MAX_LENGTH_MSG}
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                placeholder={t("Type your message") + " "}
              />
              <button>
                <SendIcon className={styles.icon} />
              </button>
            </form>
          </div>
        </div>
      </ScifiDiv>
      <div style={{ marginTop: "3%" }}>
        <ScifiButton
          onClick={() => {
            props.setAction?.("default")
          }}
        >
          {t("Cancel")}
        </ScifiButton>
      </div>
    </div>
  )
}
export { Conversation, SendNewMessageModal, NewMessage }
