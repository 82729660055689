import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import { isAuthenticated } from "data/services/authService"
import api from "../services/api"

export const usePlanets = () => {
  return useQuery(
    [queryKey.tritium.planet.planets],
    async () => {
      const { data } = await api.get<Planet[]>(`api/planet/list`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const usePlanetsByQuadrant = (quadrant: string) => {
  return useQuery(
    [queryKey.tritium.quadrant.planets],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<Planet[]>(
        `api/quadrant/planets/${quadrant}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const usePlanet = (quadrant: string, position: number) => {
  return useQuery(
    [queryKey.tritium.planet.planets],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<Planet>(
        `api/planet/${quadrant}/${position}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const usePlanetById = (id: string) => {
  return useQuery(
    [queryKey.tritium.planet.planet],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<Planet>(`api/planet/show/${id}`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const renamePlanet = (planet: number, name: string) => {
  return api.put<Return>(TRITIUM + `/api/planet/edit/${planet}`, { name: name })
}
