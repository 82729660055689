export function isLocked(research: Research, researchedList: Researched[]) {
  if (research.dependence === 0) {
    return false
  }

  if (isCompletedDependences(research, researchedList)) {
    return false
  } else {
    return true
  }
}

function isCompletedDependences(
  researche: Research,
  researchedList: Researched[]
) {
  let result = false

  researchedList.length > 0 &&
    researchedList.forEach((element) => {
      if (researche.dependence === element.code) {
        result = true
      }
    })

  return result
}

export function isFinish(research: Research, researchedList: Researched[]) {
  let result = false

  researchedList.length > 0 &&
    researchedList.forEach((researched) => {
      if (research.code === researched.code) {
        result = true
      }
    })

  return result
}
