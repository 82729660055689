export const convertToClock = (time: number, dificulty: number = 1) => {
  const totalSegundos = time * dificulty

  const horas = Math.floor(totalSegundos / 3600)
  const minutos = Math.floor((totalSegundos % 3600) / 60)
  const segundos = Math.floor(totalSegundos % 60)

  const horaFormatada = horas.toString().padStart(2, "0")
  const minutoFormatado = minutos.toString().padStart(2, "0")
  const segundoFormatado = segundos.toString().padStart(2, "0")

  return `${horaFormatada}:${minutoFormatado}:${segundoFormatado}`
}
