import styles from "./WalletConnect.module.scss"
import {
  ConnectResponse,
  InfoResponse,
  useConnectedWallet,
  useWalletProvider,
} from "@hexxagon/wallet-kit"

interface Props {
  wallets: ConnectResponse[]
  network: InfoResponse
}

const GalaxyConnect = (props: Props) => {
  const { state } = useWalletProvider()
  const wallets = useConnectedWallet()
  const { network } = state

  const address = wallets?.addresses["columbus-5"]

  return (
    <>
      {address && (
        <div>
          <div className={styles.dataTop}>
            Address: <span className={styles.address}>{address}</span>
          </div>
          <div className={styles.dataMiddle}>Status: Connected</div>
          <div className={styles.dataMiddle}>
            {network["columbus-5"].isClassic
              ? "Terra Classic"
              : "Terra Luna 2.0"}
          </div>
          <div className={styles.dataFooter}>
            ChainID: {network["columbus-5"].chainID}
          </div>
        </div>
      )}
    </>
  )
}

export default GalaxyConnect
