import { Line } from "@react-three/drei"
import {
  linesA,
  linesB,
  linesC,
  linesD,
  linesE,
  linesF,
  linesG,
  linesH,
  linesI,
  linesJ,
  linesK,
  linesL,
  linesM,
  linesN,
  linesO,
  linesP,
} from "data/lines"
import * as THREE from "three"

interface Prop {
  region: string
}

const LinesConstelations = (props: Prop) => {
  const { region } = props

  let lines: any[] = []

  switch (region) {
    case "A":
      lines = linesA
      break
    case "B":
      lines = linesB
      break
    case "C":
      lines = linesC
      break
    case "D":
      lines = linesD
      break
    case "E":
      lines = linesE
      break
    case "F":
      lines = linesF
      break
    case "G":
      lines = linesG
      break
    case "H":
      lines = linesH
      break
    case "I":
      lines = linesI
      break
    case "J":
      lines = linesJ
      break
    case "K":
      lines = linesK
      break
    case "L":
      lines = linesL
      break
    case "M":
      lines = linesM
      break
    case "N":
      lines = linesN
      break
    case "O":
      lines = linesO
      break
    case "P":
      lines = linesP
      break
  }

  //console.log(linesA)

  return (
    <>
      {lines.map((content, i) => (
        <Line
          key={i}
          lineWidth={0.25}
          dashSize={0.5}
          dashed={true}
          color={new THREE.Color(0xffffff)}
          points={content}
        ></Line>
      ))}
    </>
  )
}

export default LinesConstelations
