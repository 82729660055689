import { useQuery } from "react-query"
import { queryKey } from "../query"
import api from "../services/api"
import { TRITIUM } from "config/constants"

export const useGameModeList = () => {
  return useQuery(
    [queryKey.tritium.gameMode.list],
    async () => {
      const { data } = await api.get("api/mode/list", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useGameModeSelected = (planet: string) => {
  return useQuery(
    [queryKey.tritium.gameMode.gameModeSelected],
    async () => {
      const { data } = await api.get(`api/mode/effect/${planet}`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useGameModePlayerSelected = (player: string) => {
  return useQuery(
    [queryKey.tritium.gameMode.gameModePlayerSelected],
    async () => {
      const { data } = await api.get(`api/mode/effect-player/${player}`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const changeGameMode = (code: string) => {
  return api.post(TRITIUM + `/api/mode/change/${code}`)
}
