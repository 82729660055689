import disableScroll from "disable-scroll"

import styles from "./DetailsUnitsMembers.module.scss"
import Warning from "./Warning"
import { useState } from "react"
import { t } from "i18next"

interface UnitDeatilsProps {
  unitsDetails: UnitDetails[] | null
}
const DetailsUnitsMembers = (UnitDeatils: UnitDeatilsProps) => {
  const { unitsDetails } = UnitDeatils
  const [openIndexes, setOpenIndexes] = useState<number[]>([])
  const toggleAccordion = (index: number) => {
    if (openIndexes.includes(index)) {
      // Se o índice já estiver aberto, feche
      setOpenIndexes(openIndexes.filter((i) => i !== index))
    } else {
      // Se o índice não estiver aberto, abra
      setOpenIndexes([...openIndexes, index])
    }
  }
  return (
    <div
      className={styles.frota}
      style={{ height: "500px", overflow: "scroll" }}
      onWheel={(e) => {
        disableScroll.off()
      }}
      onMouseOut={(e) => {
        disableScroll.on()
      }}
    >
      {!unitsDetails ||
        (unitsDetails.length === 0 && (
          <Warning msg={t("This member does not have this type of unit")} />
        ))}
      {unitsDetails &&
        unitsDetails.map((u, i) => {
          return (
            <div key={i} style={{ color: "white" }}>
              <button
                style={{
                  backgroundColor: "#5850B4",
                  width: "100%",
                  marginBottom: "1px",
                }}
                onClick={() => toggleAccordion(i)}
              >
                {u.name} - {u.nick}
              </button>

              {openIndexes.includes(i) && (
                <div style={{ width: "100%", display: "flex" }}>
                  <div style={{ width: "30%" }}>
                    <img
                      src={`/assets/units/${u.image}`}
                      width={70}
                      alt={u.nick}
                    />
                  </div>
                  <div style={{ width: "70%" }}>
                    <div>{u.quantity} Und</div>
                    <div>{u.nick}</div>
                    <div>{u.type}</div>
                    <div>{u.description}</div>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      <br />
    </div>
  )
}
export default DetailsUnitsMembers
