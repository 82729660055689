import { EventTypes, Wallet } from '@hexxagon/wallet-interface'
import { CreateTxOptions, Tx } from '@hexxagon/feather.js'
import Station from '@hexxagon/station-connector'

declare global {
  interface Window {
    galaxyStation?: Station
  }
}

export default class StationWallet implements Wallet {
  async info() {
    await this._waitWindowLoad()
    if (!window.galaxyStation) throw new Error('Station extension not installed')

    return await window.galaxyStation.info()
  }

  async connect() {
    await this._waitWindowLoad()
    if (!window.galaxyStation) throw new Error('Station extension not installed')

    return await window.galaxyStation.connect()
  }

  async getPubkey() {
    await this._waitWindowLoad()
    if (!window.galaxyStation) throw new Error('Station extension not installed')

    return await window.galaxyStation.getPublicKey()
  }

  async post(tx: CreateTxOptions) {
    await this._waitWindowLoad()
    if (!window.galaxyStation) throw new Error('Station extension not installed')

    // is the chain classic?
    const networks = await this.info()
    const isClassic = !!networks[tx.chainID]?.isClassic

    const data = JSON.parse(
      JSON.stringify({
        ...tx,
        ...(tx.fee ? { fee: JSON.stringify(tx.fee.toData()) } : {}),
        msgs: tx.msgs.map((msg) => JSON.stringify(msg.toData(isClassic))),
      }),
    )

    return await window.galaxyStation.post(data)
  }

  async sign(tx: CreateTxOptions) {
    await this._waitWindowLoad()
    if (!window.galaxyStation) throw new Error('Station extension not installed')

    // is the chain classic?
    const networks = await this.info()
    const isClassic = !!networks[tx.chainID]?.isClassic

    const data = JSON.parse(
      JSON.stringify({
        ...tx,
        ...(tx.fee ? { fee: JSON.stringify(tx.fee.toData()) } : {}),
        msgs: tx.msgs.map((msg) => JSON.stringify(msg.toData(isClassic))),
      }),
    )

    return await window.galaxyStation.sign(data)
  }

  private listeners: Record<string, ((e: any) => void)[]> = {}

  addListener(event: EventTypes, cb: (data: any) => void) {
    const listener = (e: any) => cb(e.detail)
    this.listeners[event] = [...(this.listeners[event] ?? []), listener]

    switch (event) {
      case EventTypes.NetworkChange:
        window.addEventListener('galaxy_station_network_change', listener)
        break
      case EventTypes.WalletChange:
        window.addEventListener('galaxy_station_wallet_change', listener)
        break
    }
  }

  removeListener(event: EventTypes, _?: (data: any) => void) {
    const listeners = this.listeners[event]
    if (!listeners) return

    switch (event) {
      case EventTypes.NetworkChange:
        listeners.map((l) =>
          window.removeEventListener('galaxy_station_network_change', l),
        )
        break
      case EventTypes.WalletChange:
        listeners.map((l) =>
          window.removeEventListener('galaxy_station_wallet_change', l),
        )
        break
    }

    delete this.listeners[event]
  }

  isInstalled = !!window?.galaxyStation

  id = 'galaxy-station-extension'

  details = {
    name: 'Galaxy Station (Extension)',
    icon: 'https://station-assets.hexxagon.io/img/station.png',
    website: 'https://chrome.google.com/webstore/detail/akckefnapafjbpphkefbpkpcamkoaoai',
  }

  // helpers
  private async _waitWindowLoad() {
    if (document.readyState === 'complete') return

    return new Promise((resolve) => {
      const documentStateChange = (event: Event) => {
        if (
          event.target &&
          (event.target as Document).readyState === 'complete'
        ) {
          resolve(undefined)
          document.removeEventListener('readystatechange', documentStateChange)
        }
      }

      document.addEventListener('readystatechange', documentStateChange)
    })
  }
}
