import disableScroll from "disable-scroll"
import { useTranslation } from "react-i18next"
import styles from "./AlianceScores.module.scss"
import { LoadingCircular } from "components/feedback"

interface DetailsScore {
  label: string | ""
  score: number | 0
}
interface ScoresAlianceProps {
  aliance: Aliances
}
const Scores = (Props: ScoresAlianceProps) => {
  const { aliance } = Props
  const { t } = useTranslation()

  const getScores = (aliance: Aliance | undefined): DetailsScore[] => {
    let scores: DetailsScore[] = []
    if (!aliance) {
      return scores
    }

    scores.push(
      {
        label: "Energy",
        score: aliance.energy,
      },
      {
        label: "Scores",
        score: aliance.score,
      },
      {
        label: "Build",
        score: aliance.buildScore,
      },
      {
        label: "Laboratory",
        score: -1,
      },
      {
        label: "Trade",
        score: -1,
      },
      {
        label: "Attack",
        score: aliance.attackScore,
      },
      {
        label: "Defense",
        score: aliance.defenseScore,
      },
      {
        label: "War",
        score: aliance.warScore,
      }
    )

    return scores
  }

  const arrDetailsScore = getScores(aliance)
  return (
    <>
      {!arrDetailsScore ? (
        <LoadingCircular />
      ) : (
        <table
          className={styles.tbScores}
          onMouseOut={(e) => {
            disableScroll.on()
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "50%" }}>{t("Type")}</th>
              <th style={{ width: "50%" }}>{t("Score")}</th>
            </tr>
          </thead>
          <tbody>
            {arrDetailsScore && arrDetailsScore?.length > 0 && (
              <>
                {arrDetailsScore.map((ds, i) => {
                  return (
                    <tr key={i}>
                      <td>{t(ds.label)}</td>
                      <td>{ds.score}</td>
                    </tr>
                  )
                })}
              </>
            )}
          </tbody>
        </table>
      )}
    </>
  )
}

export default Scores
