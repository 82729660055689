import { useState } from "react"
import { useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"
import { Tooltip } from "@mui/material"
import SettingsIcon from "@mui/icons-material/Settings"
import HeaderIconButton from "../components/HeaderIconButton"
import ScifiModal from "components/feedback/ScifiModal"
import SettingsModalContent from "./SettingsModalContent"
import styles from "./SettingsModal.module.scss"

const Preferences = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={t("Settings")}>
        <HeaderIconButton
          onClick={() => {
            queryClient.invalidateQueries()
            handleOpen()
          }}
        >
          <SettingsIcon className={styles.settings} style={{ fontSize: 18 }} />
        </HeaderIconButton>
      </Tooltip>
      <ScifiModal isOpen={open} onRequestClose={handleClose}>
        <SettingsModalContent />
      </ScifiModal>
    </>
  )
}

export default Preferences
