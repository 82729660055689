import { useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { Flex } from "components/layout"
import { Form, FormItem, Input, Submit } from "components/form"
import { ScifiModal } from "components/feedback"
import { Link } from "react-router-dom"
import { loginUserSrv } from "data/services/authService"
import { Button } from "components/general"
import DefaultLoginTheme from "./DefaultLoginTheme"
import styles from "./Login.module.scss"
import Loading from "./Loading"
import { sendLinkVerifyEmailRestister } from "data/queries/users"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3"
import { TRITIUM_KEY_RECAPTCHA } from "config/constants"

const FormLogin = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [error, setError] = useState<Error>()
  const [success, setMessage] = useState<boolean>(false)
  const [btnSubmit, setLoading] = useState<boolean>(false)

  const [emailFrom, setEmailForm] = useState<string>("")
  const [emailVerified, setEmailVerified] = useState<boolean>(true)

  const form = useForm<User>({ mode: "onSubmit" })

  const { handleSubmit, register } = form

  const [loadingData, setLoadingData] = useState(false)

  const sendLinkVerifyEmailRestisterForm = (email: string) => {
    sendLinkVerifyEmailRestister(email)
    setMessage(true)
  }

  const [token, setToken] = useState("")
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(true)

  const modalError = !error
    ? undefined
    : {
        title: t("Error"),
        children: (
          <section>
            <aside style={{ textAlign: "center" }}>
              <Flex> {t(error.message)} </Flex>
              {!emailVerified && (
                <Link
                  onClick={() => {
                    sendLinkVerifyEmailRestisterForm(emailFrom)
                  }}
                  to=""
                >
                  {t("Click here to resend the verification e-mail.")}
                </Link>
              )}
            </aside>
          </section>
        ),
      }
  const modalMessage = !success
    ? undefined
    : {
        children: (
          <section>
            <div className={styles.message}>
              {t("E-mail successfully sent. Check your e-mail inbox!")}
            </div>
          </section>
        ),
      }

  const openFullscreen = (elem: HTMLElement) => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if ((elem as any).mozRequestFullScreen) {
      ;(elem as any).mozRequestFullScreen()
    } else if ((elem as any).webkitRequestFullscreen) {
      ;(elem as any).webkitRequestFullscreen()
    } else if ((elem as any).msRequestFullscreen) {
      ;(elem as any).msRequestFullscreen()
    }
  }

  const submit = async (user: User) => {
    setEmailForm(user.email)
    setLoading(true)
    setLoadingData(true)
    user["g-recaptcha-response"] = token
    await loginUserSrv(user)
      .then((data) => {
        if (data.success) {
          openFullscreen(document.documentElement)
          setTimeout(() => {
            navigate("/game/planet/" + data.planet)
          }, 2000)
        } else {
          if (!data.verified_email) {
            setEmailVerified(false)
          }
          setError(data)
        }
      })
      .catch((error) => {
        setError(error as Error)
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
        setRefreshReCaptchaReload()
      })
  }

  function closeModalSuccess() {
    setMessage(false)
  }

  const setTokenFunc = useCallback((getToken: any) => {
    setToken(getToken)
  }, [])

  function setRefreshReCaptchaReload() {
    setRefreshReCaptcha((r) => !r)
  }

  return (
    <>
      <DefaultLoginTheme>
        <div>
          <Form onSubmit={handleSubmit(submit)}>
            <FormItem label={"Email"}>
              <Input {...register("email", { required: true })} type="email" />
            </FormItem>
            <FormItem label={t("Password")}>
              <Input
                {...register("password", { required: true })}
                type="password"
              />
            </FormItem>
            <Submit submitting={btnSubmit}>{"Login"}</Submit>
          </Form>
          {loadingData && <Loading onDismiss={() => setLoadingData(false)} />}
          <div className={styles.extraActions}>
            <Link to="/register" className={styles.register}>
              {t("Register")}
            </Link>
            {" :: "}
            <Link className={styles.forget} to="/forget-password">
              {t("Forget Password")}
            </Link>
          </div>
        </div>
        <div>
          {modalError && (
            <ScifiModal
              {...modalError}
              onRequestClose={() => setError(undefined)}
              isOpen
            />
          )}
          {modalMessage && (
            <ScifiModal
              {...modalMessage}
              footer={() => (
                <Button color="primary" onClick={closeModalSuccess} block>
                  {"Ok"}
                </Button>
              )}
              onRequestClose={() => {
                setMessage(false)
              }}
              isOpen
            />
          )}
        </div>
      </DefaultLoginTheme>
      {window.location.hostname !== "localhost" && (
        <GoogleReCaptchaProvider reCaptchaKey={TRITIUM_KEY_RECAPTCHA}>
          <GoogleReCaptcha
            onVerify={setTokenFunc}
            refreshReCaptcha={refreshReCaptcha}
          />
        </GoogleReCaptchaProvider>
      )}
    </>
  )
}

export default FormLogin
