import { useRef } from "react"
import { useThree, useFrame } from "@react-three/fiber"
import { OrbitControls, Stars, Html, Gltf } from "@react-three/drei"
import { usePlanets } from "data/queries/planet"
import * as THREE from "three"
import Ship from "./Ship"
import SpaceWar from "../SpaceWar"

interface Props {
  selectedShip: string
  openLeave: boolean
}

const Scene = (props: Props) => {
  const { camera } = useThree()
  const shipRef = useRef<THREE.Object3D>(null)
  const refStars = useRef<THREE.Group>(null)

  const planets = usePlanets()

  useFrame(() => {
    if (refStars.current) {
      if (refStars.current.position.z < -100) {
        refStars.current.position.z = 0
      }
      refStars.current.position.z -= 0.025
    }
    if (shipRef.current && camera) {
      const shipPosition = shipRef.current.position
      const distance = 5
      const targetPosition = new THREE.Vector3(
        shipPosition.x,
        shipPosition.y + 2,
        shipPosition.z - distance
      )
      camera.position.lerp(targetPosition, 0.15)
      camera.lookAt(shipPosition.x, shipPosition.y + 2, shipPosition.z)
    }
  })

  const atirar = () => {
    console.log("Atirou")
  }

  return (
    <>
      <ambientLight intensity={0.4} />
      <spotLight position={[200, 300, 0]} intensity={0.7} />
      <spotLight position={[-200, 300, 0]} intensity={0.7} />
      <spotLight position={[0, 0, -200]} intensity={0.4} />
      <spotLight position={[0, 0, 200]} intensity={0.4} />

      <group ref={refStars} position={[0, 0, 0]}>
        <Stars radius={300} factor={3} speed={0.7} count={6000} />
      </group>
      <OrbitControls
        enableRotate={false}
        enablePan={false}
        enableZoom={false}
      />
      <Ship shipRef={shipRef} />
      <SpaceWar planets={planets.data} />
      <Gltf
        src={"/assets/3d/ship-" + props.selectedShip + ".glb"}
        ref={shipRef}
        rotation={[
          props.selectedShip === "Craft"
            ? -0.2
            : props.selectedShip === "Bomber"
            ? -0.2
            : props.selectedShip === "Cruiser"
            ? -0.2
            : props.selectedShip === "Stealth"
            ? -0.1
            : props.selectedShip === "Flagship"
            ? -0.1
            : 0,
          0,
          0,
        ]}
      />
      <Html position={[-1, -2.5, 0]}>
        <div
          style={{
            position: "absolute",
            bottom: "-150px",
            left: "30px",
            transform: "translate(-50%, -50%)",
            color: "yellow",
            fontSize: "14px",
            userSelect: "none",
            zIndex: 100,
          }}
        >
          {"[" + props.selectedShip + "]"}
        </div>
      </Html>
      {!props.openLeave && (
        <Html>
          <img
            src="/assets/combat/gauge.png"
            alt="Mira"
            draggable={false}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              opacity: 0.5,
              userSelect: "none",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              height: "400px",
              transform: "translate(-50%, -50%)",
              cursor: "url('/assets/combat/target2.png') 5 5, auto",
            }}
            onClick={atirar}
          >
            <img
              src="/assets/combat/tracker.png"
              alt="Mira"
              draggable={false}
              style={{
                opacity: 0.15,
                position: "relative",
                top: "-10px",
                left: "-1px",
                userSelect: "none",
              }}
            />
          </div>
        </Html>
      )}
    </>
  )
}

export default Scene
