import { useTranslation } from "react-i18next"
import { Row, Col } from "components/layout"
import Upgrade from "components/builds/Upgrade"
import styles from "./Miner.module.scss"

interface Props {
  building: Building
  build: Build
  setIsOpen: any
  planet: string
}

const Miner = (props: Props) => {
  const { t } = useTranslation()
  const { building, build, setIsOpen } = props

  return (
    <div>
      <Row>
        <Col>
          <div>
            <span className={styles.level}>{Number(building.level)}</span>
            <span>{t("level")}</span>
          </div>
          <div style={{ display: "flex", padding: "0px", margin: "0px" }}>
            <div style={{ marginRight: "14px" }}>
              <img
                src={`/assets/builds/build-15.png`}
                alt={t("Shipyard")}
                className={styles.buildImage}
                draggable={false}
                style={{ width: "200px" }}
              />
              <Upgrade
                setIsOpen={setIsOpen}
                build={build}
                building={building}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className={styles.buildContent}>{"Under construction"}</div>
        </Col>
      </Row>
    </div>
  )
}

export default Miner
