import { PropsWithChildren, useEffect, useState } from "react"
import classNames from "classnames/bind"
import styles from "./CircularMenu.module.scss"

interface Props {
  active: boolean
  x?: number
  y?: number
  selectedText: string
  size?: "small" | "medium" | "large"
  actionButton?: boolean
  setOnCircular?: any
}

const CircularMenu = (props: PropsWithChildren<Props>) => {
  const { active, x, y, selectedText, children, actionButton, setOnCircular } =
    props

  const [menuOpen, setMenuOpen] = useState(active)
  const [classes, setClasses] = useState<any>(null)
  //const [item, setItem] = useState<string>("")

  useEffect(() => {
    openMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function openMenu() {
    setMenuOpen(true)
    switch (props.size) {
      case "small":
        setClasses(classNames(styles.cnWrapper, styles.openedNavSmall))
        break
      case "medium":
        setClasses(classNames(styles.cnWrapper, styles.openedNavMedium))
        break
      case "large":
        setClasses(classNames(styles.cnWrapper, styles.openedNavLarge))
        break
      default:
        setClasses(classNames(styles.cnWrapper, styles.openedNavMedium))
        break
    }
  }

  function closeMenu() {
    setMenuOpen(false)
    setClasses(classNames(styles.cnWrapper))
    setTimeout(() => {
      setOnCircular(false)
    }, 400)
  }

  return (
    <div
      className={classNames(styles.container, styles.csstransforms)}
      style={{ top: actionButton ? 0 : 30 }}
    >
      <div className={styles.component} style={{ top: y, left: x }}>
        <div className={selectedText.length > 0 ? styles.show : styles.hidden}>
          <span className={styles.legend}>{selectedText}</span>
        </div>
        {menuOpen && (
          <div
            className={styles.cnButton}
            onClick={closeMenu}
            id="cnButton"
            style={{ display: actionButton ? "block" : "none" }}
          />
        )}
        <div className={classes || styles.cnWrapper} id="cnWrapper">
          {children}
        </div>
      </div>
    </div>
  )
}

export default CircularMenu
