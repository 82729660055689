import { useTranslation } from "react-i18next"
import { useColonizeMissions } from "data/queries/travel"
import { Timer } from "components/display"
import styles from "./Missions.module.scss"

const ColonizeMissions = () => {
  const { t } = useTranslation()
  const missions = useColonizeMissions()

  return (
    <div>
      {missions && missions.data && missions.data.length <= 0 && (
        <div className={styles.noMissions}>{t("No missions")}</div>
      )}
      <div className={styles.messages}></div>
      <div className={styles.content}>
        <div className={styles.tableMissions}>
          <table>
            {missions && missions.data && missions.data.length > 0 && (
              <thead>
                <tr>
                  <th>{t("From")}</th>
                  <th>{t("To")}</th>
                  <th>{t("Arrival")}</th>
                  <th>{t("Type")}</th>
                </tr>
              </thead>
            )}
            <tbody>
              {missions &&
                missions.data &&
                missions.data.map((mission: Travel) => (
                  <tr key={mission.id}>
                    <td>
                      {mission.from.name}{" "}
                      <span className={styles.address}>
                        [{mission.from.quadrant}:{mission.from.position}]
                      </span>
                    </td>
                    <td>
                      {mission.to.name}{" "}
                      <span className={styles.address}>
                        [{mission.to.quadrant}:{mission.to.position}]
                      </span>
                    </td>
                    <td>
                      <Timer end={mission.arrival * 1000} />
                    </td>
                    <td>
                      {mission.action === 1
                        ? t("Space attack")
                        : mission.action === 2
                        ? t("Land attack")
                        : mission.action === 3
                        ? t("Space defense")
                        : mission.action === 4
                        ? t("Land defense")
                        : mission.action === 5
                        ? t("Transport")
                        : mission.action === 6
                        ? t("Buy mission")
                        : mission.action === 7
                        ? t("Sell mission")
                        : mission.action === 8
                        ? t("Explorer")
                        : mission.action === 9
                        ? t("Space return")
                        : mission.action === 10
                        ? t("Land return")
                        : mission.action === 11
                        ? t("Spy")
                        : mission.action === 12
                        ? t("Colonize")
                        : t("Unknown")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ColonizeMissions
