import styles from "./ComputeShip.module.scss"

interface Props {
  qtd: number
  image: string
  name: string
  selectedShip: any
}

const ComputeShip = (props: Props) => {
  return (
    <>
      <div className={styles.ships} style={{ float: "left" }}>
        <div
          onClick={() => {
            props.selectedShip(props.name)
          }}
          className={props.qtd > 0 ? styles.image : styles.disabled}
        >
          <img src={props.image} alt={props.name} />
        </div>
        <div style={{ width: "100%", textAlign: "right", margin: "0 auto;" }}>
          <div className={styles.qtd}>{props.qtd}</div>
        </div>
      </div>
    </>
  )
}

export default ComputeShip
