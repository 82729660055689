import { useRef } from "react"
import { useCombat } from "data/queries/combat"
import { useNavigate } from "react-router-dom"
import { Timer } from "components/display"
import { Row, Col } from "components/layout"

import WatchLaterIcon from "@mui/icons-material/WatchLater"
import styles from "./Combat.module.scss"

const Combat = () => {
  const combats = useCombat()
  const navigator = useNavigate()

  const scrollableListRef = useRef<HTMLDivElement>(null)

  const manualWhell = (e: any) => {
    const container = scrollableListRef.current

    if (container) {
      if (e.deltaY > 0) {
        container.scrollTop += 30
      } else {
        container.scrollTop -= 30
      }
    }
  }

  return (
    <div>
      {combats.data && combats.data.length <= 0 && (
        <div className={styles.noCombats}>No combats</div>
      )}
      <div
        className={styles.content}
        style={{ color: "white" }}
        onWheel={manualWhell}
        ref={scrollableListRef}
      >
        {Array.isArray(combats.data) && combats.data
          ? combats.data.map((combat: any) => (
              <div
                key={combat.id}
                className={
                  combat.status === 0 || combat.status === 1
                    ? styles.combat
                    : styles.combatNoRun
                }
                onClick={() => {
                  navigator("/game/spaceCombat/" + combat.id)
                }}
              >
                <Row>
                  <Col>
                    <div>
                      <span className={styles.id}>{combat.id}</span>
                      <br />
                      <span className={styles.desc}>
                        {combat.planetName}{" "}
                        {"[" + combat.quadrant + ":" + combat.position + "]"}
                      </span>
                      <br />
                      <span className={styles.address}>{combat.player}</span>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      Status
                      <br />
                      <span className={styles.status}>
                        {combat.status === 0
                          ? "Loading"
                          : combat.status === 1
                          ? "Running"
                          : combat.status === 2
                          ? "Finished"
                          : combat.status === 3
                          ? "Canceled"
                          : "Unknown"}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      Stage
                      <br />
                      <span className={styles.stage}>{combat.stage}</span>
                    </div>
                  </Col>
                  <Col>
                    <div className={styles.timer}>
                      <span className={styles.clock}>
                        <WatchLaterIcon scale={300} />
                      </span>
                      <Timer end={combat.nextStage * 1000} />
                    </div>
                  </Col>
                </Row>
              </div>
            ))
          : null}
      </div>
    </div>
  )
}

export default Combat
