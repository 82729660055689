import { useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { useNavigate, Link } from "react-router-dom"
import { registerPlayer } from "data/queries/player"
import { Form, FormItem, Input, Submit } from "components/form"
import { ScifiModal } from "components/feedback"
import { Button } from "components/general"
import DefaultLoginTheme from "./DefaultLoginTheme"
import styles from "./Login.module.scss"
import CustomSelect from "components/form/CustomSelect"
import { TRITIUM_KEY_RECAPTCHA } from "config/constants"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3"

interface Values {
  name: string
  country: string
  email: string
  password: string
}

const Register = () => {
  const { t } = useTranslation()
  const address = "nowallet"
  const navigate = useNavigate()

  const [error, setError] = useState<Error>()
  const [success, setSuccess] = useState<boolean>(false)
  const [messages, setMessages] = useState<string>("")

  const form = useForm<Values>({ mode: "onSubmit" })

  const { handleSubmit, register, setValue } = form
  const [btnSubmit, setLoading] = useState<boolean>(false)
  const [user] = useState<User>({
    email: "",
    password: "",
    id: 0,
    name: "",
  })

  const [token, setToken] = useState("")
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

  const modalError = !error
    ? undefined
    : {
        title: t("Error"),
        children: (
          <section>
            <div className={styles.message}>
              <div dangerouslySetInnerHTML={{ __html: messages }} />
            </div>
          </section>
        ),
      }

  const modalSuccess = !success
    ? undefined
    : {
        children: (
          <section>
            <div className={styles.message}>
              {t(
                "Please check your email to complete the registration process."
              )}
            </div>
          </section>
        ),
      }

  const submit = async ({ name, country, email, password }: Values) => {
    setLoading(true)
    user.email = email
    user.password = password

    await registerPlayer({
      name: name,
      address: address || "",
      country: country,
      email: email,
      password: password,
      "g-recaptcha-response": token,
    })
      .then((data) => {
        setSuccess(true)
        localStorage.setItem("hasCompletedTour", "false")
      })
      .catch((data) => {
        let messagens = JSON.parse(data.request.response).message
        let exitHtml = ""
        Object.keys(messagens).map((key) => {
          exitHtml += `<li>${messagens[key][0]} </li>`
          return 0
        })
        setError(data)
        setMessages(exitHtml)
      })
      .finally(() => {
        setLoading(false)
        setRefreshReCaptchaReload()
      })
  }

  function closeModalSuccess() {
    setSuccess(false)
    navigate("/login", { replace: true })
  }

  const setTokenFunc = useCallback((getToken: any) => {
    setToken(getToken)
  }, [])

  function setRefreshReCaptchaReload() {
    setRefreshReCaptcha((r) => !r)
  }

  return (
    <DefaultLoginTheme>
      <div>
        <Form onSubmit={handleSubmit(submit)}>
          <FormItem label={"Player Name"}>
            <Input {...register("name", { required: true })} />
          </FormItem>
          <FormItem label={"Email"}>
            <Input {...register("email", { required: true })} type="email" />
          </FormItem>
          <FormItem label={"Password"}>
            <Input
              {...register("password", { required: true })}
              type="password"
            />
          </FormItem>
          <FormItem label={"Country"}>
            <CustomSelect
              register={register}
              onSelectCountry={(country) => setValue("country", country.id)}
            />
          </FormItem>
          <Submit submitting={btnSubmit}>{"Register"}</Submit>
        </Form>
        <div className={styles.extraActions}>
          <Link to="/login">{t("Login")}</Link>
        </div>
      </div>
      <div>
        {modalError && (
          <ScifiModal
            {...modalError}
            onRequestClose={() => setError(undefined)}
            isOpen
          />
        )}
        {modalSuccess && (
          <ScifiModal
            {...modalSuccess}
            footer={() => (
              <Button color="primary" onClick={closeModalSuccess} block>
                {t("Ok")}
              </Button>
            )}
            onRequestClose={() => {
              setSuccess(false)
              navigate("/login")
            }}
            isOpen
          />
        )}
      </div>
      {window.location.hostname !== "localhost" && (
        <GoogleReCaptchaProvider reCaptchaKey={TRITIUM_KEY_RECAPTCHA}>
          <GoogleReCaptcha
            onVerify={setTokenFunc}
            refreshReCaptcha={refreshReCaptcha}
          />
        </GoogleReCaptchaProvider>
      )}
    </DefaultLoginTheme>
  )
}

export default Register
