import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const useResearchList = () => {
  return useQuery(
    [queryKey.tritium.research.list],
    async () => {
      const { data } = await api.get(`api/research/list`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useResearched = () => {
  return useQuery(
    [queryKey.tritium.researched.details],
    async () => {
      const { data } = await api.get("api/researched", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useStatus = (code: string) => {
  return api.get(TRITIUM + `/api/research/status/${code}`)
}

export const startResearch = (code: number) => {
  return api.post(TRITIUM + `/api/research/start/${code}`)
}

export const laboratoryConfig = (planet: number, power: number) => {
  return api.post(
    TRITIUM + `/api/research/laboratory/config/${planet}/${power}`
  )
}

export const buyResearch = (code: number) => {
  return api.post(TRITIUM + `/api/research/buy/${code}`)
}
