import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import { isAuthenticated } from "data/services/authService"
import api from "../services/api"

export const useTroops = (planet: string) => {
  return useQuery(
    [queryKey.tritium.troop.troop],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<TroopUnit[]>(`api/troop/${planet}`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useTroopsOnConstruct = (planet: string) => {
  return useQuery(
    [queryKey.tritium.troop.troopUnderconstruction],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<TroopUnitUnderConstruction[]>(
        `api/troop/production/${planet}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}
