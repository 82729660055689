import { PropsWithChildren, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Tabs } from "components/layout"
import styles from "./BuildDetail.module.scss"
import { useShips } from "data/queries/ship"
import Ships from "./shipyard/Ships"
import TransportShip from "./shipyard/TransportShip"
import TrainingShip from "./shipyard/TrainingShip"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
}

const Shipyard = (props: PropsWithChildren<Props>) => {
  const { build, building, setIsOpen } = props
  const primaryShips = useShips()

  const { t } = useTranslation()

  // const primaryShips = useShips()
  const [currentShip, setCurrentShip] = useState<Ship>({
    id: "1",
    name: "name",
    attack: 5,
    defense: 5,
    image: "droid-01.png",
    metal: BigInt(200),
    uranium: BigInt(0),
    crystal: BigInt(0),
    description: "texto de descricao default",
    life: 100,
    nick: "nickname",
    time: 5,
    type: "droid",
    quantity: 0,
  })
  const [ships, setShips] = useState<Ship[]>()

  useEffect(() => {
    if (primaryShips.data) {
      setShips(primaryShips.data)
      setCurrentShip(primaryShips.data[0])
    }
  }, [primaryShips.data])

  // useEffect(() => {
  //   if (primaryUnits.data) {
  //     setUnits(primaryUnits.data)
  //     setCurrentUnit(primaryUnits.data[0])
  //   }
  // }, [primaryUnits.data])
  const tabs = [
    {
      key: "trainingship",
      tab: t("Training"),
      children: (
        <div className={styles.training}>
          <TrainingShip
            building={building}
            build={build}
            setIsOpen={setIsOpen}
            planet={building.planet}
          />
        </div>
      ),
    },
    {
      key: "transpotShip",
      tab: t("Transport Ship"),
      children: (
        <div className={styles.training}>
          <TransportShip
            building={building}
            build={build}
            setIsOpen={setIsOpen}
            planet={building.planet}
          />
        </div>
      ),
    },
    {
      key: "ships",
      tab: t("Ships"),
      children: (
        <div className={styles.build}>
          {ships && (
            <Ships
              currentShip={currentShip}
              setcurrentShip={setCurrentShip}
              ships={ships}
              building={building}
            />
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <Tabs
        tabs={tabs}
        type="card"
        defaultActiveKey="transpotShip"
        state={true}
      />
    </div>
  )
}

export default Shipyard
