import React, { useEffect, useRef } from "react"
import spectroAudio from "sounds/dark-spectrometer-11713_2.mp3"

interface BackgroundAudioProps {
  isActive: boolean
}

const BackgroundAudio: React.FC<BackgroundAudioProps> = ({ isActive }) => {
  const audioRef = useRef(new Audio(spectroAudio))

  useEffect(() => {
    if (isActive) {
      const promisePlay = audioRef.current.play()
      promisePlay
        .then(() => {
          audioRef.current.volume = 0.3
          audioRef.current.loop = true
        })
        .catch((error) => {})
    } else {
      audioRef.current.loop = false
      audioRef.current.pause()
    }
  }, [isActive])

  return null
}

export default BackgroundAudio
