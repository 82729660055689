import { useState } from "react"
import { Row } from "components/layout"
import PlanetDescription from "./PlanetDescription"
import ActionMenu from "./ActionMenu"
import Badges from "./Badges"
import styles from "./PlanetDescription.module.scss"
// import Chat from "components/conversation/Chat"
import Colonizer from "components/combat/Colonizer"
import AttackMode from "components/combat/AttackMode"
import SendResource from "components/combat/SendResource"
import MissionExplorer from "components/combat/MissionExplorer"
import SpyOn from "components/commanders/mercenary/SpyOn"
import { usePlanetById } from "data/queries/planet"
import ConversationData from "components/conversation/ConversationData"

interface Props {
  planet: Planet | null
  quadrant: Quadrant
  position: string
  setOpen: any
}

const PlanetDetail = (props: Props) => {
  const myPlanetId = localStorage.getItem("planet") ?? "0"
  const planetOrigin = usePlanetById(myPlanetId || "0")
  const { planet, quadrant, position } = props
  const [action, setAction] = useState<string>("default")
  return (
    <>
      <div className={styles.content}>
        <Row>
          <div style={{ float: "inherit" }}>
            {action === "default" && (
              <PlanetDescription
                planet={planet}
                quadrant={quadrant}
                position={position}
                playerId={planet?.player}
              />
            )}
            <div>
              {planet && (
                <>
                  {action === "attack" && (
                    <AttackMode
                      setAction={setAction}
                      target={planet.id || "0"}
                      targetName={planet.name}
                      targetCoords={planet.quadrant}
                      setOpen={props.setOpen}
                      myPlanet={myPlanetId}
                      planetOrigin={planetOrigin}
                    />
                  )}
                </>
              )}
            </div>
            <div>
              {planet && !planet.player && (
                <>
                  {action === "colonize" && (
                    <>
                      <Colonizer
                        setAction={setAction}
                        target={planet.id || "0"}
                        planet={
                          planetOrigin instanceof Array
                            ? planetOrigin[0].data
                            : planetOrigin.data
                        }
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <div>
              {planet && planet.player && (
                <>
                  {action === "resource" && (
                    <SendResource
                      setAction={setAction}
                      target={planet.id || "0"}
                      setOpen={props.setOpen}
                    />
                  )}
                </>
              )}
            </div>
            {/* mission */}
            <div>
              {planet && (
                <>
                  {action === "mission" && (
                    <MissionExplorer
                      setAction={setAction}
                      target={planet.id || "0"}
                      setOpen={props.setOpen}
                    />
                  )}
                </>
              )}
            </div>

            <div>
              {planet && (
                <>
                  {action === "message" && (
                    <ConversationData destinationId={planet.player} />
                    // <div className={styles.newMsg}>
                    //   <Chat destinationId={planet.player} />
                    // </div>
                  )}
                </>
              )}
            </div>
            <div>
              {planet && (
                <>
                  {action === "spy" && (
                    <div className={styles.newMsg}>
                      <SpyOn
                        destinationId={planet.id || "0"}
                        setAction={setAction}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {action !== "message" && <Badges />}
        </Row>
      </div>
      {action === "default" && (
        <Row>
          <div>
            <ActionMenu
              action={action}
              setAction={setAction}
              player={planet?.player}
              planetId={myPlanetId}
            />
          </div>
        </Row>
      )}
    </>
  )
}

export default PlanetDetail
