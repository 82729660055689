import { Suspense } from "react"
import { useParams } from "react-router-dom"
import { Canvas, useLoader } from "@react-three/fiber"
import { OrbitControls, Plane } from "@react-three/drei"
import { EffectComposer, Bloom } from "@react-three/postprocessing"
import { MOUSE } from "three"
import { useMyMap } from "data/queries/quadrant"
import { Image } from "@react-three/drei"
import StarsCollect from "components/comp3d/StarsCollect"
import ControlPanel from "app/sections/ControlPanel"
import CommandersControl from "app/sections/CommandersControl"
import SpaceNavigator from "components/navigation/SpaceNavigator"
import QuadrantStelar from "components/comp3d/QuadrantStelar"
// import QuadrantPlayer from "components/comp3d/QuadrantPlayer"
import RegionPanel from "components/region/RegionPanel"
import Logger from "components/notifications/Logger"
import Footer from "app/sections/Footer"
import styles from "./Galaxy.module.scss"
import LinesConstelations from "components/comp3d/LinesConstellations"
import * as THREE from "three"

const Galaxy = () => {
  const { region } = useParams()
  const regionData = useMyMap(region || "A")

  const playerQuadrant = localStorage.getItem("quadrant")

  const imageTexture = useLoader(
    THREE.TextureLoader,
    "/assets/icons/regions/nebulosa2.png"
  )

  const numberOptions = []

  for (let i = 0; i < 100; i++) {
    numberOptions.push(
      <option key={i} value={String(i).padStart(3, "0")}>
        {String(i).padStart(3, "0")}
      </option>
    )
  }

  return (
    <>
      <ControlPanel />
      <CommandersControl />
      <SpaceNavigator />
      <RegionPanel />
      <Logger />
      <Footer />
      <div className={styles.scena}>
        <Canvas>
          <EffectComposer>
            <Bloom
              luminanceThreshold={-1}
              luminanceSmoothing={3.5}
              height={300}
            />
          </EffectComposer>
          <Suspense fallback={null}>
            <OrbitControls
              makeDefault
              panSpeed={2}
              maxDistance={280}
              minDistance={60}
              maxPolarAngle={2}
              minPolarAngle={1.2}
              maxAzimuthAngle={0.5}
              minAzimuthAngle={-0.5}
              zoomSpeed={2}
              mouseButtons={{
                LEFT: MOUSE.PAN,
                MIDDLE: MOUSE.DOLLY,
              }}
            />
            <group
              rotation={[-Math.PI / 9, 0, 0]}
              position={new THREE.Vector3(0, 40, -100)}
            >
              {/* <QuadrantPlayer position="K001" /> */}
              <Plane position={[0, 0, -8]} scale={500}>
                <meshBasicMaterial attach="material" transparent>
                  <primitive attach="map" object={imageTexture} />
                </meshBasicMaterial>
              </Plane>
              {regionData && regionData.data && Array.isArray(regionData.data)
                ? regionData.data.map((element, idx) => (
                    <QuadrantStelar
                      key={idx}
                      position={element.quadrant}
                      name={element.name}
                      posX={element.x}
                      posY={element.y}
                      isPlayerQuadrant={element.quadrant === playerQuadrant}
                    />
                  ))
                : null}
              <LinesConstelations region={region || "A"} />
            </group>
            <StarsCollect
              radiusStart={300}
              radiusCustom={900}
              count={6000}
              fator={5}
            />
            <Image
              scale={50}
              url={"/assets/icons/regions/" + region + ".png"}
              transparent
              opacity={0.5}
              position={[0, 0, -88]}
            />
            <ambientLight intensity={0.7} />
          </Suspense>
        </Canvas>
      </div>
    </>
  )
}

export default Galaxy
