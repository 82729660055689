import { usePlayer } from "data/queries/player"
import Logout from "app/sections/Logout"

const Perfil = () => {
  const player = usePlayer()

  return (
    <div style={{ color: "#5850B4", width: "200px", textAlign: "left" }}>
      {player.data && (
        <>
          {player.data.name} <Logout />
        </>
      )}
    </div>
  )
}

export default Perfil
