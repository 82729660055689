import NewSale from "./NewSale"
import NewPurchase from "./NewPurchase"
interface Props {
  type: string
  resource: string | undefined
  changeScreen: (newScreen: number) => void
}

const FormMarket = (FormMarket: Props) => {
  return (
    <>
      {FormMarket.type === "S" ? (
        <>
          <NewSale
            type={FormMarket.type}
            resource={FormMarket.resource}
            changeScreen={FormMarket.changeScreen}
          />
        </>
      ) : (
        <>
          <NewPurchase
            type={FormMarket.type}
            resource={FormMarket.resource}
            changeScreen={FormMarket.changeScreen}
          />
        </>
      )}
    </>
  )
}

export default FormMarket
