import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "components/general"
import Espionage from "./mercenary/Espionage"
import Challange from "./mercenary/Challange"
import styles from "./Commanders.module.scss"

interface Option {
  label: string
  value: string
}

const options: Option[] = [
  {
    label: "Espionage",
    value: "espionage",
  },
  {
    label: "Challenge",
    value: "challenge",
  },
]

const Mercenary = () => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState("challenge")

  const handleOptionClick = (option: string) => {
    setSelectedOption(option)
  }

  return (
    <div>
      <div className={styles.top}>
        {options.map((option) => (
          <Button
            key={option.value}
            className={
              selectedOption === option.value ? styles.active : styles.button
            }
            onClick={() => handleOptionClick(option.value)}
          >
            {t(`${option.label}`)}
          </Button>
        ))}
      </div>
      <div>
        {selectedOption === "espionage" && <Espionage />}
        {selectedOption === "challenge" && <Challange />}
      </div>
    </div>
  )
}

export default Mercenary
