import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurretMissions, cancelTravel } from "data/queries/travel"
import { Timer } from "components/display"
import styles from "./Resources.module.scss"
import { useJobSleep } from "data/queries/log"
import { ScifiButton } from "components/general"
import { Alert, AlertTitle, Stack } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import {
  TRITIUM_TRAVEL_STATUS_ON_GOING,
  TRITIUM_TRAVEL_TRANSPORT_BUY,
  TRITIUM_TRAVEL_TRANSPORT_RESOURCE,
  TRITIUM_TRAVEL_TRANSPORT_SELL,
} from "config/constants"

const TravelingResources = () => {
  const typeSleep = "1" //Travel mission Send resources
  const { t } = useTranslation()
  const missions = useCurretMissions()
  const jobsleep = useJobSleep(typeSleep)
  const [error, setError] = useState<"">()
  const [alerta, setAlerta] = useState("")

  const marketMissions = missions.data?.filter(
    (mission) =>
      mission.action === TRITIUM_TRAVEL_TRANSPORT_BUY ||
      mission.action === TRITIUM_TRAVEL_TRANSPORT_SELL ||
      mission.status === TRITIUM_TRAVEL_TRANSPORT_RESOURCE
  )

  const getStatusTravel = (status: number) => {
    let descStatus = ""
    switch (status) {
      case 1:
        descStatus = t("On Load")
        break
      case 2:
        descStatus = t("On Going")
        break
      case 3:
        descStatus = t("Return")
        break
      case 4:
        descStatus = t("Finished")
        break
    }
    return descStatus
  }

  const closeModal = () => {
    setAlerta("")
    setError("")
  }

  return (
    <div>
      <div>
        {alerta && (
          <>
            <Stack sx={{ width: "100%" }} spacing={1}>
              <Alert severity={alerta === "error" ? "error" : "success"}>
                <AlertTitle>
                  {alerta === "error" ? t("Error") : t("Success")}
                </AlertTitle>
                <div
                  style={{
                    marginLeft: "438px",
                    marginTop: "-32px",
                    cursor: "pointer",
                  }}
                  onClick={() => closeModal()}
                >
                  <CloseIcon fontSize="small" />
                </div>
                {/* {t(`You do not have a sufficient quantity of cargo ships`)} */}
                {alerta === "success"
                  ? t("Travel canceled successfully")
                  : t(`${error}`)}
              </Alert>
            </Stack>
            <br />
          </>
        )}
      </div>
      <div className={styles.messages}></div>
      <div className={styles.content}>
        <div>
          {Array.isArray(jobsleep.data) && jobsleep.data
            ? jobsleep.data.map((job: JobSleep) => (
                <span key={job.id} className={styles.load}>
                  Time Loading : <Timer end={job.finished * 1000} />
                </span>
              ))
            : null}
        </div>
        <div className={styles.tableMissions}>
          <table>
            <thead>
              <tr>
                <th>{t("From")}</th>
                <th>{t("To")}</th>
                <th>{t("Arrival")}</th>
                <th>{t("Status")}</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(marketMissions) && marketMissions.length > 0 ? (
                marketMissions.map((mission) => (
                  <tr key={mission.id}>
                    <td>
                      {mission.from.name}{" "}
                      <span className={styles.address}>
                        [{mission.from.quadrant}:{mission.from.position}]
                      </span>
                    </td>
                    <td>
                      {mission.to.name}{" "}
                      <span className={styles.address}>
                        [{mission.to.quadrant}:{mission.to.position}]
                      </span>
                    </td>
                    <td>
                      <Timer end={mission.arrival * 1000} />
                    </td>
                    <td>{getStatusTravel(mission.status)}</td>
                    <td>
                      {mission.status === TRITIUM_TRAVEL_STATUS_ON_GOING && (
                        <ScifiButton
                          onClick={() => {
                            cancelTravel(mission.id)
                              .then(() => {
                                setAlerta("success")
                              })
                              .catch((error) => {
                                console.error("Error canceling travel: ", error)
                              })
                          }}
                        >
                          {t("Cancel")}
                        </ScifiButton>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>{t("No market-related travels found.")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TravelingResources
