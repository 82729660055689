import { PropsWithChildren, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { configWorker } from "data/queries/build"
import { ScifiButton } from "components/general"
import { GameContext } from "pages/GameContext"
// import MarketContent from "components/market/Market"
import Upgrade from "../Upgrade"
import SimpleAlert from "../SimpleAlert"
import mp3Click from "sounds/toggle.mp3"
import styles from "./BuildDetail.module.scss"
import MarketData from "components/market/MarketData"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
}

const Market = (props: PropsWithChildren<Props>) => {
  const { build, building, setIsOpen } = props
  const { t } = useTranslation()
  const { planet } = useContext(GameContext)

  const soundClick = new Audio(mp3Click)

  const [workers, setWorkers] = useState<any>(building.workers)
  const [alert, setAlert] = useState<boolean>(false)

  return (
    <>
      <div>
        <span className={styles.level}>{Number(building.level)}</span>
        <span>{t("level")}</span>
      </div>
      <br />
      <div style={{ display: "flex" }}>
        <div>
          <img
            src={`/assets/builds/build-18.png`}
            alt="Metal Mining"
            className={styles.buildImage}
            draggable={false}
          />
          <Upgrade setIsOpen={setIsOpen} build={build} building={building} />
        </div>
        <div className={styles.buildContent}>
          {/* <MarketContent openModal={null} /> */}
          <MarketData />
        </div>
      </div>
      <div className={styles.footer}>
        <ScifiButton
          onClick={() => {
            configWorker({
              planetId: planet,
              buildId: String(build.id),
              buildingId: String(building.id),
              workers: workers,
            })
            setWorkers(workers)
            building.workers = Number(workers)
            setAlert(true)
            soundClick.play()
          }}
          size="small"
        >
          {t("Save")}
        </ScifiButton>
      </div>
      <SimpleAlert
        open={alert}
        onClose={() => {
          setAlert(false)
        }}
      />
    </>
  )
}

export default Market
