import { useState } from "react"
import { useNavigate } from "react-router-dom"
import QuadrantPoint from "components/comp3d/QuadrantPoint"
import { Html } from "@react-three/drei"
import styles from "./Quadrant.module.scss"
import * as THREE from "three"

interface Props {
  position: string
  name: string
  posX: number
  posY: number
  isPlayerQuadrant: boolean
}

const QuadrantStelar = (props: Props) => {
  const navigate = useNavigate()
  const { position, name, posX, posY, isPlayerQuadrant } = props
  const [hovered, setHovered] = useState(false)

  const handleClick = () => {
    navigate(`/game/quadrant/${position}`)
  }

  return (
    <>
      <QuadrantPoint
        color={new THREE.Color(0x00b88f)}
        position={new THREE.Vector3(posX, posY, 0)}
        setHovered={setHovered}
      >
        <sphereGeometry />
        <Html zIndexRange={[100, 0]}>
          <div onClick={handleClick}>
            <div
              className={`${isPlayerQuadrant ? styles.playerQuadrant : ""}`}
            ></div>
            <div
              className={hovered ? styles.quadrantEffect : styles.none}
            ></div>
            <div className={hovered ? styles.quadrantText : styles.none}>
              <div>
                {position} {name}
              </div>
              <div className={styles.info}>
                [{posX}/{posY}]
              </div>
            </div>
            {isPlayerQuadrant && (
              <div className={styles.quadrantText}>
                <div>
                  {position} {name}
                </div>
                <div className={styles.info}>
                  [{posX}/{posY}]
                </div>
              </div>
            )}
          </div>
        </Html>
      </QuadrantPoint>
    </>
  )
}

export default QuadrantStelar
