import { metalBalance } from "data/queries/balance"
import { uraniumBalance } from "data/queries/balance"
import { crystalBalance } from "data/queries/balance"
import { calcConstBuild, calcSpeedConstructionBuild } from "./effect"

export const calcResourceRequirements = (
  build: Build,
  level: number,
  discountBuild: number,
  speedConstructionBuild: number
) => {
  let metalReq = 0
  let uraniumReq = 0
  let crystalReq = 0

  // Metal
  if (level === build.metalLevel) {
    metalReq = build.metalStart
  }
  if (level > build.metalLevel) {
    metalReq = build.metalStart
    for (let i = 1; i <= level - build.metalLevel; i++) {
      metalReq += Math.floor(metalReq * (build.coefficient / 100))
    }
  }

  // Uranium
  if (level === build.uraniumLevel) {
    uraniumReq = build.uraniumStart
  }
  if (level > build.uraniumLevel) {
    uraniumReq = build.metalStart
    for (let i = 1; i <= level - build.uraniumLevel; i++) {
      uraniumReq += Math.floor(uraniumReq * (build.coefficient / 100))
    }
  }

  // Crystal
  if (level === build.crystalLevel) {
    crystalReq = build.crystalStart
  }
  if (level > build.crystalLevel) {
    crystalReq = build.metalStart
    for (let i = 1; i <= level - build.crystalLevel; i++) {
      crystalReq += Math.floor(crystalReq * (build.coefficient / 100))
    }
  }

  const require: Require = {
    metal: calcConstBuild(metalReq, discountBuild),
    build: build.code,
    level: level,
    // uranium: Math.floor(uraniumReq + (uraniumReq * discountBuild) / 100),
    uranium: calcConstBuild(uraniumReq, discountBuild),
    crystal: calcConstBuild(crystalReq, discountBuild),
    time:
      calcSpeedConstructionBuild(
        metalReq + uraniumReq + crystalReq,
        speedConstructionBuild
      ) / 100,
  }

  return require
}

export const checkFunds = (
  build: Build,
  level: number,
  planet: Planet | undefined | never[],
  discountBuild: number,
  percentSpeedMining: number,
  speedConstructionBuild: number
) => {
  const require = calcResourceRequirements(
    build,
    level,
    discountBuild,
    speedConstructionBuild
  )

  if (planet instanceof Array) {
    return false
  }
  if (planet === undefined) {
    return false
  }

  const mBalance: any = metalBalance(planet, percentSpeedMining)
  const uBalance: any = uraniumBalance(planet, percentSpeedMining)
  const cBalance: any = crystalBalance(planet, percentSpeedMining)

  if (mBalance < require.metal) {
    return false
  }
  if (uBalance < require.uranium) {
    return false
  }
  if (cBalance < require.crystal) {
    return false
  }

  return true
}

export const missingFunds = (
  build: Build,
  level: number,
  planet: Planet | undefined | never[],
  discountBuild: number,
  percentSpeedMining: number,
  speedConstructionBuild: number
): string[] => {
  const required = calcResourceRequirements(
    build,
    level,
    discountBuild,
    speedConstructionBuild
  )

  if (planet instanceof Array || planet === undefined) {
    return []
  }

  const mBalance: any = metalBalance(planet, percentSpeedMining)
  const uBalance: any = uraniumBalance(planet, percentSpeedMining)
  const cBalance: any = crystalBalance(planet, percentSpeedMining)

  const missingResources: string[] = []

  if (mBalance < required.metal) {
    missingResources.push("Metal")
  }
  if (uBalance < required.uranium) {
    missingResources.push("Uranium")
  }
  if (cBalance < required.crystal) {
    missingResources.push("Crystal")
  }

  return missingResources
}

export const checkResearch = (
  researcheds: Researched[] | undefined,
  code: number
) => {
  if (researcheds === undefined) {
    return false
  }

  const found = researcheds.find((research) => research.code === code)
  if (found) {
    return true
  }
  return false
}
