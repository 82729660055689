// import { useState } from "react"
// import { useNavigate, useParams } from "react-router-dom"
// import { usePlanets } from "data/queries/planet"
// import { NoConnected } from "components/feedback"
// import ControlPanel from "app/sections/ControlPanel"
// import GameProvider from "pages/GameContext"
// import Logger from "components/notifications/Logger"
// import PanelLandCombat from "components/combat/PanelLandCombat"
// import CommandersControl from "app/sections/CommandersControl"
// import SpaceNavigator from "components/navigation/SpaceNavigator"
// import Footer from "app/sections/Footer"
import { Suspense } from "react"
import { Canvas } from "@react-three/fiber"
import { OrbitControls, Gltf } from "@react-three/drei"
import { EffectComposer, Bloom } from "@react-three/postprocessing"
import { MOUSE } from "three"
import StarsCollect from "components/comp3d/StarsCollect"
import ControlPanel from "app/sections/ControlPanel"
import CommandersControl from "app/sections/CommandersControl"
import SpaceNavigator from "components/navigation/SpaceNavigator"
// import QuadrantPlayer from "components/comp3d/QuadrantPlayer"
import RegionPanel from "components/region/RegionPanel"
import Logger from "components/notifications/Logger"
import Footer from "app/sections/Footer"
import styles from "./Galaxy.module.scss"

const LandCombat = () => {
  // const { planet } = useParams()

  // const address = "nowallet"
  // const planets = usePlanets()
  // const navigate = useNavigate()
  // const [onBuilding, setOnBuilding] = useState<boolean>(false)

  // if (planet === "0" && planets.data) {
  //   navigate(`/game/planet/${planets.data[0].id}`)
  // }

  return (
    // <GameProvider>
    //   {address && (
    //     <>
    //       {planets.data &&
    //         planets.data.map(({ id, builds }) => (
    //           <div key={id}>
    //             {planet === id &&
    //               builds &&
    //               builds.map(({ code, ready }) => (
    //                 <div>
    //                   <>
    //                     {ready &&
    //                     Number(ready) * 1000 > new Date().getTime() &&
    //                     !onBuilding
    //                       ? setOnBuilding(true)
    //                       : ""}
    //                   </>
    //                 </div>
    //               ))}
    //           </div>
    //         ))}
    //       <ControlPanel />
    //       <SpaceNavigator />
    //       <CommandersControl />
    //       <PanelLandCombat />
    //       <Logger />
    //       <Footer />
    //       {/* {planets.data && <ListPlanets planets={planets.data} />} */}
    //     </>
    //   )}
    //   {!address && <NoConnected />}
    // </GameProvider>
    <>
      <ControlPanel />
      <CommandersControl />
      <SpaceNavigator />
      <RegionPanel />
      <Logger />
      <Footer />
      <div className={styles.scena}>
        <Canvas>
          <EffectComposer>
            <Bloom
              luminanceThreshold={-1}
              luminanceSmoothing={3.5}
              height={300}
            />
          </EffectComposer>

          <spotLight position={[200, 300, 0]} intensity={0.7} />
          <spotLight position={[-200, 300, 0]} intensity={0.7} />
          <spotLight position={[0, 0, -200]} intensity={0.4} />
          <spotLight position={[0, 0, 200]} intensity={0.4} />

          <Gltf
            src={"/assets/3d/ship-Scout.glb"}
            position={[0, -26, 0]}
            scale={10}
          />

          <Suspense fallback={null}>
            <OrbitControls makeDefault />
            <StarsCollect
              radiusStart={300}
              radiusCustom={900}
              count={6000}
              fator={5}
            />
            <ambientLight intensity={0.7} />
          </Suspense>
        </Canvas>
      </div>
    </>
  )
}

export default LandCombat
