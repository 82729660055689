import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "./ConversationData.module.scss"
import {
  getMessagesInterations,
  getOwnerPlanet,
  listSearchUser,
  readMessage,
} from "data/queries/message"
import { LoadingCircular } from "components/feedback"
import SearchIcon from "@mui/icons-material/Search"
import disableScroll from "disable-scroll"
import Chat from "./Chat"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"

interface ConversatioData {
  destinationId?: number
}
const ConversationData = (props: ConversatioData) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [loadUser, setLoadUser] = useState(true)
  const [destination, setDestination] = useState<string>("")
  const [msgPlayerInterations, setMsgPlayerInterations] = useState<
    MessagePlayerInterations[]
  >([])
  const [destinationId, setDestinationId] = useState<number>(
    props.destinationId ?? 0
  )
  const [searchUser, setSearchUser] = useState("")

  const [semRegistro, setSemRegistro] = useState(false)

  useEffect(() => {
    if (destinationId !== 0 && destination.length === 0) {
      getOwnerPlanet(destinationId)
        .then((res) => {
          setDestination(res.data.name)
        })
        .catch((er) => console.log("er", er))
    }
  }, [destinationId, destination])

  useEffect(() => {
    if (msgPlayerInterations.length === 0 && searchUser.trim().length === 0) {
      setLoadUser(true)
      getInterations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msgPlayerInterations.length])

  async function getInterations() {
    getMessagesInterations()
      .then((res) => {
        setMsgPlayerInterations(res.data)
        if (res.data.length > 0) {
          setSemRegistro(false)
        }
      })
      .catch((err) => {
        console.log("erro", err)
      })
      .finally(() => setLoadUser(false))
  }

  const chooseUser = async (nome: string, destinoId: number) => {
    setDestination(nome)
    setDestinationId(destinoId)
    readMessage(destinationId)
      .catch((e) => console.log(e))
      .finally(() => {
        queryClient.invalidateQueries(queryKey.tritium.message.unread)
      })
    setMsgPlayerInterations([])
  }

  const handleSearchUser = (search: string) => {
    setSearchUser(search)
    if (search.trim().length > 3) {
      setLoadUser(true)
      listSearchUser(search)
        .then((res) => {
          setSemRegistro(res.data.length === 0)
          setMsgPlayerInterations(res.data)
        })
        .catch((err) => console.log("erro ao pesquisar", err))
        .finally(() => setLoadUser(false))
    }
    if (search.trim().length === 0) {
      setLoadUser(true)
      getInterations()
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.leftDiv}>
        <div className={styles.title} style={{ color: "#FFF" }}>
          {t("Users")}
        </div>
        <div className={styles.searchContainer}>
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Pesquisar"
            value={searchUser}
            // onClick={(e)=>handleSearchUser(e.target.value)}
            onChange={(e) => handleSearchUser(e.target.value)}
          />
          <span className={styles.searchIcon}>
            <SearchIcon />
          </span>
        </div>
        <div
          className={styles.sendersBox}
          onWheel={(e) => {
            disableScroll.off()
          }}
          onMouseOut={(e) => {
            disableScroll.on()
          }}
        >
          {loadUser && <LoadingCircular size={18} />}
          <table className={styles.senders} style={{ color: "#FFF" }}>
            <tbody>
              {!semRegistro &&
              msgPlayerInterations &&
              msgPlayerInterations.length > 0 ? (
                msgPlayerInterations.map((m, i) => {
                  return (
                    <tr onClick={() => chooseUser(m.name!, m.userId!)} key={i}>
                      <td>{m.name}</td>
                      <td>
                        {m.countNotRead && m.countNotRead > 0 && (
                          <div className={styles.notification}>
                            {m.countNotRead < 100 ? m.countNotRead : "+"}
                          </div>
                        )}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr key={0}>
                  <td>
                    <h1>{t("No conversations found!")}</h1>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.rightDiv}>
        <h2 style={{ color: "#FFF" }}>
          To: {destination ? destination : t("choose a recipient")}
        </h2>
        <div className={styles.divider} />
        {destinationId !== 0 ? <Chat destinationId={destinationId} /> : <></>}
      </div>
    </div>
  )
}
export default ConversationData
