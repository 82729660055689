import { Tooltip } from "@mui/material"
//import Badge1 from "styles/images/badges/tritiumChallange.png"
import Badge2 from "styles/images/badges/colonizer.png"
import Badge3 from "styles/images/badges/founder.png"
import FounderImage from "styles/nft/founder2.png"
import styles from "./Badges.module.scss"
import { useNFTConfig } from "data/queries/nft"
import { useWallet, WalletStatus } from "@terra-money/wallet-kit"
import { useTranslation } from "react-i18next"

const Badges = () => {
  const { t } = useTranslation()
  const { data: nftConfig } = useNFTConfig()

  const { status } = useWallet()

  const hasNFT =
    status === WalletStatus.CONNECTED &&
    nftConfig &&
    ((nftConfig.slot1 >= 1 && nftConfig.slot1 <= 128) ||
      (nftConfig.slot2 >= 1 && nftConfig.slot2 <= 128) ||
      (nftConfig.slot3 >= 1 && nftConfig.slot3 <= 128) ||
      (nftConfig.slot4 >= 1 && nftConfig.slot4 <= 128))

  const founder = status === WalletStatus.CONNECTED && (
    <>
      <img
        src={FounderImage}
        width={250}
        alt={t("Founder")}
        draggable={false}
      />
    </>
  )

  return (
    <div className={styles.badges}>
      {/*<Tooltip title="Tritium Challange">
          <img src={Badge1} alt="badge" />
        </Tooltip>*/}
      {hasNFT && (
        <Tooltip title="Colonizer">
          <img src={Badge2} alt="badge" />
        </Tooltip>
      )}
      {founder && (
        <Tooltip title="Founder">
          <img src={Badge3} alt="badge" />
        </Tooltip>
      )}
    </div>
  )
}

export default Badges
