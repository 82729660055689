import { TRITIUM } from "config/constants"
import api from "../services/api"

export const produceUnits = (
  planet: string,
  unit: string,
  quantity: number
) => {
  return api.post(TRITIUM + `/api/troop/production/${planet}`, {
    id: unit,
    quantity: quantity,
  })
}

export const produceShips = (
  planet: string,
  ship: string,
  quantity: number
) => {
  return api.post(TRITIUM + `/api/fleet/production/${planet}`, {
    id: ship,
    quantity: quantity,
  })
}
