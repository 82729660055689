import { useQuery } from "react-query"
import { queryKey, RefetchOptions } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const useShips = () => {
  return useQuery(
    [queryKey.tritium.ship.ship],
    async () => {
      const { data } = await api.get<Ship[]>("api/ship/list", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}
