import { useEffect, useRef, useState } from "react"
import { MOUSE, Group } from "three"
import { useFrame } from "@react-three/fiber"
import { OrbitControls } from "@react-three/drei"
import Planets from "components/comp3d/Planets"
import EllipseGroup from "components/comp3d/ElipseGroup"
import StarWarp from "components/comp3d/StarWarp"
import StarsCollect from "./StarsCollect"
import * as THREE from "three"

interface SystemProps {
  openNewMsg: (id: any) => void
  quadrant: Quadrant
  setSeleted: any
  setOpenDesc: any
  setSPlanet: any
}
const System: React.FC<SystemProps> = ({
  openNewMsg,
  quadrant,
  setSeleted,
  setOpenDesc,
  setSPlanet,
}) => {
  const groupRef = useRef<Group>(null)
  const speed = 0.035
  const startPosition = new THREE.Vector3(0, 0, 0)
  const finalPosition = new THREE.Vector3(0, 0, 0)
  const [start, setStart] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setStart(true)
    }, 2000)
  }, [start])

  useFrame(() => {
    if (start !== true) {
      if (groupRef.current) {
        startPosition.copy(groupRef.current.position)
      }
    }
    const newPosition = startPosition.lerp(finalPosition, speed)
    if (groupRef.current) {
      groupRef.current.position.copy(newPosition)
    }
  })

  return (
    <>
      <OrbitControls
        maxDistance={180}
        minDistance={50}
        maxPolarAngle={2.5}
        minPolarAngle={1.5}
        maxAzimuthAngle={0.5}
        minAzimuthAngle={-0.5}
        enableRotate={true}
        enablePan={true}
        zoomSpeed={2}
        mouseButtons={{
          LEFT: MOUSE.ROTATE,
          MIDDLE: MOUSE.DOLLY,
        }}
      />
      <group ref={groupRef} position={new THREE.Vector3(0, 550, -550)}>
        <StarWarp />
        <Planets
          openNewMsg={openNewMsg}
          quadrant={quadrant}
          setSeleted={setSeleted}
          setOpenDesc={setOpenDesc}
          setSPlanet={setSPlanet}
        />
        <EllipseGroup />
        <StarsCollect />
      </group>
    </>
  )
}

export default System
