import { ButtonHTMLAttributes, ForwardedRef, ReactNode } from "react"
import { forwardRef } from "react"
import styles from "./ScifiButton.module.scss"

export interface ButtonConfig {
  icon?: ReactNode
  size?: "small" | "medium" | "large"
  color?: "default" | "primary" | "danger" | "configurable"
  default?: "default" | "configurable"
  outline?: boolean
  block?: boolean
  loading?: boolean
  disabled?: boolean
}

type Props = ButtonConfig & ButtonHTMLAttributes<HTMLButtonElement>

const ScifiButton = forwardRef(
  ({ children, ...props }: Props, ref?: ForwardedRef<HTMLButtonElement>) => {
    const { ...attrs } = props

    return (
      <button
        className={styles.siButtonsScifi}
        type="button"
        {...attrs}
        ref={ref}
      >
        <span>{children}</span>
        <span className={styles.helper1}></span>
        <span className={styles.ripple}></span>
      </button>
    )
  }
)

export default ScifiButton
