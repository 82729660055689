import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { ScifiModal } from "components/feedback"
import Colonization from "./Colonization"
import EnergyCollector from "./EnergyCollector"
import MetalMining from "./MetalMining"
import UraniumMining from "./UraniumMining"
import CrystalMining from "./CrystalMining"
import HumanoidFactory from "./HumanoidFactory"
import BateryHouse from "./BateryHouse"
//import MilitaryCamp from "./MilitaryCamp"
import Laboratory from "./Laboratory"
import Wharehouse from "./Wharehouse"
import Shield from "./Shield"
import Market from "./Market"
import GalaticConcil from "./GalaticConcil"
import Shipyard from "./Shipyard"
import Tritium from "./Tritium"

interface Props {
  isOpen: boolean
  setIsOpen: any
  onClose: () => void
  building: Building
  build: Build
}

const BuildDetail = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation()
  const { isOpen, setIsOpen, onClose, building, build } = props

  return (
    <ScifiModal
      title={t(`${build.name}`)}
      maxHeight={700}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      {build.code === 1 && (
        <Colonization setIsOpen={setIsOpen} build={build} building={building} />
      )}
      {build.code === 2 && (
        <EnergyCollector
          setIsOpen={setIsOpen}
          build={build}
          building={building}
        />
      )}
      {build.code === 3 && (
        <HumanoidFactory
          setIsOpen={setIsOpen}
          build={build}
          building={building}
        />
      )}
      {build.code === 4 && (
        <MetalMining setIsOpen={setIsOpen} build={build} building={building} />
      )}
      {build.code === 5 && (
        <UraniumMining
          setIsOpen={setIsOpen}
          build={build}
          building={building}
        />
      )}
      {build.code === 6 && (
        <CrystalMining
          setIsOpen={setIsOpen}
          build={build}
          building={building}
        />
      )}
      {build.code === 7 && (
        <Laboratory setIsOpen={setIsOpen} build={build} building={building} />
      )}
      {build.code === 8 && (
        <Wharehouse setIsOpen={setIsOpen} build={build} building={building} />
      )}
      {build.code === 9 && (
        <Shipyard setIsOpen={setIsOpen} build={build} building={building} />
      )}
      {build.code === 10 && (
        <BateryHouse setIsOpen={setIsOpen} build={build} building={building} />
      )}
      {/*{build.code === 11 && (
        <MilitaryCamp setIsOpen={setIsOpen} build={build} building={building} />
      )}*/}
      {build.code === 12 && (
        <Shield setIsOpen={setIsOpen} build={build} building={building} />
      )}
      {build.code === 13 && (
        <Market setIsOpen={setIsOpen} build={build} building={building} />
      )}
      {build.code === 14 && (
        <GalaticConcil
          setIsOpen={setIsOpen}
          build={build}
          building={building}
        />
      )}
      {build.code === 15 && (
        <Tritium setIsOpen={setIsOpen} build={build} building={building} />
      )}
    </ScifiModal>
  )
}

export default BuildDetail
