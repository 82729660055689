import { useState } from "react"
import QuadrantPoint from "components/comp3d/QuadrantPoint"
import { Html } from "@react-three/drei"
import styles from "./Quadrant.module.scss"
// import stylesSpace from "./Space.module.scss"
import * as THREE from "three"

interface Props {
  quantity: number
  number: number
  name: string
  posX: number
  posY: number
}

const ShipEnimie = (props: Props) => {
  const { quantity, name, posX, posY } = props

  const [openDesc, setOpenDesc] = useState<boolean>(false)

  return (
    <>
      <QuadrantPoint
        color={new THREE.Color(0x00b88f)}
        position={new THREE.Vector3(posX, posY - 90, 150)}
        setHovered={true}
      >
        <circleGeometry args={[0.7, 3, 1]} />

        <Html zIndexRange={[100, 0]}>
          <div
            onClick={() => {
              setOpenDesc(!openDesc)
              setTimeout(() => {
                setOpenDesc(false)
              }, 3000)
            }}
          >
            <div className={styles.quadrantEffect}></div>
            <div className={styles.quadrantText}>
              <div style={{ color: "#5850B4" }}>{name}</div>
              <div className={styles.info}>[{quantity}]</div>
            </div>
          </div>
          {/* <div className={openDesc ? stylesSpace.shipEnimieDescription : stylesSpace.none}>
            <div>
              <b>{props.name}</b>
            </div>
            <div>
              <img
                src={"/assets/ships/ship-0" + props.number + ".png"}
                width={120}
                alt={props.name}
              />
            </div>
          </div> */}
        </Html>
      </QuadrantPoint>
    </>
  )
}

export default ShipEnimie
