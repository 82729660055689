import { useTranslation } from "react-i18next"
import TravelingResources from "./merchant/TravelingResources"
import { Button } from "components/general"
import styles from "./Commanders.module.scss"

const Merchant = () => {
  const { t } = useTranslation()

  return (
    <div>
      <div className={styles.top}>
        <Button className={styles.active}>Travel Resources</Button>
      </div>
      <div>
        <TravelingResources />
      </div>
    </div>
  )
}

export default Merchant
