import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const usePlayer = () => {
  return useQuery(
    [queryKey.tritium.player.player],
    async () => {
      const { data } = await api.get<Player>(`api/player/show`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const getPlayerDetail = (id: number = 0) => {
  return api.get<PlayerPublic>(`api/player/details/${id}`)
}

export const registerPlayer = (player: RegisterPlayer) => {
  return api.post<Return>(TRITIUM + `/api/player/register`, player)
}

export const getNameUser = (id: number) => {
  return api.get<string>(TRITIUM + `/api/player/name/${id}`)
}

export const changeNameAccount = (newName: string) => {
  return api.post<string>(TRITIUM + `/api/player/change-name`, {
    name: newName,
  })
}
