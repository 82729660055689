import axios from "axios"
import { getToken } from "./authService"
import { TRITIUM } from "config/constants"
import { useNavigate } from "react-router-dom"

const api = axios.create({
  baseURL: TRITIUM,
})

api.interceptors.request.use(async (config) => {
  const token = getToken()
  config.headers = config.headers ?? {}
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("tritium-token")
      const navigate = useNavigate()
      navigate("/login")
    }
    return Promise.reject(error)
  }
)

export default api
