import { useEffect, useState } from "react"
import styles from "../../app/sections/SettingsModal.module.scss"
import { Tabs } from "../layout"
import PlayerRanking from "./PlayerRanking"
import AlianceRanking from "./AlianceRanking"
import { RadioButton } from "components/form"
import { useTranslation } from "react-i18next"

interface TabsPro {
  key: string
  tab: string
  children: JSX.Element
}
const RankingModal = () => {
  const [orderSelected, setOrderSelected] = useState("score")
  const { t } = useTranslation()
  const [tabsF, setTabFs] = useState<TabsPro[]>([])

  const resetTable = (order: string) => {
    setOrderSelected(order)
    setTabFs([])
  }

  useEffect(() => {
    setTabFs(tabs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSelected])

  const tabs = [
    {
      key: "playersScore",
      tab: "Players Score",
      children: (
        <div className={styles.playersScore}>
          <PlayerRanking order={orderSelected} />
        </div>
      ),
    },
    {
      key: "alliancesScore",
      tab: "Alliances Score",
      children: (
        <div className={styles.aliancesScore}>
          <AlianceRanking order={orderSelected} />
        </div>
      ),
    },
  ]

  return (
    <>
      <hr></hr>
      <div className={styles.orderby}>
        <span>{t("Order by")} : </span>
        <RadioButton
          value="score"
          alt="Score"
          onClick={(e) => {
            resetTable(e.currentTarget.value)
          }}
          checked={orderSelected === "score" || null ? true : false}
        >
          Score
        </RadioButton>

        <RadioButton
          value="energy"
          alt="Energy"
          onClick={(e) => {
            resetTable(e.currentTarget.value)
          }}
          checked={orderSelected === "energy" ? true : false}
        >
          Energy
        </RadioButton>

        {/*<RadioButton
          value="tritium"
          alt="Tritium"
          onClick={(e) => {
            resetTable(e.currentTarget.value)
          }}
          checked={orderSelected === "tritium" ? true : false}
        >
          Tritium
        </RadioButton>*/}

        <RadioButton
          value="attackScore"
          alt="attackScore"
          onClick={(e) => {
            resetTable(e.currentTarget.value)
          }}
          checked={orderSelected === "attackScore" ? true : false}
        >
          Attack
        </RadioButton>

        <RadioButton
          value="defenseScore"
          alt="defenseScore"
          onClick={(e) => {
            resetTable(e.currentTarget.value)
          }}
          checked={orderSelected === "defenseScore" ? true : false}
        >
          Defense
        </RadioButton>
      </div>
      <Tabs
        tabs={tabsF}
        type="card"
        defaultActiveKey="playersScore"
        state={true}
      />
    </>
  )
}
export default RankingModal
