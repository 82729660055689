import { useTranslation } from "react-i18next"
import { useFleets } from "data/queries/fleet"
import { useFleetsOnConstruct } from "data/queries/fleet"
import { Tooltip } from "@mui/material"
import { Row } from "components/layout"
import FleetOnConstruct from "./FleetOnConstruct"
import styles from "./Military.module.scss"

interface Props {
  planet: string
}

const Fleet = (props: Props) => {
  const { t } = useTranslation()
  const fleetData = useFleets(props.planet)
  const fleetOnConsturct = useFleetsOnConstruct(props.planet)

  return (
    <div className={styles.container}>
      <Row>
        <div style={{ width: "640px" }}>
          <div className={styles.description}>
            {t("Fleet stationed on this planet")}
          </div>
          <div>
            {fleetData &&
              fleetData.data &&
              fleetData.data.length > 0 &&
              fleetData.data.map((element, idx) => (
                <span
                  className={styles.unitBlock}
                  style={{ float: "left", margin: "4px" }}
                  key={idx}
                >
                  <Tooltip key={idx} title={element.ship.name}>
                    <span className={styles.ship}>
                      <img
                        className={styles.unitImg}
                        src={"/assets/ships/" + element.ship.image}
                        alt={element.ship.name}
                        draggable="false"
                      />
                      <div className={styles.quantity}>{element.quantity}</div>
                    </span>
                  </Tooltip>
                </span>
              ))}
            {fleetData.data && fleetData.data.length <= 0 && (
              <div className={styles.msg}>{t("No Fleets on planet")}</div>
            )}
          </div>
        </div>
      </Row>
      <Row>
        <div style={{ width: "640px" }}>
          <div className={styles.description}>
            {t("Fleet underconstruction on this planet")}
          </div>
          <div>
            <FleetOnConstruct ships={fleetOnConsturct.data} />
          </div>
        </div>
      </Row>
    </div>
  )
}

export default Fleet
