import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "components/general"
import styles from "./Market.module.scss"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

import Trading from "./Trading"
import {
  TRITIUM_SCREEN_FINISH_ORDER,
  TRITIUM_SCREEN_HISTORY,
  TRITIUM_SCREEN_NEW_ORDER,
  TRITIUM_SCREEN_NEW_PURCH,
  TRITIUM_SCREEN_NEW_SALE,
  TRITIUM_SCREEN_TRADING,
  TRITIUM_TYPE_CRYSTAL,
  TRITIUM_TYPE_ENERGY,
  TRITIUM_TYPE_METAL,
  TRITIUM_TYPE_URANIUM,
} from "config/constants"
import FormMarket from "./form/FormMarket"

import HistoryTrading from "./list/HistoryTrading"
import FinishTrading from "./form/FinishTrading"
import { getLastTrading } from "data/queries/market"
import { capitalizeFirstLetter } from "utils/data"
interface Option {
  label: string
  value: number
}

const options: Option[] = [
  {
    label: "Metal",
    value: TRITIUM_TYPE_METAL,
  },
  // {
  //   label: "Energy",
  //   value: TRITIUM_TYPE_ENERGY,
  // },
  {
    label: "Crystal",
    value: TRITIUM_TYPE_CRYSTAL,
  },
  {
    label: "Uranium",
    value: TRITIUM_TYPE_URANIUM,
  },
]

interface OptionMenu {
  label: string
  value: number
}
const optionMenu: OptionMenu[] = [
  { label: "History", value: TRITIUM_SCREEN_HISTORY },
  { label: "New Order", value: TRITIUM_SCREEN_NEW_ORDER },
  // { label: "New Purchase", value: TRITIUM_SCREEN_NEW_PURCH },
]

interface PropsScreen {
  chanceScreen: (newScreen: number) => void
}

interface BackBarProp {
  title: string | undefined
  changeScreen: (newScreen: number) => void
  screen: number
}
const BackBar = (BackBar: BackBarProp) => {
  let { screen } = BackBar
  const [isChecked, setIsChecked] = useState<boolean>(true)

  const handleChange = () => {
    BackBar.changeScreen(
      isChecked ? TRITIUM_SCREEN_NEW_PURCH : TRITIUM_SCREEN_NEW_SALE
    )
    setIsChecked(!isChecked)
  }
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.backbar}>
        <div className={styles.itensBackBar}>
          <span
            className={styles.backicon}
            onClick={() => BackBar.changeScreen(TRITIUM_SCREEN_TRADING)}
          >
            <ArrowBackIcon />
          </span>
          <span className={styles.backtitle}>{BackBar.title} </span>
          {screen === TRITIUM_SCREEN_NEW_ORDER && (
            <div className={styles.areaSwitch}>
              <span className={styles.switchLabel}> {t("Buy")} </span>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleChange}
                />
                <span className={styles.slider}></span>
              </label>
              <span className={styles.switchLabel}> {t("Sell")}</span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const DropdownMenu = (PropsScreen: PropsScreen) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const moreAction = () => {
    setIsOpen(!isOpen)
  }
  const handleOptionSelect = (option: OptionMenu) => {
    // setSelectedOptionMenu(option)
    PropsScreen.chanceScreen(option.value)
    setIsOpen(false)
  }
  return (
    <div className={styles.dropdown}>
      <div className={styles.moreIcon} onClick={() => moreAction()}>
        <MoreVertIcon></MoreVertIcon>
        {isOpen && (
          <ul className={styles.dropdownMenu}>
            {optionMenu.map((option) => (
              <li key={option.value} onClick={() => handleOptionSelect(option)}>
                {t(`${option.label}`)}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

const MarketData = () => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] =
    useState<number>(TRITIUM_TYPE_METAL)
  const [selectedLabel, setSelectedLabel] = useState<string | undefined>(
    "Metal"
  ) //valor defaut
  const [selectedTitleScreen, setSelectedTitleScreen] = useState<
    string | undefined
  >("")
  const [lastSale, setLastSale] = useState<LastTrading>()
  const [screen, setScreen] = useState<number>(TRITIUM_SCREEN_TRADING)
  const [tradingProcess, setTradingProcess] = useState(0)
  const [typeOrder, setTypeOrder] = useState("S")

  const changeScreen = (screen: number, idTrading?: number) => {
    /**
     * controle de fluxo nova ordem, após mudanças de regra ficou definido que seria um único menu
     *  com uma única pagina sendo possível do usuario trocar pelo toogle se gostaria de vender ou comprar
     * logo, alterará o tipo de ordem P ou S e redireciona para o componente new ordem, nesse irá fazer
     * o controle de fluxo de venda ou compra
     */
    if (screen === TRITIUM_SCREEN_NEW_PURCH) {
      setTypeOrder("P")
      screen = TRITIUM_SCREEN_NEW_ORDER
    }
    if (screen === TRITIUM_SCREEN_NEW_SALE) {
      setTypeOrder("S")
      screen = TRITIUM_SCREEN_NEW_ORDER
    }
    if (!screen || !optionMenu.find((op) => op.value === screen)) {
      setScreen(TRITIUM_SCREEN_TRADING)
    }
    setTradingProcess(idTrading ? idTrading : 0)
    setSelectedTitleScreen(optionMenu.find((op) => op.value === screen)?.label)
    setScreen(screen)
  }

  const handleOptionClick = (option: number) => {
    setSelectedOption(option)
    const labelSelecionada = options.find((op) => op.value === option)
    setSelectedLabel(labelSelecionada?.label)
    filterLastSale(option)
    // setIsOpen(false)
  }

  const filterLastSale = async (type: number) => {
    var resource = "crystal"
    switch (type) {
      case TRITIUM_TYPE_ENERGY:
        resource = "energy"
        break
      case TRITIUM_TYPE_CRYSTAL:
        resource = "crystal"
        break
      case TRITIUM_TYPE_URANIUM:
        resource = "uranium"
        break
      case TRITIUM_TYPE_METAL:
        resource = "metal"
        break
    }
    await getLastTrading()
      .then((res) => {
        // console.log("resultado", res)
        var l = null
        l = res.data.find(
          (ls) => ls.resource.toLowerCase() === resource.toLowerCase()
        )
        setLastSale(l)
      })
      .catch((er) => console.error("erro"))
    // const last = LastSaleInfo.find((ls) => ls.type === type)
    // setIsOpen(false)
  }
  useEffect(() => {
    if (!lastSale) {
      /**inicia com o valor de pesquisa default */
      filterLastSale(TRITIUM_TYPE_CRYSTAL)
    }
  }, [lastSale])

  return (
    <>
      {lastSale && (
        <div className={styles.lastSale}>
          <span>
            {t("Last Transaction")}{" "}
            <span className={styles.lastSalePrice}>
              {capitalizeFirstLetter(lastSale.resource)} {t("Price")}{" "}
              {lastSale.price} ({t("Unit")})
            </span>
          </span>
        </div>
      )}
      <div
        className={`${styles.bar}  ${
          tradingProcess !== 0 ? styles.blockItens : ""
        }`}
      >
        {options.map((option) => (
          <Button
            key={option.value}
            className={
              selectedOption === option.value ? styles.active : styles.button
            }
            onClick={() => handleOptionClick(option.value)}
          >
            {t(`${option.label}`)}
          </Button>
        ))}
        <DropdownMenu chanceScreen={changeScreen} />
      </div>
      <div className={styles.container}>
        <div className={styles.title}></div>
        <div className={styles.marketBox}>
          {screen === TRITIUM_SCREEN_TRADING && (
            <Trading typeItem={selectedOption} changeScreen={changeScreen} />
          )}
          {screen === TRITIUM_SCREEN_NEW_SALE && (
            <>
              <BackBar
                title={selectedTitleScreen}
                changeScreen={changeScreen}
                screen={TRITIUM_SCREEN_NEW_SALE}
              />
              <div>
                {/* {t("Sell")} {selectedLabel} */}
                <FormMarket
                  type="S"
                  resource={selectedLabel}
                  changeScreen={changeScreen}
                />
              </div>
            </>
          )}
          {screen === TRITIUM_SCREEN_NEW_PURCH && (
            <>
              <BackBar
                title={selectedTitleScreen}
                changeScreen={changeScreen}
                screen={TRITIUM_SCREEN_NEW_PURCH}
              />
              <div>
                <FormMarket
                  type="P"
                  resource={selectedLabel}
                  changeScreen={changeScreen}
                />
              </div>
            </>
          )}
          {screen === TRITIUM_SCREEN_HISTORY && (
            <>
              <BackBar
                title={selectedTitleScreen}
                changeScreen={changeScreen}
                screen={TRITIUM_SCREEN_HISTORY}
              />
              <div>
                <HistoryTrading resource={selectedLabel?.toLocaleLowerCase()} />
              </div>
            </>
          )}

          {screen === TRITIUM_SCREEN_NEW_ORDER && (
            <>
              <BackBar
                title={`${t("New Trade")}`}
                changeScreen={changeScreen}
                screen={TRITIUM_SCREEN_NEW_ORDER}
              />

              <FormMarket
                type={typeOrder}
                // resource={selectedLabel}
                resource={selectedLabel}
                changeScreen={changeScreen}
              />
            </>
          )}

          {screen === TRITIUM_SCREEN_FINISH_ORDER && (
            <>
              <BackBar
                title={t("Finalize Trade")}
                changeScreen={changeScreen}
                screen={TRITIUM_SCREEN_FINISH_ORDER}
              />
              <FinishTrading
                idTrading={tradingProcess}
                changeScreen={changeScreen}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default MarketData
