import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useBuilds, useBuildings, useAvailableBuilds } from "data/queries/build"
import { usePlanets } from "data/queries/planet"
import { NoConnected } from "components/feedback"
import { ScifiButton } from "components/general"
import { ScifiModal } from "components/feedback"
import ControlPanel from "app/sections/ControlPanel"
import GameProvider from "pages/GameContext"
import Logger from "components/notifications/Logger"
import PlanetMap from "components/maps/PlanetMap"
import Slot from "components/builds/Slot"
import Structures from "components/builds/Structures"
import CommandersControl from "app/sections/CommandersControl"
import SpaceNavigator from "components/navigation/SpaceNavigator"
import Footer from "app/sections/Footer"
import disableScroll from "disable-scroll"
import mp3Assemble from "sounds/assemble.mp3"
import Founder from "components/assets/Founder"
import SmallCar from "components/animations/SmallCar"

const Planet = () => {
  const { planet } = useParams()

  const soundAssemble = new Audio(mp3Assemble)

  const address = "nowallet"
  const builds = useBuilds()
  const planets = usePlanets()
  const buildings = useBuildings(planet || "")
  const navigate = useNavigate()
  const availableBuilds = useAvailableBuilds(planet || "")
  const slots: any = []

  const [onBuilding, setOnBuilding] = useState<boolean>(false)
  const [isDragable, setIsDragable] = useState<boolean>(true)
  const [selectedSlot, setSelectedSlot] = useState<Number>(0)
  const [modalBuild, setModalBuild] = useState<boolean>(false)
  const [onClickX, setOnClickX] = useState<number>(0)
  const [onClickY, setOnClickY] = useState<number>(0)
  const [isWelcomeOpen, setIsWelcomeOpen] = useState<boolean>(false)

  if (planet === "0" && planets.data) {
    if (Array.isArray(planets.data)) {
      navigate(`/game/planet/${planets.data[0].id}`)
    } else {
      alert(
        "Robot detection, if you are not a robot, try again in a few seconds"
      )
    }
  }

  const startBuildModal = (i: number) => {
    disableScroll.off()
    setSelectedSlot(i)
    setModalBuild(true)
  }

  for (let i = 1; i <= 20; i++) {
    slots.push(
      <div
        key={i}
        onMouseDown={(e: any) => {
          setOnClickX(e.clientX)
          setOnClickY(e.clientY)
        }}
      >
        <Slot
          id={i}
          position={i}
          planet={planet || ""}
          constructs={builds.data}
          buildings={buildings.data}
          onClickX={onClickX}
          onClickY={onClickY}
          onClick={(e: any) => {
            if (onClickX === e.clientX && onClickY === e.clientY) {
              if (availableBuilds.data) {
                startBuildModal(i)
                soundAssemble.play()
              }
            }
          }}
          key={i}
          setIsDragable={setIsDragable}
        />
      </div>
    )
  }

  return (
    <GameProvider>
      {address && (
        <>
          {planets.data &&
            Array.isArray(planets.data) &&
            planets.data.map(({ id, builds }) => (
              <div key={id}>
                {planet === id &&
                  builds &&
                  builds.map(({ code, ready }) => (
                    <div>
                      <>
                        {ready &&
                        Number(ready) * 1000 > new Date().getTime() &&
                        !onBuilding
                          ? setOnBuilding(true)
                          : ""}
                      </>
                    </div>
                  ))}
              </div>
            ))}
          <ControlPanel />
          <SpaceNavigator />
          <CommandersControl />
          <Footer />
          {/* notification */}
          <Logger />
          <PlanetMap isDragable={isDragable}>
            {slots}
            <Founder />
            <SmallCar />
          </PlanetMap>
          <Structures
            planet={planet || ""}
            selectedSlot={selectedSlot}
            modalBuild={modalBuild}
            setModalBuild={setModalBuild}
            availableBuilds={availableBuilds}
          />
        </>
      )}
      <ScifiModal
        isOpen={isWelcomeOpen}
        onRequestClose={() => {
          setIsWelcomeOpen(false)
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div style={{ margin: "40px" }}>Welcome!</div>
          <ScifiButton
            onClick={() => {
              setIsWelcomeOpen(false)
            }}
          >
            Ok
          </ScifiButton>
        </div>
      </ScifiModal>

      {!address && <NoConnected />}
    </GameProvider>
  )
}

export default Planet
