import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const useBuilds = () => {
  return useQuery(
    [queryKey.tritium.build.builds],
    async () => {
      const { data } = await api.get<Build[]>("api/build/list", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useBuildings = (planet: string) => {
  return useQuery(
    [queryKey.tritium.building.buildings],
    async () => {
      const { data } = await api.get<Building[]>(
        `api/building/list/${planet}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useRequires = (build: Number) => {
  return useQuery(
    [queryKey.tritium.build.requires],
    async () => {
      const { data } = await api.get<Require[]>(`api/build/requires/${build}`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useRequire = (build: number, level: number) => {
  return useQuery(
    [queryKey.tritium.build.requires],
    async () => {
      const { data } = await api.get<Require>(
        `api/build/require/${build}/${level}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useAvailableBuilds = (planet: string) => {
  return useQuery(
    [queryKey.tritium.build.availableBuilds],
    async () => {
      const { data } = await api.get<Build[]>(
        `api/build/availables/${planet}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const pushBuilding = (building: Building) => {
  return api.post(TRITIUM + "/api/build/plant", building)
}

export const upBuilding = (building: Building) => {
  return api.post(TRITIUM + `/api/build/up`, building)
}

export const demolishBuild = (building: Building) => {
  return api.post(TRITIUM + `/api/build/demolish`, building)
}

interface WorkerParams {
  planetId: String
  workers: number
  buildId: String
  buildingId: String
}

export const configWorker = (params: WorkerParams) => {
  return api.post(TRITIUM + `/api/build/workers`, params)
}
