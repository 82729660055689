import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material"
import styles from "./GameModeBox.module.scss"
import { useTranslation } from "react-i18next"
import { WalletStatus, useWallet } from "@terra-money/wallet-kit"
import { useNFTConfig } from "data/queries/nft"
import {
  useWallet as useWalletGalaxy,
  WalletStatus as WalletStatusGalaxy,
} from "@hexxagon/wallet-kit"

interface Props {
  name: string
  image: string
  code: number
  onClick: () => void
  isSelected?: boolean
  requirementMet: boolean
}

const GameModeBox = (props: Props) => {
  const { name, image, onClick, isSelected, code, requirementMet } = props
  const { t } = useTranslation()

  const { status } = useWallet()

  const { status: statusGalaxy } = useWalletGalaxy()

  const nftConfig = useNFTConfig()

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className, tooltip: "tooltip-class" }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      position: "relative",
      backgroundColor: "#0a0a0a",
      color: "#f1f1f1",
      borderRadius: "5px",
      border: "1px solid #5850B46c",
      padding: "5px",
      fontSize: "15px",
      fontWeight: "bold",
      width: "auto",
      maxWidth: "600px",
    },
  }))

  const IsWalletConnect =
    status === WalletStatus.CONNECTED ||
    statusGalaxy === WalletStatusGalaxy.CONNECTED

  const hasNFT =
    IsWalletConnect &&
    nftConfig.data &&
    ((nftConfig.data?.slot1 >= 0 && nftConfig.data?.slot1 <= 128) ||
      (nftConfig.data?.slot2 >= 0 && nftConfig.data?.slot2 <= 128) ||
      (nftConfig.data?.slot3 >= 0 && nftConfig.data?.slot3 <= 128) ||
      (nftConfig.data?.slot4 >= 0 && nftConfig.data?.slot4 <= 128))

  return (
    <div>
      <StyledTooltip
        title={
          requirementMet || hasNFT ? (
            ""
          ) : (
            <>
              <div style={{ padding: "20px", width: "200px" }}>
                {code === 2 && !requirementMet && (
                  <>
                    <div style={{ fontSize: "12px", color: "#5850B4" }}>
                      {t("Requirement: ")}
                    </div>
                    <div style={{ position: "relative", top: "5px" }}>
                      <img
                        src="/assets/nft/NFT0.jpg"
                        style={{
                          borderRadius: "50%",
                          position: "relative",
                          left: "-15px",
                        }}
                        width={20}
                        alt={t("NFT")}
                      />
                      <span style={{ fontSize: "10px" }}>
                        {t("NFT Colonizer")}
                      </span>
                    </div>
                  </>
                )}
                {code === 3 && !requirementMet && (
                  <div>
                    <div style={{ fontSize: "12px", color: "#5850B4" }}>
                      {t("Requirement: ")}
                    </div>
                    <div style={{ position: "relative", top: "5px" }}>
                      <img
                        src="/assets/icons/research9.svg"
                        width={20}
                        alt={t("War Competence")}
                        style={{ position: "relative", left: "-15px" }}
                      />
                      <span style={{ fontSize: "10px" }}>
                        {t("War Competence")}
                      </span>
                    </div>
                  </div>
                )}
                {code === 4 && !requirementMet && (
                  <div>
                    <div style={{ fontSize: "12px", color: "#5850B4" }}>
                      {t("Requirement: ")}
                    </div>
                    <div style={{ position: "relative", top: "5px" }}>
                      <img
                        src="/assets/icons/research32.svg"
                        width={20}
                        alt={t("Wisdow")}
                        style={{ position: "relative", left: "-15px" }}
                      />
                      <span style={{ fontSize: "10px" }}>{t("Wisdow")}</span>
                    </div>
                  </div>
                )}
                {code === 5 && !requirementMet && (
                  <div>
                    <div style={{ fontSize: "12px", color: "#5850B4" }}>
                      {t("Requirement: ")}
                    </div>
                    <div style={{ position: "relative", top: "5px" }}>
                      <img
                        src="/assets/icons/research28.svg"
                        width={20}
                        alt={t("Space Engineering")}
                        style={{ position: "relative", left: "-15px" }}
                      />
                      <span style={{ fontSize: "10px" }}>
                        {t("Space Engineering")}
                      </span>
                    </div>
                  </div>
                )}
                {code === 6 && !requirementMet && (
                  <div>
                    <div style={{ fontSize: "12px", color: "#5850B4" }}>
                      {t("Requirement: ")}
                    </div>
                    <div style={{ position: "relative", top: "5px" }}>
                      <img
                        src="/assets/icons/research8.svg"
                        width={20}
                        alt={t("Defense")}
                        style={{ position: "relative", left: "-15px" }}
                      />
                      <span style={{ fontSize: "10px" }}>{t("Defense")}</span>
                    </div>
                  </div>
                )}
                {code === 7 && !requirementMet && (
                  <div>
                    <div style={{ fontSize: "12px", color: "#5850B4" }}>
                      {t("Requirement: ")}
                    </div>
                    <div style={{ position: "relative", top: "5px" }}>
                      <img
                        src="/assets/icons/research34.svg"
                        width={20}
                        alt={t("Expansion")}
                        style={{ position: "relative", left: "-15px" }}
                      />
                      <span style={{ fontSize: "10px" }}>{t("Expansion")}</span>
                    </div>
                  </div>
                )}
                {code === 8 && !requirementMet && (
                  <div>
                    <div style={{ fontSize: "12px", color: "#5850B4" }}>
                      {t("Requirement: ")}
                    </div>
                    <div style={{ position: "relative", top: "10px" }}>
                      <img
                        src="/assets/icons/research31.svg"
                        width={20}
                        alt={t("Gravity")}
                        style={{ position: "relative", left: "-15px" }}
                      />
                      <span style={{ fontSize: "10px" }}>{t("Gravity")}</span>
                    </div>
                  </div>
                )}
                {code === 9 && !requirementMet && (
                  <div>
                    <div style={{ fontSize: "12px", color: "#5850B4" }}>
                      {t("Requirement: ")}
                    </div>
                    <div style={{ position: "relative", top: "10px" }}>
                      <img
                        src="/assets/icons/research20.svg"
                        width={20}
                        alt={t("Space Mining")}
                        style={{ position: "relative", left: "-15px" }}
                      />
                      <span style={{ fontSize: "10px" }}>
                        {t("Space Mining")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )
        }
      >
        <div
          onClick={onClick}
          className={isSelected ? styles.selected : styles.color}
        >
          {" "}
          <div
            className={
              isSelected || requirementMet || hasNFT
                ? styles.optionActive
                : styles.option
            }
          >
            <img
              src={"/assets/gamemode/" + image}
              alt={name}
              style={{ width: "30px", height: "30px" }}
            />
          </div>
          <div style={{ padding: "10px", fontSize: "12px" }}>{name}</div>
          <br />
        </div>
      </StyledTooltip>
    </div>
  )
}

export default GameModeBox
