import { useEffect, useState, useRef } from "react"
import { isLocked } from "./Service"
import ResearchItem from "../components/ResearchItem"
import ResearchContent from "../components/ResearchContent"
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp"
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown"
import styles from "./Resource.module.scss"

interface Props {
  research: Research[] | undefined
  researched: any
  isUnlocked?: boolean
  balance: number
}

const Military = ({ research, researched, balance }: Props) => {
  const [selected, setSelected] = useState<number>(0)
  const [openResearch, setOpenResearch] = useState<Research>()

  const scrollableListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let next = false
    research &&
      research.forEach((element) => {
        if (next) {
          setSelected(Number(element.id))
          setOpenResearch(element)
          next = false
        }
        researched.data.forEach((res: Researched) => {
          if (element.code === res.code) {
            if (element.category === 1) {
              next = true
            }
          }
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleScrollUp = () => {
    const container = scrollableListRef.current
    if (container) {
      container.scrollTop -= 60
    }
  }

  const handleScrollDown = () => {
    const container = scrollableListRef.current
    if (container) {
      container.scrollTop += 60
    }
  }

  const manualWhell = (e: any) => {
    const container = scrollableListRef.current
    if (container) {
      if (e.deltaY > 0) {
        container.scrollTop += 60
      } else {
        container.scrollTop -= 60
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.arrowUp} onClick={handleScrollUp}>
        <ArrowCircleUpIcon />
      </div>
      <div
        className={styles.listCol}
        ref={scrollableListRef}
        onWheel={manualWhell}
      >
        {research &&
          research.map((research) => {
            // category militar
            if (research.category === 1) {
              return (
                <div key={research.id}>
                  <ResearchItem
                    research={research}
                    seletedId={selected}
                    islocked={isLocked(research, researched.data)}
                    researcheds={researched.data}
                    side={
                      research.id && research.id % 2 === 0 ? "left" : "right"
                    }
                    onClick={() => {
                      setSelected(Number(research.id))
                      setOpenResearch(research)
                    }}
                  />
                </div>
              )
            }
            return null
          })}
      </div>

      <div className={styles.contentCol}>
        <div className={styles.container}>
          {openResearch && (
            <ResearchContent
              balance={balance}
              research={openResearch}
              researcheds={researched.data}
            />
          )}
        </div>
      </div>
      <div className={styles.arrowDown} onClick={handleScrollDown}>
        <ArrowCircleDownIcon />
      </div>
    </div>
  )
}

export default Military
