import { PropsWithChildren, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { ScifiButton } from "components/general"
import { GameContext } from "pages/GameContext"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import { Col, Row } from "components/layout"
import { TRITIUM_RESEARCH_SPEED } from "config/constants"
import { laboratoryConfig } from "data/queries/research"
import { getDisponibleWorkers, getMaxWorkers } from "./service"
import CircularSlider from "@fseehawer/react-circular-slider"
import Upgrade from "../Upgrade"
import SimpleAlert from "../SimpleAlert"
import styles from "./BuildDetail.module.scss"
import { useResearchSpeed } from "utils/hooks/gameModeEffect"
import { calcSpeedResearch } from "utils/effect"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
}

const Laboratory = (props: PropsWithChildren<Props>) => {
  const queryClient = useQueryClient()
  const { build, building, setIsOpen } = props
  const { t } = useTranslation()
  const { player, planets, planet } = useContext(GameContext)

  const researchSpeed = useResearchSpeed(player?.id ?? "0")
  const [workers, setWorkers] = useState<any>(building.workers)

  const [alert, setAlert] = useState<boolean>(false)

  return (
    <>
      <div>
        <span className={styles.level}>{Number(building.level)}</span>
        <span>{t("level")}</span>
      </div>
      <br />
      <div style={{ display: "flex" }}>
        <div>
          <img
            src={`/assets/builds/build-08.png`}
            alt="Metal Mining"
            className={styles.buildImage}
            draggable={false}
          />
          <Upgrade setIsOpen={setIsOpen} build={build} building={building} />
        </div>
        <div className={styles.buildContent}>
          <div className={styles.robots}>
            {t("Robots Level " + Number(building.level))}
          </div>
          <div>
            <div
              style={{ padding: "15px", position: "relative", left: "100px" }}
            >
              <CircularSlider
                width={160}
                min={0}
                max={getMaxWorkers(planets, building, planet)}
                dataIndex={workers}
                label={t("Robots")}
                labelColor={"#f1f1f1"}
                onChange={(value: any) => {
                  setWorkers(value)
                }}
                progressColorFrom="#5850B4"
                progressColorTo="#5850B4"
                knobColor="#5850B4"
                trackColor="#5850B434"
                valueFontSize="18px"
                knobPosition="left"
                progressLineCap="flat"
              />
            </div>
            {workers === 0 && (
              <div className={styles.generalAlertSlider}>
                {t("Laboratory is deactivated due to lack of robots working.")}
              </div>
            )}
            <Row>
              <div>
                {t("Capacity: ")}
                {getMaxWorkers(planets, building, planet)}
                {" | "}
                {t("Available: ")}{" "}
                {Math.max(
                  getDisponibleWorkers(planets, building, planet) - workers,
                  0
                )}
              </div>
            </Row>
            <br />
            <Col>
              <div>
                {t("Research Points")}
                {": "}
                <hr />
                <div className={styles.line}>
                  {Math.round(
                    calcSpeedResearch(TRITIUM_RESEARCH_SPEED, researchSpeed) *
                      workers
                  )}
                  {" / "}
                  {t("hour")}
                </div>
                <div className={styles.line}>
                  {Math.round(
                    calcSpeedResearch(TRITIUM_RESEARCH_SPEED, researchSpeed) *
                      workers *
                      24
                  )}
                  {" / "}
                  {t("day")}
                </div>
                <div className={styles.line}>
                  {Math.round(
                    calcSpeedResearch(TRITIUM_RESEARCH_SPEED, researchSpeed) *
                      workers *
                      24 *
                      7
                  )}
                  {" / "}
                  {t("week")}
                </div>
                <div className={styles.line}>
                  {Math.round(
                    calcSpeedResearch(TRITIUM_RESEARCH_SPEED, researchSpeed) *
                      workers *
                      24 *
                      30
                  )}
                  {" / "}
                  {t("month")}
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <ScifiButton
          onClick={() => {
            laboratoryConfig(Number(planet), workers)
              .then(() => {
                setWorkers(workers)
                building.workers = Number(workers)
                queryClient.invalidateQueries(queryKey.tritium.planet.planets)
                queryClient.invalidateQueries(queryKey.tritium.planet.planet)
                queryClient.invalidateQueries(queryKey.tritium.player.player)
                setAlert(true)
              })
              .catch((error) => {
                console.error("Erro ao configurar laboratório:", error)
              })
          }}
          size="small"
        >
          {t("Save")}
        </ScifiButton>
      </div>
      <SimpleAlert
        open={alert}
        onClose={() => {
          setAlert(false)
        }}
      />
    </>
  )
}

export default Laboratory
