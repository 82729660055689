// import { useTranslation } from "react-i18next"
import { Flex } from "components/layout"
import { Row, Col } from "components/layout"
import { Timer } from "components/display"
import styles from "./CombatControl.module.scss"

interface Props {
  currentCombat: any
}

const CombatControl = (props: Props) => {
  // const { t } = useTranslation()

  const { currentCombat } = props

  return (
    <Flex>
      <div className={styles.control}>
        <Row>
          <Col>
            <div>
              <div style={{ position: "relative", top: "15px" }}>Stage</div>
              <div className={styles.number}>
                {String(currentCombat.data?.stage).padStart(3, "0")}
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <div className={styles.stage}>
                <span className={styles.clock}>
                  <Timer
                    end={Number(currentCombat.data?.nextStage) * 1000 || 0}
                  />
                </span>
              </div>
              <div className={styles.desc}>Shild activate!</div>
            </div>
          </Col>
        </Row>
      </div>
    </Flex>
  )
}

export default CombatControl
