import React, { useState, useEffect } from "react"
import GameModeBox from "../components/GameModeBox"
import styles from "./GameMode.module.scss"
import { ScifiButton } from "components/general"
import SuperScifiModal from "components/feedback/SuperScifiModal"
import { useGameModeSelected } from "data/queries/gameMode"
import { useTranslation } from "react-i18next"
import { Timer } from "components/display"

interface Props {
  mode: Mode[] | undefined
  onSelect: (selectedMode: Mode) => void
  changeIsAvailable: boolean
}

const GameMode = ({ mode, onSelect, changeIsAvailable }: Props) => {
  const { t } = useTranslation()

  const [selectedModeCode, setSelectedModeCode] = useState<number | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedMode, setSelectedMode] = useState<Mode | null>(null)
  const [changeAvailable, setChangeIsAvailable] = useState(false)
  const planetId = localStorage.getItem("planet")

  useEffect(() => {
    const storedSelectedModeCode = localStorage.getItem("selectedModeCode")
    const defaultSelectedModeCode = storedSelectedModeCode
      ? parseInt(storedSelectedModeCode)
      : mode?.find((m) => m.code === 1)?.code || null
    setSelectedModeCode(defaultSelectedModeCode)
  }, [mode])

  const handleSelect = (gameMode: Mode) => {
    setSelectedMode(gameMode)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleConfirm = async () => {
    if (selectedMode && selectedMode.requirementMet && changeIsAvailable) {
      try {
        onSelect(selectedMode)
        const newUpdateTime = Math.floor(Date.now() / 1000)
        localStorage.setItem("gameModeUpdated", newUpdateTime.toString())

        const newAvailableChangeMode = newUpdateTime + 86400 * 2
        const newChangeIsAvailable = Date.now() > newAvailableChangeMode * 1000

        setChangeIsAvailable(newChangeIsAvailable)
        setTimeout(() => {
          setChangeIsAvailable(true)
        }, 86400 * 2 * 1000)

        setIsModalOpen(false)
      } catch (error) {
        console.error("Erro ao mudar o modo de jogo:", error)
      }
    }
  }

  useEffect(() => {
    const updateAvailability = () => {
      const gameModeUpdated = localStorage.getItem("gameModeUpdated")
      if (gameModeUpdated) {
        const updateTimestamp = Number(gameModeUpdated)
        const newAvailableChangeMode = updateTimestamp + 86400 * 2
        setChangeIsAvailable(Date.now() > newAvailableChangeMode * 1000)
      }
    }

    updateAvailability()
    const interval = setInterval(updateAvailability, 1000)

    return () => clearInterval(interval)
  }, [])

  const playerModeSelect = useGameModeSelected(planetId ?? "0")
  const availableChangeMode =
    Number(playerModeSelect.data.gameModeUpdated ?? 0) + 86400 * 2

  return (
    <div className={styles.container}>
      <div className={styles.changeContent}>
        <SuperScifiModal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <div className={styles.changeTitle}>
            {t("Are you sure you want to change the game mode?")}
          </div>
          <>
            {selectedMode && (
              <>
                <div className={styles.changeContainer}>
                  <div className={styles.changeSelected}>
                    <img
                      src={`/assets/gamemode/${selectedMode.image}`}
                      alt={selectedMode.name}
                      style={{ width: "30px", height: "30px" }}
                      className={styles.changeImage}
                    />
                  </div>
                  <div
                    style={{ padding: "10px", fontSize: "12px" }}
                    className={styles.changeName}
                  >
                    {selectedMode.name}
                  </div>
                </div>
                <div className={styles.changeEffect}>
                  {t(selectedMode.description)}
                </div>
              </>
            )}
          </>
          <br />
          <div className={styles.changeText}>
            {t("The change will take")}{" "}
            <div className={styles.timer}>
              <Timer end={availableChangeMode * 1000} />
            </div>
          </div>
          <div className={styles.cancel}>
            <ScifiButton onClick={handleCloseModal}>{t("Cancel")}</ScifiButton>
          </div>
          <div className={styles.confirm}>
            <ScifiButton
              onClick={handleConfirm}
              disabled={!changeAvailable || !selectedMode?.requirementMet}
            >
              {t("Confirm")}
            </ScifiButton>
          </div>
        </SuperScifiModal>
      </div>
      <div className={styles.listHor}>
        {mode &&
          mode.map((gameMode) => {
            const isSelected = selectedModeCode === gameMode.code
            return (
              <div key={gameMode.code}>
                <GameModeBox
                  image={gameMode.image}
                  name={gameMode.name}
                  onClick={() => handleSelect(gameMode)}
                  isSelected={isSelected}
                  requirementMet={gameMode.requirementMet}
                  code={gameMode.code}
                />
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default GameMode
