import { TRITIUM_MAX_LENGTH_MSG, TRITIUM_MSG_PREVIEW } from "config/constants"
import * as linkify from "linkifyjs"

export const cutMessage = (txt: string) => {
  return txt.replace(/\s+/g, " ").trim().substring(0, TRITIUM_MAX_LENGTH_MSG)
}
export const cutMessagePreview = (txt: string) => {
  return txt.length > 50 ? txt.substring(0, TRITIUM_MSG_PREVIEW) + ` ...` : txt
}
export const findFtp = (txt: string) => {
  const regex = /\bftp:\/\/[^\s]+\b/g
  return txt.match(regex)
}

export const findUrls = (txt: string) => {
  const links = linkify.find(txt)
  return links
}

export const trataString = (txt: string) => {
  const links = findUrls(txt) ?? []
  let modifiedText = txt
  links.forEach((link) => {
    modifiedText = modifiedText.replace(link.value, "LINK")
  })

  return cutMessage(modifiedText)
}
