import { ReactNode, useState } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames/bind"
import { capitalize } from "@mui/material"
import styles from "./Menu.module.scss"

const cx = classNames.bind(styles)

interface Props {
  tabs: {
    key: string
    tab: string
    children: ReactNode
    disabled?: boolean
    icon?: ReactNode
  }[]
  type: "line" | "card" | "scifi"
  reversed?: boolean
  state?: boolean
}

const Tabs = ({ tabs, type, reversed, state }: Props) => {
  /* state */
  const [activeKey, setActiveKey] = useState("")

  return (
    <>
      <section className={cx(styles.tabs, type, { reversed })}>
        {tabs.map(({ key, tab, disabled, icon }) =>
          state ? (
            <button
              type="button"
              className={cx(styles.tab, {
                active: key === activeKey,
                disabled,
              })}
              onClick={() => !disabled && setActiveKey(key)}
              disabled={disabled}
              key={key}
            >
              <div>{icon}</div>
              {capitalize(tab)}
            </button>
          ) : disabled ? (
            <span className={classNames(styles.tab, styles.disabled)} key={key}>
              <div>{icon}</div>
              {capitalize(tab)}
            </span>
          ) : (
            <Link className={styles.tab} to={"/game/" + key} key={key}>
              <div className={disabled ? styles.transparent : ""}>{icon}</div>
              {capitalize(tab)}
            </Link>
          )
        )}
      </section>
    </>
  )
}

export default Tabs
