import CheckIcon from "@mui/icons-material/Check"
import styles from "./ConversationData.module.scss"
import disableScroll from "disable-scroll"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { LoadingCircular } from "components/feedback"
import ScifiDiv from "components/general/ScifiDiv"
import {
  getConversation,
  readMessage,
  sendNewMessage,
} from "data/queries/message"
import { trataString } from "utils/validations"
import { TRITIUM_MAX_LENGTH_MSG } from "config/constants"
import SendIcon from "@mui/icons-material/Send"
// import { HtmlProps } from "@react-three/drei/web/Html"

interface ChatProps {
  destinationId: number
}
const Chat = (ChatProps: ChatProps) => {
  let { destinationId } = ChatProps
  const { t } = useTranslation()
  const [messageText, setMessageText] = useState<string>("")

  const [loadMsg, setLoadMsg] = useState(true)
  const [conversation, setConversation] = useState<Message[]>([])
  const [longPressTimer, setLongPressTimer] = useState<NodeJS.Timeout | null>(
    null
  )
  const [showData, setShowData] = useState<boolean>(false)
  const timeRefreshChat: number = 5000

  const handleMoveScroll = () => {
    const element = document.getElementById("section-end")
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMsg(destinationId)
    }, timeRefreshChat)
    return () => clearInterval(interval)
  }, [destinationId])

  // const useConversation(destinationId)
  useEffect(() => {
    fetchMsg(destinationId)
    handleMoveScroll()
  }, [destinationId, conversation.length])

  const fetchMsg = async (destinationId: number) => {
    //  setLoadMsg(true)
    console.log("aqui? ", destinationId)
    getConversation(destinationId)
      .then((res) => {
        setConversation(res.data)
      })
      .catch((err) => console.log("error", err))
      .finally(() => setLoadMsg(false))
    readMessage(destinationId).catch((e) => console.log(e))
  }

  useEffect(() => {
    return () => {
      if (longPressTimer) {
        clearTimeout(longPressTimer)
      }
    }
  }, [longPressTimer])

  function pad(num: number, length: number) {
    return num.toString().padStart(length, "0")
  }
  function dataAtual() {
    const dataHoraAtual = new Date()
    const ano = dataHoraAtual.getFullYear()
    const mes = pad(dataHoraAtual.getMonth() + 1, 2)
    const dia = pad(dataHoraAtual.getDate(), 2)
    const horas = pad(dataHoraAtual.getHours(), 2)
    const minutos = pad(dataHoraAtual.getMinutes(), 2)
    const segundos = pad(dataHoraAtual.getSeconds(), 2)
    return `${ano}-${mes}-${dia} ${horas}:${minutos}:${segundos}`
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setMessageText(trataString(messageText))
    const conversa = conversation
    const dataAgora = dataAtual()
    const newMessage: Message = {
      content: trataString(messageText),
      read: false,
      sender: false,
      recipientId: destinationId,
      createdAt: dataAgora,
    }
    conversa.push(newMessage)
    setConversation(conversa)
    sendNewMessage(newMessage).catch((err) => console.log(t("error"), err))
    setMessageText("")
  }

  const handleMouseDown = () => {
    const timer = setTimeout(() => {
      return setShowData(!showData)
    }, 500)
    setLongPressTimer(timer)
  }

  const handleMouseUp = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer)
    }
  }
  const getDate = (date: string): string => {
    const newDate: Date = new Date(date)
    const dia = newDate.getDate().toString().padStart(2, "0")
    const mes = (newDate.getMonth() + 1).toString().padStart(2, "0")
    const ano = newDate.getFullYear().toString().slice(-2)
    return `${dia}/${mes}/${ano}`
  }
  const getHours = (hora: string): string => {
    const newDate: Date = new Date(hora)
    let horaFormatada: number = newDate.getHours()
    const minutosFormatados: string = newDate
      .getMinutes()
      .toString()
      .padStart(2, "0")
    let periodo: string = "am"

    if (horaFormatada >= 12) {
      periodo = "pm"
      if (horaFormatada > 12) {
        horaFormatada -= 12
      }
    }
    return `${horaFormatada
      .toString()
      .padStart(2, "0")}:${minutosFormatados} ${periodo}`
  }
  return (
    <div
      className={styles.conversatioBox}
      onWheel={(e) => {
        disableScroll.off()
      }}
      onMouseOut={(e) => {
        disableScroll.on()
      }}
    >
      <table
        style={{ width: "100%", minWidth: "430px", height: "120px" }}
        className={styles.table}
        onMouseOut={(e) => {
          disableScroll.on()
        }}
      >
        <tbody>
          {loadMsg && <LoadingCircular size={18} />}
          {conversation && conversation.length > 0 ? (
            conversation.map((c, i) => {
              const horaCriacao: string = c.createdAt?.toString() ?? ""
              const horaRenderizada: string = getHours(horaCriacao)
              const dataRenderizacao: string = getDate(horaCriacao)
              return (
                <tr
                  key={i}
                  draggable={true}
                  onDragStart={(e) => setShowData(!showData)}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onTouchStart={handleMouseDown}
                  onTouchEnd={handleMouseUp}
                >
                  <td
                    className={c.sender ? styles.msgSended : styles.msgReceived}
                  >
                    <div>
                      <span style={{ color: "#FFF" }}>{c.content}</span>
                      <div className={styles.hours}>{horaRenderizada}</div>
                    </div>
                  </td>
                  <td style={{ display: `${showData ? "block" : "none"}` }}>
                    <div className={styles.info}>
                      <div>{dataRenderizacao}</div>
                      <div>
                        {!c.sender && c.readAt ? (
                          <CheckIcon
                            className={styles.checkicon}
                            fontSize="small"
                          />
                        ) : (
                          <>{""}</>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <></>
          )}
        </tbody>
        <tfoot>
          <tr id="section-end">
            <td></td>
          </tr>
        </tfoot>
      </table>
      <div
        // className={`${styles.send} ${destination.length === 0 ? styles.sendBlock : ""
        //     }  `}
        className={styles.send}
      >
        <ScifiDiv>
          <div style={{ width: "100%", minWidth: "420px" }}>
            <div className={styles.inputContainer}>
              <form onSubmit={handleSubmit}>
                <input type="hidden" value={destinationId} id="destinationId" />
                <input
                  type="text"
                  value={messageText}
                  maxLength={TRITIUM_MAX_LENGTH_MSG}
                  onChange={(e) => setMessageText(e.target.value)}
                  placeholder="Type your message"
                />
                <button>
                  <SendIcon className={`${styles.icon}`} />
                </button>
              </form>
            </div>
          </div>
        </ScifiDiv>
      </div>
    </div>
  )
}

export default Chat
