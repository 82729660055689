import SearchIcon from "@mui/icons-material/Search"
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined"
import PersonIcon from "@mui/icons-material/Person"

import styles from "./InvateMemberAliance.module.scss"
import { useTranslation } from "react-i18next"
import { LoadingCircular, ScifiModal } from "components/feedback"
import { Table } from "components/layout"
import { Tooltip } from "@mui/material"
import { useState } from "react"
import { invite, listSearchPlayer } from "data/queries/aliance"
import { ScifiButton } from "components/general"

interface InvateMemberProps {
  idAliance: number
}
const InviteMemberAliance = (InvateMemberProps: InvateMemberProps) => {
  const { idAliance } = InvateMemberProps
  const { t } = useTranslation()
  const [searchUser, setSearchUser] = useState("")
  const [loadUser, setLoadUser] = useState(false)
  const [listMembers, setListMembers] = useState<MemberSearch[] | []>([])
  const [page, setPage] = useState(1)
  const [showMsg, setShowMsg] = useState("")
  const [openModal, setOpenModal] = useState(false)

  const handleSearchUser = (search: string) => {
    setSearchUser(search)
    if (search.trim().length > 3) {
      setLoadUser(true)
      listSearchPlayer(search)
        .then((res) => {
          setListMembers(res.data)
        })
        .catch((err) => console.log("erro ao pesquisar usuário", err))
        .finally(() => setLoadUser(false))
    }
    if (search.trim().length === 0) {
      setLoadUser(false)
    }
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleInviteMember = async (idMember: number, idAliance: number) => {
    await invite(idMember, idAliance)
      .then((res) => {
        setOpenModal(true)
        setShowMsg("Invitation sent successfully!")
      })
      .catch((er) => {
        setShowMsg(er.response.data.message)
        setOpenModal(true)
        // console.log("erro",er.response.data.message)
      })
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setShowMsg("")
    setListMembers([])
    setSearchUser("")
  }

  return (
    <>
      <ScifiModal isOpen={openModal} onRequestClose={() => handleCloseModal}>
        <div className={styles.msgErro}>
          {t(`${showMsg}`)}
          <div className={styles.btnContainer}>
            <ScifiButton onClick={() => handleCloseModal()}>Ok</ScifiButton>
          </div>
        </div>
      </ScifiModal>
      <div className={styles.searchContainer}>
        <input
          type="text"
          className={styles.inputAliance}
          placeholder={`${t("Search")}`}
          value={searchUser}
          onChange={(e) => handleSearchUser(e.target.value)}
        />
        <span className={styles.searchIcon}>
          <SearchIcon />
        </span>
      </div>
      {loadUser && <LoadingCircular size={18} />}
      {listMembers.length === 0 && searchUser.length > 0 && (
        <div className={styles.notFound}>{t("No users found")}</div>
      )}

      {listMembers.length > 0 && (
        <div style={{ marginTop: "8px" }}>
          <Table
            columns={[
              {
                key: "name",
                title: t("Player"),
                dataIndex: "name",
                align: "left",
                render: (name: string) => {
                  return <>{name?.substring(0, 15)}</>
                },
              },
              {
                key: "alianceName",
                title: t("Aliance"),
                dataIndex: "alianceName",
                align: "left",
                render: (alianceName: string) => {
                  return <>{alianceName?.substring(0, 15)}</>
                },
              },
              {
                key: "aliance",
                title: "",
                dataIndex: "aliance",
                align: "right",
                render: (aliance: string, record: MemberSearch) => {
                  if (record.aliance === idAliance) {
                    return (
                      <Tooltip title={t("Already a member")}>
                        <button disabled={true}>
                          <PersonIcon fontSize={"small"} />
                        </button>
                      </Tooltip>
                    )
                  } else {
                    return (
                      <Tooltip title={t("Invite")}>
                        <button
                          onClick={() =>
                            handleInviteMember(record.idPlayer, idAliance)
                          }
                        >
                          <PersonAddAltOutlinedIcon fontSize={"small"} />
                        </button>
                      </Tooltip>
                    )
                  }
                },
              },
            ]}
            dataSource={listMembers}
            size="small"
            pagination={{
              currentPage: page,
              pageSize: 100,
              onPageChange: handlePageChange,
            }}
          />
        </div>
      )}
    </>
  )
}

export default InviteMemberAliance
