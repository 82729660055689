import ChatIcon from "@mui/icons-material/Chat"
import disableScroll from "disable-scroll"
import styles from "./AlianceChat.module.scss"
import {
  getMessagesWithAliance,
  newMessageChatWinthinAliance,
  useAliancesChat,
} from "data/queries/aliance"
import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ScifiDiv from "components/general/ScifiDiv"
import { dataAtual, getDate, getHours } from "utils/date"
import {
  TRITIUM_MEMBER_DIPLOMAT,
  TRITIUM_MEMBER_FOUNDER,
  TRITIUM_MAX_LENGTH_MSG,
} from "config/constants"
import { trataString } from "utils/validations"
import SendIcon from "@mui/icons-material/Send"
import DeleteIcon from "@mui/icons-material/Delete"

interface ChatWithAlianceProps {
  idAliance: number
}
const ChatWithAliance = (ChatWithAliance: ChatWithAlianceProps) => {
  const { t } = useTranslation()
  const { idAliance } = ChatWithAliance
  const { data: aliancas } = useAliancesChat()

  const [listAliance, setListAliance] = useState(true)
  const [conversation, setConversation] = useState<MessageWithinAliances[]>([])
  const [messageText, setMessageText] = useState("")
  const [outherAliance, setOutherAliance] = useState<AlianceInteracao | null>(
    null
  )

  /**cht */
  const [showData, setShowData] = useState<boolean>(false)
  const [longPressTimer, setLongPressTimer] = useState<NodeJS.Timeout | null>(
    null
  )

  useEffect(() => {
    fecthMessage(outherAliance?.id!)
  }, [outherAliance])
  useEffect(() => {
    return () => {
      if (longPressTimer) {
        clearTimeout(longPressTimer)
      }
    }
  }, [longPressTimer])

  const handleSelectAlianceChat = (alianca: AlianceInteracao) => {
    setListAliance(false)
    setOutherAliance(alianca)
    // console.log(`minha alianca ${idAliance} vai conversar com a aliana ${alianca.id!} com o nome de ${alianca.name!} `)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const conversa = conversation
    const dataAgora = dataAtual()
    const newMessage: MessageWithinAliances = {
      idOrigem: idAliance,
      idDestino: outherAliance?.id!,
      createdAt: dataAgora,
      status: "A",
      message: trataString(messageText),
      sender: true,
    }

    conversa.push(newMessage)
    setConversation(conversa)
    newMessageChatWinthinAliance(newMessage)
      .then((res) => {
        // console.log("resposta ", res)
      })
      .catch((er) => console.log("erro ao enviar msg", er))
    setMessageText("")
  }

  const handleMouseDown = () => {
    const timer = setTimeout(() => {
      return setShowData(!showData)
    }, 500)
    setLongPressTimer(timer)
  }

  const handleMouseUp = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer)
    }
  }
  const handleDeleteMessage = (idMessage: number) => {
    // deleteMessage(idMessage)
    //   .then((res) => console.log("resposta delete ", res))
    //   .catch((err) => console.log("erro", err))
  }

  const fecthMessage = async (idDestino: number) => {
    if (idDestino) {
      getMessagesWithAliance(idDestino)
        .then((res) => {
          setConversation(res.data)
        })
        .catch((er) => console.log("erro ao recuperar msg entre aliancas", er))
    }
  }

  return (
    <>
      {listAliance ? (
        <>
          <div
            style={{ height: "300px", overflowX: "auto" }}
            onWheel={(e) => {
              disableScroll.off()
            }}
            onMouseOut={(e) => {
              disableScroll.on()
            }}
          >
            <table
              className={styles.tableAliance}
              onMouseOut={(e) => {
                disableScroll.on()
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>{""}</th>
                  <th style={{ width: "55%" }}> {t("Name")}</th>
                  <th style={{ width: "10%" }}> {""}</th>
                  <th style={{ width: "20%" }}>{""}</th>
                </tr>
              </thead>
              <tbody>
                {aliancas &&
                  aliancas.map((alianca, i) => {
                    return (
                      <>
                        {idAliance !== alianca.id && (
                          <tr key={i}>
                            <td>
                              {/* {a.logo} */}
                              <img
                                style={{ borderRadius: "50%" }}
                                src={`/assets/aliancelogo/fill/${alianca.logo}`}
                                width={40}
                                alt={t("logo")}
                              />
                            </td>
                            <td>{alianca.name}</td>
                            <td>
                              {alianca.interacao ? (
                                <div className={styles.interacao}></div>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>
                              <div
                                onClick={() => handleSelectAlianceChat(alianca)}
                              >
                                <ChatIcon />
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <div className={styles.return} onClick={() => setListAliance(true)}>
            <ArrowBackIcon />
          </div>
          <div className={styles.nameAliance}>
            {outherAliance && outherAliance.name}
          </div>
          <>
            <div className={styles.container}>
              <div className={styles.leftDiv}>
                <div
                  className={styles.sendersBox}
                  onWheel={(e) => {
                    disableScroll.off()
                  }}
                  onMouseOut={(e) => {
                    disableScroll.on()
                  }}
                >
                  {/* {loadUser && <LoadingCircular size={18} />} */}
                  <table className={styles.senders}>
                    {
                      // msgPlayerInterations && msgPlayerInterations.length > 0 ? (
                      conversation.map((c, i) => {
                        const horaCriacao: string =
                          c.createdAt?.toString() ?? ""
                        const horaRenderizada: string = getHours(horaCriacao)
                        const dataRenderizacao: string = getDate(horaCriacao)
                        return (
                          <>
                            <tr
                              key={i}
                              // draggable={true}
                              // onDragStart={(e) => setShowData(!showData)}
                              onMouseDown={handleMouseDown}
                              onMouseUp={handleMouseUp}
                              // onTouchStart={handleMouseDown}
                              // onTouchEnd={handleMouseUp}
                            >
                              <td
                                className={
                                  !c.sender
                                    ? styles.msgSended
                                    : styles.msgReceived
                                }
                              >
                                <div>
                                  {c.status ? (
                                    <span>{c.message}</span>
                                  ) : (
                                    <>
                                      <div className={styles.msgDeleted}>
                                        {t("Deleted message")}
                                      </div>
                                    </>
                                  )}
                                  <div className={styles.hours}>
                                    {!c.sender && (
                                      <span>{outherAliance?.name} &nbsp;</span>
                                    )}
                                    <span>{horaRenderizada}</span>
                                  </div>
                                </div>
                              </td>
                              <td
                                style={{
                                  display: `${showData ? "block" : "none"}`,
                                }}
                              >
                                <div className={styles.info}>
                                  <div>{dataRenderizacao}</div>
                                  {[
                                    TRITIUM_MEMBER_DIPLOMAT,
                                    TRITIUM_MEMBER_FOUNDER,
                                  ].includes(2) &&
                                    c.status && (
                                      <div
                                        className={styles.iconDel}
                                        onClick={() =>
                                          handleDeleteMessage(c.id!)
                                        }
                                      >
                                        <DeleteIcon fontSize={"small"} />
                                      </div>
                                    )}
                                  <div></div>
                                </div>
                              </td>
                            </tr>
                          </>
                        )
                      })
                    }
                  </table>
                </div>
              </div>
            </div>
            <ScifiDiv>
              <div style={{ width: "275px" }} className="txtsend">
                <div className={styles.inputContainer}>
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      value={messageText}
                      maxLength={TRITIUM_MAX_LENGTH_MSG}
                      onChange={(e) => setMessageText(e.target.value)}
                      placeholder={t("Type your message")}
                    />
                    <button>
                      <SendIcon className={`${styles.icon}`} />
                    </button>
                  </form>
                </div>
              </div>
            </ScifiDiv>
          </>
        </>
      )}
    </>
  )
}
export default ChatWithAliance
