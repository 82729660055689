import { TRITIUM } from "config/constants"
import api from "../services/api"

export const createHumanoid = (planet: number, qtd: number) => {
  return api.post(TRITIUM + `/api/factory/humanoid/create/${planet}/${qtd}`)
}

export const createTransportship = (planet: number, qtd: number) => {
  return api.post(
    TRITIUM + `/api/factory/transportship/create/${planet}/${qtd}`
  )
}
