import { useState, useEffect } from "react"
import { Line } from "@react-three/drei"

interface StarWarpProps {
  isActive?: boolean
}

const LINE_COUNT = 5000

const getInitialActiveState = () => {
  const storedValue = localStorage.getItem("power")
  return storedValue === "true"
}

const StarWarp: React.FC<StarWarpProps> = ({ isActive: propIsActive }) => {
  const [linew, setLinew] = useState<number>(0.7)
  const [isActive] = useState(propIsActive ?? getInitialActiveState())

  let pa: any = []
  let va: any = []

  for (let line_index = 0; line_index < LINE_COUNT; line_index++) {
    var x = Math.random() * 1200 - 600
    var y = Math.random() * 600 - 300
    var z = Math.random() * 1200 - 600
    var xx = x
    var yy = y
    var zz = z
    //line start
    pa[6 * line_index] = x
    pa[6 * line_index + 1] = y
    pa[6 * line_index + 2] = z + 8
    //line end
    pa[6 * line_index + 3] = xx
    pa[6 * line_index + 4] = yy + 8
    pa[6 * line_index + 5] = zz

    va[2 * line_index] = va[2 * line_index + 1] = 0
  }

  useEffect(() => {
    if (isActive) {
      setLinew(0.7)
    } else {
      setLinew(0)
    }
  }, [isActive])

  return (
    <>
      <mesh>
        <Line
          position={[0, -200, 700]}
          fog={false}
          points={pa}
          color={"#f1f1f1"}
          lineWidth={linew}
          segments
          dashed={false}
        />
      </mesh>
    </>
  )
}

export default StarWarp
