import { useState } from "react"
import { useTranslation } from "react-i18next"
import { ScifiModal } from "components/feedback"
import { useWallet, WalletStatus } from "@terra-money/wallet-kit"
import { Row, Col } from "components/layout"
import FounderAnime from "styles/nft/founder2.webp"
import FounderImage from "styles/nft/founder2.png"
import styles from "./Founder.module.scss"

const Founder = () => {
  const { t } = useTranslation()

  const [onSelect, setOnSelect] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const { status } = useWallet()
  const [display, setDisplayShow] = useState<boolean>(false)

  return (
    <>
      {status === WalletStatus.NOT_CONNECTED && (
        <>
          {display && (
            <div className={styles.title_slot_div}>
              {t("Reserved for NFT founders")}
            </div>
          )}
          <div
            className={styles.title_slot}
            onMouseOver={() => setDisplayShow(true)}
            onMouseOut={() => setDisplayShow(false)}
          ></div>
        </>
      )}
      {status !== WalletStatus.NOT_CONNECTED && (
        <>
          <div
            className={styles.founder}
            onMouseOver={() => setOnSelect(true)}
            onMouseOut={() => setOnSelect(false)}
            onClick={() => setOpen(true)}
          >
            <img
              src={FounderAnime}
              width={250}
              alt={t("Founder")}
              draggable={false}
            />
          </div>
          <div className={onSelect ? styles.label : styles.hiden}>
            {t("Founder")}
          </div>
          <ScifiModal
            title={t("Founders' Monument")}
            isOpen={open}
            onRequestClose={() => {
              setOpen(false)
            }}
          >
            <div className={styles.modalContent}>
              <div>
                <Row>
                  <Col>
                    <img
                      src={FounderImage}
                      alt={t("Founder")}
                      width={300}
                      draggable={false}
                    />
                  </Col>
                  <Col>
                    <div className={styles.box}>
                      <b>{t("Celebrating the Pioneers")}</b>
                      <br />
                      <br />
                      {t(
                        "The Terra Tritium Founders' Monument stands as a testament to the pioneers who embarked on this thrilling journey right from the beginning. As a player who is part of this exclusive group, you have left an indelible mark on the Terra Tritium's development and success."
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </ScifiModal>
        </>
      )}
    </>
  )
}

export default Founder
