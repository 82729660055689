import { useTranslation } from "react-i18next"
import styles from "./SendFleet.module.scss"
import { usePlayer } from "data/queries/player"

interface Props {
  maxShips: number
  isEmpty: any
  setDisabled: any
  getRefInput: any
  idx: number
  fleets: any
  setFleets: any
  setTransportShip: any
  planet: any
}

const TransportUnit = (props: Props) => {
  const { t } = useTranslation()
  const player = usePlayer()

  const trasports = player.data?.transportShips || 0

  return (
    <>
      <div className={styles.unit}>
        <div className={styles.tagQtd}>
          {t("Transport")}{" "}
          <span className={styles.transpQtd}>
            {props.planet.data && <>{trasports}</>}
          </span>
        </div>
        <div onClick={() => {}}>
          <img
            className={styles.unitImg}
            src={"/assets/ships/ship-06.png"}
            alt={"Transport Ship"}
            draggable="false"
          />
        </div>
        <div className={styles.quantityTransport}>
          <input
            id={"idTransportShip"}
            name={"transportShip"}
            className={styles.inputCustom}
            type="number"
            max={props.maxShips}
            disabled={trasports <= 0}
            min={0}
            ref={props.getRefInput(props.idx)}
            onChange={(e) => {
              if (trasports > 0) {
                props.setTransportShip(Number(e.target.value))
              } else {
                props.setTransportShip(0)
              }
            }}
          />
        </div>
      </div>
    </>
  )
}

export default TransportUnit
