import React from "react"
import { PropsWithChildren, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import { upBuilding } from "data/queries/build"
import { checkFunds, missingFunds } from "utils/requires"
import { Button } from "components/general"
import {
  BUILD_COLONIZATION,
  TRITIUM_CONSTRUCTION_SPEED,
} from "config/constants"
import { GameContext } from "pages/GameContext"
import { calcResourceRequirements } from "utils/requires"
import { ScifiModal } from "components/feedback"
import { Flex } from "components/layout"
import { registerLog } from "data/queries/log"
import Demolish from "./Demolish"
import UpgradeRequire from "./UpgradeRequire"
import UpIcon from "@mui/icons-material/ArrowCircleUp"
import DangerousIcon from "@mui/icons-material/Dangerous"
import Warning from "./Warning"
import styles from "./buildings/BuildDetail.module.scss"
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material"
import {
  useConstructionBuildSpeed,
  useDiscountBuild,
  useSpeedMining,
} from "utils/hooks/gameModeEffect"
import { calcSpeedConstructionBuild } from "utils/effect"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
}

const Upgrade = (props: PropsWithChildren<Props>) => {
  const { build, building, setIsOpen } = props
  const { t } = useTranslation()
  const { setOnBuilding, onBuilding, player, planetData } =
    useContext(GameContext)

  let planetId =
    typeof planetData !== "undefined" && "id" in planetData
      ? planetData.id
      : "0"

  const discountBuild = useDiscountBuild(planetId)
  const speedMining = useSpeedMining(planetId)
  const speedConstructionBuild = useConstructionBuildSpeed(planetId)

  const queryClient = useQueryClient()
  const requires = calcResourceRequirements(
    build,
    Number(building.level) + 1,
    discountBuild,
    speedConstructionBuild
  )

  const [isOpen, setOpen] = useState(false)
  const [demolish, setDemolish] = useState(false)

  const upgradeBuild = (time: number) => {
    setIsOpen(false)
    setOnBuilding(true)

    upBuilding(building)
      .then(() => {
        setTimeout(() => queryClient.invalidateQueries(), 200)
        setTimeout(() => {
          queryClient.invalidateQueries()
        }, time * 1000 * calcSpeedConstructionBuild(TRITIUM_CONSTRUCTION_SPEED, speedConstructionBuild) + 200)
      })
      .catch((error) => {
        console.error("Erro on refresh construction:", error)
      })
      .finally(() => {
        setTimeout(() => {
          setOnBuilding(false)
        }, time * 1000 * calcSpeedConstructionBuild(TRITIUM_CONSTRUCTION_SPEED, speedConstructionBuild) + 500)
      })
  }

  const getTooltipMessage = (
    build: Build,
    level: number,
    planetData: Planet | undefined | never[]
  ) => {
    const checkFunds = missingFunds(
      build,
      level + 1,
      planetData,
      discountBuild,
      speedMining,
      speedConstructionBuild
    )

    let messages: React.ReactNode[] = []

    const resourceImages: { [key: string]: string } = {
      Metal: "/assets/icons/resources/metal.png",
      Uranium: "/assets/icons/resources/uranium.png",
      Crystal: "/assets/icons/resources/crystal.png",
    }

    if (checkFunds.length > 0) {
      return (
        <div style={{ width: "auto" }}>
          {t("Insufficient resources:")}{" "}
          {checkFunds.map((resource, index) => (
            <React.Fragment key={resource}>
              {index > 0 && (
                <span style={{ position: "relative", right: "5px" }}>| </span>
              )}
              {resource}{" "}
              <img
                src={resourceImages[resource]}
                alt={resource}
                style={{ width: "30px", marginRight: "5px" }}
              />
            </React.Fragment>
          ))}
        </div>
      )
    }
    return messages
  }

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      width: "auto",
      position: "relative",
      bottom: "100px",
      left: "88px",
      backgroundColor: "#5850B41c",
      color: "#f1f1f1f1",
      borderRadius: "5px",
      border: "1px solid #5850B46c",
      margin: "5px",
      padding: "8px",
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "700px",
    },
  }))

  const tooltipMsg = getTooltipMessage(
    build,
    Number(building.level),
    planetData
  )

  return (
    <div>
      {requires && player && (
        <div className={styles.upgrade}>
          <div className={styles.upText}>{t(`${build.effect}`)}</div>
          <br />
          <UpgradeRequire requires={requires} />
          <div style={{ width: "240px" }}>
            {onBuilding && <Warning />}
            <br />
            <br />
            {build && tooltipMsg && Object.keys(tooltipMsg).length ? (
              <StyledTooltip title={tooltipMsg}>
                <span>
                  <Button
                    className={styles.upIcon}
                    onClick={() => {
                      setOpen(true)
                    }}
                    size="small"
                    disabled={
                      !checkFunds(
                        build,
                        Number(building.level) + 1,
                        planetData,
                        discountBuild,
                        speedMining,
                        speedConstructionBuild
                      ) || onBuilding
                    }
                  >
                    {t("Upgrade")}
                    <UpIcon />
                  </Button>
                </span>
              </StyledTooltip>
            ) : (
              <Button
                className={styles.upIcon}
                onClick={() => {
                  setOpen(true)
                }}
                size="small"
                disabled={
                  !checkFunds(
                    build,
                    Number(building.level) + 1,
                    planetData,
                    discountBuild,
                    speedMining,
                    speedConstructionBuild
                  ) || onBuilding
                }
              >
                {t("Upgrade")}
                <UpIcon />
              </Button>
            )}
            {build.code !== BUILD_COLONIZATION && (
              <Button
                size="small"
                className={styles.danger}
                onClick={() => setDemolish(true)}
              >
                {t("Demolish")}
                <DangerousIcon />
              </Button>
            )}
          </div>
        </div>
      )}
      <ScifiModal
        title={t("Upgrade")}
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
      >
        <Flex>
          <div className={styles.text}>
            <div className={styles.upgradeModal}>
              <div className={styles.upTextModal}>{t(`${build.effect}`)}</div>
            </div>
            <br />
            <div className={styles.msg}>
              {t("The upgrade will spend the following resources")}
            </div>
            <br />
            <div className={styles.requires}>
              <UpgradeRequire requires={requires} />
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  upgradeBuild(Number(requires.time))
                  registerLog({
                    type: "upgrade",
                    text:
                      "Upgrade " +
                      build.name +
                      " for level " +
                      String(Number(building.level) + 1),
                  })
                  queryClient.invalidateQueries(queryKey.tritium.logs.logs)
                }}
                size="small"
                disabled={
                  !checkFunds(
                    build,
                    Number(building.level) + 1,
                    planetData,
                    discountBuild,
                    speedMining,
                    speedConstructionBuild
                  ) || onBuilding
                }
              >
                {t("Confirm")}
              </Button>
            </div>
          </div>
        </Flex>
      </ScifiModal>
      <ScifiModal
        title={t("Demolish")}
        isOpen={demolish}
        onRequestClose={() => setDemolish(false)}
      >
        <Demolish
          building={building}
          buildingId={Number(building.id)}
          setOpen={setDemolish}
          setParentOpen={setIsOpen}
        />
      </ScifiModal>
    </div>
  )
}

export default Upgrade
