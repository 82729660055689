import { useEffect } from "react"
import { DefaultTheme } from "utils/localStorage"
import { useThemeState } from "data/settings/Theme"

const InitTheme = () => {
  const [theme, setTheme] = useThemeState()

  useEffect(() => {
    setTheme(DefaultTheme)
  }, [theme, setTheme])

  return null
}

export default InitTheme
