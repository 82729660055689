import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import { isAuthenticated } from "data/services/authService"
import api from "../services/api"

export const useFleets = (planet: string) => {
  return useQuery(
    [queryKey.tritium.fleet.fleet],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<FleetUnit[]>(`api/fleet/${planet}`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useFleetsOnConstruct = (planet: string) => {
  return useQuery(
    [queryKey.tritium.fleet.fleetUnderconstruction],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<FleetUnitUnderConstruction[]>(
        `api/fleet/production/${planet}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}
