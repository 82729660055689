import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { ScifiButton } from "components/general"
import { GameContext } from "pages/GameContext"
import { convert } from "data/queries/challange"
import { useChallangeMission } from "data/queries/challange"
import { Flex } from "components/layout"
import { Timer } from "components/display"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import styles from "./Mercenary.module.scss"

const Challange = () => {
  const { t } = useTranslation()
  const { planetData, player } = useContext(GameContext)
  const planet = planetData instanceof Array ? planetData[0] : planetData
  const queryClient = useQueryClient()

  const mission = useChallangeMission(planet?.id || "0")

  const [isOpen, setOpen] = useState(false)

  const Balances = () => {
    return (
      <div>
        <div>{t("Balances")}</div>
        <hr />
        <div style={{ width: "100%", padding: "20px" }}>
          <img
            src={"/assets/icons/powerTritium.png"}
            alt="Yellow Tritium"
            width={25}
            style={{ float: "left" }}
          />
          {planet !== undefined && (
            <div className={styles.tritiumBalance}>{planet.yellowTrit}</div>
          )}
          <div className={styles.labelPwTrit}>Power Tritium</div>
        </div>
        <div style={{ width: "100%", padding: "20px" }}>
          <img
            src={
              "https://raw.githubusercontent.com/terra-tritium/assets/main/trit.png"
            }
            alt="Tritium"
            width={25}
            style={{ float: "left" }}
          />
          <div className={styles.tritiumBalance}>{player?.tritium}</div>
          <div className={styles.labelPwTrit}>Tritium</div>
        </div>
      </div>
    )
  }

  const ConvertMessage = () => {
    return (
      <div>
        <b>{t("Convert Tritium Power to Tritium Tokens")}</b>
        <br />
        <br />
        {t(
          "By converting now you can guarantee the 1 to 1 exchange, but your strength for the continuation of the challenge goes to zero. Remember that at the end of the challenge time, all power will be multiplied by 3 if you manage to keep them."
        )}
        <br />
        <br />
        <br />
        <div>
          <Flex>
            <ScifiButton
              onClick={() => {
                setOpen(false)
              }}
              size="small"
            >
              {t("Cancel")}
            </ScifiButton>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ScifiButton
              onClick={() => {
                convert(planet?.id || "0")
                setOpen(false)
                queryClient.invalidateQueries(queryKey.tritium.player.player)
                queryClient.invalidateQueries(queryKey.tritium.balance)
              }}
              size="small"
            >
              {t("Convert")}
            </ScifiButton>
          </Flex>
        </div>
        <br />
        <br />
      </div>
    )
  }

  return (
    <>
      <div className={styles.content} style={{ display: "flex" }}>
        <div>
          {!isOpen && <Balances />}
          {isOpen && <ConvertMessage />}
          <div>{t("Actual Mission")}</div>
          <hr />
          <br />
          <div>
            <Flex>
              {mission && mission.data && (
                <>
                  {mission.data.from && mission.data.to && (
                    <>
                      <span className={styles.tagPlanet}>
                        <img
                          src="/assets/planets/planetDesert.png"
                          alt="Rocket"
                          width={40}
                        />
                        <br />
                        {mission.data.from.name}
                        <span className={styles.legend}>
                          {" ["}
                          {mission.data.from.quadrant}
                          {":"}
                          {mission.data.from.position}
                          {"] "}
                        </span>
                      </span>
                      {" -----> "}
                      <span className={styles.tagPlanet}>
                        <img
                          src="/assets/planets/planetDesert.png"
                          alt="Rocket"
                          width={40}
                        />
                        <br />
                        {mission.data.to.name}
                        <span className={styles.legend}>
                          {" ["}
                          {mission.data.to.quadrant}
                          {":"}
                          {mission.data.to.position}
                          {"] "}
                        </span>
                      </span>
                    </>
                  )}
                  {!mission.data.from && !mission.data.to && (
                    <span>{t("No mission at moment")}</span>
                  )}
                </>
              )}
            </Flex>
            <br />
            <br />
            {mission && mission.data && mission.data.from && mission.data.to && (
              <Flex>
                <div className={styles.tag}>
                  <Timer end={mission.data.arrival * 1000} />
                </div>
              </Flex>
            )}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        {mission && mission.data && (
          <ScifiButton
            onClick={() => {
              setOpen(true)
            }}
            disabled={mission.data.from || mission.data.to}
            size="small"
          >
            {t("Convert")}
          </ScifiButton>
        )}
      </div>
    </>
  )
}

export default Challange
