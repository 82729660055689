import { useTranslation } from "react-i18next"
import { useTroops } from "data/queries/troop"
import { useTroopsOnConstruct } from "data/queries/troop"
import { Tooltip } from "@mui/material"
import { Row } from "components/layout"
import TroopOnConsturct from "./TroopOnConstruct"
import styles from "./Military.module.scss"

interface Props {
  planet: string
}

const Troop = (props: Props) => {
  const { t } = useTranslation()
  const troopData = useTroops(props.planet)
  const troopOnConsturct = useTroopsOnConstruct(props.planet)

  return (
    <div className={styles.container}>
      <Row>
        <div style={{ width: "640px" }}>
          <div className={styles.description}>
            {t("Troop stationed on this planet")}
          </div>
          <div className={styles.unitBlock}>
            {Array.isArray(troopData.data) ? (
              troopData.data &&
              troopData.data.map((element, idx) => (
                <Tooltip key={idx} title={element.unit.name}>
                  <div className={styles.unit}>
                    <img
                      className={styles.unitImg}
                      src={"/assets/units/" + element.unit.image}
                      alt={element.unit.name}
                      draggable="false"
                    />
                    <div className={styles.quantity}>{element.quantity}</div>
                  </div>
                </Tooltip>
              ))
            ) : (
              <div className={styles.msg}>{t("No troops on planet")}</div>
            )}
          </div>
        </div>
      </Row>
      <Row>
        <div style={{ width: "640px" }}>
          <div className={styles.description}>
            {t("Troop underconstruction on this planet")}
          </div>
          <div>
            <TroopOnConsturct troops={troopOnConsturct.data} type="troop" />
          </div>
        </div>
      </Row>
    </div>
  )
}

export default Troop
