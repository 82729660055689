import { useQuery } from "react-query"
import { queryKey, RefetchOptions } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const useDisponibleAliances = () => {
  return useQuery(
    [queryKey.tritium.aliances.disponibles],
    async () => {
      const { data } = await api.get<Aliances[]>("api/aliance/list", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const useAliancesChat = () => {
  return useQuery(
    [queryKey.tritium.aliances.listAliance],
    async () => {
      const { data } = await api.get<AlianceInteracao[]>(
        "api/aliance/list-aliance-chat",
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

///ranks
export const useGetRanks = () => {
  return useQuery(
    [queryKey.tritium.aliances.ranks],
    async () => {
      const { data } = await api.get<RanksMember[]>("api/aliance/ranks", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const useGetMessagesGroup = (idAliance: number) => {
  return useQuery(
    [queryKey.tritium.aliances.messagesGroup],
    async () => {
      const { data } = await api.get<MessageChatAliance[]>(
        `api/aliance/chat/${idAliance}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const useGetMyAliancForEdit = () => {
  return useQuery(
    [queryKey.tritium.aliances.myAliance],
    async () => {
      const { data } = await api.get<Aliances>("api/aliance/my-aliance", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const createAlliance = (aliance: Aliance) => {
  return api.post<Return>("aliances/create", aliance)
}

export const deleteAlliance = (idAliance: string) => {
  return api.delete<Return>(TRITIUM + `/api/aliance/delete/${idAliance}`)
}

export const salvarNewAlliance = (aliance: AlianceBase) => {
  return api.post<Return>(TRITIUM + `/api/aliance/create`, aliance)
}

export const atualizarAlliance = (aliance: AlianceBase) => {
  return api.patch<Return>(TRITIUM)
}
export const joinAlliance = (idAliance: string) => {
  return api.post<Return>(TRITIUM + `/api/aliance/join`, {
    alianca_id: idAliance,
  })
}

export const getMyAliance = () => {
  return api.get<Aliance>(TRITIUM + `/api/aliance/my-aliance`)
}
export const getMyAlianceDetails = () => {
  return api.get<DetailsMyAliance>(TRITIUM + `/api/aliance/myaliance/details`)
}
export const getMembersAliance = (alianceId: number) => {
  return api.get<DetailsMyAliance[]>(
    TRITIUM + `/api/aliance/members/${alianceId}`
  )
}
export const getMembersPending = (alianceId: number) => {
  return api.get<MemberRequest[]>(
    TRITIUM + `/api/aliance/members/pending/${alianceId}`
  )
}
export const getRanksMembers = (alianceId: number) => {
  return api.get<DatailRankMember[]>(`/api/aliance/members-rank/${alianceId}`)
}
export const getNameAvailable = (name: string) => {
  return api.get<Return>(TRITIUM + `/api/aliance/available-name/${name}`)
}
export const removeMember = (id: number) => {
  return api.patch<Return>(TRITIUM + `/api/aliance/member/remove/${id}`)
}
export const deleteMessage = (idMessage: number) => {
  return api.put<Return>(TRITIUM + `/api/aliance/chat/${idMessage}`)
}
export const deixarPatente = (idAliance: number, idMember: number) => {
  return api.put<Return>(
    TRITIUM + `/api/aliance/relinquish-rank/${idAliance}/${idMember}`
  )
}
export const newMessageChat = (messageChatAliance: MessageChatAliance) => {
  return api.post<Return>(TRITIUM + `/api/aliance/chat`, {
    messageChatAliance,
  })
}

export const newMessageChatWinthinAliance = (
  message: MessageWithinAliances
) => {
  return api.post<Return>(TRITIUM + `/api/aliance/chat-aliance`, message)
}
export const getMessagesWithAliance = (idDestino: number) => {
  return api.get<MessageWithinAliances[]>(
    TRITIUM + `/api/aliance/chat-with-aliance/${idDestino}`
  )
}

export const getUnitsMember = (playerId: number, type: string) => {
  return api.get<UnitDetails[]>(
    TRITIUM + `/api/aliance/member/units/${playerId}/${type}`
  )
}
export const changeRankMember = (
  idRank: number,
  idMember: number,
  idAliance: number
) => {
  return api.put<Return>(
    TRITIUM + `/api/aliance/change-rank/${idRank}/${idMember}/${idAliance}`
  )
}
export const exit = (idAliance: string) => {
  return api.put<Return>(TRITIUM + `/api/aliance/exit/${idAliance}`)
}
export const cancelRequest = () => {
  return api.put<Return>(TRITIUM + `/api/aliance/cancel-request`)
}
export const responseRequestMember = (
  idMemberRequest: number,
  action: string
) => {
  return api.put<Return>(
    TRITIUM + `/api/aliance/member/update-request/${idMemberRequest}/${action}`
  )
}
export const getScoresAliance = () => {
  return api.get<Return>(TRITIUM + `/api/aliance/scores`)
}

export const updateAlliance = ({
  id,
  alliance,
}: {
  id: string
  alliance: Alliance
}) => {
  api.put(`alliances/edit/${id}`, alliance)
}

// export const deleteAlliance = (id: string) => {
//   api.delete(`alliances/delete/${id}`)
// }

export const updateLogo = ({ id, logo }: { id: string; logo: Logo }) => {
  api.put(`alliances/update-logo/${id}`, logo)
}

// export const getAllLogos = () =>{
//   return api.get<Logo>(TRITIUM + `/api/aliance/logos`);
// }

export const useGetAllLogos = () => {
  return useQuery(
    [queryKey.tritium.aliances.disponibles],
    async () => {
      const { data } = await api.get<Logo[]>("/api/aliance/logos", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const listSearchPlayer = (user: string) => {
  return api.get<MemberSearch[]>(TRITIUM + `/api/aliance/search-usuer/${user}`)
}

export const invite = (idPlayer: number, idAliance: number) => {
  return api.post<Return>(TRITIUM + `/api/aliance/invite`, {
    idPlayer: idPlayer,
    idAliance: idAliance,
  })
}
//invite
export const acceptInvite = (idInvite: number) => {
  return api.post<Return>(TRITIUM + `/api/aliance/invite-accepted`, {
    idInvite,
  })
}
export const useGetReceivedInvitations = () => {
  return useQuery(
    [queryKey.tritium.aliances.getInvites],
    async () => {
      const { data } = await api.get<ReceivedInvation[]>(
        "/api/aliance/invite",
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}
