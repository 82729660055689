import {
  deleteAlliance,
  exit,
  getMyAlianceDetails,
  getScoresAliance,
} from "data/queries/aliance"
import styles from "./AlianceDetail.module.scss"
import disableScroll from "disable-scroll"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import ExitIcon from "@mui/icons-material/Logout"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ChatIcon from "@mui/icons-material/Chat"
import { LoadingCircular, Modal } from "components/feedback"
import { Button } from "components/general"
import {
  TRITIUM_CHAT_ALIANCE,
  TRITIUM_CHAT_OTHER_ALIANCE,
  TRITIUM_MEMBER_DIPLOMAT,
  TRITIUM_MEMBER_FOUNDER,
} from "config/constants"
import AlianceChat from "./AlianceChat"

interface EditAlianceMenuProps {
  alianceId: string
  handleOptionEditAliance: (newScreen: number) => void
}
const EditAlianceMenu = (EditAlianceMenu: EditAlianceMenuProps) => {
  const { alianceId, handleOptionEditAliance } = EditAlianceMenu
  const editarAlianca = (alianceId: string) => {
    handleOptionEditAliance(4)
  }
  return (
    <div
      className={styles.editAliance}
      onClick={() => editarAlianca(alianceId)}
    >
      <EditOutlinedIcon />
    </div>
  )
}
interface OptionMenu {
  label: string
  value: number
}
interface AlianceProps {
  aliance: Aliance
  handleOptionEditAliance: (newScreen: number) => void
}
const Details = (AlianceProps: AlianceProps) => {
  const { t } = useTranslation()
  const { aliance, handleOptionEditAliance } = AlianceProps
  const [alianceMemberDetail, setAlianceMemberDetail] = useState<
    DetailsMyAliance | undefined
  >()
  const [confirmAction, setConfirmAction] = useState<boolean>(false)
  const [action, setAction] = useState("dell")
  const [loadDetail, setLoadDetail] = useState(true)
  const [screenMsg, setScreenMsg] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [typeChat, setTypeChat] = useState(TRITIUM_CHAT_ALIANCE)

  const optionMenu: OptionMenu[] = [
    { label: t("Chat Alliance"), value: TRITIUM_CHAT_ALIANCE },
    { label: t("Chat Others"), value: TRITIUM_CHAT_OTHER_ALIANCE },
  ]

  const handleOptionSelect = (option: OptionMenu) => {
    setTypeChat(option.value)
    setScreenMsg(!screenMsg)
    setIsOpen(false)
  }
  const moreChatOption = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    getScoresAliance()
      .then((res) => {
        // console.log("aliancas", res)
      })
      .catch((e) => console.log("erro ao recuperar dados das aliancas", e))
  }, [])

  useEffect(() => {
    setLoadDetail(true)
    getMyAlianceDetails()
      .then((res) => {
        setAlianceMemberDetail(res.data)
      })
      .catch((er) => console.log("detail", er))
      .finally(() => setLoadDetail(false))
  }, [aliance])

  const handleDeleteAliance = async (idAliance: string) => {
    deleteAlliance(idAliance)
      .then((res) => handleOptionEditAliance(0))
      .catch((er) => console.log("erro ao delatar alianca ", er))
  }
  const handleDeleteAlianceConfirm = async (idAliance: string) => {
    setAction("dell")
    setConfirmAction(true)
  }
  const handleExitConfirm = async (idAliance: string) => {
    setAction("exit")
    setConfirmAction(true)
  }
  const handleExit = async (idAliance: string) => {
    await exit(idAliance)
      .then((res) => {
        handleOptionEditAliance(0)
      })
      .catch((e) => console.log("error", e))
  }

  const modalConfirm = !confirmAction
    ? undefined
    : {
        title: t("Confirmar"),
        children: (
          <section>
            <section>{t("Do you really want to leave the alliance?")}</section>
          </section>
        ),
      }

  return (
    <>
      <div className={styles.message}>
        {screenMsg ? (
          <div onClick={() => setScreenMsg(!screenMsg)}>
            <ArrowBackIcon />
          </div>
        ) : (
          <>
            {[TRITIUM_MEMBER_DIPLOMAT, TRITIUM_MEMBER_FOUNDER].includes(
              alianceMemberDetail?.idRank!
            ) ? (
              <div
                className={styles.dropdown}
                onMouseOver={() => setIsOpen(true)}
                onMouseOut={() => setIsOpen(false)}
              >
                <div
                  className={styles.moreIcon}
                  onClick={() => moreChatOption()}
                >
                  <ChatIcon />
                  {isOpen && (
                    <ul className={styles.dropdownMenu}>
                      {optionMenu.map((option) => (
                        <li
                          key={option.value}
                          onClick={() => handleOptionSelect(option)}
                        >
                          {t(`${option.label}`)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ) : (
              <div onClick={() => setScreenMsg(!screenMsg)}>
                <ChatIcon />
              </div>
            )}
          </>
        )}
      </div>
      {screenMsg && alianceMemberDetail && (
        <>
          <AlianceChat
            idAliance={alianceMemberDetail?.idAliance!}
            idRank={alianceMemberDetail?.idRank!}
            player_id={alianceMemberDetail?.player_id!}
            typeChat={typeChat}
          />

          {/* <Chat destinationId={1}/> */}
        </>
      )}
      {!screenMsg && (
        <>
          {alianceMemberDetail?.idRank === TRITIUM_MEMBER_FOUNDER && (
            <>
              <EditAlianceMenu
                alianceId={aliance.id}
                handleOptionEditAliance={handleOptionEditAliance}
              />
            </>
          )}
          <div className={styles.details} style={{ height: "150px" }}>
            <div className={styles.AliancDetailLogo}>
              <img
                style={{ borderRadius: "5px" }}
                src={`/assets/aliancelogo/fill/${aliance.logo}`}
                width={90}
                alt={t("logo")}
              />
            </div>
            <div
              onWheel={(e) => {
                disableScroll.off()
              }}
              onMouseOut={(e) => {
                disableScroll.on()
              }}
              className={styles.alianceDetailDescription}
            >
              <span>{aliance.description}</span>
            </div>
          </div>
          {/* detalhes */}
          {loadDetail && <LoadingCircular />}
          {alianceMemberDetail && (
            <>
              <div className={`${styles.details} ${styles.spaceTop}`}>
                <div
                  className={`${styles.AliancDetailLogo} ${styles.titleDetail}`}
                >
                  {t("Rank")}
                </div>
                <div className={styles.alianceDetailDescription}>
                  <span>{t(alianceMemberDetail.role)}</span>
                </div>
              </div>
              <div className={`${styles.details} ${styles.spaceTop}`}>
                <div
                  className={`${styles.AliancDetailLogo} ${styles.titleDetail}`}
                >
                  {t("Since")}
                </div>
                <div className={styles.alianceDetailDescription}>
                  <span>
                    {alianceMemberDetail.dateAdmission?.toString().slice(0, 10)}
                  </span>
                </div>
              </div>
              <div className={`${styles.details} ${styles.spaceTop}`}>
                <div
                  className={`${styles.AliancDetailLogo} ${styles.titleDetail}`}
                >
                  {t("Created")}
                </div>
                <div className={styles.alianceDetailDescription}>
                  <span>
                    {alianceMemberDetail.createdAt.toString().slice(0, 10)}
                  </span>
                </div>
              </div>
              <div className={`${styles.details} ${styles.spaceTop}`}>
                <div
                  className={`${styles.AliancDetailLogo} ${styles.titleDetail}`}
                >
                  {t("Open")}
                </div>
                <div className={styles.alianceDetailDescription}>
                  <span>
                    {alianceMemberDetail.statusAliance === "A"
                      ? t("Yes")
                      : t("No")}
                  </span>
                </div>
              </div>
              <div className={`${styles.details} ${styles.spaceTop}`}>
                <div
                  className={`${styles.AliancDetailLogo} ${styles.titleDetail}`}
                >
                  {t("Members")}
                </div>
                <div className={styles.alianceDetailDescription}>
                  <span>{alianceMemberDetail.countMembers}</span>
                </div>
              </div>
              {alianceMemberDetail.idRank === TRITIUM_MEMBER_FOUNDER && (
                <div className={`${styles.details} ${styles.spaceTop}`}>
                  <label className={styles.labelAliance} htmlFor="btn"></label>
                  <br />
                  <button
                    className={styles.btnAlianceDelete}
                    disabled={false}
                    onClick={() => handleDeleteAlianceConfirm(aliance.id)}
                    type="button"
                  >
                    {t("Delete")}
                    <DeleteOutlineOutlinedIcon fontSize={"small"} />
                  </button>
                </div>
              )}
              {alianceMemberDetail.idRank !== TRITIUM_MEMBER_FOUNDER && (
                <div className={`${styles.details} ${styles.spaceTop}`}>
                  <label className={styles.labelAliance} htmlFor="btn"></label>
                  <br />
                  <button
                    className={styles.btnAlianceDelete}
                    disabled={false}
                    onClick={() => handleExitConfirm(aliance.id)}
                    type="button"
                  >
                    {t("Exit")} &nbsp; &nbsp;
                    <ExitIcon fontSize={"small"} />
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
      {confirmAction && (
        <Modal
          {...modalConfirm}
          icon={<DoneAllIcon fontSize="inherit" className="success" />}
          footer={() => (
            <Button
              color="primary"
              onClick={() => {
                // setSuccess(false)
                if (action === "dell") {
                  handleDeleteAliance(aliance.id)
                }
                if (action === "exit") {
                  handleExit(aliance.id)
                }
                setConfirmAction(false)
              }}
              block
            >
              {t("Yes")}
            </Button>
          )}
          onRequestClose={() => {
            // setSuccess(false)
            setConfirmAction(false)
          }}
          isOpen
        />
      )}
    </>
  )
}
export default Details
