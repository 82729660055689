import { PropsWithChildren } from "react"
import { Page, Banner } from "components/layout"
import styles from "./Login.module.scss"

interface Props {
  title?: string
}

const DefaultLoginTheme = (props: PropsWithChildren<Props>) => {
  const { children } = props

  return (
    <div className={styles.page}>
      <Page>
        <Banner>Lobby</Banner>
        <div className={styles.content}>
          <img className={styles.logo} src="/tritium.png" alt="Tritium" />
          <div className={styles.block}>
            <div className={styles.scifi}>
              <div className={styles.helper1} />
              <div className={styles.panel}>{children}</div>
            </div>
          </div>
        </div>
      </Page>
    </div>
  )
}

export default DefaultLoginTheme
