import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useFigthers } from "data/queries/combat"
import { useParams } from "react-router-dom"
import { useCombatLogs } from "data/queries/combat"
import { usePlayer } from "data/queries/player"
import { leaveCombat } from "data/queries/combat"
import { useCurrentCombat } from "data/queries/combat"
import { Row, Col } from "components/layout"
import { styled } from "@mui/material/styles"
import { ScifiModal } from "components/feedback"
import { ScifiButton } from "components/general"
import WinnerIMG from "styles/images/badges/colonizer.png"
import CombatControl from "components/combat/CombatControl"
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress"
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"
import ShieldIcon from "@mui/icons-material/Shield"
import ComputeShip from "./ComputeShip"
import styles from "./Figthers.module.scss"

interface Props {
  id?: string
  selectedShip: any
  setFinished: any
  openLeave: boolean
  setOpenLeave: any
}

const Fighters = (props: Props) => {
  const { t } = useTranslation()
  const { combat } = useParams()
  const player = usePlayer()
  const currentCombat = useCurrentCombat(combat || "0")
  const combatLogs = useCombatLogs(combat || "0")
  //const navigate = useNavigate()
  const figthers = useFigthers(combat || "999")

  const invaders: any[] = []
  const locals: any[] = []

  const scrollableListRef = useRef<HTMLDivElement>(null)

  const manualWhell = (e: any) => {
    const container = scrollableListRef.current
    if (container) {
      if (e.deltaY > 0) {
        container.scrollTop += 60
      } else {
        container.scrollTop -= 60
      }
    }
  }

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 6,
    borderRadius: 20,
    padding: 0,
    margin: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#061f42",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 20,
      backgroundColor: "#5850B4",
    },
  }))

  const BorderCircularProgress = styled(CircularProgress)(() => ({
    [`& .${circularProgressClasses.circle}`]: {
      color: "#5850B4",
      strokeLinecap: "round",
    },
  }))

  const BorderCircularProgressBack = styled(CircularProgress)(() => ({
    [`& .${circularProgressClasses.circle}`]: {
      color: "#061f42",
    },
  }))

  let mySide = 1
  let invadersCrafts = 0
  let invadersBombers = 0
  let invadersCruisers = 0
  let invadersScout = 0
  let invadersStealth = 0
  let invadersFlagship = 0

  let winner = "none"
  let totalInvaders = 1
  let totalLocals = 1

  let invasorStrategyId = 0
  let localStrategyId = 0
  let transportShip = 0

  if (player.data) {
    figthers.data?.map((figther) => {
      if (figther.playerId === Number(player.data.id)) {
        localStrategyId = figther.strategy
        transportShip = figther.transportShips
      }

      if (figther.playerId === Number(player.data.id) && figther.side === 1) {
        mySide = 2
      } else {
        invasorStrategyId = figther.strategy
      }
      return 0
    })
  }

  console.log(mySide)

  figthers.data?.map((figther) => {
    if (figther.side === mySide) {
      locals.push(figther)
    } else {
      invaders.push(figther)
    }
    return 0
  })

  invaders.map((invader) => {
    invadersCrafts += invader.craft
    invadersBombers += invader.bomber
    invadersCruisers += invader.cruiser
    invadersScout += invader.scout
    invadersStealth += invader.stealth
    invadersFlagship += invader.flagship
    totalInvaders +=
      invader.craft +
      invader.bomber +
      invader.cruiser +
      invader.scout +
      invader.stealth +
      invader.flagship -
      1
    return 0
  })

  locals.map((local) => {
    totalLocals +=
      local.craft +
      local.bomber +
      local.cruiser +
      local.scout +
      local.stealth +
      local.flagship -
      1
    return 0
  })

  if (totalInvaders === 0) {
    winner = "locals"
    props.setFinished(true)
  } else {
    props.setFinished(false)
  }

  if (totalLocals === 0) {
    winner = "invaders"
    props.setFinished(true)
  } else {
    props.setFinished(false)
  }

  return (
    <>
      <>
        <div className={styles.sideA}>
          <Row>
            <div className={styles.playersB}>
              {figthers.data &&
                figthers.data.map((fighter, index) => (
                  <>
                    {fighter.side === mySide && (
                      <>
                        <span key={index} className={styles.playerB}>
                          <ShieldIcon />
                          <>{fighter.player} &nbsp;&nbsp;</>
                        </span>
                      </>
                    )}
                  </>
                ))}
            </div>
          </Row>
          <Row>
            <Col span={2}>
              <div className={styles.info}>
                <span>
                  <BorderCircularProgressBack
                    style={{ position: "absolute", left: "26px" }}
                    variant="determinate"
                    size={60}
                    thickness={5}
                    value={100}
                  />
                  <BorderCircularProgress
                    style={{ position: "absolute", left: "26px" }}
                    variant="determinate"
                    size={60}
                    thickness={5}
                    value={70}
                  />
                </span>
                <span>
                  <BorderCircularProgressBack
                    style={{ position: "absolute", left: "100px" }}
                    variant="determinate"
                    size={60}
                    thickness={5}
                    value={100}
                  />
                  <BorderCircularProgress
                    style={{ position: "relative", left: "78px" }}
                    variant="determinate"
                    size={60}
                    thickness={5}
                    value={40}
                  />
                </span>
                <span className={styles.labelGeralA2}>{t("Attack")}</span>
                <span className={styles.labelGeralD2}>{t("Defense")}</span>
                <span className={styles.numberGeralA2}>070</span>
                <span className={styles.numberGeralD2}>040</span>
              </div>
            </Col>
            <Col span={3}>
              <div className={styles.info}>
                {invasorStrategyId > 0 && (
                  <img
                    src={
                      "/assets/combat/strategy/strategy" +
                      invasorStrategyId +
                      ".png"
                    }
                    alt="strategy"
                    width={50}
                    style={{ float: "left", marginRight: "10px" }}
                  />
                )}

                <span style={{ fontWeight: "bold" }}>
                  <>
                    {invasorStrategyId === 1 && "Line"}
                    {invasorStrategyId === 2 && "Sniper"}
                    {invasorStrategyId === 3 && "Guerrilha"}
                    {invasorStrategyId === 4 && "Diamond"}
                    {invasorStrategyId === 5 && "Wedge"}
                    {invasorStrategyId === 6 && "Star"}
                    {invasorStrategyId === 7 && "Delta"}
                    {invasorStrategyId === 8 && "Diagonal"}
                    {invasorStrategyId === 9 && "Column"}
                    {invasorStrategyId === 10 && "Dual Column"}
                    {invasorStrategyId === 11 && "Flanks"}
                  </>
                </span>
                <p style={{ fontSize: "10px" }}>
                  Strategy description lorem ipsun
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <div className={styles.control}>
              <CombatControl currentCombat={currentCombat} />
            </div>
          </Row>
        </div>

        <div className={styles.sideB}>
          <Row>
            <div style={{ float: "none" }}>
              <ComputeShip
                qtd={invadersCrafts}
                image="/assets/ships/ship-01.png"
                name="Craft"
                selectedShip={props.selectedShip}
              />
              <ComputeShip
                qtd={invadersBombers}
                image="/assets/ships/ship-02.png"
                name="Bomber"
                selectedShip={props.selectedShip}
              />
              <ComputeShip
                qtd={invadersCruisers}
                image="/assets/ships/ship-03.png"
                name="Cruiser"
                selectedShip={props.selectedShip}
              />
              <ComputeShip
                qtd={invadersScout}
                image="/assets/ships/ship-04.png"
                name="Scout"
                selectedShip={props.selectedShip}
              />
              <ComputeShip
                qtd={invadersStealth}
                image="/assets/ships/ship-05.png"
                name="Stealth"
                selectedShip={props.selectedShip}
              />
              <ComputeShip
                qtd={invadersFlagship}
                image="/assets/ships/ship-06.png"
                name="Flagship"
                selectedShip={props.selectedShip}
              />
              {/* <ComputeShip
                qtd={invadersScout}
                image="/assets/ships/ship-04.png"
                name="Scout"
                selectedShip={props.selectedShip}
              />
              <ComputeShip
                qtd={invadersScout}
                image="/assets/ships/ship-04.png"
                name="Scout"
                selectedShip={props.selectedShip}
              /> */}
            </div>
          </Row>
          <Row>
            <div className={styles.separator}></div>
          </Row>

          <div className={styles.players}>
            {figthers.data &&
              figthers.data.map((fighter, index) => (
                <>
                  {fighter.side !== mySide && (
                    <>
                      <div className={styles.player}>
                        <Row>
                          <Col span={5}>
                            <div>
                              <span className={styles.logoAliance}>
                                <ShieldIcon />
                              </span>
                              <span className={styles.playerName} key={index}>
                                <>{fighter.player} &nbsp;&nbsp;</>
                              </span>
                            </div>
                          </Col>
                          <Col span={1}>
                            <div
                              className={styles.label}
                              style={{
                                position: "absolute",
                                paddingTop: "4px",
                              }}
                            >
                              {t("Attack")}
                            </div>
                            <div
                              className={styles.label}
                              style={{
                                position: "relative",
                                top: "18px",
                              }}
                            >
                              {t("Defense")}
                            </div>
                          </Col>
                          <Col span={3}>
                            <span
                              style={{
                                position: "relative",
                                top: "6px",
                                left: "-18px",
                              }}
                            >
                              <BorderLinearProgress
                                variant="determinate"
                                value={50}
                              />
                            </span>
                            <span
                              style={{
                                position: "relative",
                                top: "-6px",
                                left: "-18px",
                                width: "150px",
                              }}
                            >
                              <BorderLinearProgress
                                variant="determinate"
                                value={50}
                              />
                            </span>
                          </Col>
                          <Col span={1}>
                            {fighter.player === player.data?.name &&
                              winner === "none" && (
                                <span
                                  style={{ padding: "4px", cursor: "pointer" }}
                                  onClick={() => {
                                    props.setOpenLeave(true)
                                  }}
                                >
                                  <img
                                    width={26}
                                    src="/assets/actions/leave.png"
                                    alt="leave"
                                    style={{ filter: "grayscale(100%)" }}
                                  />
                                </span>
                              )}
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </>
              ))}
          </div>

          <Row>
            <Col span={6}>
              <div className={styles.info}>
                <span>
                  <BorderCircularProgressBack
                    style={{ position: "absolute", left: "26px" }}
                    variant="determinate"
                    size={60}
                    thickness={5}
                    value={100}
                  />
                  <BorderCircularProgress
                    style={{ position: "absolute", left: "26px" }}
                    variant="determinate"
                    size={60}
                    thickness={5}
                    value={70}
                  />
                </span>
                <span>
                  <BorderCircularProgressBack
                    style={{ position: "absolute", left: "100px" }}
                    variant="determinate"
                    size={60}
                    thickness={5}
                    value={100}
                  />
                  <BorderCircularProgress
                    style={{ position: "relative", left: "78px" }}
                    variant="determinate"
                    size={60}
                    thickness={5}
                    value={40}
                  />
                </span>
                <span className={styles.labelGeralA}>{t("Attack")}</span>
                <span className={styles.labelGeralD}>{t("Defense")}</span>
                <span className={styles.numberGeralA}>070</span>
                <span className={styles.numberGeralD}>040</span>
              </div>
            </Col>
            <Col span={6}>
              <div className={styles.info}>
                {localStrategyId > 0 && (
                  <img
                    src={
                      "/assets/combat/strategy/strategy" +
                      localStrategyId +
                      ".png"
                    }
                    alt="strategy"
                    width={40}
                    style={{ float: "left", marginRight: "10px" }}
                  />
                )}

                <span style={{ fontWeight: "bold" }}>
                  <>
                    {localStrategyId === 1 && "Line"}
                    {localStrategyId === 2 && "Sniper"}
                    {localStrategyId === 3 && "Guerrilha"}
                    {localStrategyId === 4 && "Diamond"}
                    {localStrategyId === 5 && "Wedge"}
                    {localStrategyId === 6 && "Star"}
                    {localStrategyId === 7 && "Delta"}
                    {localStrategyId === 8 && "Diagonal"}
                    {localStrategyId === 9 && "Column"}
                    {localStrategyId === 10 && "Dual Column"}
                    {localStrategyId === 11 && "Flanks"}
                  </>
                </span>
                <p style={{ fontSize: "10px" }}>
                  + 10% {t("Attack")}
                  <br />- 10% {t("Defense")}
                </p>
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.info}>
                <span
                  style={{
                    fontSize: "9px",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {"TransportShip"}
                </span>
                <span className={styles.transportNumber}>{transportShip}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <div
              className={styles.info}
              style={{ maxHeight: "120px", overflow: "hidden" }}
              ref={scrollableListRef}
              onWheel={manualWhell}
            >
              {combatLogs.data &&
                combatLogs.data.map((cLog, index) => (
                  <div key={index} className={styles.log}>
                    <span>{cLog.number} - </span>
                    {cLog.message}
                  </div>
                ))}
            </div>
          </Row>
        </div>
      </>

      {winner === "invaders" && (
        <div>
          <div className={styles.message}>
            <ScifiButton
            /*onClick={() => {
                navigate("/game/planet/" + currentCombat.data?.planet)
              }}*/
            >
              <img src={WinnerIMG} width={100} alt="winner" />
              <br />
              <br />
              {t("Plundered Planet")}
              <br />
              <br />
            </ScifiButton>
          </div>
        </div>
      )}

      {winner === "locals" && (
        <div>
          <div className={styles.message}>
            <ScifiButton
            /*onClick={() => {
                navigate("/game/planet/" + currentCombat.data?.planet)
              }}*/
            >
              <img src={WinnerIMG} width={100} alt="winner" />
              <br />
              <br />
              {t("planet defended")}
              <br />
              <br />
            </ScifiButton>
          </div>
        </div>
      )}

      <div style={{ zIndex: "1" }}>
        <ScifiModal
          isOpen={props.openLeave}
          onRequestClose={() => {
            props.setOpenLeave(false)
          }}
        >
          <div style={{ textAlign: "center" }}>
            <br />
            <br />
            {t("Do you want to leave the combat?")}
            <br />
            <br />
            <div>
              <ScifiButton
                style={{ margin: "10px" }}
                onClick={() => {
                  props.setOpenLeave(false)
                }}
              >
                {t("No")}
              </ScifiButton>
              <ScifiButton
                style={{ margin: "10px" }}
                onClick={() => {
                  props.setOpenLeave(false)
                  leaveCombat(combat || "0")
                }}
              >
                {t("Yes")}
              </ScifiButton>
            </div>
          </div>
        </ScifiModal>
      </div>
    </>
  )
}

export default Fighters
