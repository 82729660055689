import { useState, useRef } from "react"
import { useTroops } from "data/queries/troop"
import { useTranslation } from "react-i18next"
// import { startTravel } from "data/queries/travel"
import { ScifiButton } from "components/general"
import { Button } from "components/general"
// import { registerLog } from "data/queries/log"
import styles from "./SendTroops.module.scss"
// import { useAvailableShip } from "data/queries/combat"

interface Props {
  setAction: any
  target: string
  setOpen: any
}

const SendTroops = (props: Props) => {
  const { t } = useTranslation()
  const planetId = localStorage.getItem("planet")
  const troopData = useTroops(String(planetId))
  const [troops, setTroops] = useState<any>([])

  // const availableShip = useAvailableShip()
  // console.log('availabe',availableShip)
  /**se nao possui naves disponível, disabled será true */
  // const [disabled, setDisabled] = useState<boolean>(
  //   !availableShip.data || !availableShip.data.ships
  //     ? true
  //     : availableShip && availableShip.data.ships === 0
  //     ? true
  //     : false
  // )

  const refInput0 = useRef<HTMLInputElement>(null)
  const refInput1 = useRef<HTMLInputElement>(null)
  const refInput2 = useRef<HTMLInputElement>(null)
  const refInput3 = useRef<HTMLInputElement>(null)
  const refInput4 = useRef<HTMLInputElement>(null)
  const refInput5 = useRef<HTMLInputElement>(null)
  const refInput6 = useRef<HTMLInputElement>(null)
  const refInput7 = useRef<HTMLInputElement>(null)
  const refInput8 = useRef<HTMLInputElement>(null)
  const refInput9 = useRef<HTMLInputElement>(null)
  const refInput10 = useRef<HTMLInputElement>(null)
  const refInput11 = useRef<HTMLInputElement>(null)
  const refInput12 = useRef<HTMLInputElement>(null)
  const refInput13 = useRef<HTMLInputElement>(null)
  const refInput14 = useRef<HTMLInputElement>(null)
  const refInput15 = useRef<HTMLInputElement>(null)

  function getRefInput(id: number) {
    switch (id) {
      case 0:
        return refInput0
      case 1:
        return refInput1
      case 2:
        return refInput2
      case 3:
        return refInput3
      case 4:
        return refInput4
      case 5:
        return refInput5
      case 6:
        return refInput6
      case 7:
        return refInput7
      case 8:
        return refInput8
      case 9:
        return refInput9
      case 10:
        return refInput10
      case 11:
        return refInput11
      case 12:
        return refInput12
      case 13:
        return refInput13
      case 14:
        return refInput14
      case 15:
        return refInput15
    }
  }

  function reset() {
    refInput0.current && (refInput0.current.value = "0")
    refInput1.current && (refInput1.current.value = "0")
    refInput2.current && (refInput2.current.value = "0")
    refInput3.current && (refInput3.current.value = "0")
    refInput4.current && (refInput4.current.value = "0")
    refInput5.current && (refInput5.current.value = "0")
    refInput6.current && (refInput6.current.value = "0")
    refInput7.current && (refInput7.current.value = "0")
    refInput8.current && (refInput8.current.value = "0")
    refInput9.current && (refInput9.current.value = "0")
    refInput10.current && (refInput10.current.value = "0")
    refInput11.current && (refInput11.current.value = "0")
    refInput12.current && (refInput12.current.value = "0")
    refInput13.current && (refInput13.current.value = "0")
    refInput14.current && (refInput14.current.value = "0")
    refInput15.current && (refInput15.current.value = "0")
    troopData &&
      troopData.data &&
      troopData.data.map((element, idx) => {
        troops[idx] = {
          unit: Number(element.unit.id),
          quantity: 0,
        }
        setTroops(troops)
        // setDisabled(true)
        return null
      })
  }

  function all() {
    refInput0.current &&
      (refInput0.current.value = getRefInput(0)?.current?.max || "0")
    refInput1.current &&
      (refInput1.current.value = getRefInput(1)?.current?.max || "0")
    refInput2.current &&
      (refInput2.current.value = getRefInput(2)?.current?.max || "0")
    refInput3.current &&
      (refInput3.current.value = getRefInput(3)?.current?.max || "0")
    refInput4.current &&
      (refInput4.current.value = getRefInput(4)?.current?.max || "0")
    refInput5.current &&
      (refInput5.current.value = getRefInput(5)?.current?.max || "0")
    refInput6.current &&
      (refInput6.current.value = getRefInput(6)?.current?.max || "0")
    refInput7.current &&
      (refInput7.current.value = getRefInput(7)?.current?.max || "0")
    refInput8.current &&
      (refInput8.current.value = getRefInput(8)?.current?.max || "0")
    refInput9.current &&
      (refInput9.current.value = getRefInput(9)?.current?.max || "0")
    refInput10.current &&
      (refInput10.current.value = getRefInput(10)?.current?.max || "0")
    refInput11.current &&
      (refInput11.current.value = getRefInput(11)?.current?.max || "0")
    refInput12.current &&
      (refInput12.current.value = getRefInput(12)?.current?.max || "0")
    refInput13.current &&
      (refInput13.current.value = getRefInput(13)?.current?.max || "0")
    refInput14.current &&
      (refInput14.current.value = getRefInput(14)?.current?.max || "0")
    refInput15.current &&
      (refInput15.current.value = getRefInput(15)?.current?.max || "0")
    troopData &&
      troopData.data &&
      troopData.data.map((element, idx) => {
        troops[idx] = {
          unit: Number(element.unit.id),
          quantity: element.quantity,
        }
        setTroops(troops)
        // setDisabled(false)
        return null
      })
  }

  // function isEmpty() {
  //   let empty = true
  //   troops &&
  //     troops.map((element: any) => {
  //       if (element.quantity > 0) {
  //         empty = false
  //       }
  //       return null
  //     })
  //   return empty
  // }

  return (
    <>
      <div className={styles.options}>
        <Button onClick={() => reset()} size="small">
          {t("Reset")}
        </Button>
        <Button onClick={() => all()} size="small">
          {t("All")}
        </Button>
      </div>
      <div className={styles.troop}>
        {troops &&
          troopData &&
          troopData.data &&
          troopData.data.map((element, idx) => (
            <div key={idx}>
              <div className={styles.unit}>
                <div
                  onClick={() => {
                    troops[idx] = {
                      unit: Number(element.unit.id),
                      quantity: element.quantity,
                    }
                    const elementRef = getRefInput(idx)
                    if (elementRef && elementRef.current) {
                      elementRef.current.value = String(element.quantity)
                    }
                    setTroops(troops)
                    // setDisabled(isEmpty())
                  }}
                >
                  <img
                    className={styles.unitImg}
                    src={"/assets/units/" + element.unit.image}
                    alt={element.unit.name}
                    draggable="false"
                  />
                </div>
                <div className={styles.quantity}>
                  <input
                    id={element.unit.id ? element.unit.id.toString() : ""}
                    name={"unit" + element.unit.id}
                    className={styles.inputCustom}
                    type="number"
                    max={element.quantity}
                    min={0}
                    ref={getRefInput(idx)}
                    onChange={(e) => {
                      let value = Number(e.target.value)
                      if (!(value >= 0 && value <= element.quantity)) {
                        e.target.value = String(element.quantity)
                        value = element.quantity
                      }
                      troops[idx] = {
                        unit: Number(element.unit.id),
                        quantity: value,
                      }
                      setTroops(troops)
                      // setDisabled(isEmpty())
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        {troopData.data && troopData.data.length <= 0 && (
          <div className={styles.msg}></div>
        )}
      </div>

      <div className={styles.actions}>
        <ScifiButton
          onClick={() => {
            props.setAction("default")
          }}
        >
          {t("Cancel")}
        </ScifiButton>
        {/* <ScifiButton
          disabled={disabled}
          onClick={() => {
            startTravel(1, localStorage.getItem("planet"), props.target, troops)
            props.setOpen(false)
            registerLog({
              type: "Space",
              text: "Send troops for " + props.target,
            })
          }}
        >
          {t("Send Troops")}
        </ScifiButton> */}
      </div>
    </>
  )
}

export default SendTroops
