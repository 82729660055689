import styles from "./Structures.module.scss"

interface Props {
  build: Build
  selectedBuild: Build
  reqResearch: boolean
  setDisableBuild: any
  setSelectedBuild: any
  slot: number
}

const StructureItem = (props: Props) => {
  const {
    build,
    selectedBuild,
    reqResearch,
    setDisableBuild,
    setSelectedBuild,
    slot,
  } = props

  if (build.code === 11) {
    return null
  }
  if (build.code === 12 && slot !== 4) {
    return null
  }
  if (slot === 4 && build.code !== 12) {
    return <></>
  }

  return (
    <div
      className={
        build.disable === true || reqResearch
          ? styles.disabled
          : selectedBuild.id === build.id
          ? `${styles.buildSelected} buildSelected`
          : styles.build
      }
      onClick={() => {
        setSelectedBuild(build)
        if (build.disable === true || reqResearch) {
          setDisableBuild(true)
        } else {
          setDisableBuild(false)
        }
      }}
    >
      <img
        src={`/assets/builds/${build.image}`}
        alt={build.image}
        height="62px"
        draggable={false}
      />
    </div>
  )
}

export default StructureItem
