import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface DisclaimerProps {
  onDismiss: () => void
}

const Loading: React.FC<DisclaimerProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const [countdown, setCountdown] = useState(10)

  useEffect(() => {
    if (countdown === 0) {
      onDismiss()
      return
    }

    const timer = setTimeout(() => {
      setCountdown(countdown - 1)
    }, 1000)

    return () => clearTimeout(timer)
  }, [countdown, onDismiss])

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h2>{t("Loading")}</h2>
      <p>{t("Loading your information, please wait...")}</p>
    </div>
  )
}

export default Loading
