import { useState } from "react"
import React from "react"
import { Canvas } from "@react-three/fiber"
import Fighters from "components/combat/Figthers"
import Scene from "components/comp3d/combat/Scene"
import ControlPanel from "app/sections/ControlPanel"
import styles from "./SpaceCombat.module.scss"

const App: React.FC = () => {
  const [selectedShip, setSelectedShip] = useState("stealth")
  const [finished, setFinished] = useState(false)
  const [openLeave, setOpenLeave] = useState(false)

  return (
    <>
      <ControlPanel />
      <Fighters
        selectedShip={setSelectedShip}
        setFinished={setFinished}
        openLeave={openLeave}
        setOpenLeave={setOpenLeave}
      />
      <div className={styles.scena}>
        {!finished && (
          <Canvas camera={{ position: [0, 0, 50] }}>
            <Scene selectedShip={selectedShip} openLeave={openLeave} />
          </Canvas>
        )}
      </div>
    </>
  )
}

export default App
