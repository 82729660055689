import { useTranslation } from "react-i18next"
import { Tabs } from "../layout"
import SendTroops from "./SendTroops"
import SendFleet from "./SendFleet"

interface Props {
  setAction: any
  target: string
  targetName: string
  targetCoords: string
  setOpen: any
  myPlanet: string
  planetOrigin: any
}
const AttackMode = (props: Props) => {
  const { t } = useTranslation()

  const tabs = [
    {
      key: "sendfleet",
      tab: t("Fleet"),
      children: (
        <div>
          <SendFleet
            setAction={props.setAction}
            target={props.target}
            setOpen={props.setOpen}
            planet={props.planetOrigin}
            targetName={props.targetName}
            targetCoods={props.targetCoords}
          />
        </div>
      ),
    },
    {
      key: "sendtroops",
      tab: t("Troops"),
      children: (
        <div>
          <SendTroops
            setAction={props.setAction}
            target={props.target}
            setOpen={props.setOpen}
          />
        </div>
      ),
    },
  ]

  return (
    <div key="attackmode" style={{ marginTop: "55px", zIndex: 99999 }}>
      <Tabs tabs={tabs} type="card" />
    </div>
  )
}
export default AttackMode
