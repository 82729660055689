import { Tooltip } from "@mui/material"
import { Timer } from "components/display"
import styles from "./Military.module.scss"

interface Props {
  troops: TroopUnitUnderConstruction[] | undefined
  type: string
}

const TroopOnConsturct = (props: Props) => {
  const { troops, type } = props
  let pathAssets = type === "fleet" ? "/assets/ships" : "/assets/units"
  return (
    <div className={styles.unitBlock}>
      {troops &&
        troops &&
        troops.map((element, idx) => (
          <div key={idx}>
            {Date.parse(new Date().toString()) / 1000 - element.ready < 0 && (
              <Tooltip key={idx} title={element.name}>
                <div className={styles.unitOnConstruct}>
                  <img
                    className={styles.unitImg}
                    src={`${pathAssets}/${element.image}`}
                    alt={element.name}
                    draggable="false"
                  />
                  <div className={styles.quantityOnConstruct}>
                    {element.quantity}
                  </div>
                  <div className={styles.timer}>
                    <Timer end={element.ready * 1000} />
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
        ))}
    </div>
  )
}

export default TroopOnConsturct
