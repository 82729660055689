import { useEffect, useState } from "react"
import {
  finishTrading,
  getDataTradingProcess,
  getPlanetUser,
} from "data/queries/market"
import { Card, Col, Flex, Row } from "components/layout"
import styles from "./FinishTrading.module.scss"
// import ShoppingCart from "@mui/icons-material/ShoppingCartOutlined"
import { useTranslation } from "react-i18next"
import { ScifiModal } from "components/feedback"
import { ScifiButton } from "components/general"
import {
  TRITIUM_SCREEN_HISTORY,
  TRITIUM_SCREEN_TRADING,
  TRITIUM_TRANSPORTSHIP_CAPACITY,
} from "config/constants"
import { usePlayer } from "data/queries/player"
import { convertToClock } from "utils/time"

interface FinishProps {
  idTrading: number
  changeScreen: (newScreen: number) => void
}
const FinishTrading = (Finish: FinishProps) => {
  const { t } = useTranslation()
  const { idTrading } = Finish
  const [trading, setTrading] = useState<Trading>({})
  const planetId = localStorage.getItem("planet")
  const [planet, setPlanet] = useState<Planet>()
  const [disabled, setDisabled] = useState(true)
  const [insufficient, setInsufficiente] = useState(false)
  const [error, setError] = useState<Error>()
  const [success, setSuccess] = useState<boolean>(false)
  const [codeErro, setCodeErro] = useState(0)
  const player = usePlayer()

  useEffect(() => {
    if (idTrading) {
      fetchTrading(idTrading)
      fetchPlanetUser(planetId ?? "0")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idTrading, planetId])

  useEffect(() => {
    if (planet) {
      validaTransacao()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planet])

  const validaTransacao = () => {
    if (planet) {
      // verifica se é o ativo está Comprando e possui nave para buscar e possui saldo (S) pq a ordem é inversa, clicou no anuncio de compra
      if (
        (trading.type === "S" &&
          (player.data?.transportShips === undefined ||
            player.data?.transportShips <=
              Math.ceil(trading.quantity! / TRITIUM_TRANSPORTSHIP_CAPACITY))) ||
        planet.energy === undefined ||
        planet.energy < trading.total
      ) {
        setDisabled(true)
        setInsufficiente(true)
        return
      }
      // verifica se é o ativo está vendendo e possui nave para entrega (P) pq a ordem é inversa, clicou no anuncio de venda
      if (
        trading.type === "P" &&
        (player.data?.transportShips === undefined ||
          player.data?.transportShips <=
            Math.ceil(trading.quantity! / TRITIUM_TRANSPORTSHIP_CAPACITY))
      ) {
        setDisabled(true)
        setInsufficiente(true)
        return
      }
    }

    if (!planet) {
      setDisabled(true)
      return
    }
    setDisabled(false)
  }
  const fetchPlanetUser = async (idPlanet: string) => {
    await getPlanetUser(idPlanet)
      .then((res) => setPlanet(res.data))
      .catch((e) => console.error("error planet", e))
  }
  const fetchTrading = async (id: number) => {
    await getDataTradingProcess(planetId ?? "0", id)
      .then((res) => {
        setTrading(res.data)
      })
      .catch((e) => console.error("error ", e))
  }
  const finalizarTransacao = async () => {
    let idPlanetPurch = ""
    let idPlanetSale = ""
    // verifica se é o ativo está Comprando e possui nave para buscar e possui saldo (S) pq a ordem é inversa, clicou no anuncio de compra
    if (trading.type === "S") {
      idPlanetPurch = planetId!
      idPlanetSale = trading.idPlanetCreator?.toString() ?? ""
    } else {
      idPlanetSale = planetId!
      idPlanetPurch = trading.idPlanetCreator?.toString() ?? ""
    }

    let finish: FinishedTrading = {
      idTrading: idTrading,
      idPlanetSale: idPlanetSale,
      idPlanetPurch: idPlanetPurch,
      quantity: trading.quantity,
      price: trading.price,
      distance: trading.distance,
      deliveryTime: trading.distance! * 2,
      status: "1",
      resource: trading.resource,
      currency: "energy",
      type: trading.type,
      planetAtivo: planetId!,
    }
    await finishTrading(finish)
      .then((res) => {
        setSuccess(true)
      })
      .catch((e) => {
        setError(e as Error)
        setCodeErro(e.response.data.code)
      })
  }
  const getMsgError = () => {
    switch (codeErro) {
      case 4001:
        return "This order is no longer available!"
      case 4002:
        return "Quantity and/or price must be greater than zero!"
      case 4003:
        return "You do not have the required amount of cargo ships to perform the transportation!"
      case 4004:
        return "You do not have sufficient balance to complete the transaction!"
      case 4005:
        return "The seller does not have the resource to complete this transaction!"
      case 4007:
        return "You do not have this quantity of resource for sale!"
      case 4008:
        return "The buying planet does not have enough energy to make the purchase"
      default:
        return "Insufficient energy"
    }
  }

  const modalError = !error
    ? undefined
    : {
        children: (
          <section>
            <aside>
              <Flex>
                {
                  // t("Order registration failed!")
                  <div className={styles.error}>{getMsgError()}</div>
                }
              </Flex>
            </aside>
          </section>
        ),
      }

  const modalSuccess = !success
    ? undefined
    : {
        children: (
          <section>
            <section>
              {/* {t("New order successfully registered!")} */}
              Procedures initiated, await completion.
            </section>
          </section>
        ),
      }

  return (
    <Row>
      <Col>
        <Card>
          {trading && (
            <form>
              <Row>
                <Col>
                  <label className={styles.fLabel}>{`${t("Trading")}`}</label>
                  <div className={styles.fResource}>
                    {/* Nesse caso é invertido, a ordem original é de Venda e no caso estamos finalizando a COMPRA dessa ordem (ATIVO COMPRANDO) */}
                    <span>
                      {trading.type === "S" ? (
                        <>{t("Purchase")}</>
                      ) : (
                        <>{t("Sale")}</>
                      )}{" "}
                      - {trading.resource}{" "}
                    </span>
                  </div>
                </Col>
                <Col>
                  <label className={styles.fLabel}>{`${t(
                    "Completion time"
                  )}`}</label>
                  <div className={styles.fResource}>
                    <span className={styles.resourceCount}>
                      {/* multiplica por 2 pq é o tempo de ida e volta */}
                      {convertToClock(trading.distance! * 2)}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className={styles.fLabel}>{`${t("Quantity")}`}</label>
                  <div className={styles.fResource}>
                    <span>{trading.quantity} </span>
                  </div>
                </Col>
                <Col>
                  <label className={styles.fLabel}>
                    {`${t("Unit Price")}`}
                  </label>
                  <div className={styles.fResource}>
                    <span>{trading.price} </span>
                  </div>
                </Col>
              </Row>
              <div className={styles.fSpace} />
              <label className={styles.fLabel}>{`${t("Total")}`}</label>
              <div className={styles.fResource}>
                <span>
                  {trading.total} {t("Energy")}
                </span>
              </div>
              <Row>
                <Col>
                  <label className={styles.fLabel}>{`${t(
                    "Transport Ship"
                  )}`}</label>
                  <div className={styles.fResource}>
                    <span>
                      {planet && player.data?.transportShips} {t("Available")}
                    </span>
                  </div>
                </Col>
                <Col>
                  <label className={styles.fLabel}>{`${t("Required")}`}</label>
                  <div className={styles.fResource}>
                    <span>
                      {Math.ceil(
                        trading.quantity! / TRITIUM_TRANSPORTSHIP_CAPACITY
                      )}{" "}
                      <span style={{ fontSize: "13px" }}>{`${t(
                        "Transport Ship"
                      )}`}</span>
                    </span>
                  </div>
                </Col>
              </Row>
              <div className={styles.fSpace} />
              {insufficient && (
                <label className={styles.label} htmlFor="btn">{`${t(
                  "Insufficient balance to complete!"
                )}`}</label>
              )}
              <br />
              <Col>
                <button
                  className={styles.fResource}
                  disabled={disabled}
                  type="button"
                  onClick={() => finalizarTransacao()}
                >
                  {t("Finish")}
                </button>
              </Col>
            </form>
          )}
        </Card>
      </Col>
      {modalError && (
        <ScifiModal
          {...modalError}
          footer={() => (
            <div className={styles.button}>
              <ScifiButton
                color="primary"
                onClick={() => {
                  setSuccess(false)
                  Finish.changeScreen(TRITIUM_SCREEN_HISTORY)
                }}
                block
              >
                {t("Ok")}
              </ScifiButton>
            </div>
          )}
          onRequestClose={() => {
            setError(undefined)
            Finish.changeScreen(TRITIUM_SCREEN_TRADING)
          }}
          isOpen
        />
      )}
      {modalSuccess && (
        <ScifiModal
          {...modalSuccess}
          footer={() => (
            <div className={styles.buttons}>
              <ScifiButton
                color="primary"
                onClick={() => {
                  setSuccess(false)
                  Finish.changeScreen(TRITIUM_SCREEN_HISTORY)
                }}
                block
              >
                {t("Ok")}
              </ScifiButton>
            </div>
          )}
          onRequestClose={() => {
            setSuccess(false)
            Finish.changeScreen(TRITIUM_SCREEN_HISTORY)
          }}
          isOpen
        />
      )}
    </Row>
  )
}

export default FinishTrading
