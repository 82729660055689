import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const useNFTEffect = () => {
  return useQuery<NFTEffect[]>(
    [queryKey.tritium.nftEffect],
    async () => {
      const response = await api.get(`/api/nft-effect/get`, {
        baseURL: TRITIUM,
      })
      return response.data
    },
    {
      staleTime: Infinity,
      retry: false,
    }
  )
}
