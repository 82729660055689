import { useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"
import RefreshIcon from "@mui/icons-material/Refresh"
import HeaderIconButton from "../components/HeaderIconButton"
import { Tooltip } from "@mui/material"

const Prefreneces = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return (
    <Tooltip title={t("Refresh")}>
      <HeaderIconButton onClick={() => queryClient.invalidateQueries()}>
        <RefreshIcon style={{ fontSize: 18 }} />
      </HeaderIconButton>
    </Tooltip>
  )
}

export default Prefreneces
