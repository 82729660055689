import React, { createContext, useState, useCallback, ReactNode } from "react"
import Shepherd from "shepherd.js"
import "shepherd.js/dist/css/shepherd.css"
import "./TourGuide.css"

export const tour = new Shepherd.Tour({
  useModalOverlay: true,
  defaultStepOptions: {
    classes: "custom",
  },
})

tour.addSteps([
  {
    id: "welcome",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>Welcome to the Terra Tritium!</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: {
      element: "body",
    },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary customButton",
      },
      {
        text: "Next",
        action: tour.next,
        classes: "shepherd-button-custom",
      },
    ],
  },
  {
    id: "build",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
        <b>Lets build the first building! Click on Selected Slot to show the Builds.</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: {
      element: ".mainSlotTour",
      on: "left",
    },
    scrollTo: false,
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
    ],
    when: {
      show: () => {
        setTimeout(() => {
          const selectedBuildElement = document.querySelector(".mainSlotTour")

          if (selectedBuildElement) {
            const nextStep = () => {
              selectedBuildElement.removeEventListener("click", nextStep)
              tour.next()
            }

            selectedBuildElement.addEventListener("click", nextStep)
          } else {
            console.error("Elemento selecionado não encontrado")
          }
        }, 0)
      },
    },
  },
  {
    id: "build1",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>Now click on Selected Build!</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: {
      element: ".buildSelected",
      on: "bottom",
    },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
    ],
    beforeShowPromise: function () {
      return new Promise<void>(function (resolve) {
        const checkExist = setInterval(function () {
          const selectedBuildElement = document.querySelector(".buildSelected")
          if (selectedBuildElement) {
            clearInterval(checkExist)
            resolve()
          }
        }, 100)
      })
    },
    when: {
      show: () => {
        setTimeout(() => {
          const selectedBuildElement = document.querySelector(".buildSelected")
          if (selectedBuildElement) {
            const nextStep = () => {
              selectedBuildElement.removeEventListener("click", nextStep)
              tour.next()
            }

            selectedBuildElement.addEventListener("click", nextStep)
          } else {
            console.error("Elemento selecionado não encontrado")
          }
        }, 0)
      },
    },
  },
  {
    id: "build2",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>Here, you can see also the information about the build, like the description, time to build, resources necessaries</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: {
      element: ".descriptionTour",
      on: "left",
    },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Next",
        action: tour.next,
        classes: "shepherd-button-custom",
      },
    ],
  },
  {
    id: "build3",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>Click on Build Button.</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: {
      element: ".buildButton",
      on: "bottom",
    },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
    ],
    when: {
      show: () => {
        const buildButton = document.querySelector(".buildButton")
        if (buildButton) {
          buildButton.addEventListener("click", () => {
            setTimeout(() => {
              tour.next()
            }, 0)
          })
        } else {
          console.error("O botão de construir não foi encontrado.")
        }
      },
    },
  },
  {
    id: "control-panel",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>Now let's explore the Control Panel. The Control Panel stays on top.</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: { element: ".controlPanel", on: "bottom-left" },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Next",
        action: tour.next,
        classes: "shepherd-button-custom",
      },
    ],
    when: {
      show: () => {
        setTimeout(() => {
          const selectedBuildElement = document.querySelector(".controlPanel")

          if (selectedBuildElement) {
            const nextStep = () => {
              selectedBuildElement.removeEventListener("click", nextStep)
              tour.next()
            }

            selectedBuildElement.addEventListener("click", nextStep)
          } else {
            console.error("Elemento selecionado não encontrado")
          }
        }, 0)
      },
    },
  },
  {
    id: "control-panel-left1",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>In the left of the Control Panel, you have information about "Galaxy Name".</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: { element: ".controlPanel-left1", on: "bottom" },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Next",
        action: tour.next,
        classes: "shepherd-button-custom",
      },
    ],
  },
  {
    id: "control-panel-left2",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>You have also how many "Population".To increase the amount of the Population you need construct Humanoid Factory.</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: { element: ".controlPanel-left2", on: "bottom" },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Next",
        action: tour.next,
        classes: "shepherd-button-custom",
      },
    ],
  },
  {
    id: "control-panel-left3",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>And "Energy". To increase the amount of the Energy you need construct Energy Collector.</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: { element: ".controlPanel-left3", on: "bottom" },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Next",
        action: tour.next,
        classes: "shepherd-button-custom",
      },
    ],
  },
  {
    id: "control-panel-left4",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>The Resources of the planet are "Metal", "Uranium" and "Crystal".All Planets have "Metal", but each terrain have a specific minerium,"Uranium" or "Crystal".</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: { element: ".controlPanel-left", on: "bottom" },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Next",
        action: tour.next,
        classes: "shepherd-button-custom",
      },
    ],
  },
  {
    id: "control-panel-center",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>In the center of the Control Panel, you have the Central Navigator. Here, you can navigate by your "Planet", "Quadrant" or "Region".</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: { element: ".controlPanel-center", on: "bottom" },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Next",
        action: tour.next,
        classes: "shepherd-button-custom",
      },
    ],
  },
  {
    id: "control-panel-right",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>In the right of the Control Panel, you have the "Ranking", where you can see your position and the other players. You have also your "NFTs" and "Tritium balance".</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: { element: ".controlPanel-right", on: "bottom" },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Next",
        action: tour.next,
        classes: "shepherd-button-custom",
      },
    ],
  },
  {
    id: "control-panel-right",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>You have also "Tritium balance".</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: { element: ".controlPanel-right1", on: "bottom" },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Next",
        action: tour.next,
        classes: "shepherd-button-custom",
      },
    ],
  },
  {
    id: "control-panel-right",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>And "NFTs".</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: { element: ".controlPanel-right2", on: "bottom" },
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Next",
        action: tour.next,
        classes: "shepherd-button-custom",
      },
    ],
  },
  {
    id: "colonizerInfo",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>The Colonizer has your necessary information in the game. You can choose the "Game Mode", see the "Ranking", change your "NFT" profile, and send or receive messages.</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>  
    `,
    attachTo: { element: ".colonizerInfo", on: "left" },
    scrollto: false,
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
    ],
    when: {
      show: () => {
        setTimeout(() => {
          const selectedBuildElement = document.querySelector(".colonizerInfo")

          if (selectedBuildElement) {
            const nextStep = () => {
              selectedBuildElement.removeEventListener("click", nextStep)
              tour.next()
            }

            selectedBuildElement.addEventListener("click", nextStep)
          } else {
            console.error("Elemento selecionado não encontrado")
          }
        }, 0)
      },
    },
  },
  {
    id: "scientistInfo",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>The Scientist has information about Research that is necessary to unlock some buildings and abilities. You can also see your Research Points. To enable the research and earn "Research Points" you need to build a Laboratory.</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>  
    `,
    attachTo: { element: ".scientistInfo", on: "left" },
    scrollto: false,
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
    ],
    when: {
      show: () => {
        setTimeout(() => {
          const selectedBuildElement = document.querySelector(".scientistInfo")

          if (selectedBuildElement) {
            const nextStep = () => {
              selectedBuildElement.removeEventListener("click", nextStep)
              tour.next()
            }

            selectedBuildElement.addEventListener("click", nextStep)
          } else {
            console.error("Elemento selecionado não encontrado")
          }
        }, 0)
      },
    },
  },
  {
    id: "militaryInfo",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>The Commandant has information about "Troops", "Defense Strategy", and "Attack Strategy". To enable the troops and shipyards you need to build a "Military Info".</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>  
    `,
    attachTo: { element: ".militaryInfo", on: "left" },
    scrollTo: false,
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
    ],
    when: {
      show: () => {
        setTimeout(() => {
          const selectedBuildElement = document.querySelector(".militaryInfo")
          if (selectedBuildElement) {
            const nextStep = () => {
              selectedBuildElement.removeEventListener("click", nextStep)
              tour.next()
            }

            selectedBuildElement.addEventListener("click", nextStep)
          } else {
            console.error("Elemento selecionado não encontrado")
          }
        }, 0)
      },
    },
  },
  {
    id: "marketInfo",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>The Merchant has information about the "Market". To enable the Market you need to build a "Market".</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>  
    `,
    attachTo: { element: ".marketInfo", on: "left" },
    scrollTo: false,
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
    ],
    when: {
      show: () => {
        setTimeout(() => {
          const selectedBuildElement = document.querySelector(".marketInfo")
          if (selectedBuildElement) {
            const nextStep = () => {
              selectedBuildElement.removeEventListener("click", nextStep)
              tour.next()
            }

            selectedBuildElement.addEventListener("click", nextStep)
          } else {
            console.error("Elemento selecionado não encontrado")
          }
        }, 0)
      },
    },
  },
  {
    id: "mercenaryInfo",
    text: `
  <div class="custom-step-content">
    <div class="text-content">
    <b>The Mercenary has information about "Spies".</b>
    </div>
    <div class="image-content">
      <img src="/assets/person/person55.png" alt="Character">
    </div>
  </div>  
  `,
    attachTo: { element: ".mercenaryInfo", on: "left" },
    scrollTo: false,
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
    ],
    when: {
      show: () => {
        setTimeout(() => {
          const selectedBuildElement = document.querySelector(".mercenaryInfo")
          if (selectedBuildElement) {
            const nextStep = () => {
              selectedBuildElement.removeEventListener("click", nextStep)
              tour.next()
            }

            selectedBuildElement.addEventListener("click", nextStep)
          } else {
            console.error("Elemento selecionado não encontrado")
          }
        }, 0)
      },
    },
  },
  {
    id: "space-warp",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>The "Space Warp" stays on the bottom. Here, you can navigate in the "Quadrants" and see the planets explored and unexploited.</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: { element: ".spaceWarp", on: "top" },
    scrollTo: false,
    buttons: [
      {
        text: "Skip",
        action: tour.cancel,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
      {
        text: "Next",
        action: tour.next,
        classes: "shepherd-button-custom",
      },
    ],
  },
  {
    id: "end-of-journey",
    title: "End of the Journey",
    text: `
    <div class="custom-step-content">
      <div class="text-content">
      <b>Now you can explore the Terra Tritium universe and chart your own destiny among the stars. Forge ahead with courage, creativity, and the spirit of discovery that dwells within you. Every great journey begins with a single step, and this is yours. Go forth and make your mark on the cosmos!</b>
      </div>
      <div class="image-content">
        <img src="/assets/person/person55.png" alt="Character">
      </div>
    </div>
  `,
    attachTo: { element: "body" },
    buttons: [
      {
        text: "Back",
        action: tour.back,
        classes: "shepherd-button-secondary",
      },
      {
        text: "OK",
        action: tour.complete,
        classes: "shepherd-button-custom",
      },
    ],
  },
])

type TourContextType = {
  isTourOpen: boolean
  startTour: () => void
  nextStep: () => void
  prevStep: () => void
  endTour: () => void
}

const TourContext = createContext<TourContextType>({
  isTourOpen: false,
  startTour: () => {},
  nextStep: () => {},
  prevStep: () => {},
  endTour: () => {},
})

export const TourProvider = ({ children }: { children: ReactNode }) => {
  const [isTourOpen, setIsTourOpen] = useState(false)

  const startTour = useCallback(() => {
    tour.start()
  }, [])

  const nextStep = useCallback(() => {
    tour.next()
  }, [])

  const prevStep = useCallback(() => {
    tour.back()
  }, [])

  const endTour = useCallback(() => {
    setIsTourOpen(false)
  }, [])

  return (
    <TourContext.Provider
      value={{ isTourOpen, startTour, nextStep, prevStep, endTour }}
    >
      {children}
    </TourContext.Provider>
  )
}
