import { Col, Row } from "components/layout"
import styles from "./BuildDescriptionModal.module.scss"
import { useTranslation } from "react-i18next"
import { usePlanetById } from "data/queries/planet"
import { researchBalance } from "data/queries/balance"
import { PropsWithChildren, useContext, useEffect, useState } from "react"
import { GameContext } from "pages/GameContext"
import { useResearchSpeed } from "utils/hooks/gameModeEffect"
import { getPlayerDetail } from "data/queries/player"
import { useConversationUnread } from "data/queries/message"

interface Props {
  players?: Planet | null
}

const ColonizationDetails = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation()
  const planetId = localStorage.getItem("planet")
  const planetData = usePlanetById(planetId || "1")
  const { player, planets } = useContext(GameContext)
  const { players } = props

  const [playerScore, setPlayerScore] = useState<PlayerPublic>()

  const unreadMessages = useConversationUnread()

  const planet =
    planetData instanceof Array ? planetData[0].data : planetData.data

  useEffect(() => {
    if (players !== null) {
      getPlayerDetail(planet.player).then((res) => setPlayerScore(res.data))
    }
  }, [players, planet.player])

  const researchSpeed = useResearchSpeed(player?.id ?? "0")

  const unreadCount = unreadMessages.data?.length || 0

  return (
    <>
      <Row>
        <Col>
          <div>
            <div className={styles.specialTitle}>{t("POPULATION")}</div>
            <hr />
            <div className={styles.lineDetails}>
              {t("Robots Idle")} {": "}
              {planet && Number(planet.workersWaiting)}
              <br />
            </div>
            <div className={styles.lineDetails}>
              {t("Robots on Metal Miner")} {": "}
              {planet && Number(planet.workersOnMetal)}
              <br />
            </div>
            <div className={styles.lineDetails}>
              {t("Robots on Uranium Miner")} {": "}
              {planet && Number(planet.workersOnUranium)}
            </div>
            <div className={styles.lineDetails}>
              {t("Robots on Crystal Miner")} {": "}
              {planet && Number(planet.workersOnCrystal)}
              <br />
            </div>
            <div className={styles.lineDetails}>
              {t("Robots on Laboratory")} {": "}
              {planet && Number(planet.workersOnLaboratory)}
              <br />
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div>
            <div className={styles.specialTitle}>
              {t("TOTAL RESEARCH POINTS")}
            </div>
            <div className={styles.lineDetails}>
              {t("Research Points")} {": "}
              {Number(researchBalance(player, planets, researchSpeed))}
              <br />
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div>
            <div className={styles.specialTitle}>{t("Messages")}</div>
            <div className={styles.lineDetails}>
              {t("Pending Messages")} {": "}
              {unreadCount}
              <br />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ColonizationDetails
