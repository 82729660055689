import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"
import { isAuthenticated } from "data/services/authService"

export const useMessage = () => {
  return useQuery(
    [queryKey.tritium.message.messages],
    async () => {
      if (!isAuthenticated()) return []

      const { data } = await api.get<Market[]>(`api/???`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useResourcePlanet = (planet: string) => {
  return useQuery(
    [queryKey.tritium.market.resource],
    async () => {
      const { data } = await api.get(`api/trading/player/resource/${planet}`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const getAllTradingResourceByMarket = (
  planetId: number,
  res: number,
  typeOrder: string
) => {
  let resource = ""
  switch (res) {
    case 1:
      resource = "Crystal"
      break
    case 2:
      resource = "Metal"
      break
    case 3:
      resource = "Uranium"
      break
    default:
      resource = "Crystal"
      break
  }
  return api.get<Trading[]>(
    TRITIUM + `/api/trading/all/${planetId}/${resource}/${typeOrder}`
  )
}
export const getMyResources = () => {
  return api.get<ResourcesInfo>(TRITIUM + `/api/trading/myresources`)
}
export const tradingNewSale = (newOrder: NewOrder) => {
  return api.post<Return>(TRITIUM + `/api/trading/new-sale`, newOrder)
}
export const tradingNewPurch = (newOrder: NewOrder) => {
  return api.post<Return>(TRITIUM + `/api/trading/new-purch`, newOrder)
}
export const getHistory = (resource: string, planetId: string) => {
  return api.get<HistoryTrading[]>(
    TRITIUM + `/api/trading/my-history/${planetId}/${resource}`
  )
}

export const getLastTrading = () => {
  return api.get<LastTrading[]>(TRITIUM + `/api/trading/last-trading`)
}
export const cancelOrder = (id: number, planetId: string) => {
  return api.patch<Return>(TRITIUM + `/api/trading/cancel/${planetId}/${id}`)
}

export const getDataTradingProcess = (planetId: string, id: number) => {
  return api.get<Trading>(
    TRITIUM + `/api/trading/trading-process/${planetId}/${id}`
  )
}

export const getPlanetUser = (idPlanet: string) => {
  return api.get<Planet>(TRITIUM + `/api/planet/show/${idPlanet}`)
}
export const buyFreighter = (idPlanet: string) => {
  return api.patch<Return>(TRITIUM + `/api/trading/buy-freighter/${idPlanet}`)
}
export const finishTrading = (finish: FinishedTrading) => {
  return api.post<Return>(TRITIUM + `/api/trading/finish`, finish)
}
