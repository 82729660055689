import { useState } from "react"
import { useTranslation } from "react-i18next"
import { ScifiButton } from "components/general"
import styles from "./SendResource.module.scss"
import { start } from "data/queries/challange"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import travelAnimation from "styles/images/animations/mercenary.png"

interface Props {
  setAction: any
  target: string
  setOpen: any
}
const MissionExplorer = (props: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  // const { target } = props
  const planetId = localStorage.getItem("planet") ?? "0"

  const [isOpen, setOpen] = useState(false)
  const [success, setSuccess] = useState(false)

  const successMessage = t(
    "Tritium exploration mission started, see trip details in the Mercenery"
  )
  const errorMessage = t("There is already a mission in progress")

  return (
    <>
      <div className={styles.resource}>
        <h1>{t("Tritium Explorer")}</h1>
        <br />
        <br />
        <br />
        <div style={{ display: isOpen ? "block" : "none" }}>
          {success && <>{successMessage}</>}
          {!success && <>{errorMessage}</>}
        </div>
        <div>
          <img src={travelAnimation} width={516} alt="mission" />
        </div>
      </div>

      <div className={styles.actions}>
        <ScifiButton
          onClick={() => {
            props.setAction("default")
          }}
        >
          {t("Cancel")}
        </ScifiButton>
        <ScifiButton
          disabled={success}
          onClick={() => {
            start(planetId, props.target).then((res) => {
              if (res.data === "Success") {
                setSuccess(true)
                queryClient.invalidateQueries(
                  queryKey.tritium.travel.challangeMission
                )
              } else {
                setSuccess(false)
              }
              setOpen(true)
            })
          }}
        >
          {t("Send Mercenary")}
        </ScifiButton>
      </div>
    </>
  )
}
export default MissionExplorer
