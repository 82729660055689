import { useState } from "react"
import { Flex } from "components/layout"
import Popover from "@mui/material/Popover"
import styles from "./RegionPanel.module.scss"

interface Props {
  position: string
  name: string
}

const RegionMenu = (props: Props) => {
  const { position, name } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  function active(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  function disable() {
    setAnchorEl(null)
  }

  return (
    <>
      <div
        className={styles.regionButton}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={active}
        onMouseLeave={disable}
        onClick={() => {
          localStorage.setItem("region", position)
          document.location.href = `/game/galaxy/${position}`
        }}
      >
        {position}
      </div>
      <Popover
        PaperProps={{
          style: { backgroundColor: "transparent" },
        }}
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={disable}
        disableRestoreFocus
      >
        <div className={styles.regionPopover}>
          <Flex>
            {/* <div>{name}</div> */}
            <div style={{ textAlign: "center" }}>
              <img
                src={"/assets/icons/regions/" + position + ".png"}
                alt={name}
                width="100px"
              />
              <div>{name}</div>
            </div>
          </Flex>
        </div>
      </Popover>
    </>
  )
}

export default RegionMenu
