import { PropsWithChildren, useState, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { configWorker } from "data/queries/build"
import { ScifiButton } from "components/general"
import { GameContext } from "pages/GameContext"
import DisponibleAliances from "components/aliance/DisponibleAliances"
import NewAliance from "components/aliance/NewAliance"
import Upgrade from "../Upgrade"
import SimpleAlert from "../SimpleAlert"
import mp3Click from "sounds/toggle.mp3"
import styles from "./BuildDetail.module.scss"
import AddIcon from "@mui/icons-material/Add"
import SearchIcon from "@mui/icons-material/Search"
import {
  acceptInvite,
  getMyAliance,
  useGetReceivedInvitations,
} from "data/queries/aliance"
import AlianceData from "components/aliance/AlianceData"
import { InfoPending } from "components/aliance/Aliance"
import { Table } from "components/layout"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Tooltip } from "@mui/material"
import { ScifiModal } from "components/feedback"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
}
const GalaticConcil = (props: PropsWithChildren<Props>) => {
  const { build, building, setIsOpen } = props
  const { t } = useTranslation()
  const { planet } = useContext(GameContext)
  const listInvites = useGetReceivedInvitations()
  const invitations: ReceivedInvation[] = listInvites.data ?? []

  const soundClick = new Audio(mp3Click)

  const [workers, setWorkers] = useState<any>(building.workers)
  const [alert, setAlert] = useState<boolean>(false)
  const [newAliance, setNewAliance] = useState<boolean>(false)
  const [searchAliance, setSearchAliance] = useState<boolean>(false)
  const [option, setOption] = useState(3)
  const [aliancas, setAliancas] = useState<Aliances | undefined>()
  const [alianceEdit, setAlianceEdit] = useState<boolean>(false)

  const [showModalDetails, setShowModalDetails] = useState(false)
  const [invite, setInveite] = useState<ReceivedInvation | null>()

  const [showModalErro, setShowModalErro] = useState(false)
  const [showMsg, setShowMsg] = useState("")
  const [page, setPage] = useState(1)

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleOption = (opt: number) => {
    if (opt === 1) {
      setNewAliance(true)
      setSearchAliance(false)
      setAlianceEdit(false)
    } else if (opt === 2) {
      setNewAliance(false)
      setSearchAliance(true)
      setAlianceEdit(false)
    } else if (opt === 0) {
      setAliancas(undefined)
      setNewAliance(false)
      setSearchAliance(false)
      setAlianceEdit(false)
    } else if (opt === 4) {
      setNewAliance(true)
      setSearchAliance(false)
      setAliancas(undefined)
      setAlianceEdit(true)
    }
    setOption(opt)
  }
  useEffect(() => {
    if (alianceEdit) return
    handleGetMyAliance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option])

  const handleGetMyAliance = async () => {
    await getMyAliance()
      .then((res) => {
        setAliancas(res.status === 200 ? res.data : undefined)
      })
      .catch((e) => {
        console.log("erro", e)
      })
  }
  const handleAceptInvite = (idInvite: number, invite: ReceivedInvation) => {
    setInveite(invite)
    setShowModalDetails(true)
  }

  const handleCloseModal = () => {
    setShowModalDetails(false)
    setShowModalErro(false)
    setShowMsg("")
  }
  const handleJoinAliance = async (idInvite: number) => {
    await acceptInvite(idInvite)
      .then((res) => {
        setShowModalDetails(false)
        setShowModalErro(false)
        setInveite(null)
        setShowMsg("")
        handleGetMyAliance()
      })
      .catch((er) => {
        setShowModalErro(true)
        setShowMsg(er.response.data.message)
      })
  }
  return (
    <>
      {showModalErro && (
        <ScifiModal
          isOpen={showModalErro}
          onRequestClose={() => handleCloseModal()}
        >
          {showMsg}
          <div className={styles.btnContainer}>
            <ScifiButton onClick={() => handleCloseModal()}>{"Ok"}</ScifiButton>
          </div>
        </ScifiModal>
      )}
      {showModalDetails && (
        <>
          <ScifiModal
            isOpen={showModalDetails}
            onRequestClose={() => handleCloseModal()}
          >
            <div>
              {invite && (
                <>
                  <img
                    style={{ borderRadius: "50%" }}
                    src={`/assets/aliancelogo/fill/${invite.aliance?.logo}`}
                    width={60}
                    alt={t("logo")}
                  />
                  &nbsp;&nbsp;{invite.aliance?.name}
                  <br />
                  <br />
                  {t("Description")} : {invite.aliance?.description}
                  <br />
                  {t("Members")} - {invite.members}
                  <br />
                  {t("Supported")} - {invite.totalMembers}
                  <br />
                  {invite.message && (
                    <>
                      {t("Message")} - {invite.message}{" "}
                    </>
                  )}
                  <br />
                  <div className={styles.msgWarning}>
                    {t(
                      "If you accept joining this alliance, the sent and received requests will be deleted."
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={styles.btnContainer}>
              <ScifiButton onClick={() => handleJoinAliance(invite?.id!)}>
                {t("Join")}
              </ScifiButton>
            </div>
          </ScifiModal>
        </>
      )}
      <div>
        <span className={styles.level}>{Number(building.level)}</span>
        <span>{t("level")}</span>
      </div>
      <br />
      <div style={{ display: "flex" }}>
        <div>
          <img
            src={`/assets/builds/build-14.png`}
            alt="Metal Mining"
            className={styles.buildImage}
            draggable={false}
          />
          <Upgrade setIsOpen={setIsOpen} build={build} building={building} />
        </div>
        <div className={styles.buildContent}>
          {!aliancas ? (
            <div>
              <div>
                {newAliance && <NewAliance changeScreen={handleOption} />}
                {searchAliance && (
                  <DisponibleAliances changeScreen={handleOption} />
                )}
              </div>
              {!newAliance && !searchAliance && (
                <div>
                  <ScifiButton onClick={() => handleOption(1)} size="large">
                    <AddIcon />
                    <br />
                    &nbsp;&nbsp;{t("New alliance")} &nbsp;&nbsp;
                  </ScifiButton>
                  &nbsp;
                  <ScifiButton onClick={() => handleOption(2)} size="large">
                    <SearchIcon />
                    <br />
                    {t("Search alliance")}
                  </ScifiButton>
                  <br />
                  <div>
                    {invitations.length > 0 && (
                      // invitations.map((i, key) => {
                      //   return <>{`id da alianca ${i.alianceId} nome da alianca ${i.aliance?.name} logo ${i.aliance?.logo}`} <br/></>
                      // })
                      <>
                        <div className={styles.titleConvite}>
                          {t("Received Invitations")}
                        </div>
                      </>
                    )}
                    <Table
                      columns={[
                        {
                          key: "alianceLogo",
                          title: "",
                          dataIndex: "aliance",
                          align: "left",
                          render: (aliance: Aliance) => {
                            return (
                              <img
                                style={{ borderRadius: "50%" }}
                                src={`/assets/aliancelogo/fill/${aliance.logo}`}
                                width={40}
                                alt={t("logo")}
                              />
                            )
                          },
                        },
                        {
                          key: "alianceName",
                          title: t("Name"),
                          dataIndex: "aliance",
                          align: "left",
                          render: (aliance: Aliance) => {
                            return <>{aliance?.name.slice(0, 15)}</>
                          },
                        },
                        {
                          key: "members",
                          title: "",
                          dataIndex: "aliance",
                          align: "left",
                          render: (
                            aliance: Aliance,
                            restored: ReceivedInvation
                          ) => {
                            return (
                              <>{`${restored.members}/${restored.totalMembers}`}</>
                            )
                          },
                        },
                        {
                          key: "invite",
                          title: "#",
                          dataIndex: "id",
                          align: "right",
                          render: (id: number, restored: ReceivedInvation) => {
                            if (restored?.members! >= restored?.totalMembers!) {
                              return (
                                <button disabled={true}>
                                  <Tooltip title={t("Full")}>
                                    <MoreVertIcon />
                                  </Tooltip>
                                </button>
                              )
                            } else {
                              return (
                                <Tooltip title="Details">
                                  <button
                                    onClick={() =>
                                      handleAceptInvite(id, restored)
                                    }
                                  >
                                    <MoreVertIcon />
                                  </button>
                                </Tooltip>
                              )
                            }
                          },
                        },
                      ]}
                      dataSource={invitations}
                      size="small"
                      pagination={{
                        currentPage: page,
                        pageSize: 100,
                        onPageChange: handlePageChange,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              {aliancas && aliancas.status === "P" ? (
                <>
                  <InfoPending changeScreen={handleOption} />
                </>
              ) : (
                <AlianceData changeScreen={handleOption} aliance={aliancas} />
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <ScifiButton
          onClick={() => {
            configWorker({
              planetId: planet,
              buildId: String(build.id),
              buildingId: String(building.id),
              workers: workers,
            })
            setWorkers(workers)
            building.workers = Number(workers)
            setAlert(true)
            soundClick.play()
          }}
          size="small"
        >
          {t("Save")}
        </ScifiButton>
      </div>
      <SimpleAlert
        open={alert}
        onClose={() => {
          setAlert(false)
        }}
      />
    </>
  )
}

export default GalaticConcil
