// import { Input, TextArea } from "components/form";
import { BackBar } from "./Aliance"
import { Card, Col, Flex, Row } from "components/layout"
import styles from "./Aliance.module.scss"
import { useTranslation } from "react-i18next"
import disableScroll from "disable-scroll"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { useEffect, useRef, useState } from "react"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import DoneIcon from "@mui/icons-material/Done"
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp"
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"

import {
  getNameAvailable,
  salvarNewAlliance,
  useGetAllLogos,
  useGetMyAliancForEdit,
} from "data/queries/aliance"
import { LoadingCircular, Modal } from "components/feedback"

interface NewAlianceProps {
  changeScreen: (newScreen: number) => void
}
const NewAliance = (newAliance: NewAlianceProps) => {
  const { changeScreen } = newAliance
  const { t } = useTranslation()
  const [logoName, setLogoName] = useState("")
  const [nameAliance, setNameAliance] = useState("")
  const [description, setDescription] = useState("")
  const [currentStep, setCurrentStep] = useState(1)
  const planet = localStorage.getItem("planet")
  const logos = useGetAllLogos()
  const [loadVerifyName, setLoadName] = useState(false)
  const [validName, setValidName] = useState(false)
  const [isValidStep1] = useState(true)
  const [isOpen, setOpen] = useState(false)
  const [error, setError] = useState<Error>()
  const [msgErr, setMsgErr] = useState("")

  const logoRef = useRef<HTMLDivElement>(null)

  const myAlianceEdit = useGetMyAliancForEdit()

  useEffect(() => {
    if (myAlianceEdit.data) {
      setNameAliance(myAlianceEdit.data.name)
      setDescription(myAlianceEdit.data.description)
      setOpen(myAlianceEdit.data.status === "F" ? false : true)
      setLogoName(myAlianceEdit.data.logo ?? "")
      setValidName(myAlianceEdit.data.id ? true : false)
    }
  }, [myAlianceEdit.data])
  const nextStep = () => {
    if (myAlianceEdit.data)
      setValidName(myAlianceEdit.data?.name === nameAliance.trim())
    if (
      !validName ||
      nameAliance.trim().length === 0 ||
      description.trim().length === 0
    ) {
      setError(new Error())
      setMsgErr("Todos os campos devem ser devidamente preenchidos")
    } else {
      setCurrentStep((prevStep) => prevStep + 1)
    }
  }
  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1)
  }
  const salvar = async () => {
    // if (myAlianceEdit.data)
    //   setValidName(myAlianceEdit.data?.name === nameAliance.trim())
    await handleAvailabelName(nameAliance)
    if (
      nameAliance.trim().length > 0 &&
      description.trim().length > 0 &&
      logoName.trim().length > 0
    ) {
      salvarNewAlliance({
        id: myAlianceEdit.data?.id,
        name: nameAliance,
        founder: parseInt(planet ?? "0"),
        description: description,
        logo: logoName,
        color: "",
        status: isOpen ? "A" : "F",
      })
        .then((res) => {
          if (res.status === 200) {
            newAliance.changeScreen(0)
          }
        })
        .catch((e) => {
          console.log("erro", e)
          setError(e as Error)
          setMsgErr("Todos os campos devem ser devidamente preenchidos")
        })
    } else {
      setError(new Error())
      setMsgErr("Todos os campos devem ser devidamente preenchidos")
    }
  }
  const handleAvailabelName = (txt: string) => {
    //verifica se não houve alteração do nome na edicao
    if (myAlianceEdit.data?.name === txt) {
      setValidName(true)
      return
    }
    if (txt.length > 0) {
      setLoadName(true)
      getNameAvailable(txt)
        .then((res) => {
          setValidName(true)
        })
        .catch((e) => {
          setError(e as Error)
          setMsgErr(e.response.data.message)
          setValidName(false)
        })
        .finally(() => setLoadName(false))
    }
  }

  const modalError = !error
    ? undefined
    : {
        title: t("Error"),
        children: (
          <section>
            <aside>
              <Flex>
                {
                  // t("Order registration failed!")
                  msgErr
                }
              </Flex>
            </aside>
          </section>
        ),
      }

  const handleScrollUp = () => {
    const container = logoRef.current
    if (container) {
      container.scrollTop -= 80
    }
  }

  const handleScrollDown = () => {
    const container = logoRef.current
    if (container) {
      container.scrollTop += 80
    }
  }

  //
  return (
    <div>
      <BackBar changeScreen={changeScreen} />
      <br />
      <Row>
        {/* <form onSubmit={handleSubmit}> */}
        <Col>
          <Card>
            <h1 className={styles.title}>
              {myAlianceEdit.data && myAlianceEdit.data.founder ? (
                <>{t("Edit alliance")}</>
              ) : (
                <>{t("New alliance")}</>
              )}
            </h1>{" "}
            {/* etapa 1 */}
            <div
              className={`${styles["form-step"]} ${styles["step-1"]} ${
                currentStep === 1 ? styles.active : ""
              }`}
            >
              <label className={styles.labelAliance} htmlFor="nameAliance">
                {t("Name")}
              </label>
              <input
                type="text"
                name="nameAliance"
                className={styles.inputAliance}
                id="nameAliance"
                maxLength={30}
                required
                value={nameAliance}
                onChange={(e) => setNameAliance(e.target.value)}
                onBlur={(e) => handleAvailabelName(e.target.value)}
              />
              {loadVerifyName && <LoadingCircular size={18} />}
              <br />
              <br />
              <label
                className={styles.labelAliance}
                htmlFor="descriptionAliance"
              >
                {t("Description")}
              </label>
              <textarea
                onWheel={(e) => {
                  disableScroll.off()
                }}
                onMouseOut={(e) => {
                  disableScroll.on()
                }}
                name="descriptionAliance"
                className={styles.textarea}
                id="descriptionAliance"
                rows={4}
                cols={4}
                maxLength={300}
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <br />
              <label>Status</label>
              <div className={styles.areaSwitch}>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    checked={isOpen}
                    onChange={() => setOpen(!isOpen)}
                  />
                  <span className={styles.slider}></span>
                </label>
                <span className={styles.switchLabel}>
                  {" "}
                  {isOpen ? "Open" : "Closed"}
                </span>
              </div>
              <label className={styles.labelAliance} htmlFor="btn"></label>

              <button
                className={styles.btnAliance}
                style={{ marginLeft: "70%" }}
                disabled={!isValidStep1}
                type="button"
                onClick={nextStep}
              >
                {t("Next")}
                <NavigateNextIcon />
              </button>
            </div>
            {/* etapa 2 */}
            <div
              className={`${styles["form-step"]} ${styles["step-2"]} ${
                currentStep === 2 ? styles.active : ""
              }`}
            >
              <div className={styles.arrowUp} onClick={handleScrollUp}>
                <ArrowCircleUpIcon />
              </div>
              <div
                className={styles.logos}
                onWheel={(e) => {
                  disableScroll.off()
                }}
                onMouseOut={(e) => {
                  disableScroll.on()
                }}
                ref={logoRef}
              >
                {logos.data?.map((logo, i) => {
                  return (
                    <>
                      <div key={i} className={styles.logo}>
                        <img
                          style={{ borderRadius: "5px" }}
                          src={`/assets/aliancelogo/fill/${logo.name}`}
                          width={80}
                          className={
                            logo.name === logoName ? styles.logoSelected : ""
                          }
                          alt={t("logo")}
                          onClick={() => {
                            setLogoName(logo.name)
                          }}
                        />
                      </div>
                    </>
                  )
                })}
                <div className={styles.arrowDown} onClick={handleScrollDown}>
                  <ArrowCircleDownIcon />
                </div>
              </div>
              <div className={styles.formButtons}>
                <button
                  className={styles.btnAliance}
                  type="button"
                  onClick={prevStep}
                >
                  <NavigateBeforeIcon />
                  {t("Back")}
                </button>
                <button className={styles.btnAliance} onClick={() => salvar()}>
                  {t("Finish")} &nbsp;
                  <DoneIcon />
                </button>
              </div>
            </div>
          </Card>
        </Col>
        {/* </form> */}
      </Row>
      {modalError && (
        <Modal
          {...modalError}
          icon={<ErrorOutlineIcon fontSize="inherit" className="danger" />}
          onRequestClose={() => {
            setError(undefined)
          }}
          isOpen
        />
      )}
    </div>
  )
}

export default NewAliance
