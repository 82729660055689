import { TOKEN_KEY } from "config/constants"
import { loginUser, logoutUser } from "data/queries/users"

export const isAuthenticated = () => {
  return localStorage.getItem(TOKEN_KEY)
}

export const getToken = () => localStorage.getItem(TOKEN_KEY) || ""

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const isTokenExpired = () => {
  const token: string = getToken()
  const decodeJWT = JSON.parse(btoa(token.split(".")[1]))
  if (decodeJWT.exp * 1000 < Date.now()) {
    return true
  } else {
    return false
  }
}

export const loginUserSrv = async (user: User) => {
  return await loginUser(user)
    .then((data) => {
      if (data.data.success) {
        setToken(data.data.token)
      }
      return data.data
    })
    .catch((error) => {
      return false
    })
}

export const logoutUserSrv = async () => {
  await logoutUser()
}
