import { useEffect, useState } from "react"
import styles from "./Purchaser.module.scss"
import {
  TRITIUM_SCREEN_FINISH_ORDER,
  TRITIUM_TYPE_CRYSTAL,
} from "config/constants"
// import { sort } from "utils/sorters"
import disableScroll from "disable-scroll"

import { getAllTradingResourceByMarket } from "data/queries/market"
import { LoadingCircular, Warning } from "components/feedback"
import { Table } from "components/layout"
import { useTranslation } from "react-i18next"
import { Tooltip } from "@mui/material"

interface PurchaserProps {
  typeItem: number | undefined
  changeScreen: (newScreen: number, idTrading?: number) => void
}
const Purchaser = (PurchaserProps: PurchaserProps) => {
  const [compradoresArr, setCompradoresArr] = useState<Trading[]>([])
  const planetId = parseInt(localStorage.getItem("planet") ?? "0")
  const [showLoad, setShowLoad] = useState(true)
  /**
   * @TODO refazer esse useffect apos ligar com o back-end
   */
  useEffect(() => {
    fetchCompradores()
    // @todo ajustar as dependencias e remover o lint disable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PurchaserProps.typeItem])

  const fetchCompradores = () => {
    setShowLoad(true)
    getAllTradingResourceByMarket(
      planetId,
      PurchaserProps.typeItem ?? TRITIUM_TYPE_CRYSTAL,
      "P"
    )
      .then((res) => {
        setCompradoresArr(Array.isArray(res.data) ? res.data : [])
      })
      .catch((e) => console.log(e))
      .finally(() => setShowLoad(false))
  }

  /**Refactor */
  const [colunaOrdem, setColunaOrdem] = useState("")
  const [ordem, setOrdem] = useState("asc")

  const requestSort = (coluna: keyof Trading) => {
    setColunaOrdem(coluna)
    const newDirection =
      coluna === colunaOrdem && ordem === "asc" ? "desc" : "asc"
    const newList = [...compradoresArr].sort((a, b) => {
      const aValue = a[coluna] || 0
      const bValue = b[coluna] || 0
      const comparacao = aValue - bValue
      return newDirection === "asc" ? comparacao : -comparacao
    })
    setCompradoresArr(newList)
    setOrdem(newDirection)
  }

  const selectedRow = (id: number, idPlanetCreator: number) => {
    if (planetId !== idPlanetCreator) {
      PurchaserProps.changeScreen(TRITIUM_SCREEN_FINISH_ORDER, id)
      // console.log(`selecionou a linha ${id}`)
    }
  }
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const handlePageChange = (page: number) => {
    setPage(page)
  }
  const filteredData = compradoresArr
  return (
    <>
      {showLoad && <LoadingCircular style={{ color: "#5850B4" }} />}
      {!showLoad && (
        <>
          <div className={styles.tbTitle}>{t("Purchases")}</div>
          <div className={styles.tbPurchTitles}>
            <div style={{ width: "45%" }} className="nomeVendedor">
              {t("Name")}
            </div>
            <Tooltip title={t("Unit price")}>
              <div
                style={{ width: "27%" }}
                onClick={() => requestSort("price")}
              >
                {t("Price")}
              </div>
            </Tooltip>
            <Tooltip title={t("Astronomical Unit")}>
              <div
                style={{ width: "28%" }}
                onClick={() => requestSort("distance")}
              >
                {t("Distance")}
              </div>
            </Tooltip>
          </div>
          <div
            className={styles.containerPurch}
            onMouseOver={() => disableScroll.off()}
            onMouseOut={() => disableScroll.on()}
          >
            {!compradoresArr || compradoresArr.length === 0 ? (
              <Warning msg="No buy orders registered" />
            ) : (
              <div className={styles.tbPurch}>
                <Table
                  columns={[
                    {
                      key: "name",
                      dataIndex: "name",
                      align: "left",
                      render: (name: string) => {
                        // if (player) {
                        //   if (name === player.name) {
                        //     return <div className={styles.destak}>{name}</div>
                        //   }
                        // }
                        return <>{name?.split(" ")[0]}</>
                      },
                    },
                    {
                      key: "price",
                      dataIndex: "price",
                      align: "left",
                      render: (price: number) => {
                        return <>{price.toFixed(2)}</>
                      },
                    },
                    {
                      key: "distance",
                      dataIndex: "distance",
                      align: "center",
                      render: (distance: number) => {
                        return (
                          <Tooltip title={t("Astronomical Unit")}>
                            <>{distance}</>
                          </Tooltip>
                        )
                      },
                    },
                  ]}
                  dataSource={filteredData}
                  size="small"
                  pagination={{
                    currentPage: page,
                    pageSize: page,
                    onPageChange: handlePageChange,
                  }}
                  onRowClick={(data) =>
                    selectedRow(data.id!, data.idPlanetCreator!)
                  }
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default Purchaser
