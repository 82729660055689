import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ReactQueryDevtools } from "react-query/devtools"
import { RecoilRoot } from "recoil"
import { WalletProvider, getInitialConfig } from "@terra-money/wallet-kit"
import TerraStationMobileWallet from "@terra-money/terra-station-mobile"
import {
  WalletProvider as GalaxyWalletProvider,
  getInitialConfig as getInitialConfigGalaxy,
} from "@hexxagon/wallet-kit"
import "tippy.js/dist/tippy.css"

import "config/lang"
import { debug } from "utils/env"

import "index.scss"
import ScrollToTop from "app/ScrollToTop"
import InitTheme from "app/InitTheme"
import InitQueryClient from "app/InitQueryClient"
import App from "app/App"

const container = document.getElementById("station")
const root = createRoot(container!)

Promise.all([getInitialConfig(), getInitialConfigGalaxy()]).then(
  ([config, defaultNetworks]) => {
    root.render(
      <StrictMode>
        <RecoilRoot>
          <BrowserRouter>
            <ScrollToTop />
            <InitTheme />
            <InitQueryClient>
              <WalletProvider
                extraWallets={[new TerraStationMobileWallet()]}
                defaultNetworks={config}
              >
                <GalaxyWalletProvider defaultNetworks={defaultNetworks}>
                  <App />
                </GalaxyWalletProvider>
              </WalletProvider>
            </InitQueryClient>
            {debug.query && <ReactQueryDevtools position="bottom-right" />}
          </BrowserRouter>
        </RecoilRoot>
      </StrictMode>
    )
  }
)
