import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useRoutes } from "react-router-dom"

import Rocket from "@mui/icons-material/Rocket"

/* menu */
import Game from "pages/game/Game"
import LoginConnect from "./LoginConnect"

/* game */
import Lobby from "pages/login/Lobby"
import Galaxy from "pages/game/Galaxy"
import Quadrant from "pages/game/Quadrant"
import Planet from "pages/game/Planet"
import LandCombat from "pages/game/LandCombat"
import SpaceCombat from "pages/game/SpaceCombat"
import Register from "pages/login/Register"

/* settings */
import Settings from "pages/Settings"

/* 404 */
import NotFound from "pages/NotFound"

import { isAuthenticated } from "data/services/authService"

/* Login Fomr*/
import FormLogin from "pages/login/FormLogin"
import ResetPassword from "pages/login/ResetPassword"
import ForgetPassword from "pages/login/ForgetPassword"
import { usePlanetById } from "data/queries/planet"
import BadRequest from "pages/BadRequest"
import EmailVerify from "pages/login/EmailVerify"

interface ApiError {
  response?: {
    status?: number
  }
}

const PlanetRoute = () => {
  const { planet } = useParams()
  const { error } = usePlanetById(planet as string)

  if (error && (error as ApiError).response?.status === 403) {
    return <BadRequest />
  }

  return <Planet />
}

export const RequireAuth = ({ children }: any) => {
  return isAuthenticated() ? children : null
}

const ICON_SIZE = { width: 20, height: 20 }

export const useNav = () => {
  const { t } = useTranslation()

  const menu = [
    {
      path: "/moonshot",
      title: t("Moonshot"),
      icon: <Rocket {...ICON_SIZE} />,
    },
  ]

  const routes = [
    {
      path: "/",
      element: <Lobby />,
    },
    {
      path: "/game",
      element: <Game />,
    },
    {
      path: "/game/galaxy/:region",
      element: (
        <RequireAuth>
          <Galaxy />
        </RequireAuth>
      ),
    },
    {
      path: "/game/planet/:planet",
      element: (
        <RequireAuth>
          <PlanetRoute />
        </RequireAuth>
      ),
    },
    {
      path: "/game/landcombat/:combat",
      element: (
        <RequireAuth>
          <LandCombat />
        </RequireAuth>
      ),
    },
    {
      path: "/game/spaceCombat/:combat",
      element: (
        <RequireAuth>
          <SpaceCombat />
        </RequireAuth>
      ),
    },
    {
      path: "/game/quadrant/:code",
      element: (
        <RequireAuth>
          <Quadrant />
        </RequireAuth>
      ),
    },
    /* auth */
    {
      path: "/settings",
      element: (
        <RequireAuth>
          <Settings />
        </RequireAuth>
      ),
    },

    /* Routes without authentication */

    { path: "/register", element: <Register /> },
    /* Login Form*/
    { path: "/login", element: <FormLogin /> },
    { path: "/reset-password/:token/:email", element: <ResetPassword /> },
    { path: "/forget-password", element: <ForgetPassword /> },
    { path: "/email-verify/:id/:hash", element: <EmailVerify /> },
    { path: "/login-connect", element: <LoginConnect /> },
    /* 404 */
    { path: "*", element: <NotFound /> },
  ]

  return { menu, element: useRoutes(routes) }
}

/* helpers */
export const useGoBackOnError = ({ error }: QueryState) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (error) navigate("..", { replace: true })
  }, [error, navigate])
}
