import { PropsWithChildren } from "react"
import Construct from "./Construct"

interface Props {
  id: number
  position: number
  onClick?: any
  onMouseDown?: any
  planet: string
  buildings?: Building[]
  constructs?: Build[]
  setIsDragable: any
  onClickX: number
  onClickY: number
}

const Slot = (props: PropsWithChildren<Props>) => {
  const {
    id,
    planet,
    buildings,
    constructs,
    onClick,
    onMouseDown,
    position,
    setIsDragable,
    onClickX,
    onClickY,
  } = props

  const elements: any = []

  if (constructs && buildings) {
    elements.push(
      <Construct
        constructs={constructs}
        planet={planet}
        buildings={buildings}
        onClick={onClick}
        onClickX={onClickX}
        onClickY={onClickY}
        onMouseDown={onMouseDown}
        space={position}
        key={id}
        setIsDragable={setIsDragable}
      />
    )
  }

  return <>{elements}</>
}

export default Slot
