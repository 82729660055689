import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ScifiButton } from "components/general"
import { Button } from "components/general"
import styles from "./SendResource.module.scss"
import { sendResources, useAvailableResources } from "data/queries/combat"
import { useCaluleDestiny } from "data/queries/quadrant"
import { Alert, AlertTitle, Stack } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { convertToClock } from "utils/time"
import { usePlayer } from "data/queries/player"

import {
  TRITIUM_TRANSPORTSHIP_CAPACITY,
  TRITIUM_CHARGING_SPEED,
} from "config/constants"

interface Props {
  setAction: any
  target: string
  setOpen: any
}
const SendResource = (props: Props) => {
  const { t } = useTranslation()
  const { target } = props
  const planetId = localStorage.getItem("planet") ?? "0"
  const availabeResources = useAvailableResources(planetId)
  const distacyPlanet = useCaluleDestiny(planetId, target)
  const [metal, setMetal] = useState(0)
  const [uranium, setUranium] = useState(0)
  const [crystal, setCrystal] = useState(0)
  const [error, setError] = useState<"">()
  const [alerta, setAlerta] = useState("")
  const player = usePlayer()

  /**se nao possui cargueiro disponível, disabled será true */
  const [disabled, setDisabled] = useState<boolean>(
    availabeResources.data && (player.data?.transportShips ?? 0) > 0
      ? false
      : true
  )
  useEffect(() => {
    isResourceSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metal, uranium, crystal])

  const isResourceSelected = () => {
    if (availabeResources && player.data?.transportShips === 0) {
      setDisabled(true)
    } else {
      if (metal === 0 && uranium === 0 && crystal === 0) {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
  }
  function reset() {
    setMetal(0)
    setCrystal(0)
    setUranium(0)
  }

  function all() {
    let resources = availabeResources.data ?? null
    setMetal(resources?.metal ?? 0)
    setCrystal(resources?.crystal ?? 0)
    setUranium(resources?.uranium ?? 0)
  }

  const handleSendResource = () => {
    let origin = planetId
    sendResources({ metal, uranium, crystal, target, origin })
      .then((res) => {
        setAlerta("success")
      })
      .catch((er) => {
        setAlerta("error")
        setError(er.response.data.error)
        console.log("error ao enviar ", er)
      })
  }
  const closeModal = () => {
    setAlerta("")
    setError("")
  }

  const caluleChargingTime = () => {
    let totalRecursos = metal + uranium + crystal
    let transportShipsInUse = Math.floor(
      totalRecursos / TRITIUM_TRANSPORTSHIP_CAPACITY
    )
    transportShipsInUse +=
      totalRecursos % TRITIUM_TRANSPORTSHIP_CAPACITY > 0 ? 1 : 0

    let timeLoad = transportShipsInUse * TRITIUM_CHARGING_SPEED

    return convertToClock(timeLoad, 1)
  }

  const capacity =
    Number(player?.data?.transportShips) * TRITIUM_TRANSPORTSHIP_CAPACITY -
    (crystal + metal + uranium)

  const distanceTime =
    distacyPlanet?.data?.distance === undefined
      ? 0
      : distacyPlanet?.data?.distance

  return (
    <>
      <div className={styles.options}>
        <Button onClick={() => reset()} size="small">
          {t("Reset")}
        </Button>
        <Button onClick={() => all()} size="small">
          {t("All")}
        </Button>
      </div>
      <div className={styles.resource}>
        <br />
        {availabeResources && player.data?.transportShips === 0 && (
          <>
            <Stack sx={{ width: "100%" }} spacing={1}>
              <Alert className={styles.container} severity="warning">
                <AlertTitle className={styles.warning}>Warning</AlertTitle>

                {t(`You do not have a sufficient quantity of cargo ships`)}
              </Alert>
            </Stack>
            <br />
          </>
        )}
        {alerta && (
          <>
            <Stack sx={{ width: "100%" }} spacing={1}>
              <Alert severity={alerta === "error" ? "error" : "success"}>
                <AlertTitle>
                  {alerta === "error" ? t("Error") : t("Success")}
                </AlertTitle>
                <div
                  style={{
                    marginLeft: "438px",
                    marginTop: "-32px",
                    cursor: "pointer",
                  }}
                  onClick={() => closeModal()}
                >
                  <CloseIcon fontSize="small" />
                </div>
                {/* {t(`You do not have a sufficient quantity of cargo ships`)} */}
                {alerta === "success"
                  ? t("Resources sent successfully")
                  : t(`${error}`)}
              </Alert>
            </Stack>
            <br />
          </>
        )}
        <div>
          {/* teste */}
          <div className={styles.container}>
            <label className={styles.label} htmlFor="metal">
              {t("Metal")}
            </label>
            <img
              className={styles.imgResouece}
              key={`lbl-metal`}
              src={`/assets/icons/resources/metal.png`}
              width={22}
              alt={`Attack`}
            />
            <input
              type="text"
              name="metal"
              className={styles.myResource}
              id="metal"
              step="1"
              min="0"
              pattern="^[0-9]+$"
              value={metal !== null ? metal : ""}
              onChange={(event) => {
                const value = (event.target as HTMLInputElement).value
                if (value === "") {
                  setMetal(0)
                } else {
                  const parsedValue = parseInt(value, 10)
                  if (!isNaN(parsedValue)) {
                    setMetal(parsedValue)
                  }
                }
              }}
              required
            />
            <label className={styles.label} htmlFor="uranium">
              {t("Uranium")}
            </label>
            <img
              className={styles.imgResouece}
              key={`lbl-uranium`}
              src={`/assets/icons/resources/uranium.png`}
              width={22}
              alt={`Attack`}
            />
            <input
              type="text"
              name="uranium"
              className={styles.myResource}
              id="uranium"
              step="1"
              min="0"
              pattern="^[0-9]+$"
              value={uranium !== null ? uranium : ""}
              onChange={(event) => {
                const value = (event.target as HTMLInputElement).value
                if (value === "") {
                  setUranium(0)
                } else {
                  const parsedValue = parseInt(value, 10)
                  if (!isNaN(parsedValue)) {
                    setUranium(parsedValue)
                  }
                }
              }}
              required
            />

            {/* crystal */}
            <label className={styles.label} htmlFor="crystal">
              {t("Crystal")}
            </label>
            <img
              className={styles.imgResouece}
              key={`lbl-crystal`}
              src={`/assets/icons/resources/crystal.png`}
              width={22}
              alt={`Attack`}
            />
            <input
              type="text"
              name="crystal"
              className={styles.myResource}
              id="crystal"
              step="1"
              min="0"
              pattern="^[0-9]+$"
              value={crystal !== null ? crystal : ""}
              onChange={(event) => {
                const value = (event.target as HTMLInputElement).value
                if (value === "") {
                  setCrystal(0)
                } else {
                  const parsedValue = parseInt(value, 10)
                  if (!isNaN(parsedValue)) {
                    setCrystal(parsedValue)
                  }
                }
              }}
              required
            />
          </div>
          <div className={styles.lineDetails}>
            {t("Transport Ship")} {": "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {player.data?.transportShips}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {t("Capacity")} {": "} {capacity}
          </div>
          <div className={styles.lineDetails}>
            {t("Charging time ")} {":"} {caluleChargingTime()}
            <br />
            {t("Travel duration ")} {": "} {convertToClock(distanceTime, 1)}
            <br />
            {t("Destiny")} {":"} {availabeResources.data?.name}
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <ScifiButton
          onClick={() => {
            props.setAction("default")
          }}
        >
          {t("Cancel")}
        </ScifiButton>
        <ScifiButton
          disabled={disabled || capacity < 0}
          onClick={() => {
            handleSendResource()
          }}
        >
          {t("Send Resource")}
        </ScifiButton>
      </div>
    </>
  )
}

export default SendResource
