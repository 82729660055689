import { PropsWithChildren } from "react"
import { ScifiModal } from "components/feedback"

interface Props {
  title?: string
  open: boolean
  onClose: () => void
}

const BuildModal = (props: PropsWithChildren<Props>) => {
  const { title, children, open, onClose } = props

  return (
    <>
      <ScifiModal
        maxHeight={600}
        isOpen={open}
        title={title}
        onRequestClose={onClose}
      >
        {children}
      </ScifiModal>
    </>
  )
}

export default BuildModal
