import { useState, useEffect } from "react"
import { region } from "data/regions"
import { useTranslation } from "react-i18next"
import { getPlayerDetail } from "data/queries/player"
import styles from "./PlanetDescription.module.scss"

interface Props {
  planet: Planet | null
  quadrant: Quadrant
  position: string
  playerId: number | undefined
}

const PlanetDescription = (props: Props) => {
  const { t } = useTranslation()
  const { planet, quadrant, position, playerId } = props
  const [player, setPlayer] = useState<PlayerPublic>()

  const quadrantNumber = Number(quadrant.quadrant.slice(-3))

  useEffect(() => {
    if (planet !== null) {
      getPlayerDetail(planet.player).then((res) => setPlayer(res.data))
    }
  }, [planet])

  return (
    <div className={styles.page}>
      {planet && playerId && (
        <div>
          <div className={styles.planet}>
            <img
              src={
                quadrantNumber % 4 === 0
                  ? "/assets/planets/planetDesert.png"
                  : quadrantNumber % 4 === 1
                  ? "/assets/planets/planetGrass.png"
                  : quadrantNumber % 4 === 2
                  ? "/assets/planets/planetIce.png"
                  : "/assets/planets/planetVulcan.png"
              }
              width="160px"
              alt="planet"
              draggable={false}
            />
          </div>
          {player && (
            <div>
              <div className={styles.title}>{planet.name}</div>
              <div className={styles.player}>{player.name}</div>
              <div className={styles.aliance}>{player.aliance}</div>
              <div className={styles.score}>
                {t("Score")} {player.score}
              </div>
            </div>
          )}
        </div>
      )}
      {(!planet || !playerId) && (
        <div>
          <div className={styles.planet}>
            <img
              src="/assets/planets/planetInative.png"
              width="160px"
              alt="planet"
              draggable={false}
            />
          </div>
          <div className={styles.title}>Unexplored Planet</div>
        </div>
      )}
      <div className={styles.description}>
        <div>
          {t("Name")}: {region(quadrant.quadrant)}
        </div>
        <div>
          {t("Aliance")}: {region(quadrant.quadrant)}
        </div>
        <div>
          {t("Region")}: {region(quadrant.quadrant)}
        </div>
        <div>
          {t("Quadrant")}: {quadrant.name}
        </div>
        <div>
          {t("Position")}: {quadrant.quadrant}:{position}
        </div>
        <div>
          {t("Distance")}: {quadrant.distance} AU
        </div>
        <div>
          {t("Ore")}: {t("Uranium")}
        </div>
        <div>{t("Atmosphere")}: Vegetal</div>
        <div>{t("Temperature")}: 49</div>
      </div>
    </div>
  )
}

export default PlanetDescription
