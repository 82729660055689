import { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import WalletConnect from "app/sections/WalletConnect"
import { TRITIUM_NFT_ORIGINS } from "config/constants"
import {
  useConnectedWallet,
  useWallet,
  WalletStatus,
  useLcdClient,
} from "@terra-money/wallet-kit"
import { Flex } from "components/layout"
import { ScifiButton } from "components/general"
import { setUseNFT } from "data/queries/nft"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import { nftNameToCode } from "data/nft"
import styles from "./NFTPage.module.scss"
import {
  useConnectedWallet as useConnectedWalletGalaxy,
  useWallet as useWalletGalaxy,
  WalletStatus as WalletStatusGalaxy,
  useLcdClient as useLcdClientGalaxy,
} from "@hexxagon/wallet-kit"
import { useNFTEffect } from "data/queries/nftEffect"
import PaginationButtons from "components/layout/PaginationButtons"

interface Prop {
  slot: number
}

const NFTPage = (props: Prop) => {
  const { t } = useTranslation()
  const { status } = useWallet()
  const { status: statusGalaxy } = useWalletGalaxy()
  const lcd = useLcdClient()
  const lcdGalaxy = useLcdClientGalaxy()
  const queryClient = useQueryClient()

  const [nfts, setNfts] = useState<any>()
  const [nftSelected, setNftSelected] = useState<number>(-1)
  const [nftName, setNftName] = useState<string>("")
  const [effectId, setEffectId] = useState<number>()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const wallet = useConnectedWallet()
  const address = wallet?.addresses["columbus-5"]

  const walletGalaxy = useConnectedWalletGalaxy()
  const addressGalaxy = walletGalaxy?.addresses["columbus-5"]

  const lcdRef = useRef(lcd)
  const lcdGalaxyRef = useRef(lcdGalaxy)

  useEffect(() => {
    if (nftSelected >= 1 && nftSelected <= 68) {
      setEffectId(1)
    } else if (nftSelected >= 69 && nftSelected <= 98) {
      setEffectId(2)
    } else if (nftSelected >= 99 && nftSelected <= 128) {
      setEffectId(3)
    }
  }, [nftSelected])

  const nftEffect = useNFTEffect()
  const effect = nftEffect.data?.find((e: any) => e.id === effectId)

  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        const data =
          statusGalaxy === WalletStatusGalaxy.CONNECTED && addressGalaxy
            ? await lcdGalaxyRef.current.wasm.contractQuery(
                TRITIUM_NFT_ORIGINS,
                {
                  tokens_with_info: { owner: addressGalaxy },
                }
              )
            : await lcdRef.current.wasm.contractQuery(TRITIUM_NFT_ORIGINS, {
                tokens_with_info: { owner: address },
              })

        if (JSON.stringify(nfts) !== JSON.stringify(data)) {
          setNfts(data)
        }
      } catch (error) {
        console.error("Erro ao buscar NFTs:", error)
      }
    }

    if (
      (statusGalaxy === WalletStatusGalaxy.CONNECTED && addressGalaxy) ||
      (status === WalletStatus.CONNECTED && address)
    ) {
      fetchNFTs()
    }
  }, [status, address, statusGalaxy, addressGalaxy, nfts])

  function getRarityClass(rarity: number) {
    switch (rarity) {
      case 1:
        return "common"
      case 2:
        return "uncommon"
      case 3:
        return "rare"
      default:
        return ""
    }
  }

  function getEffectIdFromNftCode(nftCode: number) {
    if (nftCode >= 1 && nftCode <= 68) {
      return 1
    }
    if (nftCode >= 69 && nftCode <= 98) {
      return 2
    }
    if (nftCode >= 99 && nftCode <= 128) {
      return 3
    }
  }

  function getRarity(rarity: number) {
    switch (rarity) {
      case 1:
        return "COMMON"
      case 2:
        return "UNCOMMON"
      case 3:
        return "RARE"
      default:
        return ""
    }
  }

  const itemsPerPage = 12
  const showPagination = totalPages > 1

  useEffect(() => {
    if (nfts) {
      setTotalPages(Math.ceil(nfts.tokens.length / itemsPerPage))
    }
  }, [nfts])

  const handlePrev = () => {
    setCurrentPage(currentPage - 1)
  }

  const handleNext = () => {
    setCurrentPage(currentPage + 1)
  }

  const indexOfLastNft = currentPage * itemsPerPage
  const indexOfFirstNft = indexOfLastNft - itemsPerPage
  const currentNfts = nfts
    ? nfts.tokens.slice(indexOfFirstNft, indexOfLastNft)
    : []

  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          {effect && (
            <>
              <div className={styles.nftSelected}>
                <img
                  src={`/assets/nft/NFT${nftSelected}.jpg`}
                  alt={`NFT ${nftSelected}`}
                />
                <div
                  className={`${styles.rarity} ${
                    styles[getRarityClass(effect.rarity)]
                  }`}
                >
                  {getRarity(effect.rarity)}
                </div>
                <div className={styles.nftName}>{nftName}</div>
              </div>
              <div className={styles.effect}>{effect.effect}</div>
            </>
          )}
          {nftSelected === -1 && (
            <div className={styles.nftEmpty}>{props.slot}</div>
          )}
        </div>
        {status === WalletStatus.NOT_CONNECTED &&
          statusGalaxy === WalletStatusGalaxy.NOT_CONNECTED && (
            <div className={styles.contentInative}>
              <Flex>
                <div className={styles.msg}>
                  {t(
                    "Your wallet is not connected yet, please connect your wallet first"
                  )}
                </div>
              </Flex>
              <Flex>
                <div>
                  <WalletConnect />
                </div>
              </Flex>
            </div>
          )}
        {(status === WalletStatus.CONNECTED ||
          statusGalaxy === WalletStatusGalaxy.CONNECTED) && (
          <div className={styles.content}>
            {currentNfts.map((nft: any, key: number) => {
              const nftCode = Number(nftNameToCode(nft.name))
              const currentEffect = nftEffect.data?.find(
                (e) => e.id === getEffectIdFromNftCode(nftCode)
              )
              return (
                <div
                  className={`${styles.imgNFT} ${
                    styles[getRarityClass(nft.rarity)]
                  }`}
                  key={nft.token_id}
                  onClick={() => {
                    setNftSelected(Number(nftNameToCode(nft.name)))
                    setNftName(nft.name)
                  }}
                >
                  {/* <div className={styles.nftName}>{nft.name}</div> */}
                  {/* <div className={styles.nftDesc}>{nft.boost}</div> */}
                  <img
                    src={"/assets/nft/NFT" + nftNameToCode(nft.name) + ".jpg"}
                    alt={nft.name}
                    className={
                      nftSelected === nft.token_id
                        ? styles.imgNFTSelected
                        : styles.imgNFT
                    }
                  />
                  {currentEffect && (
                    <div
                      className={`${styles.imgNFT} ${
                        styles[getRarityClass(currentEffect.rarity)]
                      }`}
                    ></div>
                  )}
                </div>
              )
            })}

            {currentNfts.length === 0 && (
              <div className={styles.msg}>
                {t("There is no NFT compatible with this game yet")}
              </div>
            )}
            {showPagination && (
              <div className={styles.pagination}>
                <PaginationButtons
                  current={currentPage}
                  total={totalPages}
                  onPrev={currentPage > 1 ? handlePrev : undefined}
                  onNext={currentPage < totalPages ? handleNext : undefined}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <ScifiButton
          onClick={() => {
            setUseNFT(props.slot, -99)
              .then(() => {
                queryClient.invalidateQueries(queryKey.tritium.NFT.config)
              })
              .catch((error) => {
                console.error("Erro ao desconfigurar NFT:", error)
              })
          }}
          size="small"
        >
          {t("Remove")}
        </ScifiButton>
        <ScifiButton
          onClick={() => {
            setUseNFT(props.slot, nftSelected)
              .then((response) => {
                queryClient.invalidateQueries(queryKey.tritium.NFT.config)
              })
              .catch((error) => {
                console.error("Erro ao configurar NFT:", error)
              })
          }}
          size="small"
          disabled={
            (status === WalletStatus.NOT_CONNECTED &&
              statusGalaxy === WalletStatusGalaxy.NOT_CONNECTED) ||
            (nfts && nfts.tokens.length === 0 && nftSelected === -99)
          }
        >
          {t("Insert NFT")}
        </ScifiButton>
      </div>
    </>
  )
}

export default NFTPage
