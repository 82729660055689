import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const getQuadrant = (code: string) => {
  return api.get<Quadrant>(`api/quadrant/show/${code}`, {
    baseURL: TRITIUM,
  })
}

export const useQuadrant = (code: string, planet?: string) => {
  return useQuery(
    [queryKey.tritium.quadrant.quadrant],
    async () => {
      let path = ""

      if (planet) {
        path = `api/quadrant/show/${code}/${planet}`
      } else {
        path = `api/quadrant/show/${code}`
      }

      const { data } = await api.get<Quadrant>(path, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: 0, retry: false } }
  )
}

export const useMyMap = (region: string) => {
  return useQuery(
    [queryKey.tritium.region.quadrant],
    async () => {
      const { data } = await api.get<Quadrant[]>(`api/quadrant/map/${region}`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useCaluleDestiny = (origin: string, destiny?: string) => {
  return useQuery(
    [queryKey.tritium.region.quadrant],
    async () => {
      const { data } = await api.get<Quadrant>(
        `api/quadrant/calule-distancte/${origin}/${destiny}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: 0, retry: false } }
  )
}
