import { useTranslation } from "react-i18next"
import { useState } from "react"
import { Button } from "components/general"
import styles from "./Aliance.module.scss"
import { Card, Col, Row } from "components/layout"
import Details from "./AlianceDetails"
import Members from "./AlianceMembers"
import Scores from "./AlianceScores"

interface Option {
  label: string
  value: number
}

const options: Option[] = [
  {
    label: "Details",
    value: 1,
  },
  {
    label: "Members",
    value: 2,
  },
  {
    label: "Scores",
    value: 3,
  },
]

interface AlianceDataProps {
  changeScreen: (newScreen: number) => void
  aliance: Aliances
}
const AlianceData = (AlianceData: AlianceDataProps) => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState<number>(1)
  const { aliance, changeScreen } = AlianceData

  const [screen, setScreen] = useState<number>(1)

  const handleOptionClick = (option: number) => {
    setSelectedOption(option)
    if (option === 4) {
      handleOptionEditAliance(option)
      return
    }

    const optSelecionado = options.find((op) => op.value === option)
    setScreen(optSelecionado?.value ?? 1)
  }

  const handleOptionEditAliance = (screen: number) => {
    if (screen === 0) {
      changeScreen(0)
      return
    }
    if (screen === 4) {
      changeScreen(4)
      return
    }
    setSelectedOption(screen)
    setScreen(screen)
  }

  return (
    <div>
      <>
        {options.map((option) => (
          <Button
            key={option.value}
            className={
              selectedOption === option.value
                ? styles.activeMenu
                : styles.buttonMenu
            }
            onClick={() => handleOptionClick(option.value)}
          >
            {t(`${option.label}`)}
          </Button>
        ))}
        <br />
        <br />
        <div className={styles.divider} />
      </>
      <br />
      <Row>
        <Col>
          <Card>
            {screen === 1 && (
              <Details
                aliance={aliance}
                handleOptionEditAliance={handleOptionEditAliance}
              />
            )}
            {screen === 2 && (
              <Members
                idAliance={Number.parseInt(aliance.id)}
                founderId={aliance.founder}
              />
            )}
            {screen === 3 && <Scores aliance={aliance} />}
            {screen === 4 && <h1>Vai la editar</h1>}
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default AlianceData
