export const region = (quadrant: string) => {
  const regions = [
    ["Andromeda Arm"],
    ["Blazing Blue"],
    ["Centauri Citadel"],
    ["Draco Dominion"],
    ["Eridanus Enclave"],
    ["Felicity Falls"],
    ["Ghostly Grove"],
    ["Harmony Harbor"],
    ["Iris Interstice"],
    ["Juniper Junction"],
    ["Kronos Keep"],
    ["Luminous Lagoon"],
    ["Misty Moors"],
    ["Nova Nexus"],
    ["Onyx Oasis"],
    ["Polaris Pathway"],
  ]

  return regions[quadrant.charAt(0).charCodeAt(0) - 65]
}
