import { PropsWithChildren } from "react"
import { Stars } from "@react-three/drei"

interface Props {
  radiusStart?: number
  radiusCustom?: number
  count?: number
  fator?: number
}

const StarsCollect = (props: PropsWithChildren<Props>) => {
  const { radiusStart, count, fator } = props
  return (
    <>
      <Stars
        radius={radiusStart ? radiusStart : 100}
        depth={100}
        count={count ? count : 1800}
        factor={fator ? fator : 4}
        saturation={1}
        speed={1}
        fade
      />
    </>
  )
}

export default StarsCollect
