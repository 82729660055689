import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  changeGameMode,
  useGameModeList,
  useGameModeSelected,
} from "data/queries/gameMode"
import { Button } from "components/general"
import GameMode from "./resource/GameMode"
import styles from "./Commanders.module.scss"
import ConversationData from "components/conversation/ConversationData"
import NFTGameMode from "./components/NFTGameMode"
import ColonizeMissions from "./components/ColonizeMissions"
import { useNFTEffect } from "data/queries/nftEffect"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import { Timer } from "components/display"

interface Props {
  mode: Mode[] | undefined
}

interface Option {
  label: string
  value: string
}

const options: Option[] = [
  {
    label: "Game Mode",
    value: "gameMode",
  },
  {
    label: "Missions",
    value: "missions",
  },
  {
    label: "NFT",
    value: "nft",
  },
  {
    label: "Message",
    value: "message",
  },
]

const ModeList = ({ mode }: Props) => {
  const { t } = useTranslation()

  const [selectedOption, setSelectedOption] = useState("gameMode")
  const [selectedMode, setSelectedMode] = useState<Mode | null>()
  const [selectedEffect, setSelectedEffect] = useState<string | undefined>("")
  const [changeIsAvailable, setChangeIsAvailable] = useState(false)
  const queryClient = useQueryClient()
  const planetId = localStorage.getItem("planet")

  const playerModeSelect = useGameModeSelected(planetId ?? "0")
  const availableChangeMode =
    Number(playerModeSelect.data.gameModeUpdated ?? 0) + 86400 * 2

  console.log("banco ", playerModeSelect.data.gameModeUpdated)

  const nftEffect = useNFTEffect()

  function getEffectIdFromNftCode(nftCode: number) {
    if (nftCode >= 1 && nftCode <= 68) {
      return 1
    }
    if (nftCode >= 69 && nftCode <= 98) {
      return 2
    }
    if (nftCode >= 99 && nftCode <= 128) {
      return 3
    }
  }

  const handleNftSelect = (nftCode: number) => {
    const effectId = getEffectIdFromNftCode(nftCode)
    const effect = nftEffect.data?.find((e) => e.id === effectId)
    setSelectedEffect(effect ? effect.effect : "")
  }

  useEffect(() => {
    const storedSelectedModeCode = localStorage.getItem("selectedModeCode")
    if (storedSelectedModeCode) {
      const foundMode = mode?.find(
        (m) => m.code === parseInt(storedSelectedModeCode)
      )
      if (foundMode) {
        setSelectedMode(foundMode)
      }
    }
    const updatedChangeMode =
      Number(playerModeSelect.data?.gameModeUpdated ?? 0) + 86400 * 2
    const now = new Date().getTime()
    setChangeIsAvailable(now > updatedChangeMode * 1000)
  }, [mode, playerModeSelect.data?.gameModeUpdated])

  const handleGameModeSelect = async (selectedGameMode: Mode) => {
    if (!changeIsAvailable) return

    localStorage.setItem("selectedModeCode", selectedGameMode.code.toString())

    try {
      await changeGameMode(selectedGameMode.code.toString())
      const newUpdateTimestamp = Math.floor(Date.now() / 1000)
      localStorage.setItem("gameModeUpdated", newUpdateTimestamp.toString())

      const newAvailableChangeMode = newUpdateTimestamp + 86400 * 2
      localStorage.setItem(
        "newAvailableChangeMode",
        newAvailableChangeMode.toString()
      )
      setChangeIsAvailable(false)
      setTimeout(() => {
        setChangeIsAvailable(true)
      }, 86400 * 2 * 1000)

      queryClient.invalidateQueries(queryKey.tritium.build.availableBuilds)
      queryClient.invalidateQueries(queryKey.tritium.gameMode.gameModeSelected)
      queryClient.invalidateQueries(queryKey.tritium.planet.planet)
      queryClient.invalidateQueries(
        queryKey.tritium.gameMode.gameModePlayerSelected
      )
    } catch (e) {
      console.error(e)
    }
  }

  const handleOptionClick = (option: string) => {
    setSelectedOption(option)
  }

  return (
    <div>
      <div className={styles.top}>
        {options.map((option) => (
          <Button
            key={option.value}
            className={
              selectedOption === option.value ? styles.active : styles.button
            }
            onClick={() => handleOptionClick(option.value)}
          >
            {t(`${option.label}`)}
          </Button>
        ))}
      </div>

      {selectedOption !== "message" && <div className={styles.line}></div>}

      <div className={styles.commanderScreen}>
        {selectedOption === "gameMode" && selectedMode && (
          <div>
            {!changeIsAvailable && (
              <div className={styles.timeChangeMode}>
                {t("Mode switch available at :")}
                <div className={styles.timer}>
                  <Timer end={availableChangeMode * 1000} />
                </div>
              </div>
            )}
            <div className={styles.text}>
              {t(`${selectedMode.description}`)}
            </div>
          </div>
        )}

        {selectedOption === "missions" && <div>{""}</div>}
        {selectedOption === "nft" && (
          <>
            <div className={styles.effect}>{selectedEffect}</div>
            <div className={styles.contentBlock}>
              <div className={styles.nftList}>
                <NFTGameMode onNftSelect={handleNftSelect} />
              </div>
            </div>
          </>
        )}
      </div>

      {selectedOption === "gameMode" && (
        <div className={styles.ranking}>
          <GameMode
            mode={mode}
            onSelect={handleGameModeSelect}
            changeIsAvailable={changeIsAvailable}
          />
        </div>
      )}

      {selectedOption === "missions" && (
        <div className={styles.classification}>
          <ColonizeMissions />
        </div>
      )}

      {selectedOption === "message" && (
        <div style={{ width: "72%", color: "white" }}>
          <ConversationData />
        </div>
      )}
    </div>
  )
}

const Player = () => {
  const mode = useGameModeList()

  return (
    <div>
      <ModeList mode={mode.data} />
    </div>
  )
}

export default Player
