import { useTranslation } from "react-i18next"
import { convertToClock } from "utils/time"
import styles from "./buildings/BuildDetail.module.scss"
import { TRITIUM_CONSTRUCTION_SPEED } from "config/constants"

interface Props {
  requires: Require
}

const UpgradeRequire = (props: Props) => {
  const { requires } = props
  const { t } = useTranslation()

  function formatNumber(value: number) {
    if (value >= 1000000000) {
      const billions = value / 1000000000
      return (Math.floor(billions * 100) / 100).toFixed(1) + "B"
    } else if (value >= 1000000) {
      const millions = value / 1000000
      return (Math.floor(millions * 100) / 100).toFixed(1) + "M"
    } else if (value >= 1000) {
      const thousands = value / 1000
      return (Math.floor(thousands * 100) / 100).toFixed(1) + "k"
    } else {
      return value.toString()
    }
  }

  return (
    <>
      <div className={styles.upResources}>
        <div>
          <img
            src="/assets/icons/resources/metal.png"
            width={40}
            alt={t("Metal")}
          />
          <span style={{ color: "#FFC100", fontWeight: 600 }}>
            {formatNumber(Number(requires.metal))}
          </span>
          <img
            src="/assets/icons/resources/uranium.png"
            width={40}
            alt={t("Uranium")}
          />
          <span style={{ color: "#C806DD", fontWeight: 600 }}>
            {formatNumber(Number(requires.uranium))}
          </span>
          <img
            src="/assets/icons/resources/crystal.png"
            width={40}
            alt={t("Crystal")}
          />
          <span style={{ color: "#00A7F5", fontWeight: 600 }}>
            {formatNumber(Number(requires.crystal))}
          </span>
          <br />
          <img
            src="/assets/icons/resources/time.png"
            width={40}
            alt={t("Time")}
          />
          <span className={styles.resourceCount}>
            {convertToClock(requires.time * TRITIUM_CONSTRUCTION_SPEED)}
          </span>
        </div>
      </div>
      <br />
    </>
  )
}

export default UpgradeRequire
