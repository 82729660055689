import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Tooltip } from "@mui/material"
import PlanetList from "components/planets/PlanetList"
import PlanetImage from "styles/images/navigation/planet.png"
import OnFireAlert from "styles/images/animations/alert.gif"
import styles from "./CentralNavigator.module.scss"

interface Props {
  planet: Planet
}

const PlanetView = (props: Props) => {
  const { planet } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentPlanet = localStorage.getItem("planet")
  const currentPlanetName = localStorage.getItem("planetName")

  const [planetList, setPlanetList] = useState(false)

  const navigateToPlanet = () => {
    if (document.location.pathname.includes("/game/planet/")) {
      setPlanetList(true)
    } else {
      navigate("/game/planet/" + currentPlanet)
    }
  }

  return (
    <div className={styles.planetView}>
      <div>
        <img
          onClick={navigateToPlanet}
          width={72}
          height={72}
          src={PlanetImage}
          alt="planet"
          draggable={false}
        />
        {planet.onFire === 1 && (
          <Tooltip title={t("This planet is under attack")}>
            <img
              onClick={navigateToPlanet}
              className={styles.onFire}
              src={OnFireAlert}
              alt="on fire"
              draggable={false}
            />
          </Tooltip>
        )}
      </div>
      <div className={styles.selectorDescription}>
        <div className={styles.planetName}>{t("Planet")}</div>
        <div className={styles.quadrant}>{currentPlanetName}</div>
      </div>
      <PlanetList planet={0} open={planetList} setOpen={setPlanetList} />
    </div>
  )
}

export default PlanetView
