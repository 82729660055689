import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { ScifiButton } from "components/general"
import { TRITIUM_ENERGY_BASE } from "config/constants"
import { Flex } from "components/layout"
import Upgrade from "../Upgrade"
import CircularSlider from "@fseehawer/react-circular-slider"
import mp3Click from "sounds/toggle.mp3"
import styles from "./BuildDetail.module.scss"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
}

const Shield = (props: PropsWithChildren<Props>) => {
  const { building, build, setIsOpen } = props
  const { t } = useTranslation()

  const soundClick = new Audio(mp3Click)

  return (
    <>
      <div>
        <span className={styles.level}>{Number(building.level)}</span>
        <span>{t("level")}</span>
      </div>
      <div style={{ display: "flex" }}>
        <div>
          <img
            src={`/assets/builds/build-13.png`}
            alt="Shield"
            className={styles.buildImage}
            draggable={false}
          />
          <Upgrade setIsOpen={setIsOpen} build={build} building={building} />
        </div>
        <div className={styles.buildContent}>
          <div style={{ width: "100%", padding: "20px" }}>
            <Flex>
              <CircularSlider
                width={160}
                min={0}
                max={100}
                dataIndex={TRITIUM_ENERGY_BASE * Number(building.level)}
                label={t("Shield Level")}
                labelColor={"#f1f1f1"}
                progressColorFrom="#5850B4"
                progressColorTo="#5850B4"
                knobColor="#fefb53"
                knobDraggable={false}
                trackColor="#5850B434"
                valueFontSize="18px"
                hideKnob={true}
                knobPosition="left"
                progressLineCap="flat"
              />
            </Flex>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <ScifiButton
          onClick={() => {
            soundClick.play()
          }}
          size="small"
        >
          {t("Save")}
        </ScifiButton>
      </div>
    </>
  )
}

export default Shield
