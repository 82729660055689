import { useState } from "react"
import { readLog, useLogs } from "data/queries/log"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import styles from "./Logger.module.scss"

const Logger = () => {
  const logs = useLogs()
  const [open, setOpen] = useState(false)

  const removeLog = (id: string) => {
    readLog(id).finally(() => {
      logs.refetch()
    })
  }

  const hasLogs = Array.isArray(logs.data) && logs.data.length > 0

  return (
    <div>
      <div
        className={styles.open}
        onClick={() => {
          setOpen(!open)
        }}
      >
        {":::::::::::::"}
      </div>
      {hasLogs && ( // This will only render if there are logs
        <div
          className={styles.notificationsCount}
          onClick={() => {
            setOpen(!open)
          }}
        >
          {logs.data?.length}
        </div>
      )}
      <div className={styles.openBord}></div>
      <div className={open ? styles.hide : styles.display}>
        {hasLogs &&
          logs.data?.map((log, idx) => (
            <div key={idx} className={styles.log}>
              {log.text}
            </div>
          ))}
      </div>
      <div className={styles.swipe}>
        <SwipeableDrawer
          variant="persistent"
          PaperProps={{
            sx: {
              height: `calc(60vh)`,
              background: "transparent",
              width: "440px",
            },
          }}
          anchor="bottom"
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          onOpen={() => {}}
        >
          <div className={styles.content}>
            {hasLogs &&
              logs.data?.map((log, idx) => (
                <div key={idx} className={styles.log}>
                  <div className={styles.text}>
                    <span className={styles.type}>
                      {"["}
                      {log.type}
                      {"]"}
                    </span>{" "}
                    {log.text}
                  </div>
                  <div
                    className={styles.confirm}
                    onClick={() => removeLog(log.id)}
                  >
                    x
                  </div>
                </div>
              ))}
          </div>
        </SwipeableDrawer>
      </div>
    </div>
  )
}

export default Logger
