import {
  TRITIUM_MODE_BUILDER,
  TRITIUM_MODE_COLONIZER,
  TRITIUM_MODE_ENGINEER,
  TRITIUM_MODE_MINER,
  TRITIUM_MODE_NAVIGATOR,
  TRITIUM_MODE_RESEARCHER,
  TRITIUM_MODE_SPACE_TITAN,
} from "config/constants"
import {
  useGameModePlayerSelected,
  useGameModeSelected,
} from "data/queries/gameMode"
import { useState, useEffect } from "react"

export const useSpeedMining = (planet: string) => {
  const [speedMining, setSpeedMining] = useState(0)
  const gameModeEffect = useGameModeSelected(planet)

  useEffect(() => {
    if (gameModeEffect.data) {
      setSpeedMining(
        [TRITIUM_MODE_SPACE_TITAN, TRITIUM_MODE_MINER].includes(
          gameModeEffect.data.gameMode
        )
          ? gameModeEffect.data.speedMining
          : 0
      )
    }
  }, [gameModeEffect.data])

  return speedMining
}

export const useDiscountBuild = (planet: string) => {
  const [discountBuild, setDiscountBuild] = useState(0)
  const gameModeEffect = useGameModeSelected(planet)

  useEffect(() => {
    if (gameModeEffect.data) {
      setDiscountBuild(
        [TRITIUM_MODE_COLONIZER, TRITIUM_MODE_BUILDER].includes(
          gameModeEffect.data.gameMode
        )
          ? gameModeEffect.data.discountBuild
          : 0
      )
    }
  }, [gameModeEffect.data])

  return discountBuild
}

export const useShipsConstructionSpeed = (planet: string) => {
  const gameModeEffect = useGameModeSelected(planet)
  const [constructionSpeed, setConstructionSpeed] = useState(0)

  useEffect(() => {
    if (gameModeEffect.data) {
      setConstructionSpeed(
        [
          TRITIUM_MODE_ENGINEER,
          TRITIUM_MODE_BUILDER,
          TRITIUM_MODE_NAVIGATOR,
        ].includes(gameModeEffect.data.gameMode)
          ? gameModeEffect.data.speedProduceShip
          : 0
      )
    }
  }, [gameModeEffect.data])

  return constructionSpeed
}

export const useRobotConstructionSpeed = (planet: string) => {
  const gameModeEffect = useGameModeSelected(planet)
  const [constructionSpeed, setConstructionSpeed] = useState(0)

  useEffect(() => {
    if (gameModeEffect.data) {
      setConstructionSpeed(
        [
          TRITIUM_MODE_SPACE_TITAN,
          TRITIUM_MODE_BUILDER,
          TRITIUM_MODE_NAVIGATOR,
        ].includes(gameModeEffect.data.gameMode)
          ? gameModeEffect.data.speedProduceUnit
          : 0
      )
    }
  }, [gameModeEffect.data])

  return constructionSpeed
}

export const useConstructionBuildSpeed = (planet: string) => {
  const gameModeEffect = useGameModeSelected(planet)
  const [constructionBuildSpeed, setConstructionBuildSpeed] = useState(0)

  useEffect(() => {
    if (gameModeEffect.data) {
      setConstructionBuildSpeed(
        [TRITIUM_MODE_RESEARCHER].includes(gameModeEffect.data.gameMode)
          ? gameModeEffect.data.speedConstructionBuild
          : 0
      )
    }
  }, [gameModeEffect.data])

  return constructionBuildSpeed
}

export const useResearchSpeed = (playerId: string) => {
  const gameModeEffect = useGameModePlayerSelected(playerId)
  const [researchSpeed, setResearchSpeed] = useState(0)
  useEffect(() => {
    if (gameModeEffect.data) {
      setResearchSpeed(
        [
          TRITIUM_MODE_RESEARCHER,
          TRITIUM_MODE_SPACE_TITAN,
          TRITIUM_MODE_ENGINEER,
        ].includes(gameModeEffect.data.gameMode)
          ? gameModeEffect.data.speedResearch
          : 0
      )
    }
  }, [gameModeEffect.data])

  return researchSpeed
}
