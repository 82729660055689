import { useQuery } from "react-query"
import { queryKey, RefetchOptions } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const useCombat = () => {
  return useQuery(
    [queryKey.tritium.combat.combats],
    async () => {
      const { data } = await api.get<Figther[]>("api/combat/list", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const useCurrentCombat = (combat: string) => {
  return useQuery(
    [queryKey.tritium.combat.currentCombat],
    async () => {
      const { data } = await api.get<Combat>(`api/combat/current/${combat}`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const useCombatLogs = (combat: string) => {
  return useQuery(
    [queryKey.tritium.combat.CombatLogs],
    async () => {
      const { data } = await api.get<CombatLog[]>(
        `api/combat/stages/${combat}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const useFigthers = (combat: string) => {
  return useQuery(
    [queryKey.tritium.combat.figthers],
    async () => {
      const { data } = await api.get<Figther[]>(
        `/api/combat/figthers/${combat}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const useStrategies = () => {
  return useQuery(
    [queryKey.tritium.combat.strategies],
    async () => {
      const { data } = await api.get<Strategy[]>(`api/combat/strategy/list`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}
export const useStrategySelected = (planet: string) => {
  return useQuery(
    [queryKey.tritium.combat.strategiesSelected],
    async () => {
      const { data } = await api.get<StrategySelected>(
        `api/combat/strategy/selected/${planet}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const useCheckNumberPlanets = () => {
  return useQuery(
    [queryKey.tritium.combat.numberPlanets],
    async () => {
      const { data } = await api.get<any>(`api/combat/check-number-planets`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}
/**Nave disponível */
export const useAvailableShip = () => {
  return useQuery(
    [queryKey.tritium.combat.availableShip],
    async () => {
      const { data } = await api.get<any>(`api/combat/available-ship`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const useAvailableResources = (planet: string) => {
  return useQuery(
    [queryKey.tritium.combat.availableResources],
    async () => {
      const { data } = await api.get<Planet>(
        `api/combat/available-resources/${planet}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}

export const colonizerPlanet = (planet: string) => {
  return api.put<Return>(TRITIUM + `/api/combat/colonizer/${planet}`)
}

export const leaveCombat = (combat: string) => {
  return api.post<Return>(TRITIUM + `/api/combat/space-leave/${combat}`)
}

export const changeStrategy = (
  planet: string,
  type: string,
  newStrategy: number
) => {
  return api.put<Return>(
    TRITIUM + `/api/combat/strategy/${planet}/${type}/${newStrategy}`
  )
}

interface Resources {
  metal: number
  uranium: number
  crystal: number
  target: string
  origin: string
}
export const sendResources = (resources: Resources) => {
  return api.post<Return>(TRITIUM + `/api/combat/sendresource`, resources)
}
