import { Tooltip } from "@mui/material"
import { Timer } from "components/display"
import styles from "./Military.module.scss"

interface Props {
  ships: FleetUnitUnderConstruction[] | undefined
}

const FleetOnConstruct = (props: Props) => {
  const { ships } = props

  return (
    <div>
      {ships &&
        ships &&
        ships.map((element, idx) => (
          <div key={idx}>
            {Date.parse(new Date().toString()) / 1000 - element.ready < 0 && (
              <Tooltip key={idx} title={element.name}>
                <div className={styles.unitOnConstruct}>
                  <img
                    className={styles.unitImg}
                    src={"/assets/ships/" + element.image}
                    alt={element.name}
                    draggable="false"
                  />
                  <div className={styles.quantityOnConstruct}>
                    {element.quantity}
                  </div>
                  <div className={styles.timer}>
                    <Timer end={element.ready * 1000} />
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
        ))}
    </div>
  )
}

export default FleetOnConstruct
