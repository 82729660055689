import { useTranslation } from "react-i18next"
import { Row, Col } from "components/layout"
import TroopOnConsturct from "components/commanders/military/TroopOnConstruct"
import Upgrade from "components/builds/Upgrade"
import styles from "./TrainingShip.module.scss"
import { useFleetsOnConstruct } from "data/queries/fleet"

interface Props {
  building: Building
  build: Build
  setIsOpen: any
  planet: string
}

const TrainingShip = (props: Props) => {
  const { t } = useTranslation()
  const { building, build, setIsOpen, planet } = props

  // const troopOnConsturct = useTroopsOnConstruct(planet)

  const fleetOnConstruct = useFleetsOnConstruct(planet)

  return (
    <div>
      <Row>
        <Col>
          <div>
            <span className={styles.level}>{Number(building.level)}</span>
            <span>{t("level")}</span>
          </div>
          <div style={{ display: "flex", padding: "0px", margin: "0px" }}>
            <div style={{ marginRight: "14px" }}>
              <img
                src={`/assets/builds/build-10.png`}
                alt={t("Shipyard")}
                className={styles.buildImage}
                draggable={false}
                style={{ width: "220px" }}
              />
              <Upgrade
                setIsOpen={setIsOpen}
                build={build}
                building={building}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className={styles.buildContent}>
            <TroopOnConsturct troops={fleetOnConstruct.data} type="fleet" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default TrainingShip
