export const BuildKeys = {
  COLONIZATION: 1,
  ENERGYCOLLECTOR: 2,
  HUMANOIDFACTORY: 3,
  METALMINING: 4,
  URANIUMMINING: 5,
  CRYSTALMINING: 6,
  LABORATORY: 7,
  WAREHOUSE: 8,
  SHIPYARD: 9,
  BATERYHOUSE: 10,
  MILITARYCAMP: 11,
  SHIELD: 12,
  MARKET: 13,
  GALACTICCOUNCIL: 14,
  TRITIUMMINING: 15,
}
