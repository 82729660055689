import { useState, useContext } from "react"
import { Input } from "components/form"
import { useNavigate } from "react-router-dom"
import { GameContext } from "pages/GameContext"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import { useTranslation } from "react-i18next"
import { Row, Col } from "components/layout"
import { Slider } from "@mui/material"
import { metalBalance } from "data/queries/balance"
import { ScifiModal } from "components/feedback"
import { ScifiButton } from "components/general"
import { produceShips } from "data/queries/production"
import { TRITIUM_PRODUCTION_SPEED } from "config/constants"
import ProgressBar from "components/feedback/ProgressBar"
import styles from "./Ships.module.scss"
import {
  useShipsConstructionSpeed,
  useSpeedMining,
} from "utils/hooks/gameModeEffect"
import { calcSpeedProduction } from "utils/effect"

interface Props {
  ships: Ship[]
  currentShip: Ship | null
  setcurrentShip: any
  building: Building
}

const Ship = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { ships, currentShip, setcurrentShip, building } = props

  const { planetData } = useContext(GameContext)
  const planet = planetData instanceof Array ? planetData[0] : planetData

  const workers = Number(building.workers)
  const [qtdShips, setQtdShips] = useState<number>(0)
  const [alert, setAlert] = useState<boolean>(false)
  const speedMining = useSpeedMining(planet?.id ?? "0")
  const constructShipSpeed = useShipsConstructionSpeed(planet?.id ?? "0")

  function maxUnits() {
    if (planet !== undefined && currentShip !== null) {
      return Number(
        (
          Number(metalBalance(planet, speedMining)) / Number(currentShip.metal)
        ).toFixed(0)
      )
    } else {
      return 0
    }
  }

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0")
  }

  function convertMsToTime(milliseconds: number) {
    milliseconds = calcSpeedProduction(milliseconds, constructShipSpeed)
    let seconds = Math.floor(milliseconds / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)

    seconds = seconds % 60
    minutes = minutes % 60

    hours = hours % 24

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
      seconds
    )}`
  }

  function formatNumber(value: number) {
    if (value >= 1000000000) {
      const billions = value / 1000000000
      return (Math.floor(billions * 100) / 100).toFixed(2) + "B"
    } else if (value >= 1000000) {
      const millions = value / 1000000
      return (Math.floor(millions * 100) / 100).toFixed(2) + "M"
    } else if (value >= 1000) {
      const thousands = value / 1000
      return (Math.floor(thousands * 100) / 100).toFixed(2) + "k"
    } else {
      return value.toString()
    }
  }

  return (
    <div>
      <Row>
        <div>
          {ships &&
            ships.map((element) => (
              <div key={element.id} style={{ float: "left" }}>
                <>{currentShip === null && setcurrentShip(element)}</>
                {currentShip !== null && (
                  <div
                    className={
                      currentShip.id === element.id
                        ? styles.unitSelected
                        : styles.unit
                    }
                    onClick={() => {
                      setcurrentShip(element)
                    }}
                  >
                    <img
                      src={`/assets/ships/${element.image}`}
                      alt={element.image}
                      draggable={false}
                    />
                  </div>
                )}
              </div>
            ))}
        </div>
      </Row>
      <Row>
        <div>
          {currentShip && (
            <div className={styles.buildContent}>
              <Row>
                <div className={styles.unitName}>{currentShip.name}</div>
              </Row>
              <div style={{ width: "660px", padding: "10px" }}>
                <Row>
                  <Col>
                    <div>
                      <span className={styles.statusLabel}>{t("Attack")}</span>
                      <ProgressBar
                        filledColor="#5850B4"
                        color="#5850B431"
                        size="small"
                        currentValue={currentShip.attack}
                        maxValue={100}
                        bars={Number(15)}
                      />
                    </div>
                    <div>
                      <span className={styles.statusLabel}>{t("Defense")}</span>
                      <ProgressBar
                        filledColor="#5850B4"
                        color="#5850B431"
                        size="small"
                        currentValue={currentShip.defense}
                        maxValue={100}
                        bars={Number(15)}
                      />
                    </div>
                    <div>
                      <span className={styles.statusLabel}>{t("Life")}</span>
                      <ProgressBar
                        filledColor="#5850B4"
                        color="#5850B431"
                        size="small"
                        currentValue={currentShip.life}
                        maxValue={100}
                        bars={Number(15)}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <img
                        className={styles.imageDescription}
                        src={`/assets/ships/${currentShip.image}`}
                        alt={currentShip?.name}
                        width={260}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "center" }}>
                      <span
                        className={styles.statusLabel}
                        style={{ left: "0" }}
                      >
                        {t("Type")}
                      </span>
                      <div className={styles.tag2}>{currentShip.type}</div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <span
                        className={styles.statusLabel}
                        style={{ left: "0" }}
                      >
                        {t("Range")}
                      </span>
                      <div className={styles.tag2}>2</div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <div className={styles.unitDescription}>
                  {t(`${currentShip?.description}`)}
                </div>
              </div>
              <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                  <Row>
                    <Col span={3}>
                      <Slider
                        min={0}
                        max={maxUnits()}
                        value={Number(qtdShips)}
                        onChange={(
                          event: Event,
                          value: number | number[],
                          activeThumb: number
                        ) => {
                          setQtdShips(Number(value))
                        }}
                        valueLabelDisplay="auto"
                        sx={{
                          color: "#5850B4",
                          "& .MuiSlider-thumb": {
                            borderRadius: "1px",
                          },
                          "& .MuiSlider-rail": {
                            borderRadius: "0px",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Input
                      style={{ fontSize: "18px", width: "640px" }}
                      min={0}
                      onChange={(e) => {
                        setQtdShips(Number(e.target.value))
                      }}
                      type="number"
                      value={qtdShips}
                      className={styles.input}
                    />
                  </Row>
                </div>
                <Row>
                  <Col>
                    <div className={styles.unitResources}>
                      <img
                        src="/assets/icons/resources/metal.png"
                        width={40}
                        alt={t("Metal")}
                      />
                      <span style={{ color: "#FFC100", fontWeight: 600 }}>
                        {formatNumber(
                          qtdShips > 0
                            ? qtdShips * Number(currentShip?.metal ?? 0)
                            : Number(currentShip?.metal ?? 0)
                        )}
                      </span>
                      <img
                        src="/assets/icons/resources/uranium.png"
                        width={40}
                        alt={t("Uranium")}
                      />
                      <span style={{ color: "#C806DD", fontWeight: 600 }}>
                        {formatNumber(
                          qtdShips > 0
                            ? qtdShips * Number(currentShip?.uranium ?? 0)
                            : Number(currentShip?.uranium ?? 0)
                        )}
                      </span>
                      <img
                        src="/assets/icons/resources/crystal.png"
                        width={40}
                        alt={t("Crystal")}
                      />
                      <span style={{ color: "#00A7F5", fontWeight: 600 }}>
                        {formatNumber(
                          qtdShips > 0
                            ? qtdShips * Number(currentShip?.crystal ?? 0)
                            : Number(currentShip?.crystal ?? 0)
                        )}
                      </span>
                      <div className={styles.clock}>
                        <img
                          src="/assets/icons/resources/time.png"
                          width={40}
                          alt={t("Time")}
                        />
                        <span className={styles.resourceCount}>
                          {qtdShips > 0
                            ? convertMsToTime(
                                qtdShips *
                                  (Number(currentShip?.time ?? 0) / workers) *
                                  1000 *
                                  TRITIUM_PRODUCTION_SPEED
                              )
                            : convertMsToTime(
                                (Number(currentShip?.time ?? 0) / workers) *
                                  1000 *
                                  TRITIUM_PRODUCTION_SPEED
                              )}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    {currentShip && (
                      <ScifiButton
                        onClick={() => {
                          if (
                            ships &&
                            planet &&
                            qtdShips > 0 &&
                            qtdShips * Number(currentShip.metal) <=
                              Number(planet.metal) &&
                            qtdShips * Number(currentShip.uranium) <=
                              Number(planet.uranium) &&
                            qtdShips * Number(currentShip.crystal) <=
                              Number(planet.crystal)
                          ) {
                            produceShips(
                              planet.id,
                              currentShip.id,
                              qtdShips
                            ).then((res) => console.log("aki na execução"))

                            queryClient.invalidateQueries(
                              queryKey.tritium.troop.troopUnderconstruction
                            )
                            setAlert(true)
                          }
                        }}
                        disabled={
                          !(
                            ships &&
                            planet &&
                            qtdShips > 0 &&
                            qtdShips * Number(currentShip.metal) <=
                              Number(planet.metal) &&
                            qtdShips * Number(currentShip.uranium) <=
                              Number(planet.uranium) &&
                            qtdShips * Number(currentShip.crystal) <=
                              Number(planet.crystal)
                          )
                        }
                        size="small"
                      >
                        {t("Produce")}
                      </ScifiButton>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>
      </Row>
      <ScifiModal isOpen={alert} onRequestClose={() => setAlert(false)}>
        <div style={{ textAlign: "center" }}>
          <br />
          <br />
          {t("Success, new units queued for construction")}
          <br />
          <br />
          <ScifiButton
            onClick={() => {
              setAlert(false)
              navigate("#training")
            }}
          >
            {t("Ok")}
          </ScifiButton>
        </div>
      </ScifiModal>
    </div>
  )
}

export default Ship
