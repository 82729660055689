import { useTranslation } from "react-i18next"
import { joinAlliance, useDisponibleAliances } from "data/queries/aliance"
// import { Table } from "components/layout";\
import { BackBar } from "./Aliance"
import disableScroll from "disable-scroll"
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined"
import styles from "./Aliance.module.scss"
import SearchIcon from "@mui/icons-material/Search"
import { useState } from "react"
import { Modal } from "components/feedback"
import { Button } from "components/general"
import DoneAllIcon from "@mui/icons-material/DoneAll"

interface DisponibleAliancesProps {
  changeScreen: (newScreen: number) => void
}
const DisponibleAliances = (DisponibleAliances: DisponibleAliancesProps) => {
  const { t } = useTranslation()
  const aliances = useDisponibleAliances()
  const { changeScreen } = DisponibleAliances
  const [confirmAction, setConfirmAction] = useState<boolean>(false)

  const handleRequestAccess = (idAliance: string, status: string) => {
    if (status === "F") {
      setConfirmAction(true)
    }
    joinAlliance(idAliance)
      .then((res) => changeScreen(0))
      .catch((e) => console.log("erro ao solicitar", e))
  }

  const modalConfirm = !confirmAction
    ? undefined
    : {
        title: t("Confirmar"),
        children: (
          <section>
            <section>
              {t(
                "This league is closed, your request has been sent to the founder!"
              )}
            </section>
          </section>
        ),
      }

  return (
    <div>
      <BackBar changeScreen={changeScreen} />

      {aliances.data && (
        <div>
          <div className={styles.searchContainer}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Pesquisar"
            />
            <span className={styles.searchIcon}>
              <SearchIcon />
            </span>
          </div>
          <div
            style={{ height: "380px", overflowX: "auto" }}
            onWheel={(e) => {
              disableScroll.off()
            }}
            onMouseOut={(e) => {
              disableScroll.on()
            }}
          >
            <table
              className={styles.tableAliance}
              onMouseOut={(e) => {
                disableScroll.on()
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>{"##"}</th>
                  <th style={{ width: "50%" }}> {t("Name")}</th>
                  <th style={{ width: "25%" }}>{t("Members")}</th>
                  <th style={{ width: "10%" }}>{"#"}</th>
                </tr>
              </thead>
              <tbody>
                {aliances.data.map((a, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {/* {a.logo} */}
                        <img
                          style={{ borderRadius: "50%" }}
                          src={`/assets/aliancelogo/fill/${a.logo}`}
                          width={40}
                          alt={t("logo")}
                        />
                      </td>
                      <td>{a.name}</td>
                      <td>
                        {a.countMembers}/{a.totalMembers}
                      </td>
                      <td>
                        <button
                          disabled={
                            (a.countMembers ?? 0) >= (a.totalMembers ?? 0)
                          }
                          className={styles.joinAliance}
                          onClick={() => {
                            if (
                              !((a.countMembers ?? 0) <= (a.totalMembers ?? 0))
                            ) {
                              alert("não pode se juntar")
                            } else {
                              handleRequestAccess(a.id, a.status!)
                            }
                          }}
                        >
                          <GroupAddOutlinedIcon />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {t("Total")}: {aliances.data.length}
          </div>
        </div>
      )}
      {confirmAction && (
        <Modal
          {...modalConfirm}
          icon={<DoneAllIcon fontSize="inherit" className="success" />}
          footer={() => (
            <Button
              color="primary"
              onClick={() => {
                setConfirmAction(false)
              }}
              block
            >
              {t("Ok")}
            </Button>
          )}
          onRequestClose={() => {
            setConfirmAction(false)
          }}
          isOpen
        />
      )}
    </div>
  )
}

export default DisponibleAliances
