import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const useEspionage = () => {
  return useQuery(
    [queryKey.tritium.espionage],
    async () => {
      const { data } = await api.get<Espionage[]>(`api/espionage/list`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}
