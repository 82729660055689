import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { Flex } from "components/layout"
import { Form, FormItem, Input, Submit } from "components/form"
import { Modal } from "components/feedback"
import DefaultLoginTheme from "./DefaultLoginTheme"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { Link, useNavigate } from "react-router-dom"
import styles from "./ForgetPassword.module.scss"
import { forgotPasswordUser } from "data/queries/users"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import { Button } from "components/general"

const ForgetPassword = () => {
  const { t } = useTranslation()

  const [error, setError] = useState<Error>()
  const [success, setSuccess] = useState<boolean>(false)
  const [btnSubmit, setLoading] = useState<boolean>(false)

  const form = useForm<User>({ mode: "onSubmit" })

  const navigate = useNavigate()

  const { handleSubmit, register } = form

  const modalError = !error
    ? undefined
    : {
        title: t("Error"),
        children: (
          <section>
            <aside>
              <Flex>{error.message}</Flex>
            </aside>
          </section>
        ),
      }
  const modalSuccess = !success
    ? undefined
    : {
        title: t("Success"),
        children: (
          <section>
            <section>{t("Sent with success")}</section>
          </section>
        ),
      }
  const submit = async (user: User) => {
    setLoading(true)
    try {
      await forgotPasswordUser(user)
        .then((data) => {
          if (data.data.success) {
            setSuccess(true)
          } else {
            setError(new Error(data.data.message))
          }
        })
        .finally(() => {
          setLoading(false)
        })
      setSuccess(true)
    } catch (error) {
      setError(error as Error)
    }
  }

  function closeModalSuccess() {
    setSuccess(false)
    navigate("/login", { replace: true })
  }

  return (
    <DefaultLoginTheme>
      <div>
        <Form onSubmit={handleSubmit(submit)}>
          <FormItem label="E-mail">
            <Input {...register("email", { required: true })} type="email" />
          </FormItem>
          <Submit submitting={btnSubmit}>{t("Send")}</Submit>
        </Form>
        <div className={styles.extraActions}>
          <Link className={styles.forget} to="/login">
            {"Login"}
          </Link>
        </div>
      </div>
      <div>
        {modalError && (
          <Modal
            {...modalError}
            icon={<ErrorOutlineIcon fontSize="inherit" className="danger" />}
            onRequestClose={() => setError(undefined)}
            isOpen
          />
        )}
        {modalSuccess && (
          <Modal
            {...modalSuccess}
            icon={<DoneAllIcon fontSize="inherit" className="success" />}
            footer={() => (
              <Button color="primary" onClick={closeModalSuccess} block>
                {"Ok"}
              </Button>
            )}
            onRequestClose={() => {
              setSuccess(false)
              navigate("/login")
            }}
            isOpen
          />
        )}
      </div>
    </DefaultLoginTheme>
  )
}

export default ForgetPassword
