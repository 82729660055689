import HeaderIconButton from "../components/HeaderIconButton"
import LogoutIcon from "@mui/icons-material/Logout"
import { logoutUserSrv } from "data/services/authService"

const Logout = () => {
  const handleLogout = () => {
    logoutUserSrv()
      .then(() => {
        window.location.href = "https://play.terratritium.com"
      })
      .catch((error) => {
        console.error("Logout failed:", error)
      })
  }

  return (
    <HeaderIconButton onClick={handleLogout}>
      <LogoutIcon style={{ fontSize: 18, position: "relative", top: 4 }} />
    </HeaderIconButton>
  )
}

export default Logout
