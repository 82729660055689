import { Flex } from "components/layout"
import styles from "./Research.module.scss"
import { useTranslation } from "react-i18next"

interface Props {
  research: Research
  side: string
  onClick?: () => void
  islocked?: boolean
  seletedId: number
  researcheds: Researched[]
}

const ResearchItem = (props: Props) => {
  const { research, seletedId, researcheds } = props
  const { t } = useTranslation()

  function isResearched() {
    let last = false
    researcheds.forEach((res: Researched) => {
      if (research.code === res.code) {
        last = true
      }
    })
    return last
  }

  function getClassEffect() {
    return props.islocked
      ? styles.imgIconLock
      : !isResearched()
      ? seletedId === research.id
        ? styles.imgIconResearchedSeleted
        : styles.imgIconResearched
      : seletedId === research.id
      ? styles.imgIconSelected
      : styles.imgIcon
  }

  return (
    <div
      className={`${styles.item} ${props.islocked ? "" : styles.active}`}
      onClick={props.onClick}
    >
      <Flex>
        {props.side === "left" ? (
          <>
            <div className={styles.elementL}>
              {research.id && (
                <img
                  key={research.id}
                  width={48}
                  className={getClassEffect()}
                  src={`/assets/icons/research${research.id}.svg`}
                  alt={`research${research.id}`}
                />
              )}
              {props.islocked && (
                <div className={styles.lock}>
                  <img width={20} src="/assets/icons/lock.png" alt="lock" />
                </div>
              )}
            </div>
            <div
              className={!props.islocked ? styles.titleL : styles.titleLLock}
            >
              {t(`${research.title}`)}
            </div>
          </>
        ) : (
          <>
            <div className={styles.elementR}>
              {research.code && (
                <img
                  key={research.code}
                  width={48}
                  className={getClassEffect()}
                  src={`/assets/icons/research${research.id}.svg`}
                  alt={`research${research.code}`}
                />
              )}
              {props.islocked && (
                <div className={styles.lock}>
                  <img width={20} src="/assets/icons/lock.png" alt="lock" />
                </div>
              )}
            </div>
            <div className={props.islocked ? styles.titleRLock : styles.titleR}>
              {t(`${research.title}`)}
            </div>
          </>
        )}
      </Flex>
    </div>
  )
}

export default ResearchItem
