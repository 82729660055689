import { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import styles from "./Commanders.module.scss"
import { useResearchList } from "data/queries/research"
import { useResearched } from "data/queries/research"
import { Button } from "components/general"
import { GameContext } from "pages/GameContext"
import { researchBalance } from "data/queries/balance"
import Military from "./resource/Military"
import Economy from "./resource/Economy"
import Science from "./resource/Science"
import { useResearchSpeed } from "utils/hooks/gameModeEffect"

interface Props {
  research: Research[] | undefined
}

interface Option {
  label: string
  value: string
}

const options: Option[] = [
  {
    label: "Military",
    value: "military",
  },
  {
    label: "Economy",
    value: "economy",
  },
  {
    label: "Science",
    value: "science",
  },
]

const ResearchList = ({ research }: Props) => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState("military")

  const { player, planets } = useContext(GameContext)
  const researchSpeed = useResearchSpeed(player?.id ?? "0")
  const researchedList = useResearched()

  const handleOptionClick = (option: string) => {
    setSelectedOption(option)
  }

  function getBalance() {
    return Number(researchBalance(player, planets, researchSpeed))
  }

  return (
    <div>
      <div className={styles.top}>
        {options.map((option) => (
          <Button
            key={option.value}
            className={
              selectedOption === option.value ? styles.active : styles.button
            }
            onClick={() => handleOptionClick(option.value)}
          >
            {t(`${option.label}`)}
          </Button>
        ))}
      </div>

      <div className={styles.points}>
        {t("Research Points")}
        <div>{Number(researchBalance(player, planets, researchSpeed))}</div>
      </div>

      <div className={styles.researchBlock}>
        {selectedOption === "military" && (
          <Military
            research={research}
            researched={researchedList}
            balance={getBalance()}
          />
        )}
        {selectedOption === "economy" && (
          <Economy
            research={research}
            researched={researchedList}
            balance={getBalance()}
          />
        )}
        {selectedOption === "science" && (
          <Science
            research={research}
            researched={researchedList}
            balance={getBalance()}
          />
        )}
      </div>
    </div>
  )
}

const Scientist = () => {
  const research = useResearchList()

  return (
    <div>
      <ResearchList research={research.data} />
    </div>
  )
}

export default Scientist
