import disableScroll from "disable-scroll"
import styles from "./AlianceChat.module.scss"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { LoadingCircular } from "components/feedback"
import ScifiDiv from "components/general/ScifiDiv"
import {
  TRITIUM_CHAT_ALIANCE,
  TRITIUM_MEMBER_DIPLOMAT,
  TRITIUM_MEMBER_FOUNDER,
  TRITIUM_MAX_LENGTH_MSG,
} from "config/constants"
import { trataString } from "utils/validations"
import {
  deleteMessage,
  newMessageChat,
  useGetMessagesGroup,
} from "data/queries/aliance"
import SendIcon from "@mui/icons-material/Send"
import DeleteIcon from "@mui/icons-material/Delete"
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn"
import GroupsIcon from "@mui/icons-material/Groups"
import { dataAtual, getDate, getHours } from "utils/date"
import ChatWithAliance from "./ChatWithAliance"

interface AlianceChatProps {
  player_id: number
  idAliance: number
  idRank: number
  typeChat: number
}
const AlianceChat = (AlianceChatProps: AlianceChatProps) => {
  const { player_id, idAliance, idRank, typeChat } = AlianceChatProps
  const { t } = useTranslation()
  const [messageText, setMessageText] = useState("")
  const [conversation, setConversation] = useState<MessageChatAliance[]>([])
  const { data: messages } = useGetMessagesGroup(idAliance)
  // Quando TRITIUM_CHAT_ALIANCE, indica que a conversa é com os membros da aliança
  //quando nao (TRITIUM_CHAT_OTHER_ALIANCE), conversa com outra aliança
  const [isConversationMyAliance, setConversationMyAliance] = useState(
    typeChat === TRITIUM_CHAT_ALIANCE
  )
  // const aliances = useDisponibleAliances()

  useEffect(() => {
    if (messages) {
      setConversation(messages)
    }
  }, [messages])
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setMessageText(trataString(messageText))
    const conversa = conversation
    const dataAgora = dataAtual()
    const newMessage: MessageChatAliance = {
      idAliance: idAliance,
      idRemetente: player_id,
      message: trataString(messageText),
      status: true,
      createdAt: dataAgora,
      idChatGroup: 1,
      nameRemetente: "Eu",
      sender: true,
    }

    conversa.push(newMessage)
    setConversation(conversa)
    newMessageChat(newMessage).catch((e) => console.log("erro ", e))
    setMessageText("")
  }

  /**chat */
  const [showData, setShowData] = useState<boolean>(false)
  const [longPressTimer, setLongPressTimer] = useState<NodeJS.Timeout | null>(
    null
  )
  useEffect(() => {
    return () => {
      if (longPressTimer) {
        clearTimeout(longPressTimer)
      }
    }
  }, [longPressTimer])

  const handleMouseDown = () => {
    const timer = setTimeout(() => {
      return setShowData(!showData)
    }, 500)
    setLongPressTimer(timer)
  }

  const handleMouseUp = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer)
    }
  }
  const handleDeleteMessage = (idMessage: number) => {
    deleteMessage(idMessage)
      .catch((err) => console.log("erro", err))
      .finally(() => {
        setShowData(false)
        setLongPressTimer(null)
        messages?.forEach((m, i) => {
          if (m.id === idMessage) {
            messages[i].status = false
          }
        })
      })
  }
  const changeConversationMyAliance = () => {
    setConversationMyAliance(!isConversationMyAliance)
  }

  return (
    <>
      {[TRITIUM_MEMBER_DIPLOMAT, TRITIUM_MEMBER_FOUNDER].includes(idRank) && (
        <div
          className={styles.chatWithAliance}
          onClick={() => changeConversationMyAliance()}
        >
          {isConversationMyAliance ? <DataSaverOnIcon /> : <GroupsIcon />}
        </div>
      )}
      {!isConversationMyAliance && (
        <>
          <ChatWithAliance idAliance={idAliance} />
        </>
      )}
      {!conversation && <LoadingCircular />}
      {isConversationMyAliance && (
        <>
          <div className={styles.container}>
            <div className={styles.leftDiv}>
              <div
                className={styles.sendersBox}
                onWheel={(e) => {
                  disableScroll.off()
                }}
                onMouseOut={(e) => {
                  disableScroll.on()
                }}
              >
                {/* {loadUser && <LoadingCircular size={18} />} */}
                <table className={styles.senders}>
                  {
                    // msgPlayerInterations && msgPlayerInterations.length > 0 ? (
                    conversation.map((c, i) => {
                      const horaCriacao: string = c.createdAt?.toString() ?? ""
                      const horaRenderizada: string = getHours(horaCriacao)
                      const dataRenderizacao: string = getDate(horaCriacao)
                      return (
                        <>
                          <tr
                            key={i}
                            draggable={true}
                            onDragStart={(e) => setShowData(!showData)}
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onTouchStart={handleMouseDown}
                            onTouchEnd={handleMouseUp}
                          >
                            <td
                              className={
                                !c.sender
                                  ? styles.msgSended
                                  : styles.msgReceived
                              }
                            >
                              <div>
                                {c.status ? (
                                  <span>{c.message}</span>
                                ) : (
                                  <>
                                    <div className={styles.msgDeleted}>
                                      {t("Message deleted")}
                                    </div>
                                  </>
                                )}
                                <div className={styles.hours}>
                                  {!c.sender && (
                                    <span>{c.nameRemetente} &nbsp;</span>
                                  )}
                                  <span>{horaRenderizada}</span>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                display: `${showData ? "block" : "none"}`,
                              }}
                            >
                              <div className={styles.info}>
                                <div>{dataRenderizacao}</div>
                                {[
                                  TRITIUM_MEMBER_DIPLOMAT,
                                  TRITIUM_MEMBER_FOUNDER,
                                ].includes(idRank) &&
                                  c.status && (
                                    <div
                                      className={styles.iconDel}
                                      onClick={() => handleDeleteMessage(c.id!)}
                                    >
                                      <DeleteIcon fontSize={"small"} />
                                    </div>
                                  )}
                                <div>
                                  {/* {!c.sender && c.readAt && (
                                                            <CheckIcon
                                                                className={styles.checkicon}
                                                                fontSize="small"
                                                            />
                                                        )} */}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    })
                  }
                </table>
              </div>
            </div>
          </div>
          <ScifiDiv>
            <div style={{ width: "275px" }} className="txtsend">
              <div className={styles.inputContainer}>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    value={messageText}
                    maxLength={TRITIUM_MAX_LENGTH_MSG}
                    onChange={(e) => setMessageText(e.target.value)}
                    placeholder={t("Type your message")}
                  />
                  <button>
                    <SendIcon className={`${styles.icon}`} />
                  </button>
                </form>
              </div>
            </div>
          </ScifiDiv>
        </>
      )}
    </>
  )
}

export default AlianceChat

/*


*/
