import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { Button } from "components/general"
import { demolishBuild } from "data/queries/build"

interface Props {
  buildingId: number
  setOpen: any
  setParentOpen: any
  building: Building
}

const Demolish = (props: Props) => {
  const queryClient = useQueryClient()
  const { building } = props

  const { t } = useTranslation()

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <div>
          {t(
            "Are you sure you want to completely demolish this build? this action cannot be undone"
          )}
        </div>
        <br />
        <br />
        <Button
          onClick={() => {
            demolishBuild(building)
            props.setOpen(false)
            props.setParentOpen(false)
            queryClient.invalidateQueries()
          }}
          size="small"
        >
          {t("Confirm")}
        </Button>
      </div>
    </div>
  )
}

export default Demolish
