import styles from "./SendFleet.module.scss"

interface Props {
  fleetUnit: FleetUnit
  isEmpty: any
  setDisabled: any
  getRefInput: any
  idx: number
  fleets: any
  setFleets: any
}

const ShipUnit = (props: Props) => {
  return (
    <>
      <div className={styles.unit}>
        <div className={styles.tagQtd}>{props.fleetUnit.quantity}</div>
        <div
          onClick={() => {
            props.fleets[props.idx] = {
              unit: Number(props.fleetUnit.id),
              quantity: props.fleetUnit.quantity,
            }
            const elementRef = props.getRefInput(props.idx)
            if (elementRef && elementRef.current) {
              elementRef.current.value = String(props.fleetUnit.quantity)
            }
            props.setFleets(props.fleets)
            props.setDisabled(props.isEmpty())
          }}
        >
          <img
            className={styles.unitImg}
            src={"/assets/ships/" + props.fleetUnit.ship.image}
            alt={props.fleetUnit.ship.name}
            draggable="false"
          />
        </div>
        <div className={styles.quantity}>
          <input
            id={props.fleetUnit.id ? props.fleetUnit.id.toString() : ""}
            name={"unit" + props.fleetUnit.id}
            className={styles.inputCustom}
            type="number"
            max={props.fleetUnit.quantity}
            min={0}
            ref={props.getRefInput(props.idx)}
            onChange={(e) => {
              let value = Number(e.target.value)
              if (!(value >= 0 && value <= props.fleetUnit.quantity)) {
                e.target.value = String(props.fleetUnit.quantity)
                value = props.fleetUnit.quantity
              }
              props.fleets[props.idx] = {
                unit: Number(props.fleetUnit.ship.id),
                quantity: value,
              }
              props.setFleets(props.fleets)
              props.setDisabled(props.isEmpty())
            }}
          />
        </div>
      </div>
    </>
  )
}

export default ShipUnit
