import { PropsWithChildren, useContext } from "react"
import { useTranslation } from "react-i18next"
import { Table } from "components/layout"
//import { ScifiButton } from "components/general"
import { GameContext } from "pages/GameContext"
import Upgrade from "../Upgrade"
import ProgressBar from "components/feedback/ProgressBar"
import styles from "./BuildDetail.module.scss"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
}

const Wharehouse = (props: PropsWithChildren<Props>) => {
  const { building, build, setIsOpen } = props
  const { t } = useTranslation()
  const { player, planetData } = useContext(GameContext)

  const planet = planetData instanceof Array ? planetData[0] : planetData

  const metal = planet?.metal ?? 0
  const capMetal = planet?.capMetal ?? 10000

  const crystal = planet?.crystal ?? 0
  const capCrystal = planet?.capCrystal ?? 10000

  const uranium = planet?.uranium ?? 0
  const capUranium = planet?.capUranium ?? 10000

  const calculateProtected = (
    resourceAmount: number,
    capacity: number
  ): number => {
    return Math.min(resourceAmount, capacity)
  }

  const calculateUnprotected = (
    resourceAmount: number,
    capacity: number
  ): number => {
    return Math.max(resourceAmount - capacity, 0)
  }

  const protectedMetal = calculateProtected(metal, capMetal)
  const unprotectedMetal = calculateUnprotected(metal, capMetal)

  const protectedCrystal = calculateProtected(crystal, capCrystal)
  const unprotectedCrystal = calculateUnprotected(crystal, capCrystal)

  const protectedUranium = calculateProtected(uranium, capUranium)
  const unprotectedUranium = calculateUnprotected(uranium, capUranium)

  const resourceData = [
    {
      key: "metal",
      resource: t("Metal"),
      protected: protectedMetal,
      unprotected: unprotectedMetal,
      totalAmount: capMetal,
    },
    {
      key: "crystal",
      resource: t("Crystal"),
      protected: protectedCrystal,
      unprotected: unprotectedCrystal,
      totalAmount: capCrystal,
    },
    {
      key: "uranium",
      resource: t("Uranium"),
      protected: protectedUranium,
      unprotected: unprotectedUranium,
      totalAmount: capUranium,
    },
  ]

  return (
    <>
      <div>
        <span className={styles.level}>{Number(building.level)}</span>
        <span>{t("level")}</span>
      </div>
      <div style={{ display: "flex" }}>
        <div>
          <img
            src={`/assets/builds/build-09.png`}
            alt="Colonization"
            className={styles.buildImage}
            draggable={false}
          />
          <Upgrade setIsOpen={setIsOpen} build={build} building={building} />
        </div>
        <div className={styles.buildContent}>
          {player && (
            <Table
              columns={[
                {
                  key: "resource",
                  title: t("resource"),
                  dataIndex: "resource",
                  align: "left",
                },
                {
                  key: "amount",
                  title: t("protected"),
                  dataIndex: "protected",
                  align: "center",
                },
                {
                  key: "unprotected",
                  title: t("unprotected"),
                  dataIndex: "unprotected",
                  align: "center",
                },
                {
                  key: "totalAmount",
                  title: t("capacity"),
                  dataIndex: "totalAmount",
                  align: "center",
                },
              ]}
              dataSource={resourceData}
              size="small"
            />
          )}
          <div style={{ margin: "5px" }}>
            <div>{t("Metal")}</div>
            <ProgressBar
              filledColor="#fefb53"
              color="#fefb5331"
              size="custom-build"
              currentValue={metal}
              maxValue={capMetal}
              bars={Number(30)}
            />
          </div>
          <div style={{ margin: "5px" }}>
            <div>{t("Crystal")}</div>
            <ProgressBar
              filledColor="#49DBFE"
              color="#49DBFE31"
              size="custom-build"
              currentValue={crystal}
              maxValue={capCrystal}
              bars={Number(30)}
            />
          </div>
          <div style={{ margin: "5px" }}>
            <div>{t("Uranium")}</div>
            <ProgressBar
              filledColor="#FA27FE"
              color="#FA27FE31"
              size="custom-build"
              currentValue={uranium}
              maxValue={capUranium}
              bars={Number(30)}
            />
          </div>
        </div>
      </div>
      {/*<div className={styles.footer}>
        <ScifiButton onClick={() => { }} size="small">
          {t("Save")}
        </ScifiButton>
        </div>*/}
    </>
  )
}

export default Wharehouse
