import { useTranslation } from "react-i18next"
import styles from "./warning.module.scss"

export default function DescriptionAlerts() {
  const { t } = useTranslation()

  return (
    <div className={styles.warning}>
      {t("Please wait...construction is finishing.")}
    </div>
  )
}
