import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { TRITIUM_NFT_ORIGINS } from "config/constants"
import {
  useConnectedWallet,
  useWallet,
  WalletStatus,
  useLcdClient,
} from "@terra-money/wallet-kit"
import { Flex } from "components/layout"
import { nftNameToCode } from "data/nft"
import styles from "./NFTGameMode.module.scss"
import {
  useConnectedWallet as useConnectedWalletGalaxy,
  useWallet as useWalletGalaxy,
  WalletStatus as WalletStatusGalaxy,
  useLcdClient as useLcdClientGalaxy,
} from "@hexxagon/wallet-kit"
import { setUseNFT } from "data/queries/nft"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import { useNFTEffect } from "data/queries/nftEffect"
import PaginationButtons from "components/layout/PaginationButtons"

interface NFTGameModeProps {
  onNftSelect: (nftCode: number) => void
}

const NFTGameMode = ({ onNftSelect }: NFTGameModeProps) => {
  const { t } = useTranslation()
  const { status } = useWallet()
  const { status: statusGalaxy } = useWalletGalaxy()
  const queryClient = useQueryClient()
  const lcd = useLcdClient()
  const lcdGalaxy = useLcdClientGalaxy()

  const [nfts, setNfts] = useState<any>()
  const [nftSelected, setNftSelected] = useState<number>(-1)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const wallet = useConnectedWallet()
  const address = wallet?.addresses["columbus-5"]

  const walletGalaxy = useConnectedWalletGalaxy()
  const addressGalaxy = walletGalaxy?.addresses["columbus-5"]

  const nftEffect = useNFTEffect()

  useEffect(() => {
    if (statusGalaxy === WalletStatusGalaxy.CONNECTED) {
      lcdGalaxy.wasm
        .contractQuery(TRITIUM_NFT_ORIGINS, {
          tokens_with_info: { owner: addressGalaxy },
        })
        .then((data) => {
          setNfts(data)
        })
    }
    if (status === WalletStatus.CONNECTED) {
      lcd.wasm
        .contractQuery(TRITIUM_NFT_ORIGINS, {
          tokens_with_info: { owner: address },
        })
        .then((data) => {
          setNfts(data)
        })
    }
  }, [lcd.wasm, status, address, addressGalaxy, lcdGalaxy.wasm, statusGalaxy])

  function getEffectIdFromNftCode(nftCode: number) {
    if (nftCode >= 1 && nftCode <= 68) {
      return 1
    }
    if (nftCode >= 69 && nftCode <= 98) {
      return 2
    }
    if (nftCode >= 99 && nftCode <= 128) {
      return 3
    }
  }

  function getRarityClass(rarity: number) {
    switch (rarity) {
      case 1:
        return "common"
      case 2:
        return "uncommon"
      case 3:
        return "rare"
      default:
        return ""
    }
  }

  const itemsPerPage = 15
  const showPagination = totalPages > 1

  useEffect(() => {
    if (nfts) {
      setTotalPages(Math.ceil(nfts.tokens.length / itemsPerPage))
    }
  }, [nfts])

  const handlePrev = () => {
    setCurrentPage(currentPage - 1)
  }

  const handleNext = () => {
    setCurrentPage(currentPage + 1)
  }

  const indexOfLastNft = currentPage * itemsPerPage
  const indexOfFirstNft = indexOfLastNft - itemsPerPage
  const currentNfts = nfts
    ? nfts.tokens.slice(indexOfFirstNft, indexOfLastNft)
    : []

  return (
    <>
      <div style={{ display: "flex" }}>
        {status === WalletStatus.NOT_CONNECTED &&
          statusGalaxy === WalletStatusGalaxy.NOT_CONNECTED && (
            <div className={styles.contentInative}>
              <Flex>
                <div className={styles.msg}>
                  {t(
                    "Your wallet is not connected yet, please connect your wallet first"
                  )}
                </div>
              </Flex>
            </div>
          )}
        {(status === WalletStatus.CONNECTED ||
          statusGalaxy === WalletStatusGalaxy.CONNECTED) && (
          <div className={styles.content}>
            <div className={styles.legend}>
              <div className={styles.legendItem}>
                <span className={`${styles.dot} ${styles.commonDot}`}></span>
                <span>COMMON</span>
              </div>
              <div className={styles.legendItem}>
                <span className={`${styles.dot} ${styles.uncommonDot}`}></span>
                <span>UNCOMMON</span>
              </div>
              <div className={styles.legendItem}>
                <span className={`${styles.dot} ${styles.rareDot}`}></span>
                <span>RARE</span>
              </div>
            </div>

            {currentNfts.map((nft: any, key: number) => {
              const nftCode = Number(nftNameToCode(nft.name))
              const currentEffect = nftEffect.data?.find(
                (e) => e.id === getEffectIdFromNftCode(nftCode)
              )
              return (
                <div
                  className={`${styles.imgNFT} ${
                    styles[getRarityClass(nft.rarity)]
                  }`}
                  key={nft.token_id}
                  onClick={() => {
                    setNftSelected(nftCode)
                    setUseNFT(5, nftCode)
                    queryClient.invalidateQueries(queryKey.tritium.NFT.config)
                    onNftSelect(nftCode)
                  }}
                >
                  <img
                    src={`/assets/nft/NFT${nftCode}.jpg`}
                    alt={`NFT ${nft.name}`}
                    className={
                      nftSelected === nft.token_id
                        ? styles.imgNFTSelected
                        : styles.imgNFT
                    }
                  />
                  {currentEffect && (
                    <div
                      className={`${styles.imgNFT} ${
                        styles[getRarityClass(currentEffect.rarity)]
                      }`}
                    ></div>
                  )}
                </div>
              )
            })}

            {currentNfts.length === 0 && (
              <div className={styles.msg}>
                {t("There is no NFT compatible with this game yet")}
              </div>
            )}
          </div>
        )}
        {showPagination && (
          <div className={styles.pagination}>
            <PaginationButtons
              current={currentPage}
              total={totalPages}
              onPrev={currentPage > 1 ? handlePrev : undefined}
              onNext={currentPage < totalPages ? handleNext : undefined}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default NFTGameMode
