import { PropsWithChildren, useContext } from "react"
import { useTranslation } from "react-i18next"
import { ScifiButton } from "components/general"
import { TRITIUM_ENERGY_BASE } from "config/constants"
import { Col, Flex } from "components/layout"
import { GameContext } from "pages/GameContext"
import { getMaxWorkers } from "./service"
import Upgrade from "../Upgrade"
import mp3Click from "sounds/toggle.mp3"
import styles from "./BuildDetail.module.scss"
import ProgressBar from "components/feedback/ProgressBar"
import { usePlanetById } from "data/queries/planet"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
}

const EnergyCollector = (props: PropsWithChildren<Props>) => {
  const { building, build, setIsOpen } = props
  const { t } = useTranslation()
  const planetId = localStorage.getItem("planet")
  const planetData = usePlanetById(planetId || "1")
  const { planets, planet } = useContext(GameContext)

  const planetEnergy =
    planetData instanceof Array ? planetData[0].data : planetData.data

  const soundClick = new Audio(mp3Click)

  return (
    <>
      <div>
        <span className={styles.level}>{Number(building.level)}</span>
        <span>{t("level")}</span>
      </div>
      <div style={{ display: "flex" }}>
        <div>
          <img
            src={`/assets/builds/build-02.png`}
            alt="Colonization"
            className={styles.buildImage}
            draggable={false}
          />
          <Upgrade setIsOpen={setIsOpen} build={build} building={building} />
        </div>
        <div className={styles.buildContent}>
          <div>
            {t("Robots on energy transformation")} {": "}{" "}
            {planets ? planets[0].workersWaiting : 0} {"/"}{" "}
            {planetEnergy && Number(planetEnergy?.workers)}
          </div>
          <div style={{ width: "100%", padding: "20px" }}>
            <Flex>
              <ProgressBar
                currentValue={planets ? planets[0].workersWaiting : 0}
                maxValue={planetEnergy && Number(planetEnergy?.workers)}
                filledColor="#90ee90"
                color="#90ee9033"
                size="large"
                bars={20}
              />
            </Flex>
          </div>
          <br />
          <Col>
            <div>
              {t("Production")}
              {": "}
              <hr />
              <div className={styles.line}>
                {TRITIUM_ENERGY_BASE * getMaxWorkers(planets, building, planet)}
                {" / "}
                {t("hour")}
              </div>
              <div className={styles.line}>
                {TRITIUM_ENERGY_BASE *
                  getMaxWorkers(planets, building, planet) *
                  24}
                {" / "}
                {t("day")}
              </div>
              <div className={styles.line}>
                {TRITIUM_ENERGY_BASE *
                  getMaxWorkers(planets, building, planet) *
                  24 *
                  7}
                {" / "}
                {t("week")}
              </div>
              <div className={styles.line}>
                {TRITIUM_ENERGY_BASE *
                  getMaxWorkers(planets, building, planet) *
                  24 *
                  30}
                {" / "}
                {t("month")}
              </div>
            </div>
          </Col>
        </div>
      </div>
      <div className={styles.footer}>
        <ScifiButton
          onClick={() => {
            soundClick.play()
          }}
          size="small"
        >
          {t("Save")}
        </ScifiButton>
      </div>
    </>
  )
}

export default EnergyCollector
