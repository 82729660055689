import React from "react"
import { useTranslation } from "react-i18next"
import { useState, useContext } from "react"
import { GameContext } from "pages/GameContext"
import { usePlayersRankingByCategory } from "data/queries/ranking"
import { Table } from "components/layout"
import Filter from "components/display/Filter"
import disableScroll from "disable-scroll"
import { ScifiButton } from "components/general"
import styles from "./PlayerRanking.module.scss"
import { Tooltip } from "@mui/material"

interface Props {
  order: string
}
const PlayerRanking = ({ order }: Props) => {
  const { t } = useTranslation()
  const [filterValue, setFilterValue] = useState("")
  const [appliedFilter, setAppliedFilter] = useState("")
  const [page, setPage] = useState(1)

  const { player } = useContext(GameContext)

  const pageSize = 100

  const list = usePlayersRankingByCategory("general", page, order)

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value)
  }

  const handleGoClick = () => {
    setAppliedFilter(filterValue)
  }

  const filteredData = Array.isArray(list.data)
    ? list.data.filter((player) =>
        player.name.toLowerCase().includes(appliedFilter.toLowerCase())
      )
    : []

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const fieldPontuation: any =
    order === "score"
      ? {
          key: "score",
          title: t("score"),
          dataIndex: "score",
          align: "center",
        }
      : {
          key: "energy",
          title: t("energy"),
          dataIndex: "energy",
          align: "center",
        }

  return (
    <div>
      <div className={styles.filter}>
        <Filter
          value={filterValue}
          onChange={handleFilterChange}
          placeholder="Filter by Name"
        />
      </div>
      <div className={styles.go}>
        <ScifiButton onClick={handleGoClick}>{t("GO")}</ScifiButton>
      </div>
      <div
        className={styles.table}
        onMouseOver={() => disableScroll.off()}
        onMouseOut={() => disableScroll.on()}
      >
        <Table
          columns={[
            {
              key: "position",
              title: t("position"),
              align: "center",
              render: (text, record, index) => {
                const originalIndex =
                  list && list.data ? list.data.indexOf(record) : -1
                const position =
                  originalIndex !== -1
                    ? originalIndex + 1
                    : (page - 1) * pageSize + index + 1
                return position
              },
            },
            {
              key: "name",
              title: t("name"),
              dataIndex: "name",
              align: "left",
              render: (name: string) => {
                if (player) {
                  if (name === player.name) {
                    return <div className={styles.destak}>{name}</div>
                  }
                }
                return <>{name}</>
              },
            },
            {
              key: "alianceName",
              title: t("aliance"),
              dataIndex: "alianceName",
              align: "center",
              render: (alianceName: string, record: any) => {
                if (record?.logo) {
                  return (
                    <Tooltip title={alianceName}>
                      <img
                        src={`/assets/aliancelogo/fill/${record?.logo}`}
                        alt={alianceName}
                        className={styles.alianceLogo}
                      />
                    </Tooltip>
                  )
                } else {
                  return <div className={styles.alianceLogo}></div>
                }
              },
            },
            fieldPontuation,
            {
              key: "action",
              title: t("action"),
              dataIndex: "action",
              align: "center",
              render: (text, record) => <a href="_">{}</a>,
            },
          ]}
          dataSource={filteredData}
          size="small"
          pagination={{
            currentPage: page,
            pageSize: pageSize,
            onPageChange: handlePageChange,
          }}
        />
      </div>
    </div>
  )
}

export default PlayerRanking
