import { useQuery } from "react-query"
import { queryKey } from "../query"
import { TRITIUM } from "config/constants"
import { isAuthenticated } from "data/services/authService"
import api from "../services/api"

export const startTravel = (
  action: number,
  from: string | null,
  to: string,
  troop?: TroopUnit[],
  fleet?: FleetUnit[],
  strategy?: number,
  transportShip?: number
) => {
  const finalTroop: TroopUnit[] = []
  const finalFleet: FleetUnit[] = []

  if (troop) {
    troop.forEach((element) => {
      if (element !== null) {
        finalTroop.push(element)
      }
    })
  }

  if (fleet) {
    fleet.forEach((element) => {
      if (element !== null) {
        finalFleet.push(element)
      }
    })
  }

  return api.post(TRITIUM + `/api/travel/start`, {
    action: action,
    from: String(from),
    to: String(to),
    troop: finalTroop,
    fleet: finalFleet,
    strategy: strategy,
    transportShips: transportShip || 0,
  })
}

export const useMissionsAttack = () => {
  return useQuery(
    [queryKey.tritium.travel.attack],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<Travel[]>(`api/travel/missions/1`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useMissionsDefense = () => {
  return useQuery(
    [queryKey.tritium.travel.defense],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<Travel[]>(`api/travel/missions/2`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useMilitarMissions = () => {
  return useQuery(
    [queryKey.tritium.travel.militar],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<Travel[]>(`api/travel/missions/militar`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useColonizeMissions = () => {
  return useQuery(
    [queryKey.tritium.travel.colonize],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<Travel[]>(`api/travel/missions/12`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: Infinity, retry: false } }
  )
}

export const useMissions = (mission: string) => {
  return useQuery(
    [queryKey.tritium.travel.militar],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<Travel[]>(
        `api/travel/missions/${mission}`,
        {
          baseURL: TRITIUM,
        }
      )
      return data
    },
    { ...{ staleTime: 0, retry: false } }
  )
}

export const useCurretMissions = () => {
  return useQuery(
    [queryKey.tritium.travel.transport],
    async () => {
      if (!isAuthenticated()) return []
      const { data } = await api.get<Travel[]>(`api/travel/current`, {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...{ staleTime: 0, retry: false } }
  )
}

export const cancelTravel = (travel: string) => {
  return api.put<Return>(TRITIUM + `/api/travel/cancel/${travel}`)
}

export const sendMissionSpey = (from: string, to: string, type: string) => {
  return api.post<Return>(TRITIUM + `/api/travel/spey`, {
    from: from,
    to: to,
    type: type,
  })
}
