import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import PlanetList from "components/planets/PlanetList"
import QuadrantImage from "styles/images/navigation/quadrant.png"
import styles from "./CentralNavigator.module.scss"

const QuadrantView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [planetList, setPlanetList] = useState(false)

  const quadrant = localStorage.getItem("quadrant")
  const quadrantName = localStorage.getItem("quadrantName")

  return (
    <div className={styles.planetView}>
      <div>
        <img
          onClick={() => {
            if (!document.location.pathname.includes("/game/quadrant/")) {
              navigate("/game/quadrant/" + quadrant)
            }
          }}
          width={72}
          height={72}
          src={QuadrantImage}
          alt="planet"
          draggable={false}
        />
      </div>
      <div className={styles.selectorDescription}>
        <div className={styles.planetName}>{t("Quadrant")}</div>
        <div className={styles.quadrant}>
          {quadrant} {quadrantName}
        </div>
      </div>
      <PlanetList planet={0} open={planetList} setOpen={setPlanetList} />
    </div>
  )
}

export default QuadrantView
