import { ScifiButton } from "components/general"
import styles from "./Colonizer.module.scss"
import { useTranslation } from "react-i18next"
import { colonizerPlanet, useCheckNumberPlanets } from "data/queries/combat"
import { useBuildings } from "data/queries/build"
import { metalBalance } from "data/queries/balance"
import { uraniumBalance } from "data/queries/balance"
import { crystalBalance } from "data/queries/balance"
import { useSpeedMining } from "utils/hooks/gameModeEffect"
import { Row, Col } from "components/layout"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"

interface PropsColonizer {
  setAction: any
  target: string
  planet: Planet
  // setOpen: any
}
const Colonizer = (propsColonizer: PropsColonizer) => {
  const { planet } = propsColonizer

  const builds = useBuildings(planet.id)

  const { t } = useTranslation()
  const { target } = propsColonizer
  let checkNumberPlanets = useCheckNumberPlanets()

  const sppedMining = useSpeedMining((planet && planet.id) || "0")

  let disabled = false

  const handleColonizerPlanet = async () => {
    await colonizerPlanet(target)
      .catch((er) => console.log("err", er))
      .finally(() => propsColonizer.setAction("default"))
  }

  return (
    <>
      <div className={styles.btnColonizer}>
        <div>
          <div className={styles.reqs}>
            <Row>
              <Col>
                <img
                  className={styles.imgResource}
                  key={`lbl-metal`}
                  src={`/assets/icons/resources/tritium.png`}
                  alt={`Metal`}
                />
              </Col>
              <Col>
                <span className={styles.value}>
                  {builds.data && builds.data[0] && checkNumberPlanets.data && (
                    <>
                      {checkNumberPlanets.data.count +
                        "/" +
                        builds.data[0].level}
                    </>
                  )}
                </span>
              </Col>
              <Col>
                <div className={styles.value}>
                  {builds.data &&
                  checkNumberPlanets.data &&
                  (builds.data[0].level || 0) >
                    checkNumberPlanets.data.count ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <>
                      {(disabled = true)}
                      {<CancelIcon color="error" />}
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <img
                  className={styles.imgResource}
                  key={`lbl-metal`}
                  src={`/assets/icons/resources/metal.png`}
                  alt={`Metal`}
                />
              </Col>
              <Col>
                <span className={styles.value}>{"100000"}</span>
              </Col>
              <Col>
                <div className={styles.value}>
                  {planet && (
                    <>
                      {Number(
                        Number(planet.metal) +
                          Number(metalBalance(planet, sppedMining))
                      ) >= 100000 ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <>
                          {(disabled = true)}
                          {<CancelIcon color="error" />}
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <img
                  className={styles.imgResource}
                  key={`lbl-uranium`}
                  src={`/assets/icons/resources/uranium.png`}
                  width={22}
                  alt={`Uranium`}
                />
              </Col>
              <Col>
                <span className={styles.value}>{"50000"}</span>
              </Col>
              <Col>
                <div className={styles.value}>
                  {planet && (
                    <>
                      {Number(uraniumBalance(planet, sppedMining)) >= 50000 ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <>
                          {(disabled = true)}
                          {<CancelIcon color="error" />}
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <img
                  className={styles.imgResource}
                  key={`lbl-crystal`}
                  src={`/assets/icons/resources/crystal.png`}
                  alt={`Crystal`}
                />
              </Col>
              <Col>
                <span className={styles.value}>{"50000"}</span>
              </Col>
              <Col>
                <div className={styles.value}>
                  {planet && (
                    <>
                      {Number(crystalBalance(planet, sppedMining)) >= 50000 ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <>
                          {(disabled = true)}
                          {<CancelIcon color="error" />}
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className={styles.info}>
          {checkNumberPlanets.data && checkNumberPlanets.data.allowed && (
            <div className={styles.fundationDesc}>
              {
                "To this foundation you need to send a quantity of resources that will be used for construction and establishing your new colony, half of these resources are available for initial constructions on the new planet"
              }
            </div>
          )}
        </div>
        <div className={styles.infoNotAllowed}>
          {checkNumberPlanets.data && !checkNumberPlanets.data.allowed && (
            <span>
              {" "}
              {t("You have reached the maximum capacity of planets")}
            </span>
          )}
        </div>
      </div>

      <div className={styles.actions}>
        <ScifiButton
          onClick={() => {
            propsColonizer.setAction("default")
          }}
        >
          {t("Cancel")}
        </ScifiButton>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <ScifiButton
          disabled={disabled}
          onClick={() => handleColonizerPlanet()}
        >
          {t("Colonize Planet")}
        </ScifiButton>
      </div>
    </>
  )
}

export default Colonizer
