import { PropsWithChildren, useState, useContext } from "react"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import { useTranslation } from "react-i18next"
import { configWorker } from "data/queries/build"
import { Row, Col } from "components/layout"
import { ScifiButton } from "components/general"
import { GameContext } from "pages/GameContext"
import { getMaxWorkers, getDisponibleWorkers } from "./service"
import { TRITIUM_CRYSTAL_BASE } from "config/constants"
import CircularSlider from "@fseehawer/react-circular-slider"
import SimpleAlert from "../SimpleAlert"
import Upgrade from "../Upgrade"
import mp3Click from "sounds/toggle.mp3"
import styles from "./BuildDetail.module.scss"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
}

const CrystalMining = (props: PropsWithChildren<Props>) => {
  const queryClient = useQueryClient()
  const { build, building, setIsOpen } = props
  const { t } = useTranslation()
  const { planets, planet } = useContext(GameContext)

  const soundClick = new Audio(mp3Click)

  const [workers, setWorkers] = useState<any>(building.workers)
  const [alert, setAlert] = useState<boolean>(false)

  return (
    <>
      <div>
        <span className={styles.level}>{Number(building.level)}</span>
        <span>{t("level")}</span>
      </div>
      <br />
      <div style={{ display: "flex" }}>
        <div>
          <img
            src={`/assets/builds/build-06.png`}
            alt="Crystal Mining"
            className={styles.buildImage}
            draggable={false}
          />
          <Upgrade setIsOpen={setIsOpen} build={build} building={building} />
        </div>
        <div className={styles.buildContent}>
          <div className={styles.robots}>
            {t("Robots Level " + Number(building.level))}
          </div>
          <Row>
            <div
              style={{ padding: "15px", position: "relative", left: "100px" }}
            >
              <CircularSlider
                width={160}
                min={0}
                max={getMaxWorkers(planets, building, planet)}
                dataIndex={workers}
                label={t("Robots")}
                labelColor={"#f1f1f1"}
                onChange={(value: any) => {
                  setWorkers(value)
                }}
                progressColorFrom="#49DBFE"
                progressColorTo="#49DBFE"
                knobColor="#49DBFE"
                trackColor="#49DBFE34"
                valueFontSize="18px"
                knobPosition="left"
                progressLineCap="flat"
              />
            </div>
          </Row>
          {workers === 0 && (
            <div className={styles.generalAlertSlider}>
              {t(
                "Crystal Mining is deactivated due to lack of robots working."
              )}
            </div>
          )}
          <Row>
            <div>
              {t("Capacity: ")}
              {getMaxWorkers(planets, building, planet)}
              {" | "}
              {t("Available: ")}{" "}
              {Math.max(
                getDisponibleWorkers(planets, building, planet) - workers,
                0
              )}
            </div>
          </Row>
          <br />
          <Col>
            <div>
              {t("Production")}
              {": "}
              <hr />
              <div className={styles.line}>
                {Math.round(TRITIUM_CRYSTAL_BASE * workers)}
                {" / "}
                {t("hour")}
              </div>
              <div className={styles.line}>
                {Math.round(TRITIUM_CRYSTAL_BASE * workers * 24)}
                {" / "}
                {t("day")}
              </div>
              <div className={styles.line}>
                {Math.round(TRITIUM_CRYSTAL_BASE * workers * 24 * 7)}
                {" / "}
                {t("week")}
              </div>
              <div className={styles.line}>
                {Math.round(TRITIUM_CRYSTAL_BASE * workers * 24 * 30)}
                {" / "}
                {t("month")}
              </div>
            </div>
          </Col>
        </div>
      </div>

      <SimpleAlert
        open={alert}
        onClose={() => {
          setAlert(false)
        }}
      />

      <div className={styles.footer}>
        {planets && (
          <ScifiButton
            onClick={() => {
              configWorker({
                planetId: planet,
                buildId: String(build.id),
                buildingId: String(building.id),
                workers: workers,
              })
                .then(() => {
                  setWorkers(workers)
                  building.workers = Number(workers)
                  setAlert(true)
                  queryClient.invalidateQueries(queryKey.tritium.planet.planets)
                  queryClient.invalidateQueries(queryKey.tritium.planet.planet)
                  soundClick.play()
                })
                .catch((error) => {
                  console.error("Erro ao configurar trabalhadores:", error)
                })
            }}
            size="small"
          >
            {t("Save")}
          </ScifiButton>
        )}
      </div>
    </>
  )
}

export default CrystalMining
