import { PropsWithChildren } from "react"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import styles from "./SideModal.module.scss"

interface Props {
  title?: string
  open: boolean
  setOpen: any
  resetSelect?: any
}

const SideModal = (props: PropsWithChildren<Props>) => {
  const { children, open, setOpen, resetSelect } = props

  return (
    <>
      <SwipeableDrawer
        PaperProps={{
          sx: {
            height: "700px",
            width: "780px",
            background: "transparent",
            position: "absolute",
            top: "210px",
            clipPath: `polygon(
              0 16px,
              0 584px,
              16px 600px,
              784px 600px,
              784px 0,
              16px 0
            )`,
          },
        }}
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false)
          if (resetSelect) {
            resetSelect(null)
          }
        }}
        onOpen={() => {}}
      >
        <div className={styles.content}>{children}</div>
        <div className={styles.bar}></div>
      </SwipeableDrawer>
    </>
  )
}

export default SideModal
