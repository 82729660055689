import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { Form, FormItem, Input, Submit } from "components/form"
import { ScifiModal } from "components/feedback"
import { Link } from "react-router-dom"
import { resetPasswordUser } from "data/queries/users"
import { Button } from "components/general"
import { useParams } from "react-router-dom"
import DefaultLoginTheme from "./DefaultLoginTheme"
import styles from "./Login.module.scss"

const FormResetPassword = () => {
  const { t } = useTranslation()

  const [error, setError] = useState<Error>()
  const [success, setSuccess] = useState<boolean>(false)
  const form = useForm<ResetPassword>({ mode: "onSubmit" })

  const { handleSubmit, register } = form

  const [btnSubmit, setLoading] = useState<boolean>(false)

  const { email, token } = useParams()

  const modalError = !error
    ? undefined
    : {
        title: t("Error"),
        children: (
          <section>
            <div className={styles.message}>{error.message}</div>
          </section>
        ),
      }
  const modalSuccess = !success
    ? undefined
    : {
        children: (
          <div className={styles.message}>
            <section>{t("Password reseted with successfully")}</section>
          </div>
        ),
      }

  const submit = async (reset: ResetPassword) => {
    setLoading(true)
    reset.token = token || ""
    reset.email = email || ""
    await resetPasswordUser(reset)
      .then((data) => {
        if (data.data.success) {
          setSuccess(true)
        } else {
          setError(new Error(data.data.message))
        }
      })
      .catch((error) => {
        setError(new Error(error.response.data.message.password[0]))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function closeModalSuccess() {
    setSuccess(false)
  }

  return (
    <DefaultLoginTheme>
      <div>
        <Form onSubmit={handleSubmit(submit)}>
          <FormItem label={t("New Password")}>
            <Input
              {...register("password", { required: true })}
              type="password"
            />
          </FormItem>
          <FormItem label={t("Confirm New Password")}>
            <Input
              {...register("password_confirmation", { required: true })}
              type="password"
            />
          </FormItem>
          <Submit submitting={btnSubmit}>{t("Send")}</Submit>
        </Form>
        <Link to="/login" className={styles.register}>
          {t("Login")}
        </Link>
        {" :: "}
        <Link className={styles.forget} to="/forget-password">
          {t("Forget Password")}
        </Link>
      </div>
      <div>
        {modalError && (
          <ScifiModal
            {...modalError}
            onRequestClose={() => setError(undefined)}
            isOpen
          />
        )}
        {modalSuccess && (
          <ScifiModal
            {...modalSuccess}
            footer={() => (
              <Button color="primary" onClick={closeModalSuccess} block>
                {t("Ok")}
              </Button>
            )}
            onRequestClose={() => {
              setSuccess(false)
            }}
            isOpen
          />
        )}
      </div>
    </DefaultLoginTheme>
  )
}

export default FormResetPassword
