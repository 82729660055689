import { useQuery } from "react-query"
import { queryKey, RefetchOptions } from "../query"
import { TRITIUM } from "config/constants"
import api from "../services/api"

export const useCountrys = () => {
  return useQuery(
    [queryKey.tritium.country.countrys],
    async () => {
      const { data } = await api.get<Country[]>("api/country/list", {
        baseURL: TRITIUM,
      })
      return data
    },
    { ...RefetchOptions.DEFAULT }
  )
}
