import React, { useState } from "react"
import { styled, alpha } from "@mui/material/styles"
import Button from "@mui/material/Button"
import Menu, { MenuProps } from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { useCountrys } from "data/queries/country"
import disableScroll from "disable-scroll"
import styles from "./CustomSelect.module.scss"
import { useTranslation } from "react-i18next"

interface Country {
  id: string
  name: string
  image: string
}

interface CustomSelectProps {
  register: any
  onSelectCountry: (country: Country) => void
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.5)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 30,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

export default function CustomSelect({
  register,
  onSelectCountry,
}: CustomSelectProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const countrys = useCountrys()
  const { t } = useTranslation()

  const handleCountrySelect = (country: Country) => {
    setSelectedCountry(country)
    handleClose()
    register("country")
    onSelectCountry(country)
  }
  return (
    <div className={styles.menuWrapper}>
      <Button
        onClick={handleClick}
        style={{
          width: "100%",
          backgroundColor: "#111111",
          color: "white",
          justifyContent: "flex-start",
          textAlign: "start",
        }}
      >
        {selectedCountry ? (
          <>
            <img
              src={`/assets/countrys/${selectedCountry.image}`}
              alt={selectedCountry.name}
              className={styles.flagIcon}
            />
            <span className={styles.menuItemText}>{selectedCountry.name}</span>
          </>
        ) : (
          t("Select Country")
        )}
        <ArrowDropDownIcon className={styles.arrow} />
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "480px",
            backgroundColor: "#111111",
            color: "white",
            maxHeight: "300px",
          },
        }}
        onWheel={() => {
          disableScroll.off()
        }}
        onMouseOut={() => {
          disableScroll.on()
        }}
      >
        {countrys.data &&
          countrys.data.map(({ id, name, code, image }) => (
            <MenuItem
              key={id}
              value={id}
              className={styles.menuItem}
              onClick={() => {
                id && image && handleCountrySelect({ id, name, image })
              }}
            >
              <>
                <img
                  src={`/assets/countrys/${image}`}
                  alt={name}
                  className={styles.flagIcon}
                />
                <span className={styles.menuItemText}>{name}</span>
              </>
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  )
}
