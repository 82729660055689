import { Row, Col } from "components/layout"
import { useState, useEffect } from "react"
import styles from "./HistoryTrading.module.scss"
import { cancelOrder, getHistory } from "data/queries/market"
import disableScroll from "disable-scroll"
import { t } from "i18next"
import { LoadingCircular, Warning } from "components/feedback"

interface Props {
  resource: string | undefined
}
const HistoryTrading = (HistoryProps: Props) => {
  const planetId = localStorage.getItem("planet") ?? "0"

  const [activeIndex, setActiveIndex] = useState<number | string | null>(null)
  const [historyTrading, setHistoryTrading] = useState<HistoryTrading[]>([])
  const [showLoad, setShowLoad] = useState(true)

  const handleAccordionClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  useEffect(() => {
    if (HistoryProps.resource) {
      fetchHistory(HistoryProps.resource!, planetId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HistoryProps.resource, planetId])

  const fetchHistory = (resource: string, planetId: string) => {
    setShowLoad(true)
    getHistory(resource, planetId)
      .then((res) => {
        setHistoryTrading(res.data)
      })
      .catch((e) => console.log(e))
      .finally(() => setShowLoad(false))
    handleAccordionClick(-1)
  }
  const getDate = (date: string): string => {
    if (!date.length) return "-"
    const newDate: Date = new Date(date)
    const dia = newDate.getDate().toString().padStart(2, "0")
    const mes = (newDate.getMonth() + 1).toString().padStart(2, "0")
    const ano = newDate.getFullYear().toString().slice(-2)
    return `${dia}/${mes}/${ano}`
  }

  const cancelarOrdem = async (id: number) => {
    await cancelOrder(id, planetId)
      .then((res) => console.log("ok"))
      .catch((e) => console.log("err", e))
    fetchHistory(HistoryProps.resource!, planetId)
    handleAccordionClick(-1)
  }

  return (
    <>
      {showLoad && <LoadingCircular style={{ color: "#5850B4" }} />}
      {!showLoad && (
        <div>
          <Row>
            <Col>
              <div
                className={styles.hsAccordion}
                onWheel={(e) => {
                  disableScroll.off()
                }}
                onMouseOut={(e) => {
                  disableScroll.on()
                }}
              >
                {!historyTrading || historyTrading.length === 0 ? (
                  <Warning msg="No transaction history" />
                ) : (
                  <>
                    {Array.isArray(historyTrading) &&
                      historyTrading.map((history, index) => {
                        return (
                          <>
                            <div
                              className={`accordion-item ${
                                activeIndex === index ? "active" : ""
                              }`}
                              key={history.id}
                            >
                              <div
                                className="accordion-header"
                                key={index}
                                onClick={() => {
                                  handleAccordionClick(index)
                                }}
                              >
                                <div className={styles.hsContentHeader}>
                                  <div>
                                    {history.type === "S"
                                      ? `${t("Sale")}`
                                      : `${t("Purchase")}`}
                                  </div>

                                  <div className="">{history.total}</div>
                                  <div>
                                    {history.statusTrading === 1 &&
                                      `${t("Open")}`}
                                    {history.statusTrading === 3 &&
                                      `${t("Pending")}`}

                                    {history.statusTrading === 0 &&
                                      `${t("Canceled")}`}
                                    {history.statusTrading === 2 &&
                                      `${t("Finished")}`}
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-content">
                                <div className={styles.hsAccordionContentBody}>
                                  <div className={styles.hsDiv}>
                                    <div className={styles.leftDiv}>
                                      <span>
                                        {`${t("Quantity")}:`}{" "}
                                        <span> {history.quantity}</span>
                                      </span>
                                    </div>
                                    <div className={styles.rightDiv}>
                                      <span>
                                        {`${t("Unit Price")}:`}{" "}
                                        <span>{history.price}</span>
                                      </span>
                                    </div>
                                  </div>
                                  <div className={styles.hsDiv}>
                                    <div className={styles.leftDiv}>
                                      <span>
                                        {`${t("Opening")}: `}
                                        <span>
                                          {getDate(
                                            history.createdAt?.toString() ?? ""
                                          )}
                                        </span>
                                      </span>
                                    </div>
                                    <div className={styles.rightDiv}>
                                      <span>
                                        {`${t("Update")}: `}
                                        <span>
                                          {getDate(
                                            history.updatedAt?.toString() ?? ""
                                          )}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <div className={styles.hsDiv}>
                                    <div className={styles.leftDiv}>
                                      <span>
                                        {`${t("Resource")}:`}{" "}
                                        <span>{history.resource}</span>
                                      </span>
                                    </div>
                                    <div className={styles.rightDiv}>
                                      <span>
                                        {`${t("Delivered")}: `}
                                        <span>
                                          {history.statusFinished === 1
                                            ? `${t("Yes")}`
                                            : `${t("No")}`}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <Row>
                                    <div>
                                      {history.statusFinished !== 1 &&
                                        history.statusTrading === 1 && (
                                          <>
                                            <div
                                              className={styles.hsLinkCancelar}
                                              onClick={(e) =>
                                                cancelarOrdem(history.id!)
                                              }
                                            >
                                              {`${t("Cancel Order")}`}
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default HistoryTrading
