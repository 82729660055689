import { useState, useEffect } from "react"
import mp3Text from "sounds/type.mp3"
import styles from "./AnimateText.module.scss"

interface Props {
  text: string
}

const AnimateText = (props: Props) => {
  const { text } = props

  const soundText = useState(new Audio(mp3Text))

  const [txt, setTxt] = useState<string>("")
  const speed = 100

  const autoTyping = (txt: string, cont: number = 0) => {
    const promissePlay = soundText[0].play()

    if (promissePlay !== undefined) {
      promissePlay.then((_) => {}).catch((error) => {})
    }

    if (txt && cont < txt.length) {
      setTxt(text.slice(0, cont + 1))
      setTimeout(() => autoTyping(txt, cont + 1), speed)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      autoTyping(text)
    }, 1200)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.text}>
      {txt}
      <span className={styles.cursor}>{"|"}</span>
    </div>
  )
}

export default AnimateText
