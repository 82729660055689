import { PropsWithChildren } from "react"
import styles from "./ScifiDiv.module.scss"

interface Props {
  title?: string
}

const ScifiDiv = (props: PropsWithChildren<Props>) => {
  const { children } = props

  return (
    <div className={styles.siDivScifi}>
      <span>{children}</span>
    </div>
  )
}

export default ScifiDiv
