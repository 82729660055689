import styles from "./Trading.module.scss"
import Purchaser from "./list/Purchaser"
import Sellers from "./list/Sellers"

interface TradingProps {
  typeItem: number | undefined
  changeScreen: (newScreen: number) => void
}
const Trading = (TradingProps: TradingProps) => {
  return (
    <>
      <div className={styles.containerTrading}>
        <div className={styles.tradingLeftDiv}>
          <Sellers
            typeItem={TradingProps.typeItem}
            changeScreen={TradingProps.changeScreen}
          />
        </div>
        <div className={styles.tradingRightDiv}>
          <Purchaser
            typeItem={TradingProps.typeItem}
            changeScreen={TradingProps.changeScreen}
          />
        </div>
      </div>
    </>
  )
}

export default Trading
