import { useEffect, useState } from "react"

import { Card, Col, Flex, Row } from "components/layout"

import { useTranslation } from "react-i18next"
import { TRITIUM_SCREEN_TRADING } from "config/constants"

import { getMyResources, tradingNewPurch } from "data/queries/market"
import { ScifiModal } from "components/feedback"
import { ScifiButton } from "components/general"
import styles from "./FormMarket.module.scss"

interface Props {
  type: string
  resource: string | undefined
  changeScreen: (newScreen: number) => void
}
const NewPurchase = (NewPurch: Props) => {
  const { t } = useTranslation()
  const { resource } = NewPurch
  // const [resourceInfo, setMyResourceInfo] = useState<ResourcesInfo>()
  // const [quantityResource, setQuantityResource] = useState(0)
  const [disabled, setDisabled] = useState<boolean>(false)
  const planetId = localStorage.getItem("planet") ?? "0"

  /*form*/
  const [quantidade, setQuantidade] = useState<number>(0)
  const [precoUnitario, setPrecoUnitario] = useState<number>(0)

  const calcularPrecoTotal = () => {
    if (precoUnitario * quantidade <= 0) {
      setError(new Error())
    } else {
      try {
        tradingNewPurch({
          resource: NewPurch.resource,
          quantity: quantidade,
          unitityPrice: precoUnitario,
          total: precoUnitario * quantidade,
          type: "P",
          origin: planetId,
        })
          .then((res) => {
            setSuccess(true)
          })
          .catch((e) => {
            setError(e as Error)
          })
      } catch (error) {
        console.error("erro", error)
        setError(error as Error)
      }
    }
  }

  const calcularPrecoTotalUp = () => {
    const total = quantidade * precoUnitario
    return total.toFixed(2)
  }
  /*fim form*/

  const [error, setError] = useState<Error>()
  const [success, setSuccess] = useState<boolean>(false)

  const modalError = !error
    ? undefined
    : {
        children: (
          <section>
            <aside>
              <Flex>
                <div className={styles.error}>
                  {t("Order registration failed!")}
                </div>
              </Flex>
            </aside>
          </section>
        ),
      }

  const modalSuccess = !success
    ? undefined
    : {
        children: (
          <section>
            <section>
              <div className={styles.sucess}>
                {t("New order successfully registered!")}
              </div>
            </section>
          </section>
        ),
      }

  useEffect(() => {
    getMyResources().then((res) => {
      // setMyResourceInfo(res.data)
    })
  }, [])

  useEffect(() => {
    let total = 0
    if (
      quantidade &&
      !isNaN(quantidade) &&
      precoUnitario &&
      !isNaN(precoUnitario)
    ) {
      total = quantidade * precoUnitario
    }
    setDisabled(total <= 0 ? true : false)
  }, [precoUnitario, quantidade])

  return (
    <>
      {
        <Row>
          <Col>
            <Card>
              <form>
                <div className={styles.myResource}>
                  <span>{t("Desired Resource")} - </span>
                  <span className={styles.myResourceQtd}>{resource} </span>
                </div>

                <Col>
                  <label className={styles.label} htmlFor="quantidade">
                    {t("Desired Quantity")}
                  </label>
                  <input
                    type="text"
                    name="quantidade"
                    className={styles.myResource}
                    id="quantidade"
                    pattern="^[0-9]+$"
                    value={quantidade !== null ? quantidade : ""}
                    onChange={(event) => {
                      const value = (event.target as HTMLInputElement).value
                      if (value === "") {
                        setQuantidade(0)
                      } else {
                        const parsedValue = parseInt(value, 10)
                        if (!isNaN(parsedValue)) {
                          setQuantidade(parsedValue)
                        }
                      }
                    }}
                    required
                  />
                </Col>
                <Row>
                  <Col>
                    <label className={styles.label} htmlFor="preco-unitario">
                      {t("Unit Price")}: ({t("Energy")})
                    </label>
                    <input
                      type="text"
                      name="preco-unitario"
                      id="preco-unitario"
                      className={styles.myResource}
                      step="1"
                      pattern="^[0-9]+$"
                      value={precoUnitario !== null ? precoUnitario : ""}
                      onChange={(event) => {
                        const value = (event.target as HTMLInputElement).value
                        if (value === "") {
                          setPrecoUnitario(0)
                        } else {
                          const parsedValue = parseInt(value, 10)
                          if (!isNaN(parsedValue)) {
                            setPrecoUnitario(parsedValue)
                          }
                        }
                      }}
                      required
                    />
                  </Col>
                  <Col>
                    <label className={styles.label} htmlFor="preco-total">
                      {t("Total")}:
                    </label>
                    <input
                      type="text"
                      name="preco-total"
                      id="preco-total"
                      className={styles.myResource}
                      // step="0.01"
                      // value={precoTotal}
                      value={calcularPrecoTotalUp()}
                      readOnly
                    />
                  </Col>
                </Row>
                <Col>
                  <label className={styles.label} htmlFor="btn"></label>

                  <button
                    className={styles.myResource}
                    disabled={disabled}
                    type="button"
                    onClick={calcularPrecoTotal}
                  >
                    {t("Place Order")}
                  </button>
                </Col>
                {/* <Submit submitting={btnSubmit}>
                  {t("Register new Order")}
                </Submit> */}
              </form>
              {/* </Form> */}
            </Card>
          </Col>
        </Row>
      }
      {modalError && (
        <ScifiModal
          {...modalError}
          footer={() => (
            <div className={styles.button}>
              <ScifiButton
                color="primary"
                onClick={() => {
                  setSuccess(false)
                  NewPurch.changeScreen(TRITIUM_SCREEN_TRADING)
                }}
                block
              >
                {t("Ok")}
              </ScifiButton>
            </div>
          )}
          onRequestClose={() => {
            setError(undefined)
            NewPurch.changeScreen(TRITIUM_SCREEN_TRADING)
          }}
          isOpen
        />
      )}
      {modalSuccess && (
        <ScifiModal
          {...modalSuccess}
          footer={() => (
            <div className={styles.button}>
              <ScifiButton
                color="primary"
                onClick={() => {
                  setSuccess(false)
                  NewPurch.changeScreen(TRITIUM_SCREEN_TRADING)
                }}
                block
              >
                {t("Ok")}
              </ScifiButton>
            </div>
          )}
          onRequestClose={() => {
            setSuccess(false)
            NewPurch.changeScreen(TRITIUM_SCREEN_TRADING)
          }}
          isOpen
        />
      )}
    </>
  )
}

export default NewPurchase
