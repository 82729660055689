import { useState, useEffect } from "react"
import { Timer } from "components/display"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import AnimeConstruct1 from "styles/images/animations/construct1.webp"
import AnimeConstruct2 from "styles/images/animations/construct2.webp"
import AnimeConstruct3 from "styles/images/animations/construct3.webp"
import styles from "./Structures.module.scss"

interface Props {
  ready: number
  image: string
  code: number
}

const UnderConstruction = (props: Props) => {
  const { ready, image, code } = props

  const [animationFase, setAnimationFase] = useState<number>(1)
  const [endTimer, setEndTimer] = useState<boolean>(false)

  const queryClient = useQueryClient()

  useEffect(() => {
    const clock = ready * 1000 - new Date().getTime()

    setTimeout(() => {
      setAnimationFase(2)
    }, 1700)

    setTimeout(() => {
      setAnimationFase(3)
    }, clock - 800)

    setTimeout(() => {
      setEndTimer(true)
    }, clock - 300)

    setTimeout(() => {
      queryClient.invalidateQueries(queryKey.tritium.building.buildings)
    }, clock + 500)
  }, [animationFase, queryClient, ready])

  return (
    <>
      <img
        className={
          code === 1
            ? styles.underconstructionSpecial
            : code === 12
            ? styles.underconstructionShield
            : styles.underconstruction
        }
        src={
          animationFase === 1
            ? AnimeConstruct1
            : animationFase === 2
            ? AnimeConstruct2
            : AnimeConstruct3
        }
        alt={image}
        draggable={false}
      />
      <div
        className={
          endTimer
            ? styles.timerEnd
            : code === 1
            ? styles.timerSpecial
            : code === 12
            ? styles.timerShield
            : styles.timer
        }
      >
        <Timer end={ready * 1000} />
      </div>
    </>
  )
}

export default UnderConstruction
