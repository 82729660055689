import { TRITIUM_ENERGY_BASE } from "config/constants"
import { TRITIUM_METAL_BASE } from "config/constants"
import { TRITIUM_URANIUM_BASE } from "config/constants"
import { TRITIUM_CRYSTAL_BASE } from "config/constants"
import { TRITIUM_RESEARCH_SPEED } from "config/constants"
import { calcBaseMining, calcSpeedResearch } from "utils/effect"
// p1.timeMetal, p1.metal, TRITIUM_METAL_BASE, p1.pwMetal

const balance = (
  time: Number | undefined,
  base: Number | undefined,
  minerBase: number,
  power: Number | undefined,
  percentSpeedMining: number = 0
) => {
  const hourMining = (Number(Date.now()) - Number(time) * 1000) / 3600000

  const score = Number(
    Number(base) +
      hourMining * calcBaseMining(minerBase, percentSpeedMining) * Number(power)
  ).toFixed(0)

  return Number(power) === 0 || Number(score) <= 0 ? base : score
}

export const researchBalance = (
  player: Player | undefined,
  planets: Planet[] | undefined,
  percentResearchSpeed: number
) => {
  let points = 0
  let researchTime = 0

  if (player) {
    points += player.researchPoints
  }

  player &&
    planets &&
    planets.forEach((planet) => {
      researchTime =
        Math.floor(new Date().getTime() / 1000) - Number(planet.timeResearch)
      points +=
        planet.pwResearch *
        (calcSpeedResearch(TRITIUM_RESEARCH_SPEED, percentResearchSpeed) /
          1000) *
        researchTime
    })
  return points.toFixed(0)
}

export const tritiumBalance = (
  player: Player | undefined,
  planets: Planet[] | undefined
) => {
  return 0
}

export const energyBalance = (p1: Planet) => {
  const padronizedLevel = 10 * Number(p1.pwEnergy)
  let fator = 1
  if (p1.timeEnergy === null) {
    return 0
  }
  if (padronizedLevel < p1.workersWaiting) {
    fator = padronizedLevel
  } else {
    fator = p1.workersWaiting
  }
  return balance(p1.timeEnergy, p1.energy, TRITIUM_ENERGY_BASE, fator)
}

export const metalBalance = (p1: Planet, percentSpeedMining: number) => {
  return balance(
    p1.timeMetal,
    p1.metal,
    TRITIUM_METAL_BASE,
    p1.pwMetal,
    percentSpeedMining
  )
}

export const uraniumBalance = (p1: Planet, percentSpeedMining: number) => {
  return balance(
    p1.timeUranium,
    p1.uranium,
    TRITIUM_URANIUM_BASE,
    p1.pwUranium,
    percentSpeedMining
  )
}

export const crystalBalance = (p1: Planet, percentSpeedMining: number) => {
  return balance(
    p1.timeCrystal,
    p1.crystal,
    TRITIUM_CRYSTAL_BASE,
    p1.pwCrystal,
    percentSpeedMining
  )
}

export const workerBalance = (p1: Planet) => {
  return p1.workers
}

export const hasFunds = (
  p1: Planet,
  require: Require | undefined,
  percentSpeedMining: number | 0
) => {
  if (require !== undefined) {
    if (Number(metalBalance(p1, percentSpeedMining)) < Number(require.metal)) {
      return false
    }
    if (
      Number(uraniumBalance(p1, percentSpeedMining)) < Number(require.uranium)
    ) {
      return false
    }
    if (
      Number(crystalBalance(p1, percentSpeedMining)) < Number(require.crystal)
    ) {
      return false
    }
    return true
  } else {
    return false
  }
}
