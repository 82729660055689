import { PropsWithChildren, useState, useContext, useEffect } from "react"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import { GameContext } from "pages/GameContext"
import { useTranslation } from "react-i18next"
import { ScifiButton } from "components/general"
import { energyBalance } from "data/queries/balance"
import { metalBalance } from "data/queries/balance"
import { Slider } from "@mui/material"
import { usePlanetById } from "data/queries/planet"
import { createHumanoid } from "data/queries/factory"
import { TRITIUM_HUMANOID_PRICE } from "config/constants"
import { Tooltip } from "@mui/material"
import SimpleAlert from "../SimpleAlert"
import Upgrade from "../Upgrade"
import ProgressBar from "components/feedback/ProgressBar"
import { Col, Row } from "components/layout"
import { useBuildings } from "data/queries/build"
import { useSpeedMining } from "utils/hooks/gameModeEffect"
import styles from "./BuildDetail.module.scss"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
}

const HumanoidFactory = (props: PropsWithChildren<Props>) => {
  const queryClient = useQueryClient()
  const { building, build, setIsOpen } = props
  const { planet } = useContext(GameContext)
  const { t } = useTranslation()

  const selectedPlanet = usePlanetById(planet)
  const selectedBuldings = useBuildings(planet)

  const [alert, setAlert] = useState<boolean>(false)
  const [worker, setWorker] = useState<number>(0)
  const [maxHumanoids, setMaxHumanoids] = useState<number>(10)
  const speedMining = useSpeedMining(planet)

  const handleSliderChange = (event: Event, value: number | number[]) => {
    if (!(selectedPlanet.data instanceof Array) && selectedPlanet.data) {
      const numWorkers = Array.isArray(value) ? value[0] : value
      const energy = Number(energyBalance(selectedPlanet.data))
      const metal = Number(metalBalance(selectedPlanet.data, speedMining))
      const maxWorkersBasedOnEnergy = Math.floor(
        energy / TRITIUM_HUMANOID_PRICE
      )
      const maxWorkersBasedOnMetal = Math.floor(metal / TRITIUM_HUMANOID_PRICE)
      const maxWorkers = Math.min(
        maxWorkersBasedOnEnergy,
        maxWorkersBasedOnMetal
      )
      setWorker(Math.min(numWorkers, maxWorkers))
    }
  }

  const calculatePrice = (numWorkers: number) => {
    return TRITIUM_HUMANOID_PRICE * (numWorkers > 0 ? numWorkers : 1)
  }

  const planetData =
    selectedPlanet instanceof Array
      ? selectedPlanet[0].data
      : selectedPlanet.data

  const initialValue = 30

  const canCreate = maxHumanoids * Number(building.level)

  const MaxbyLevel = initialValue + canCreate

  const currentRobots = planet && Number(planetData.workers)

  const disableButton =
    !selectedPlanet.data ||
    selectedPlanet.data instanceof Array ||
    Number(energyBalance(selectedPlanet.data)) <= TRITIUM_HUMANOID_PRICE ||
    Number(metalBalance(selectedPlanet.data, speedMining)) <=
      TRITIUM_HUMANOID_PRICE ||
    MaxbyLevel - Number(currentRobots) <= 0

  useEffect(() => {
    if (selectedBuldings.data) {
      const building = selectedBuldings.data.find((b) => b.code === 3)
      if (building) {
        const newMaxHumanoids = Number(building.max_humanoids)
        setMaxHumanoids((prevMax) =>
          Math.min(
            newMaxHumanoids,
            initialValue + newMaxHumanoids * Number(building.level)
          )
        )
      }
    }
  }, [selectedBuldings.data, building.level, initialValue])

  function formatNumber(value: number) {
    if (value >= 1000000000) {
      const billions = value / 1000000000
      return (Math.floor(billions * 100) / 100).toFixed(2) + "B"
    } else if (value >= 1000000) {
      const millions = value / 1000000
      return (Math.floor(millions * 100) / 100).toFixed(2) + "M"
    } else if (value >= 1000) {
      const thousands = value / 1000
      return (Math.floor(thousands * 100) / 100).toFixed(2) + "k"
    } else {
      return value.toString()
    }
  }

  return (
    <>
      <div>
        <span className={styles.level}>{Number(building.level)}</span>
        <span>{t("level")}</span>
      </div>
      <div>
        {selectedPlanet.data && !(selectedPlanet.data instanceof Array) && (
          <>
            {Number(energyBalance(selectedPlanet.data)) <
              TRITIUM_HUMANOID_PRICE &&
            Number(metalBalance(selectedPlanet.data, speedMining)) <
              TRITIUM_HUMANOID_PRICE ? (
              <div className={styles.generalAlert}>
                {t("No Metal and Energy available")}
              </div>
            ) : Number(energyBalance(selectedPlanet.data)) <
              TRITIUM_HUMANOID_PRICE ? (
              <Tooltip
                title={t(
                  "You need to upgrade the Energy Collector to capture more energy, increasing your energy reserve"
                )}
              >
                <div className={styles.generalAlert}>
                  <>{t("No Energy available")}</>
                </div>
              </Tooltip>
            ) : Number(metalBalance(selectedPlanet.data, speedMining)) <
              TRITIUM_HUMANOID_PRICE ? (
              <Tooltip
                title={t(
                  "You need to upgrade the Metal Mining to capture more metal, increasing your metal reserve"
                )}
              >
                <div className={styles.generalAlert}>
                  {t("No Metal available")}
                </div>
              </Tooltip>
            ) : null}
          </>
        )}

        <div style={{ display: "flex" }}>
          <div>
            <img
              src={`/assets/builds/build-03.png`}
              alt="Colonization"
              className={styles.buildImage}
              draggable={false}
            />
            <Upgrade setIsOpen={setIsOpen} build={build} building={building} />
          </div>
          <div className={styles.buildContent}>
            {selectedPlanet.data &&
              !(selectedPlanet.data instanceof Array) &&
              Number(energyBalance(selectedPlanet.data)) > 0 && (
                <>
                  <div className={styles.tip}>
                    <div className={styles.label}>
                      <img
                        src="/assets/icons/resources/energy.png"
                        alt="energy"
                        style={{ width: "30px" }}
                      />
                      {formatNumber(calculatePrice(worker))}
                    </div>
                    <span className={styles.stats}>
                      <div
                        style={{
                          position: "relative",
                          right: "100px",
                          top: "10px",
                        }}
                      >
                        <ProgressBar
                          filledColor="#90ee90"
                          color="#90ee9033"
                          size="custom-build"
                          currentValue={Number(
                            energyBalance(selectedPlanet.data)
                          )}
                          maxValue={TRITIUM_HUMANOID_PRICE}
                          bars={Number(5)}
                        />
                      </div>
                      <div className={styles.priceLabel}>
                        {t("Available: ")}
                        {formatNumber(
                          Math.floor(
                            Math.max(
                              Number(energyBalance(selectedPlanet.data)) -
                                worker * TRITIUM_HUMANOID_PRICE,
                              0
                            )
                          )
                        )}
                      </div>
                    </span>
                  </div>
                  <div className={styles.tipMetal}>
                    <div className={styles.label}>
                      <img
                        src="/assets/icons/resources/metal.png"
                        alt="metal"
                        style={{ width: "30px" }}
                      />
                      {formatNumber(calculatePrice(worker))}
                    </div>
                    <span className={styles.stats}>
                      <div
                        style={{
                          position: "relative",
                          right: "110px",
                          top: "10px",
                        }}
                      >
                        <ProgressBar
                          filledColor="#fefb53"
                          color="#fefb5331"
                          size="custom-build"
                          currentValue={Number(
                            metalBalance(selectedPlanet.data, speedMining)
                          )}
                          maxValue={TRITIUM_HUMANOID_PRICE}
                          bars={Number(5)}
                        />
                      </div>
                      <div className={styles.priceLabel}>
                        {t("Available: ")}
                        {formatNumber(
                          Math.floor(
                            Math.max(
                              Number(
                                metalBalance(selectedPlanet.data, speedMining)
                              ) -
                                worker * TRITIUM_HUMANOID_PRICE,
                              0
                            )
                          )
                        )}
                      </div>
                    </span>
                  </div>
                  <div className={styles.box}>
                    {t("Humanoid Robots")}
                    <Slider
                      min={0}
                      max={MaxbyLevel - Number(currentRobots)}
                      defaultValue={2}
                      value={worker}
                      onChange={handleSliderChange}
                      valueLabelDisplay="auto"
                      sx={{
                        color: "#5850B4",
                        "& .MuiSlider-thumb": {
                          borderRadius: "1px",
                        },
                        "& .MuiSlider-rail": {
                          borderRadius: "0px",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <input
                      className={styles.input}
                      type="number"
                      value={worker}
                      onChange={(value: any) => {
                        setWorker(value)
                      }}
                    />
                  </div>
                  <br />
                  <Row>
                    <Col>
                      <div>
                        <div className={styles.specialTitle}>
                          {t("ROBOTS ON DUTY")}
                        </div>
                        <hr />
                        <div className={styles.lineDetails}>
                          <span className={styles.tag}>{currentRobots}</span>
                          {"/"}
                          <span className={styles.tag}>{MaxbyLevel}</span>
                          {t("Factory Capacity")}
                          <br />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <div>
                        <div className={styles.specialTitle}>
                          {t("POPULATION")}
                        </div>
                        <hr />
                        <div className={styles.lineDetails}>
                          <span className={styles.tag}>
                            {planet &&
                              Math.round(Number(planetData.workersWaiting))}
                          </span>
                          {t("Robots on Energy Collect")}
                          <br />
                        </div>
                        <div className={styles.lineDetails}>
                          <span className={styles.tag}>
                            {planet &&
                              Math.round(Number(planetData.workersOnMetal))}
                          </span>
                          {t("Robots on Metal Miner")}
                          <br />
                        </div>
                        <div className={styles.lineDetails}>
                          <span className={styles.tag}>
                            {planet &&
                              Math.round(Number(planetData.workersOnUranium))}
                          </span>
                          {t("Robots on Uranium Miner")}
                        </div>
                        <div className={styles.lineDetails}>
                          <span className={styles.tag}>
                            {planet &&
                              Math.round(Number(planetData.workersOnCrystal))}
                          </span>
                          {t("Robots on Crystal Miner")}
                          <br />
                        </div>
                        <div className={styles.lineDetails}>
                          <span className={styles.tag}>
                            {planet &&
                              Math.round(
                                Number(planetData.workersOnLaboratory)
                              )}
                          </span>
                          {t("Robots on Laboratory")}
                          <br />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <ScifiButton
          onClick={() => {
            createHumanoid(Number(planet), worker)
              .then(() => {
                queryClient.invalidateQueries(queryKey.tritium.planet.planet)
                queryClient.invalidateQueries(queryKey.tritium.planet.planets)
                queryClient.invalidateQueries(
                  queryKey.tritium.building.buildings
                )
                setWorker(0)
                setAlert(true)
              })
              .catch((error) => {
                console.error("Erro ao criar Humanoid:", error)
              })
          }}
          size="small"
          disabled={disableButton}
        >
          {t("Production")}
        </ScifiButton>
      </div>
      <SimpleAlert
        open={alert}
        onClose={() => {
          setAlert(false)
        }}
      />
    </>
  )
}

export default HumanoidFactory
