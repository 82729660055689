import { useState } from "react"
import { region } from "data/regions"
import { useTranslation } from "react-i18next"
import PlanetList from "components/planets/PlanetList"
import styles from "./CentralNavigator.module.scss"

interface Props {
  region: string | null
}

const RegionView = (props: Props) => {
  const { t } = useTranslation()

  const regionName = region(props.region || "A")

  const regionCode = String(regionName).charAt(0)

  const [planetList, setPlanetList] = useState(false)

  return (
    <div className={styles.planetView}>
      <div>
        <img
          onClick={() => {
            if (!document.location.pathname.includes("/game/galaxy/")) {
              document.location.href = "/game/galaxy/" + regionCode
            }
          }}
          width={72}
          height={72}
          src={"/assets/icons/regions/" + regionCode + ".png"}
          alt="planet"
          draggable={false}
        />
      </div>
      <div className={styles.selectorDescription}>
        <div className={styles.planetName}>{t("Region")}</div>
        <div className={styles.quadrant}>{regionName}</div>
      </div>
      <PlanetList planet={0} open={planetList} setOpen={setPlanetList} />
    </div>
  )
}

export default RegionView
