import { Html } from "@react-three/drei"
import { usePlayer } from "data/queries/player"
import { usePlanetsByQuadrant } from "data/queries/planet"
import styles from "./Dimension.module.scss"

interface Props {
  quadrant: string
}

const PlanetLabels = (props: Props) => {
  const labels: any = []

  const player = usePlayer()

  const planets = usePlanetsByQuadrant(props.quadrant)
  const pos = [
    { x: 0, y: 0, z: 0, s: 6 }, // 0
    { x: 49, y: -27, z: 0, s: 2.4 }, // 1
    { x: 56, y: 18.2, z: 0, s: 2 }, // 2
    { x: 10, y: 14, z: 0, s: 2.5 }, // 3
    { x: 40, y: 0, z: 0, s: 2 }, // 4
    { x: -11, y: -30, z: 0, s: 2 }, // 5
    { x: -41, y: 22, z: 0, s: 2.5 }, // 6
    { x: 15, y: -21, z: 0, s: 2 }, // 7
    { x: -16, y: 9.2, z: 0, s: 1.8 }, // 8
    { x: -24, y: -13.2, z: 0, s: 2 }, // 9
    { x: -60, y: 0, z: 0, s: 2.5 }, // 10
    { x: 23, y: 14, z: 0, s: 2.5 }, // 11
    { x: -2, y: 32, z: 0, s: 2 }, // 12
    { x: -1, y: -16, z: 0, s: 2 }, // 13
    { x: -40, y: -4.4, z: 0, s: 1.8 }, // 14
    { x: -46, y: -16, z: 0, s: 2 }, // 15
    { x: 60, y: 0, z: 0, s: 1.5 }, // 16
  ]

  for (let idx = 1; idx < 17; idx++) {
    player.data &&
      planets.data &&
      labels.push(
        <Html
          zIndexRange={[100, 0]}
          key={idx}
          position={[pos[idx].x, pos[idx].y - 3, 0]}
          className={styles.label}
        >
          {planets.data[idx] !== undefined && (
            <span
              className={
                planets.data[idx].player === Number(player.data.id)
                  ? styles.myPlanetLabel
                  : styles.otherPlanetLabel
              }
            >
              {!planets.data[idx].player ? (
                <span
                  className={
                    planets.data[idx].yellowTrit > 0
                      ? styles.yellowTritOn
                      : styles.explored
                  }
                >
                  {"Unexplored"}
                </span>
              ) : (
                planets.data[idx].name
              )}
            </span>
          )}
        </Html>
      )
  }

  return labels
}

export default PlanetLabels
