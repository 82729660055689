import { useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "./Mercenary.module.scss"
import { ScifiButton } from "components/general"
import { RadioButton } from "components/form"
import { useCaluleDestiny } from "data/queries/quadrant"
import { useAvailableResources } from "data/queries/combat"
import { sendMissionSpey } from "data/queries/travel"
import { convertToClock } from "utils/time"
import { TRITIUM_TRAVEL_MISSION_SPIONAGE_COST } from "config/constants"
import { Alert, AlertTitle, Stack } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

interface SpyOnPros {
  destinationId: string
  setAction: any
}

const SpyOn = (props: SpyOnPros) => {
  let { destinationId } = props
  const { t } = useTranslation()
  const [error, setError] = useState("")
  const [alerta, setAlerta] = useState("")
  const [typeSelected, setTypeSelected] = useState("1")
  const planetId = localStorage.getItem("planet") ?? "0"
  const distacyPlanet = useCaluleDestiny(planetId, destinationId)
  const destiny = useAvailableResources(planetId)

  const resetTable = (order: string) => {
    setTypeSelected(order)
  }

  const closeModal = () => {
    setAlerta("")
    setError("")
  }

  const sendMissionSpy = () => {
    sendMissionSpey(planetId, destinationId, typeSelected)
      .then((res) => {
        if (res.data.success) {
          setAlerta("success")
        } else {
          setAlerta("error")
          setError(res.data.message)
        }
      })
      .catch((er) => {
        setAlerta("error")
        setError(er.message)
      })
  }

  const distanceTime =
    distacyPlanet?.data?.distance === undefined
      ? 0
      : distacyPlanet?.data?.distance

  return (
    <>
      <div className={styles.resource}>
        {alerta && (
          <>
            <Stack sx={{ width: "62%" }} spacing={1}>
              <Alert severity={alerta === "error" ? "error" : "success"}>
                <AlertTitle>
                  {alerta === "error" ? t("Error") : t("Success")}
                </AlertTitle>
                <div
                  style={{
                    marginLeft: "550px",
                    marginTop: "-32px",
                    cursor: "pointer",
                  }}
                  onClick={() => closeModal()}
                >
                  <CloseIcon fontSize="small" />
                </div>
                {/* {t(`You do not have a sufficient quantity of cargo ships`)} */}
                {alerta === "success" ? t("Send successfully") : t(`${error}`)}
              </Alert>
            </Stack>
            <br />
          </>
        )}
      </div>
      <div className={styles.options}>
        <h1>{t("Espionage mission objective")}</h1>
        <RadioButton
          value="1"
          alt="Level Research"
          onClick={(e) => {
            resetTable(e.currentTarget.value)
          }}
          checked={typeSelected === "1" || null ? true : false}
        >
          Level Research
        </RadioButton>
        <RadioButton
          value="4"
          alt="ships"
          onClick={(e) => {
            resetTable(e.currentTarget.value)
          }}
          checked={typeSelected === "4" || null ? true : false}
        >
          How many ships
        </RadioButton>
        <RadioButton
          value="3"
          alt="Humanoid"
          onClick={(e) => {
            resetTable(e.currentTarget.value)
          }}
          checked={typeSelected === "3" || null ? true : false}
        >
          How many humanoid
        </RadioButton>
        <RadioButton
          value="2"
          alt="How many resources  (metal, uranim and crystal)"
          onClick={(e) => {
            resetTable(e.currentTarget.value)
          }}
          checked={typeSelected === "2" || null ? true : false}
        >
          Resources
        </RadioButton>
        <div className={styles.lineDetails}>
          {t("Travel duration ")} {": "} {convertToClock(distanceTime, 1)}
          <br />
          {t("Destiny")} {":"} {destiny.data?.quadrant}:{destiny.data?.name}
          <br />
          {t("Cost")} {":"} {TRITIUM_TRAVEL_MISSION_SPIONAGE_COST} Tritium
        </div>
      </div>

      <div className={styles.actions}>
        <ScifiButton
          onClick={() => {
            props.setAction("default")
          }}
        >
          {t("Cancel")}
        </ScifiButton>
        <ScifiButton
          onClick={() => {
            sendMissionSpy()
          }}
        >
          {t("Send")}
        </ScifiButton>
      </div>
    </>
  )
}

export default SpyOn
