import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useEspionage } from "data/queries/espionage"
import { Timer } from "components/display"
import styles from "./Mercenary.module.scss"
import { Alert, AlertTitle, Stack } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { ScifiButton } from "components/general"

const Espionage = () => {
  const { t } = useTranslation()
  const espionages = useEspionage()
  const [error, setError] = useState<"">()
  const [alerta, setAlerta] = useState("")
  const [espionage, setEspionage] = useState<Espionage>()
  const [show, setShow] = useState<boolean>(false)

  const closeModal = () => {
    setAlerta("")
    setError("")
  }

  const showEspionede = (espionage: Espionage) => {
    setEspionage(espionage)
    setShow(!show)
  }

  return (
    <div>
      <div>
        {alerta && (
          <>
            <Stack sx={{ width: "100%" }} spacing={1}>
              <Alert severity={alerta === "error" ? "error" : "success"}>
                <AlertTitle>
                  {alerta === "error" ? t("Error") : t("Success")}
                </AlertTitle>
                <div
                  style={{
                    marginLeft: "438px",
                    marginTop: "-32px",
                    cursor: "pointer",
                  }}
                  onClick={() => closeModal()}
                >
                  <CloseIcon fontSize="small" />
                </div>
                {/* {t(`You do not have a sufficient quantity of cargo ships`)} */}
                {alerta === "success"
                  ? t("Travel canceled successfully")
                  : t(`${error}`)}
              </Alert>
            </Stack>
            <br />
          </>
        )}
      </div>
      <div className={styles.content}>
        {!show ? (
          <div className={styles.tableMissions}>
            <table>
              <thead>
                <tr>
                  <th>{t("Planet")}</th>
                  <th>{t("Success")}</th>
                  <th>{t("Arrival")}</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(espionages.data) && espionages.data
                  ? espionages.data.map((espionage: Espionage) => (
                      <tr key={espionage.id}>
                        <td>
                          <span className={styles.address}>
                            {`${espionage.name} : ${espionage.quadrant}`}
                          </span>
                        </td>
                        <td>
                          <span className={styles.address}>
                            {espionage.success
                              ? t("Yes")
                              : !espionage.success && !espionage.finished
                              ? "-"
                              : t("Not")}
                          </span>
                        </td>
                        <td>
                          <span className={styles.address}>
                            <Timer end={espionage.arrival * 1000} />
                          </span>
                        </td>
                        <td>
                          <ScifiButton
                            onClick={() => {
                              showEspionede(espionage)
                            }}
                          >
                            {t("Show")}
                          </ScifiButton>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <div>
              <div className={styles.load}>
                {t("Planet")} : {`${espionage?.name} : ${espionage?.quadrant}`}
                <br />
                {t("Success")} {": "}{" "}
                {espionage?.success
                  ? t("Yes")
                  : !espionage?.success && !espionage?.finished
                  ? "-"
                  : t("Not")}
                <br />
                {espionage?.typeSpy === 1 && (
                  <>
                    {t("Level Research")} {": "}
                    <br />- Military : {espionage?.military}
                    <br />- Economy :{espionage?.economy}
                    <br />- Science : {espionage?.science}
                  </>
                )}
                {espionage?.typeSpy === 4 && (
                  <>
                    {t("Ships")} {": "}
                    <br></br>
                    {Array.isArray(espionages.data)
                      ? JSON.parse(espionage?.fleet || "[{}]").map(
                          (fle: Ship) => (
                            <span key={fle?.id}>
                              - {fle?.name} : {fle?.quantity} <br></br>
                            </span>
                          )
                        )
                      : null}
                  </>
                )}
                {espionage?.typeSpy === 3 && (
                  <>
                    {t("Humanoid")} {": "} <br />
                    {Array.isArray(espionages.data)
                      ? JSON.parse(espionage?.troop || "[{}]").map(
                          (uni: Unit) => (
                            <span key={uni?.id}>
                              - {uni?.name} : {uni?.quantity} <br></br>
                            </span>
                          )
                        )
                      : null}
                  </>
                )}
                {espionage?.typeSpy === 2 && (
                  <>
                    {t("Resouces")} {": "}
                    <br />- Metal : {espionage?.metal}
                    <br />- Crystal :{espionage?.crystal}
                    <br />- Uranimum : {espionage?.uranium}
                  </>
                )}
              </div>
            </div>
            <div className={styles.actions}>
              <ScifiButton
                onClick={() => {
                  setShow(false)
                }}
              >
                {t("Back")}
              </ScifiButton>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Espionage
