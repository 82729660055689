import { Tabs } from "components/layout"
import styles from "./SettingsModal.module.scss"
import LanguageSetting from "./LanguageSetting"
import { useTranslation } from "react-i18next"
import { Toggle } from "components/form"
import StarWarp from "components/comp3d/StarWarp"
import { Canvas } from "@react-three/fiber"
import { useEffect, useState } from "react"

const SettingsModalContent = () => {
  const { t } = useTranslation()

  const [power, setPower] = useState<boolean>(
    localStorage.getItem("power") === "true"
  )

  useEffect(() => {
    localStorage.setItem("power", power.toString())
  }, [power])

  const handleToggleChange = () => {
    setPower((prevPower) => !prevPower)
  }

  const tabs = [
    {
      key: "languages",
      tab: "Languages",
      children: (
        <div className={styles.tabLanguage}>
          <LanguageSetting />
        </div>
      ),
    },
    {
      key: "effects",
      tab: "Effects",
      children: (
        <div className={styles.Toggle}>
          <Toggle checked={power} onChange={handleToggleChange}>
            {t("Power On/Off")}
          </Toggle>
        </div>
      ),
    },
  ]

  return (
    <div>
      <Tabs tabs={tabs} type="card" />
      <Canvas>
        <StarWarp isActive={power} />
      </Canvas>
    </div>
  )
}

export default SettingsModalContent
