import React from "react"
import styles from "./Filter.module.scss"

interface FilterProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

const Filter: React.FC<FilterProps> = ({
  value,
  onChange,
  placeholder = "Filter",
}) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={styles.filter}
    />
  )
}

export default Filter
