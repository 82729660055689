import { useEffect, useState } from "react"
import Refresh from "app/sections/Refresh"
import Settings from "app/sections/Settings"
import Docs from "app/sections/Docs"
import Perfil from "app/sections/Perfil"
import CentralNavigator from "components/navigation/CentralNavigator"
import {
  energyBalance,
  metalBalance,
  uraniumBalance,
  crystalBalance,
} from "data/queries/balance"
import { usePlanetById } from "data/queries/planet"
import ProgressBar from "components/feedback/ProgressBar"
import { TRITIUM_GALAXY } from "config/constants"
import { useTranslation } from "react-i18next"
import { ReactComponent as ControlShape } from "styles/images/elements/control-shape.svg"
import { usePlayersRanking } from "data/queries/ranking"
import Ranking from "components/ranking/Ranking"
import NFTPanel from "components/nft/NFTPanel"
import Tooltip from "@mui/material/Tooltip"
import { Conversation } from "app/sections/Conversation"
import WalletConnect from "app/sections/WalletConnect"
import { ScifiModal } from "../../components/feedback"
import RankingModal from "../../components/ranking/RankingModal"
import TritiumDisplay from "components/tritium/tritium"
import { useSpeedMining } from "utils/hooks/gameModeEffect"
import styles from "./ControlPanel.module.scss"
import { Toggle } from "components/form"
import BackgroundAudio from "pages/BackgroundAudio"

const ControlPanel = () => {
  const planetId = localStorage.getItem("planet")
  const planetData = usePlanetById(planetId || "1")
  const players = usePlayersRanking()
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const [isAudioPlaying, setIsAudioPlaying] = useState(() => {
    const localStorageValue = localStorage.getItem("audioPlaying")
    return localStorageValue ? localStorageValue === "true" : true
  })

  const planet =
    planetData instanceof Array ? planetData[0].data : planetData.data

  const speedMining = useSpeedMining(planetId ?? "0")

  function formatNumber(value: number) {
    if (value >= 1000000000) {
      const billions = value / 1000000000
      return (Math.floor(billions * 100) / 100).toFixed(2) + "B"
    } else if (value >= 1000000) {
      const millions = value / 1000000
      return (Math.floor(millions * 100) / 100).toFixed(2) + "M"
    } else if (value >= 1000) {
      const thousands = value / 1000
      return (Math.floor(thousands * 100) / 100).toFixed(2) + "k"
    } else {
      return value.toString()
    }
  }

  function safeConvertToNumber(
    value: string | Number | undefined,
    defaultValue: number = 0
  ): number {
    if (typeof value === "string") {
      return parseFloat(value)
    } else if (typeof value === "number") {
      return value
    } else if (value === undefined) {
      return defaultValue
    } else {
      throw new Error("Invalid input value")
    }
  }

  useEffect(() => {
    localStorage.setItem("audioPlaying", isAudioPlaying.toString())
  }, [isAudioPlaying])

  const handleToggleAudio = () => {
    setIsAudioPlaying(!isAudioPlaying)
  }

  return (
    <div className={styles.control}>
      <ControlShape className={`${styles.shape}  controlPanel`} />
      <div className={`${styles.galaxyName} controlPanel-left1`}>
        {TRITIUM_GALAXY}
      </div>
      {planet && (
        <div className={`${styles.resources} controlPanel-left`}>
          <div className={styles.resourceBlock}>
            <Tooltip className={styles.resourceIco} title={t("Metal")}>
              <div>
                <img src="/assets/icons/resources/metal.png" alt={t("Metal")} />
              </div>
            </Tooltip>
            <div className={styles.countResource}>
              <>
                {formatNumber(
                  safeConvertToNumber(metalBalance(planet, speedMining))
                )}
              </>
            </div>
            <ProgressBar
              filledColor="#FFC100"
              color="#FFC100"
              size="small"
              currentValue={Number(metalBalance(planet, speedMining))}
              maxValue={planet.capMetal}
              bars={Number(15)}
            />
          </div>
          <div className={styles.resourceBlock}>
            <Tooltip className={styles.resourceIco} title={t("Uranium")}>
              <div className={styles.resourceIco}>
                <img
                  src="/assets/icons/resources/uranium.png"
                  alt={t("Uranium")}
                />
              </div>
            </Tooltip>
            <div className={styles.countResource}>
              <>
                {formatNumber(
                  safeConvertToNumber(uraniumBalance(planet, speedMining))
                )}
              </>
            </div>
            <ProgressBar
              filledColor="#C806DD"
              color="#C806DD"
              size="small"
              currentValue={Number(uraniumBalance(planet, speedMining))}
              maxValue={planet.capUranium}
              bars={Number(15)}
            />
          </div>
          <div className={styles.resourceBlock}>
            <Tooltip className={styles.resourceIco} title={t("Crystal")}>
              <div className={styles.resourceIco}>
                <img
                  src="/assets/icons/resources/crystal.png"
                  alt={t("Crystal")}
                />
              </div>
            </Tooltip>
            <div className={styles.countResource}>
              <>
                {formatNumber(
                  safeConvertToNumber(crystalBalance(planet, speedMining))
                )}
              </>
            </div>
            <ProgressBar
              filledColor="#00A7F5"
              color="#00A7F5"
              size="small"
              currentValue={Number(crystalBalance(planet, speedMining))}
              maxValue={planet.capCrystal}
              bars={Number(15)}
            />
          </div>
        </div>
      )}
      <div className={`${styles.tritiumBlock} controlPanel-left2`}>
        <div className={styles.specialTitle}>{t("POPULATION")}</div>
        <Tooltip
          title={
            <>
              {t("Available Robots")} {": "}
              {planet && Number(planet.workersWaiting)}
              <br />
              {t("Robots on metal miner")} {": "}
              {planet && Number(planet.workersOnMetal)}
              <br />
              {t("Robots on uranium miner")} {": "}
              {planet && Number(planet.workersOnUranium)}
              <br />
              {t("Robots on crystal miner")} {": "}
              {planet && Number(planet.workersOnCrystal)}
              <br />
              {t("Robots on laboratory")} {": "}
              {planet && Number(planet.workersOnLaboratory)}
            </>
          }
        >
          <div className={styles.specialIco}>
            <img
              src="/assets/icons/resources/population.png"
              width={60}
              alt={t("Population")}
            />
          </div>
        </Tooltip>
        <div className={styles.specialCounter}>
          {planet && Number(planet.workers)}
        </div>
      </div>
      {planet && (
        <div className={`${styles.energyBlock} controlPanel-left3`}>
          <div className={styles.specialTitle}>{t("ENERGY")}</div>
          <Tooltip title={t("Energy")}>
            <div className={styles.specialIco}>
              <img
                src="/assets/icons/resources/energy.png"
                width={60}
                alt={t("Energy")}
              />
            </div>
          </Tooltip>
          <div className={styles.specialCounter}>
            <>{formatNumber(safeConvertToNumber(energyBalance(planet)))}</>
          </div>
        </div>
      )}
      <div className={styles.centralNav}>
        <CentralNavigator />
      </div>
      <div className={styles.specialTitleRanking}>{t("RANKING")}</div>
      <div
        className={`${styles.ranking} controlPanel-right`}
        onClick={() => {
          setOpen(true)
        }}
      >
        {players.data && <Ranking ranking={players.data} />}
        <div className={styles.rankingMore}>{t("SHOW ALL")}</div>
      </div>
      <div className={`${styles.tritium} controlPanel-right1`}>
        <TritiumDisplay />
      </div>
      <div style={{ width: "130px" }}></div>
      <div className={`${styles.nft} controlPanel-right2`}>
        <NFTPanel />
      </div>
      <div className={styles.perfil}>
        <Perfil />
      </div>
      <div className={styles.web3control}>
        <Refresh />
        <Settings />
        <Docs />
        <Conversation openModal={null} />
        <div className={styles.toggle}>
          <Toggle checked={isAudioPlaying} onChange={handleToggleAudio}>
            {t("Audio")}
            <BackgroundAudio isActive={isAudioPlaying} />
          </Toggle>
        </div>
      </div>
      <div className={styles.connectWallet}>
        <WalletConnect />
      </div>
      <ScifiModal
        title={t("Ranking")}
        isOpen={open}
        onRequestClose={() => {
          setOpen(false)
        }}
      >
        <RankingModal />
      </ScifiModal>
    </div>
  )
}

export default ControlPanel
