import { useEffect, useState } from "react"
import styles from "./Sellers.module.scss"
import {
  TRITIUM_SCREEN_FINISH_ORDER,
  TRITIUM_TYPE_CRYSTAL,
} from "config/constants"
import disableScroll from "disable-scroll"
import { getAllTradingResourceByMarket } from "data/queries/market"
import { LoadingCircular, Warning } from "components/feedback"
import { Table } from "components/layout"
import { useTranslation } from "react-i18next"
import { Tooltip } from "@mui/material"

interface SellersProps {
  typeItem: number | undefined
  changeScreen: (newScreen: number, idTrading?: number) => void
}
const Sellers = (SellersProps: SellersProps) => {
  const [vendedoresArr, setVendedoresArr] = useState<Trading[]>([])
  const planetId = parseInt(localStorage.getItem("planet") ?? "0")
  const [showLoad, setShowLoad] = useState(true)
  /**
   * @TODO refazer esse useffect apos ligar com o back-end
   */
  useEffect(() => {
    fetchVendedores()

    // @todo ajustar as dependencias e remover o lint disable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SellersProps.typeItem])

  const fetchVendedores = () => {
    setShowLoad(true)
    getAllTradingResourceByMarket(
      planetId,
      SellersProps.typeItem ?? TRITIUM_TYPE_CRYSTAL,
      "S"
    )
      .then((res) => {
        setVendedoresArr(Array.isArray(res.data) ? res.data : [])
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setShowLoad(false)
      })
  }

  // refactor
  const [colunaOrdem, setColunaOrdem] = useState("")
  const [ordem, setOrdem] = useState("asc")

  const requestSort = (coluna: keyof Trading) => {
    setColunaOrdem(coluna)
    const newDirection =
      coluna === colunaOrdem && ordem === "asc" ? "desc" : "asc"
    const newList = [...vendedoresArr].sort((a, b) => {
      const aValue = a[coluna] || 0
      const bValue = b[coluna] || 0
      const comparacao = aValue - bValue
      return newDirection === "asc" ? comparacao : -comparacao
    })
    setVendedoresArr(newList)
    setOrdem(newDirection)
  }
  const selectedRow = (id: number, idPlanetCreator: number) => {
    if (idPlanetCreator !== planetId) {
      SellersProps.changeScreen(TRITIUM_SCREEN_FINISH_ORDER, id)
      // console.log(`selecionou a linha ${id}`)
    }
  }
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const handlePageChange = (page: number) => {
    setPage(page)
  }
  const filteredData = vendedoresArr

  return (
    <>
      {showLoad && <LoadingCircular style={{ color: "#5850B4" }} />}
      {!showLoad && (
        <>
          <div className={styles.tbTitle}>{t("Sales")}</div>
          <div className={styles.tbSellerTitles}>
            <Tooltip title={t("Unit price")}>
              <div
                style={{ width: "40%" }}
                onClick={() => requestSort("price")}
              >
                {t("Price")}
              </div>
            </Tooltip>
            <Tooltip title={t("Astronomical Unit")}>
              <div
                style={{ width: "30%" }}
                onClick={() => requestSort("distance")}
              >
                <>{t("Distance")}</>
              </div>
            </Tooltip>

            <div style={{ width: "30%" }} onClick={() => requestSort("total")}>
              {t("Total")}
            </div>
          </div>
          <div
            className={styles.containerSeller}
            onMouseOver={() => disableScroll.off()}
            onMouseOut={() => disableScroll.on()}
          >
            {!vendedoresArr || vendedoresArr.length === 0 ? (
              <>
                <Warning msg="No sell orders registered" />
              </>
            ) : (
              <>
                <div className={styles.tbSeller}>
                  <Table
                    columns={[
                      {
                        key: "price",
                        dataIndex: "price",
                        align: "left",
                        render: (price: number) => {
                          return <>{price.toFixed(2)}</>
                        },
                      },
                      {
                        key: "distance",
                        dataIndex: "distance",
                        align: "left",
                        render: (distance: number) => {
                          return (
                            <Tooltip title={t("Astronomical Unit")}>
                              <>{distance}</>
                            </Tooltip>
                          )
                        },
                      },
                      {
                        key: "total",
                        dataIndex: "total",
                        align: "center",
                      },
                    ]}
                    dataSource={filteredData}
                    size="small"
                    pagination={{
                      currentPage: page,
                      pageSize: page,
                      onPageChange: handlePageChange,
                    }}
                    onRowClick={(data) =>
                      selectedRow(data.id!, data.idPlanetCreator!)
                    }
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}
export default Sellers
